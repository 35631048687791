import BaseURL from "../api-common";
import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";

export async function getAllUsers(endUserSession) {
    try {
        let url = BaseURL.getBaseURL() + "/userManagement/getAllUsers";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });
        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The user get request is invalid.");
        }
        return res.data.allUsers;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        The users could not be obtained
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function addUser(endUserSession, data) {
    try {
        let url = BaseURL.getBaseURL() + "/userManagement/createUser";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The user add request is invalid.");
        } else if (!res.data.isInserted) {
            throw new Error("The user could not be inserted into the database");
        }
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        The user could not be added.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function deleteUser(endUserSession, id) {
    try {
        let url = BaseURL.getBaseURL() + "/userManagement/deleteUser";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: { _id: id }
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The user delete request is invalid.");
        } else if (!res.data.isDeleted) {
            throw new Error("The user could not be removed from the database");
        }
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        user(s) could not be deleted.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function updateUser(endUserSession, data) {
    try {
        let url = BaseURL.getBaseURL() + "/userManagement/updateUser";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            this.props.endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The user update request is invalid.");
        } else if (!res.data.isUpdated) {
            throw new Error("The user database document could not be updated");
        }
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        The user could not be updated
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}
