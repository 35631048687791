/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2021-06-16 17:13:34
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:21:21
 */

import React, { Component } from "react";

import { connect } from "react-redux";
import { IconButton, Whisper, Popover, toaster, Message } from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import ProjectCard from "../components/projectCard";

//import { GetFirstStep } from "../services/myProjects";
import history from "../shared/history";

class MyProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helpActive: false,
            sideNavExpanded: true
        };

        this.handleExpandToggle = this.handleExpandToggle.bind(this);
        this.handleProjectClick = this.handleProjectClick.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
    }

    handleExpandToggle(expanded) {
        this.setState({ sideNavExpanded: expanded });
    }

    async handleProjectClick(project) {
        if (project.steps.length === 0) {
            toaster.push(
                <Message type="info" showIcon closable>
                    You do not have access to any steps
                </Message>
            );
            return;
        }
        if (this.props.userDetails.userType === "Admin") {
            history.push(
                "/" +
                    project.projectName +
                    project.steps[0].functionality.pathTree.path +
                    "/configuration"
            );
        } else {
            history.push(
                "/" +
                    project.projectName +
                    project.steps[0].functionality.pathTree.path
            );
        }
    }

    render() {
        return (
            <div
                style={{
                    padding: "20px",
                    backgroundColor: "rgb(236, 236, 236)",
                    height: "100%",
                    width: "100%"
                }}
            >
                <h2
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    My Projects
                </h2>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Here you can all the projects assigned to user
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        appearance="primary"
                    />
                </Whisper>

                <ProjectCard
                    assignedProjects={this.props.assignedProjects}
                    handleProjectClick={this.handleProjectClick}
                />
            </div>
        );
    }
}

/**
 *
 * @param {Object} state contains the state data got from API's
 * Redux - Using redux we are getting the reducers state as props
 */
const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects,
        stepsData: state.auth.stepsData
    };
};

/**
 *
 * @param {event} dispatch contains the event to get fired
 * Redux - Using redux we are dispatching few events/actions
 * to update the state data of reducers or passing updated
 * data to different components
 */
const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProjects);
