/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-02-12 17:42:39
 * @Last Modified by:   Malcolm MacArthur
 * @Last Modified time: 2021-02-12 17:42:39
*/

import { createBrowserHistory } from "history";

export default createBrowserHistory();
