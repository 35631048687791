import "./LeakIncidentTimeline.css";
import React, { Component } from "react";
import { Button, toaster, Message, Timeline } from "rsuite";
import LegacyCheckIcon from "@rsuite/icons/legacy/Check";
import LegacyExclamationIcon from "@rsuite/icons/legacy/Exclamation";
import { Scrollbars } from "react-custom-scrollbars";

class LeakIncidentTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    time: new Date("01/21/21 12:32:12").toLocaleString("en-GB"),
                    text: "Device# 4av774e, leak active for 1h",
                    priority: 3 // 0= repaired 1-3 Not repaired
                },
                {
                    time: new Date("01/20/21 23:20:50").toLocaleString("en-GB"),
                    text: "Device# 2ae74e, leak active for 12h",
                    priority: 1 // 0= repaired 1-3 Not repaired
                },
                {
                    time: new Date("01/20/21 20:32:12").toLocaleString("en-GB"),
                    text: "Device# 1aaa4e, leak active for 15h",
                    priority: 2 // 0= repaired 1-3 Repaired
                },
                {
                    time: new Date("01/20/21 18:32:12").toLocaleString("en-GB"),
                    text: "Device# 4a974b, repaired 1h ago",
                    priority: 0 // 0= repaired 1-3 Not repaired
                },
                {
                    time: new Date("01/20/21 18:32:12").toLocaleString("en-GB"),
                    text: "Device# 4a974a, leak active for 17h",
                    priority: 2 // 0= repaired 1-3 Not repaired
                },
                {
                    time: new Date("01/20/21 06:52:12").toLocaleString("en-GB"),
                    text: "Device# 56774e, repaired",
                    priority: 0 // 0= repaired 1-3 Not repaired
                },
                {
                    time: new Date("01/20/21 06:20:20").toLocaleString("en-GB"),
                    text: "Device# 4a474e, repaired",
                    priority: 0 // 0= repaired 1-3 Not repaired
                },
                {
                    time: new Date("01/20/21 05:32:12").toLocaleString("en-GB"),
                    text: "Device# 2a474e, leak active for more than a day",
                    priority: 1 // 0= repaired 1-3 Not repaired
                },
                {
                    time: new Date("01/20/21 04:30:12").toLocaleString("en-GB"),
                    text: "Device# 2a474b, repaired after 13h",
                    priority: 0 // 0= repaired 1-3 Not repaired
                }
            ]
        };

        this.backgrounds = ["#120078", "#120078", "orange", "rgb(240, 79, 67)"];
        this.icons = [
            <LegacyCheckIcon
                key={1}
                size="2x"
                style={{
                    background: "#120078",
                    color: "#fff"
                }}
            />,
            <LegacyExclamationIcon
                key={2}
                size="2x"
                style={{
                    background: "#120078",
                    color: "#fff"
                }}
            />,
            <LegacyExclamationIcon
                key={3}
                size="2x"
                style={{
                    background: "orange",
                    color: "#fff"
                }}
            />,
            <LegacyExclamationIcon
                key={4}
                size="2x"
                style={{
                    background: "rgb(240, 79, 67)",
                    color: "#fff"
                }}
            />
        ];
    }

    render() {
        let currentdate = new Date();
        let datetime = currentdate.toLocaleString("en-GB");

        return (
            <Scrollbars
                style={{
                    width: "100%",
                    height: "calc(100% - 65px)",
                    marginBottom: "20px"
                }}
            >
                <Timeline
                    className="custom-timeline"
                    align="left"
                    endless
                    style={{
                        paddingLeft: "20px",
                        paddingBottom: "20px",
                        paddingRight: "20px",
                        paddingTop: "10px"
                    }}
                >
                    {this.state.data.map((incident) => {
                        return (
                            <Timeline.Item
                                key={incident.time}
                                dot={this.icons[incident.priority]}
                                style={{
                                    paddingBottom: "10px"
                                }}
                            >
                                <p>{incident.time}</p>
                                <p>{incident.text}</p>
                                <p>
                                    {incident.priority > 0 ? (
                                        <Button
                                            appearance="primary"
                                            active
                                            onClick={() =>
                                                toaster.push(
                                                    <Message
                                                        type="success"
                                                        showIcon
                                                        closable
                                                    >
                                                        Order has been created!
                                                    </Message>
                                                )
                                            }
                                            size={"sm"}
                                        >
                                            Create Order
                                        </Button>
                                    ) : null}
                                </p>
                            </Timeline.Item>
                        );
                    })}
                </Timeline>
            </Scrollbars>
        );
    }
}

export default LeakIncidentTimeline;
