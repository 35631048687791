/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-07-15 17:04:19
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-19 12:26:24
 */

/**
 * @class BaseURL
 * to define the base values
 * which can be used in different files.
 * This way we can change here and it will get
 * reflected in other file (which uses these values)
 */
class BaseURL {
    /**
     * @public function getBaseURL()
     * function return the base API url
     */
    getBaseURL() {
        //return "https://server.ntwist.com:8104/api";
        // return "https://server.ntwist.com:8114/api";
        // return "https://localhost:4000/api";
        // return "http://localhost:4000/api";
        // return "https://p80api.ntwist.com/api";
        return "https://demo-api.ntwist.com/api";
    }
}
export default new BaseURL();
