/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 13:39:16
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:20:08
 */

import React, { Component } from "react";

import { Nav, Sidenav, Divider, FlexboxGrid, Button, Dropdown } from "rsuite";
import LegacyTrendIcon from "@rsuite/icons/legacy/Trend";
import LegacyProjectIcon from "@rsuite/icons/legacy/Project";
import LegacyLeftIcon from "@rsuite/icons/legacy/Left";
import LegacyRightIcon from "@rsuite/icons/legacy/Right";
import LegacyHandshakeOIcon from "@rsuite/icons/legacy/HandshakeO";
import LegacyTasksIcon from "@rsuite/icons/legacy/Tasks";
import LegacyGroupIcon from "@rsuite/icons/legacy/Group";
import LegacyListIcon from "@rsuite/icons/legacy/List";
import LegacySignOutIcon from "@rsuite/icons/legacy/SignOut";
import "./sideNavBar.css";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import axios from "axios";
import history from "../../shared/history";
import BaseURL from "../../api-common";
import { ReactComponent as User } from "../../assets/user.svg";
import { withRouter } from "react-router-dom";

class SideNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            activeKey: props.location.pathname
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleProfileClick = this.handleProfileClick.bind(this);
        this.generateProjectDropDowns =
            this.generateProjectDropDowns.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        /*
        If history is used to change the url,
        show the appropriate selection on the side nav.
        The project name and the first step pathTree node path are only used
        */
        let pathParts = props.location.pathname.split("/");
        let realActiveKey = "/" + pathParts[1] + "/" + pathParts[2];

        if (state.activeKey === realActiveKey) {
            return null;
        }
        return { activeKey: props.location.pathname };
    }

    handleToggle() {
        let sideNavClass;
        if (this.state.expanded) {
            sideNavClass = "sidenav-custom-collapse-out";
        } else {
            sideNavClass = "sidenav-custom-collapse-in";
        }
        let expanded = !this.state.expanded;
        this.setState({
            expanded: expanded,
            sideNavClass: sideNavClass
        });
        this.props.onExpandToggle(expanded);
    }

    async handleSelect(eventKey) {
        if (eventKey === "logout") {
            let url = BaseURL.getBaseURL() + "/authentication/logout";
            await axios({
                method: "get",
                url: url,
                withCredentials: true,
                data: {}
            });
            this.props.endUserSession();
            return;
        }

        history.push(eventKey);
    }

    handleProfileClick() {
        history.push("/profile");
    }

    generateProjectDropDowns() {
        let dropDown = [];
        let subMenu = [];
        for (let project of this.props.assignedProjects) {
            subMenu = [];
            for (let step of project.steps) {
                let eventKey;
                if (this.props.userDetails.userType === "User") {
                    eventKey =
                        "/" +
                        project.projectName +
                        step.functionality.pathTree.path;
                } else {
                    eventKey =
                        "/" +
                        project.projectName +
                        step.functionality.pathTree.path +
                        "/configuration";
                }
                subMenu.push(
                    <Nav.Item
                        key={eventKey}
                        eventKey={eventKey}
                        icon={<LegacyTrendIcon />}
                        onSelect={this.handleSelect}
                    >
                        {step.functionality.name}
                    </Nav.Item>
                );
            }
            dropDown.push(
                <Nav.Menu
                    key={"/" + project.projectName}
                    eventKey={"/" + project.projectName}
                    placement="rightStart"
                    title={project.projectName}
                    icon={<LegacyProjectIcon />}
                >
                    {subMenu}
                </Nav.Menu>
            );
        }

        return dropDown;
    }

    render() {
        let profileName = "";
        if (
            this.props.hasOwnProperty("userDetails") &&
            this.props.userDetails !== null &&
            this.props.userDetails !== undefined
        ) {
            profileName =
                this.props.userDetails.firstName +
                " " +
                this.props.userDetails.lastName;
        }

        let projectDropDowns = this.generateProjectDropDowns();

        return (
            <div id="sidenav">
                <FlexboxGrid justify="end">
                    <Button
                        appearance="primary"
                        size="lg"
                        onClick={this.handleToggle}
                        style={{ width: "56px" }}
                    >
                        {this.state.expanded ? (
                            <LegacyLeftIcon />
                        ) : (
                            <LegacyRightIcon />
                        )}
                    </Button>
                </FlexboxGrid>

                <Sidenav expanded={this.state.expanded} appearance="inverse">
                    <Sidenav.Header>
                        <div className="custom-header">
                            <div
                                className="profile-image"
                                onClick={this.handleProfileClick}
                            >
                                <User
                                    fill="#120078"
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </div>

                            {this.state.expanded ? profileName : null}
                        </div>
                    </Sidenav.Header>
                    <Sidenav.Body>
                        <Nav
                            activeKey={this.state.activeKey}
                            onSelect={this.handleSelect}
                        >
                            <Divider style={{ margin: "0" }} />

                            {this.props.userDetails.userType ===
                            "SuperAdmin" ? (
                                <Nav.Item
                                    eventKey="/clientManagement"
                                    icon={<LegacyHandshakeOIcon />}
                                >
                                    Client{"\u00A0"}Management
                                </Nav.Item>
                            ) : null}

                            {this.props.userDetails.userType ===
                            "SuperAdmin" ? (
                                <Nav.Item
                                    eventKey="/functionalityManagement"
                                    icon={<LegacyTasksIcon />}
                                >
                                    Function{"\u00A0"}Management
                                </Nav.Item>
                            ) : null}

                            {["SuperAdmin", "Admin"].includes(
                                this.props.userDetails.userType
                            ) ? (
                                <Nav.Item
                                    eventKey="/projectManagement"
                                    icon={<LegacyProjectIcon />}
                                >
                                    Project{"\u00A0"}Management
                                </Nav.Item>
                            ) : null}

                            {["SuperAdmin", "Admin"].includes(
                                this.props.userDetails.userType
                            ) ? (
                                <Nav.Item
                                    eventKey="/userManagement"
                                    icon={<LegacyGroupIcon />}
                                >
                                    User{"\u00A0"}Management
                                </Nav.Item>
                            ) : null}

                            {["Admin", "User"].includes(
                                this.props.userDetails.userType
                            ) ? (
                                <Nav.Item
                                    eventKey="/myProjects"
                                    icon={<LegacyListIcon />}
                                >
                                    My{"\u00A0"}Projects
                                </Nav.Item>
                            ) : null}

                            {/*{this.props.userDetails.userType === "Admin" ||
                            this.props.userDetails.userType === "SuperAdmin" ? (
                                <Nav.Item
                                    eventKey="/roleManagement"
                                    icon={<LegacyGroupIcon />}
                                >
                                    Role{"\u00A0"}Management
                                </Nav.Item>
                            ) : null}*/}

                            {/* <Divider style={{ margin: "0" }} /> */}
                            {projectDropDowns}
                            <Divider style={{ margin: "0" }} />
                            <Nav.Item
                                eventKey="logout"
                                icon={<LegacySignOutIcon />}
                            >
                                Logout
                            </Nav.Item>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects, //state.auth.assignedProjects
        stepsData: state.auth.stepsData // state.auth.stepsData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SideNavBar)
);
