/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:25:07
 */

import React, { Component } from "react";

import {
    Button,
    Form,
    InputPicker,
    Modal,
    Schema,
    Whisper,
    Popover,
    IconButton,
    Input
} from "rsuite";
import LegacyPencilIcon from "@rsuite/icons/legacy/Pencil";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import { updateFunctionalityStatus } from "../../../services/functionalityManagement";
import Editor from "@monaco-editor/react";

class ViewAndEditFunctionalityModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: {},
            formJSON: {},
            pathTreeJSON: "{}",
            configurationJSON: "{}",
            activeNavKey: "function",
            statusPickerOptions: [
                {
                    label: "Inactive",
                    value: "inactive",
                    role: "Master"
                }
            ],
            editMode: false
        };

        this.clearForm = this.clearForm.bind(this);
        this.validateConfiguration = this.validateConfiguration.bind(this);
        this.handleNavSelect = this.handleNavSelect.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModeChangeClick = this.handleModeChangeClick.bind(this);
        this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);

        this.functionValidationModel = Schema.Model({
            name: validators.functionalityNameValidation,
            commonName: validators.functionalityNameValidation,
            description: validators.descriptionValidation,
            status: validators.statusValidation,
            location: validators.pathValidation
        });

        this.categoryValidationModel = Schema.Model({
            name: validators.functionalityNameValidation,
            description: validators.descriptionValidation,
            status: validators.statusValidation
        });
    }

    clearForm() {
        let functionFormJSON = this.state.functionFormJSON;
        for (let field in functionFormJSON) {
            if (functionFormJSON.hasOwnProperty(field)) {
                functionFormJSON[field] = "";
            }
        }

        let categoryFormJSON = this.state.categoryFormJSON;
        for (let field in categoryFormJSON) {
            if (categoryFormJSON.hasOwnProperty(field)) {
                categoryFormJSON[field] = "";
            }
        }

        this.setState({
            functionFormJSON: functionFormJSON,
            categoryFormJSON: categoryFormJSON,
            configurationJSON: "{}",
            functionErrorMessages: {},
            categoryErrorMessages: {},
            editMode: false
        });
    }

    validateConfiguration() {
        try {
            JSON.parse(this.state.configurationJSON);
            this.editorWhisper.close();
            return true;
        } catch (e) {
            this.editorWhisper.open();
            return false;
        }
    }

    handleNavSelect(activeNavKey) {
        this.setState({ activeNavKey: activeNavKey });
    }

    handleEditorChange(newJSON) {
        this.setState({
            configurationJSON: newJSON
        });
    }

    handleModalOpen() {
        this.clearForm();
        let statusPickerOptions;
        if (this.props.parentStatus === "inactive") {
            statusPickerOptions = [
                {
                    label: "Inactive",
                    value: "inactive",
                    role: "Master"
                }
            ];
        } else {
            statusPickerOptions = [
                {
                    label: "Active",
                    value: "active",
                    role: "Master"
                },
                {
                    label: "Inactive",
                    value: "inactive",
                    role: "Master"
                }
            ];
        }

        let formJSON = {};
        let pathTreeJSON = {};
        let configurationJSON = {};
        if (this.props.functionality.type === "function") {
            formJSON.name = this.props.functionality.name;
            formJSON.commonName = this.props.functionality.commonName;
            formJSON.description = this.props.functionality.description;
            formJSON.status = this.props.functionality.status;
            formJSON.location = this.props.functionality.location;
            pathTreeJSON = JSON.stringify(
                this.props.functionality.pathTree,
                null,
                4
            );
            configurationJSON = JSON.stringify(
                this.props.functionality.defaultConfig,
                null,
                4
            );
        } else {
            formJSON.name = this.props.functionality.name;
            formJSON.description = this.props.functionality.description;
            formJSON.status = this.props.functionality.status;
        }

        this.setState({
            loading: false,
            statusPickerOptions: statusPickerOptions,
            formJSON: formJSON,
            pathTreeJSON: pathTreeJSON,
            configurationJSON: configurationJSON
        });
    }

    handleModeChangeClick() {
        if (this.state.editMode) {
            let formJSON = this.state.formJSON;
            formJSON.status = this.props.functionality.status;
            this.setState({
                editMode: false,
                formJSON: formJSON,
                errorMessages: { status: null }
            });
        } else {
            this.setState({ editMode: true });
        }
    }

    async handleSaveButtonClick() {
        let result;
        if (this.props.functionality.type === "function") {
            let formCheckResult = this.functionForm.check();
            if (this.validateConfiguration() && formCheckResult) {
                this.setState({ loading: true });
                let data = {};
                data.status = this.state.formJSON.status;
                data._id = this.props.functionality._id;
                result = await updateFunctionalityStatus(
                    this.props.endUserSession,
                    data
                );
            }
        } else {
            if (this.categoryForm.check()) {
                this.setState({ loading: true });
                let data = {};
                data.status = this.state.formJSON.status;
                data._id = this.props.functionality._id;
                result = await updateFunctionalityStatus(
                    this.props.endUserSession,
                    data
                );
            }
        }

        if (result) {
            this.props.onSubmit();
            this.setState({ editMode: false });
        } else {
            this.setState({ loading: false });
        }
    }

    Textarea(props, ref) {
        return <Input {...props} as="textarea" ref={ref} />;
    }

    render() {
        let statusPickerOptions;
        if (this.props.functionality.parentStatus === "inactive") {
            statusPickerOptions = [
                {
                    label: "Inactive",
                    value: "inactive",
                    role: "Master"
                }
            ];
        } else {
            statusPickerOptions = [
                {
                    label: "Active",
                    value: "active",
                    role: "Master"
                },
                {
                    label: "Inactive",
                    value: "inactive",
                    role: "Master"
                }
            ];
        }

        let form;
        if (this.props.functionality.type === "function") {
            form = (
                <div>
                    <Form
                        ref={(forum) => (this.functionForm = forum)}
                        layout="horizontal"
                        formValue={this.state.formJSON}
                        model={this.functionValidationModel}
                        checkTrigger="none"
                        onChange={(newFormJSON) => {
                            this.setState({ formJSON: newFormJSON });
                        }}
                        onCheck={(errorMessages) => {
                            this.setState({
                                errorMessages: errorMessages
                            });
                        }}
                        style={{ marginTop: "20px" }}
                    >
                        <Form.Group>
                            <Form.ControlLabel>Name</Form.ControlLabel>
                            <Form.Control
                                name="name"
                                errorMessage={this.state.errorMessages.name}
                                plaintext
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Common Name</Form.ControlLabel>
                            <Form.Control
                                name="commonName"
                                errorMessage={
                                    this.state.errorMessages.commonName
                                }
                                plaintext
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Description</Form.ControlLabel>
                            <Form.Control
                                name="description"
                                rows={5}
                                accepter={React.forwardRef(this.Textarea)}
                                errorMessage={
                                    this.state.errorMessages.description
                                }
                                plaintext
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Status</Form.ControlLabel>
                            <Form.Control
                                name="status"
                                accepter={InputPicker}
                                data={statusPickerOptions}
                                errorMessage={this.state.errorMessages.status}
                                plaintext={!this.state.editMode}
                            />
                        </Form.Group>
                    </Form>

                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        Path Tree
                    </div>
                    <div
                        style={{
                            border: "2px solid #b4b4b4",
                            height: "250px"
                        }}
                    >
                        <Editor
                            defaultLanguage="json"
                            options={{
                                minimap: {
                                    enabled: false
                                },
                                automaticLayout: true,
                                scrollBeyondLastLine: false,
                                readOnly: true
                            }}
                            defaultValue={this.state.pathTreeJSON}
                        />
                    </div>

                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        Default Config
                    </div>
                    <Whisper
                        ref={(editorWhisper) => {
                            this.editorWhisper = editorWhisper;
                        }}
                        placement="bottomStart"
                        trigger="none"
                        speaker={
                            <Popover
                                style={{
                                    color: "#f44336",
                                    margin: "0",
                                    paddingTop: "4px",
                                    paddingRight: "8px",
                                    paddingBottom: "4px",
                                    paddingLeft: "8px"
                                }}
                            >
                                Invalid JSON
                            </Popover>
                        }
                    >
                        <div
                            style={{
                                border: "2px solid #b4b4b4",
                                height: "250px"
                            }}
                        >
                            <Editor
                                defaultLanguage="json"
                                options={{
                                    minimap: {
                                        enabled: false
                                    },
                                    automaticLayout: true,
                                    scrollBeyondLastLine: false,
                                    readOnly: true
                                }}
                                defaultValue={this.state.configurationJSON}
                                onChange={this.handleEditorChange}
                            />
                        </div>
                    </Whisper>
                </div>
            );
        } else {
            form = (
                <Form
                    ref={(forum) => (this.categoryForm = forum)}
                    layout="horizontal"
                    formValue={this.state.formJSON}
                    model={this.categoryValidationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ formJSON: newFormJSON });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({ errorMessages: errorMessages });
                    }}
                    style={{ marginTop: "20px" }}
                >
                    <Form.Group>
                        <Form.ControlLabel>Name</Form.ControlLabel>
                        <Form.Control
                            name="name"
                            errorMessage={this.state.errorMessages.name}
                            plaintext
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Description</Form.ControlLabel>
                        <Form.Control
                            name="description"
                            rows={5}
                            accepter={React.forwardRef(this.Textarea)}
                            errorMessage={this.state.errorMessages.description}
                            plaintext
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Status</Form.ControlLabel>
                        <Form.Control
                            name="status"
                            accepter={InputPicker}
                            data={statusPickerOptions}
                            errorMessage={this.state.errorMessages.status}
                            plaintext={!this.state.editMode}
                        />
                    </Form.Group>
                </Form>
            );
        }

        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>
                        {this.state.editMode
                            ? "Edit Functionality"
                            : "View Functionality"}

                        {!this.state.editMode ? (
                            <IconButton
                                circle
                                appearance="subtle"
                                icon={<LegacyPencilIcon />}
                                style={{ marginLeft: "20px" }}
                                onClick={this.handleModeChangeClick}
                            />
                        ) : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{form}</Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        {this.state.editMode ? (
                            <div>
                                <Button
                                    onClick={this.handleSaveButtonClick}
                                    appearance="primary"
                                    type="submit"
                                    loading={this.state.loading}
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={this.handleModeChangeClick}
                                    appearance="subtle"
                                    disabled={this.state.loading}
                                >
                                    Cancel
                                </Button>
                            </div>
                        ) : (
                            <Button
                                onClick={this.props.onClose}
                                appearance="primary"
                            >
                                Done
                            </Button>
                        )}
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewAndEditFunctionalityModal);
