/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:57:24
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:22:13
 */

import React, { Component } from "react";
import history from "../shared/history";
import * as validators from "../shared/modelValidators";
import { withRouter } from "react-router";
import { verifyPasswordChangeToken, changePassword } from "../services/auth";
import { Form, Button, Schema, ButtonToolbar, toaster, Message } from "rsuite";

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessages: {},
            formJSON: {
                password: "",
                reenterPassword: ""
            },
            loading: false
        };

        this.handleChangePasswordClick =
            this.handleChangePasswordClick.bind(this);

        let schema = {
            password: validators.passwordValidation,
            reenterPassword: validators.passwordReEnterValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    async componentDidMount() {
        let token = this.props.match.params.token;
        let username = this.props.match.params.username;
        let result = await verifyPasswordChangeToken({
            token: token,
            username: username
        });
        if (result === false) {
            history.replace("/");
            toaster.push(
                <Message type="error" showIcon closable duration={0}>
                    {<text>Password change request is invalid</text>}
                </Message>
            );
        }
    }

    async handleChangePasswordClick() {
        if (this.form.check()) {
            this.setState({ loading: true });
            let token = this.props.match.params.token;
            let username = this.props.match.params.username;
            let result = await changePassword({
                token: token,
                username: username,
                password: this.state.formJSON.password
            });
            if (result) {
                history.replace("/");
                toaster.push(
                    <Message type="success" showIcon closable>
                        Successfully changed password
                    </Message>
                );
            } else {
                this.setState({ loading: false });
            }
        }
    }

    render() {
        return (
            <div
                style={{
                    border: "1px solid rgb(229, 229, 234)",
                    padding: "24px",
                    width: "100%",
                    maxWidth: "600px",
                    borderRadius: "6px",
                    backgroundColor: "white"
                }}
            >
                <h4>Enter New Password</h4>
                <Form
                    ref={(forum) => (this.form = forum)}
                    fluid
                    formValue={this.state.formJSON}
                    model={this.validationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({
                            formJSON: newFormJSON
                        });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({
                            errorMessages: errorMessages
                        });
                    }}
                    formError={this.state.errorMessages}
                    style={{ marginTop: "20px" }}
                >
                    <Form.Group>
                        <Form.Control name="password" type="password" placeholder="New Password" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            name="reenterPassword"
                            type="password"
                            placeholder="Re-enter New Password " />
                    </Form.Group>
                </Form>
                <ButtonToolbar>
                    <Button
                        appearance="primary"
                        loading={this.state.loading}
                        onClick={this.handleChangePasswordClick}
                        block
                        style={{ marginTop: "24px" }}
                    >
                        Change Password
                    </Button>
                </ButtonToolbar>

                {/*Place for server error messages*/}
                <div
                    style={{
                        textAlign: "center",
                        color: "#f44336"
                    }}
                >
                    {this.state.errorMessages.serverError}
                </div>
            </div>
        );
    }
}

export default withRouter(ChangePassword);
