/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-24 17:20:37
 */

import React, { Component } from "react";
import { Table, InputPicker, DatePicker, Button, Form, Schema } from "rsuite";
import { connect } from "react-redux";
import { refreshToken } from "../../shared/auth";
import * as actions from "../../store/actions/auth";
import AdvancedTable from "../../components/advancedTable";
import * as validators from "../../shared/modelValidators";
import axios from "axios";
import BaseURL from "../../api-common";
import { downloadTrainingData, getTrainingData } from "../../services/retrain";
import { withRouter } from "react-router-dom";
import { isWithinInterval } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

class DataGeneration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formJSON: {
                startDate: new Date(1578421800000),
                endDate: new Date(1578767400000)
            },
            errorMessages: {
                targetTag: "",
                startDate: null,
                endDate: null
            },
            tableData: [],
            tableHeight: null,
            targetTagPickerOptions: ["Ni"],
            loadingGetData: false,
            loadingDownload: false
        };

        let schema = {
            targetTag: validators.targetTagValidation,
            startDate: validators.startDateValidation,
            endDate: validators.endDateValidation
        };

        this.validationModel = Schema.Model(schema);

        this.handleResize = this.handleResize.bind(this);
        this.handleGetDataClick = this.handleGetDataClick.bind(this);
        this.handleDownloadClick = this.handleDownloadClick.bind(this);
        this.handleStartTrainingClick =
            this.handleStartTrainingClick.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.renderDocumentCount = this.renderDocumentCount.bind(this);

        if (props.trainingProcess !== null) {
            this.state.formJSON = {
                targetTag: props.trainingProcess.options.targetTag,
                startDate: props.trainingProcess.options.startDate,
                endDate: props.trainingProcess.options.endDate
            };
            this.state.documentCount = props.trainingProcess.documentCount;
        } else {
            this.state.formJSON = {
                targetTag: "",
                startDate: new Date(1578421800000),
                endDate: new Date(1578767400000)
            };
            this.state.documentCount = null;
        }
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);

        // Detect resize windows events so that the table height can be adjusted appropriately
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        // Manually dynamically set the height of the table since the table doesn't support dynamic heights
        let formHeight;
        let stepsHeight;
        try {
            formHeight = document.getElementById(
                "data-generation-form"
            ).clientHeight;
            stepsHeight =
                document.getElementById("retraining-steps").clientHeight;
        } catch (e) {
            // Cancel the calculation, if one of the elements is not there
            return;
        }
        let tableHeight = window.innerHeight - (formHeight + stepsHeight + 266);

        // If calculated height is less than the min height, than use min height
        if (tableHeight < 300) {
            tableHeight = 300;
        }

        this.setState({ tableHeight: tableHeight });
    }

    async handleGetDataClick() {
        if (this.form.check()) {
            const params = {
                startDate: this.state.formJSON.startDate,
                endDate: this.state.formJSON.endDate
            };
            this.setState({ loadingGetData: true });
            const result = await getTrainingData(
                this.props.location.pathname,
                params
            );
            this.setState({
                tableData: result.tags,
                loadingGetData: false,
                documentCount: result.count
            });
            this.props.onTrainingProcessChange({
                options: {
                    targetTag: this.state.formJSON.targetTag,
                    startDate: this.state.formJSON.startDate,
                    endDate: this.state.formJSON.endDate
                },
                documentCount: result.count,
                trainingStatus: -1,
                output: []
            });
        }
    }

    async handleDownloadClick() {
        if (this.form.check()) {
            const params = {
                startDate: this.state.formJSON.startDate,
                endDate: this.state.formJSON.endDate
            };
            this.setState({ loadingDownload: true });
            await downloadTrainingData(
                this.props.endUserSession,
                this.props.location.pathname,
                params
            );
            this.setState({ loadingDownload: false });
        }
    }

    handleStartTrainingClick() {
        this.props.onStepChange(1);
    }

    handleFormChange(newFormJSON) {
        if (
            !Number.isInteger(newFormJSON.startDate) &&
            newFormJSON.startDate !== null
        ) {
            newFormJSON.startDate = newFormJSON.startDate.getTime();
        }

        if (
            !Number.isInteger(newFormJSON.endDate) &&
            newFormJSON.endDate !== null
        ) {
            newFormJSON.endDate = newFormJSON.endDate.getTime();
        }
        this.setState({ formJSON: newFormJSON });
    }

    renderDocumentCount() {
        if (this.state.documentCount === null) {
            return (
                <div style={{ display: "inline-block" }}>
                    (Document Count: )
                </div>
            );
        } else if (this.state.documentCount > 0) {
            return (
                <div style={{ display: "inline-block" }}>
                    (Document Count: {this.state.documentCount} )
                </div>
            );
        } else {
            return (
                <div style={{ display: "inline-block", color: "red" }}>
                    (Document Count: {this.state.documentCount} )
                </div>
            );
        }
    }

    render() {
        let documentCount = this.renderDocumentCount();

        return (
            <div>
                <h3
                    style={{
                        flex: 1,
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Data Generation
                </h3>
                <h5 style={{ marginTop: "30px" }}>Data Selection</h5>
                <Form
                    id="data-generation-form"
                    ref={(forum) => (this.form = forum)}
                    layout="inline"
                    formValue={this.state.formJSON}
                    model={this.validationModel}
                    checkTrigger="none"
                    onChange={this.handleFormChange}
                    onCheck={(errorMessages) => {
                        this.setState({ errorMessages: errorMessages });
                    }}
                    style={{ marginTop: "10px" }}
                >
                    <Form.Group>
                        <Form.ControlLabel>Target Tag</Form.ControlLabel>
                        <Form.Control
                            name="targetTag"
                            accepter={InputPicker}
                            data={this.props.config.targetTags}
                            errorMessage={this.state.errorMessages.targetTag}
                            disabled={this.state.loading}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Start</Form.ControlLabel>
                        <Form.Control
                            name="startDate"
                            accepter={DatePicker}
                            errorMessage={this.state.errorMessages.startDate}
                            format="dd MMM yyyy hh:mm"
                            disabledDate={(date) =>
                                !isWithinInterval(date, {
                                    start: new Date("2019-12-31T00:00:00.000Z"),
                                    end: new Date("2020-03-18T00:00:00.000Z")
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>End</Form.ControlLabel>
                        <Form.Control
                            name="endDate"
                            accepter={DatePicker}
                            errorMessage={this.state.errorMessages.endDate}
                            format="dd MMM yyyy hh:mm"
                            disabledDate={(date) =>
                                !isWithinInterval(date, {
                                    start: new Date("2019-12-31T00:00:00.000Z"),
                                    end: new Date("2020-03-18T00:00:00.000Z")
                                })
                            }
                        />
                    </Form.Group>

                    <Button
                        appearance="primary"
                        onClick={this.handleGetDataClick}
                        loading={this.state.loadingGetData}
                    >
                        Get Data
                    </Button>
                    <Button
                        appearance="primary"
                        onClick={this.handleDownloadClick}
                        loading={this.state.loadingDownload}
                    >
                        Download
                    </Button>
                </Form>
                <h5 style={{ display: "inline-block" }}>Tags In Data </h5>{" "}
                {documentCount}
                <AdvancedTable
                    bordered
                    cellBordered
                    wordWrap
                    height={this.state.tableHeight}
                    loading={this.state.loading}
                    rows={this.state.tableData}
                    initialSortColumn="name"
                    initialSortType="desc"
                    onRowSelectionChange={this.handleRowSelectionChange}
                    style={{ marginTop: "20px", backgroundColor: "white" }}
                >
                    <Column
                        minWidth={200}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Tags
                        </HeaderCell>
                        <Cell dataKey="tag" />
                    </Column>

                    <Column
                        minWidth={100}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            LL Limit
                        </HeaderCell>
                        <Cell dataKey="llLimit" />
                    </Column>

                    <Column
                        minWidth={100}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Low Limit
                        </HeaderCell>
                        <Cell dataKey="lowLimit" />
                    </Column>

                    <Column
                        minWidth={100}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            High Limit
                        </HeaderCell>
                        <Cell dataKey="highLimit" />
                    </Column>

                    <Column
                        minWidth={100}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            HH Limit
                        </HeaderCell>
                        <Cell dataKey="hhLimit" />
                    </Column>

                    <Column
                        minWidth={100}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Units
                        </HeaderCell>
                        <Cell dataKey="units" />
                    </Column>
                </AdvancedTable>
                <Button
                    appearance="primary"
                    style={{ marginTop: "20px", float: "right" }}
                    onClick={this.handleStartTrainingClick}
                    disabled={
                        this.props.trainingProcess === null ||
                        this.state.loadingGetData ||
                        !(this.state.documentCount > 0)
                    }
                >
                    Start Model Training
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DataGeneration)
);
