/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-08-06 15:19:39
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-21 20:09:12
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getTrainingProcess(
    endUserSession,
    clientPathName,
    userID
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/retrain/trainingProcess?userID=" +
            userID;

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        return res.data.trainingProcess;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        The training process info could not be obtained.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function getTrendsData(endUserSession, clientPathName, body) {
    try {
        let url =
            BaseURL.getBaseURL() + clientPathName + "/retrain/getTrendsData";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: body
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return null;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        return res.data.plotData;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        The trends info could not be obtained.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function deleteTrainingProcess(
    endUserSession,
    clientPathName,
    userID
) {
    try {
        let url =
            BaseURL.getBaseURL() + clientPathName + "/retrain/trainingProcess";

        let res = await axios({
            method: "delete",
            url: url,
            withCredentials: true,
            data: { userID: userID }
        });

        if (res.data.hasOwnProperty("msg") && res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        return res.data.isDeleted;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        The model could not be deleted
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function downloadTrainingData(
    endUserSession,
    clientPathName,
    params
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/retrain/downloadTrainingData";
        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            params: params
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        const downloadUrl = window.URL.createObjectURL(
            new Blob([res.data.data])
        );
        const downloadElement = document.createElement("a");
        downloadElement.href = downloadUrl;
        downloadElement.setAttribute("download", "training-data.csv");
        downloadElement.click();
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        Could not download training data.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getTrainingData(clientPathName, params) {
    try {
        let url =
            BaseURL.getBaseURL() + clientPathName + "/retrain/getTrainingData";
        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            params: params
        });

        if (res.data.msg === "UNAUTHORIZED") {
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        return res.data.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        Could not get training data.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getTrainingConfig(endUserSession, clientPathName) {
    try {
        let url =
            BaseURL.getBaseURL() + clientPathName + "/retrain/getRetrainConfig";
        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        return res.data.config;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        Could not get training config.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function startTrainingProcess(
    endUserSession,
    clientPathName,
    data
) {
    try {
        let url =
            BaseURL.getBaseURL() + clientPathName + "/retrain/trainingProcess";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        return res.data.isCreated;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        The training process could not be started.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function updateThresholdConfig(
    endUserSession,
    clientPathName,
    userID,
    configData
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/retrain/updateThresholdConfig";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: {
                userID: userID,
                configData: configData
            }
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        return res.data.isUpdated;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        The config was not updated.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function getThresholdConfig(
    endUserSession,
    clientPathName,
    userID
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/retrain/getThresholdConfig";
        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: {
                userID: userID
            }
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        }

        return res.data.thresholdConfig;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={1500}>
                {
                    <text>
                        Could not get the current config values
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}
