/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-15 11:07:40
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:18:49
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function activateJob(endUserSession, jobID) {
    try {
        let url = BaseURL.getBaseURL() + "/job/" + jobID + "/activate";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The job activation request is invalid.");
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Job could not be activated.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function deactivateJob(endUserSession, jobID) {
    try {
        let url = BaseURL.getBaseURL() + "/job/" + jobID + "/deactivate";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The job deactivation request is invalid.");
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Job could not be deactivated.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}
