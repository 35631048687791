/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2022-04-18 12:23:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-27 18:43:41
 */

import React from "react";
import { Form, Table } from "rsuite";
import building from "../assets/building.svg";
import Auxiliary from "../hoc/Auxiliary";

let stylingObject = {
    card: {
        width: "300px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        paddingBottom: "20px",
        backgroundImage: "linear-gradient(white, #120078)",
        overflow: "hidden",
        cursor: "pointer"
    },
    "card-wrapper": {
        width: "100%",
        padding: 0,
        margin: 0,
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 300px)",
        gap: "20px"
    },
    h3: {
        marginTop: "1rem",
        textAlign: "center",
        color: "white"
    },
    img: {
        width: "100%",
        height: "300px",
        objectFit: "fill",
        mixBlendMode: "multiply",
        backgroundImage: "url(" + building + ")",
        backgroundRepeat: "no-repeat"
    },
    formGroupStyle: {
        marginBottom: "5px !important"
    }
};
const predictionsSection = (props) => {
    // const { Column, HeaderCell, Cell } = Table;
    return (
        <Auxiliary>
            <b style={{ color: "#120078" }}>Predictions</b>
            <Form fluid layout="horizontal">
                <Form.Group>
                    <Form.ControlLabel>Level (%): </Form.ControlLabel>
                    <Form.Control
                        name="level"
                        placeholder="level"
                        readOnly="true"
                        value={props.predictions.level}
                    />
                </Form.Group>
                {/* style={{ marginBottom: "5px" }} */}
                <Form.Group>
                    <Form.ControlLabel>Raw Level (%): </Form.ControlLabel>
                    <Form.Control
                        name="rawlevel"
                        placeholder="rawlevel"
                        readOnly="true"
                        value={props.predictions.rawLevel}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>Confidence </Form.ControlLabel>
                    <Form.Control
                        name="confidence"
                        placeholder="confidence"
                        readOnly="true"
                        value={props.predictions.confidence}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>Alarm Flag </Form.ControlLabel>
                    <Form.Control
                        name="alarmflag"
                        placeholder="alarmflag"
                        readOnly="true"
                        value={props.predictions.alarmFlag}
                    />
                </Form.Group>
                {/* <b style={{ color: "#120078" }}>Quality Status:</b>
                <Table
                    bordered
                    cellBordered
                    autoHeight
                    data={props.rows}
                    style={{ marginTop: "5px" }}
                >
                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Window Name
                        </HeaderCell>
                        <Cell dataKey="window" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Contrast Value
                        </HeaderCell>
                        <Cell dataKey="contrast" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Intensity Value
                        </HeaderCell>
                        <Cell dataKey="intensity" />
                    </Column>
                </Table> */}
            </Form>
        </Auxiliary>
    );
};

export default predictionsSection;
