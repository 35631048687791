/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2022-01-14 16:45:59
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-01-20 15:02:29
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getAnalysis(endUserSession, clientPathName, ID) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/analyses/" + ID;

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data === null) {
            return res.data;
        }

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={60000}>{<text>
                Could not get the analysis.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function getAnalyses(endUserSession, clientPathName) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/analyses";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={60000}>{<text>
                Could not get list of analyses.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function createAnalysis(endUserSession, clientPathName, analysis) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/analyses";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: analysis
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={60000}>{<text>
                Could not create analysis.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function updateAnalysis(
    endUserSession,
    clientPathName,
    dataset,
    ID
) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/analyses/" + ID;

        let res = await axios({
            method: "put",
            url: url,
            withCredentials: true,
            data: dataset
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={60000}>{<text>
                Could not update analysis.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function deleteAnalysis(endUserSession, clientPathName, ID) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/analyses/" + ID;

        let res = await axios({
            method: "delete",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return true;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={60000}>{<text>
                Could not delete analysis.
                <br />
                {e.toString()}
            </text>}</Message>);
        return false;
    }
}

export async function startAnalysis(endUserSession, clientPathName, ID) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/analyses/" +
            ID +
            "/start";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={60000}>{<text>
                Could not start analysis.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function cancelAnalysis(endUserSession, clientPathName, ID) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/analyses/" +
            ID +
            "/cancel";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={60000}>{<text>
                Could not cancel analysis.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function resetAnalysis(endUserSession, clientPathName, ID) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/analyses/" +
            ID +
            "/reset";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={60000}>{<text>
                Could not reset analysis.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}
