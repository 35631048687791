/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:43
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-06-13 14:10:49
 */

import React, { Component } from "react";
import { Button, Modal, toaster, Message } from "rsuite";
import { connect } from "react-redux";
import { populate } from "../../services/populator";
import * as actions from "../../store/actions/auth";
import { withRouter } from "react-router-dom";

class PopulateConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleModalEnter = this.handleModalEnter.bind(this);
    }

    async handleDeleteClick() {
        this.setState({ loading: true });
        let result = await populate(
            this.props.endUserSession,
            this.props.location.pathname
        );

        if (result) {
            toaster.push(
                <Message type="success" showIcon closable duration={5000}>
                    {<text>Repopulation Nuke Launched</text>}
                </Message>
            );
            this.props.onExit();
            this.props.onSubmit();
        } else {
            this.setState({ loading: false });
        }
    }

    handleModalEnter() {
        this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.props.show}
                    onEnter={this.handleModalEnter}
                    onExit={this.props.onExit}
                    backdrop="static"
                >
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Repopulation Nuke Armed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: "center" }}>
                        Are you sure you want to <strong>replace ALL</strong> of
                        the stored table data with the default data? <br />
                        ALL users using this project will be effected.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.handleDeleteClick}
                            loading={this.state.loading}
                            style={{
                                backgroundColor: "rgb(240, 79, 67)",
                                color: "white"
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={this.props.onExit}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PopulateConfirmation)
);
