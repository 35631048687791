/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-02-12 17:42:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:18:23
 */

import React from "react";
import { toaster, Message } from "rsuite";
import axios from "axios";
import BaseURL from "../api-common";

export async function refreshToken(endUserSessionFunction) {
    try {
        let url = BaseURL.getBaseURL() + "/authentication/refreshToken";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSessionFunction();
        }
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not attempt token refresh.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
    }
}
