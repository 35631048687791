import React, { Component } from "react";
import * as actions from "../../store/actions/auth";
import { connect } from "react-redux";
import {
    updateThresholdConfig,
    getThresholdConfig
} from "../../services/retrain";
import "./config.css";
import { InputNumber, Button } from "rsuite";
import { toaster, Message } from "rsuite";
import { withRouter } from "react-router-dom";

class Config extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trainingAccuracy: 0,
            testingAccuracy: 0,
            trainingR2Score: 0,
            testingR2Score: 0,
            testingMinMax: {
                min: 0,
                max: 100
            },
            trainingMinMax: {
                min: 0,
                max: 100
            }
        };

        this.handleTestingAccuracyChange =
            this.handleTestingAccuracyChange.bind(this);
        this.handleTrainingAccuracyChange =
            this.handleTrainingAccuracyChange.bind(this);
        this.handleTrainingR2ScoreChange =
            this.handleTrainingR2ScoreChange.bind(this);
        this.handleTestingR2ScoreChange =
            this.handleTestingR2ScoreChange.bind(this);
        this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
        this.handleResetButtonClick = this.handleResetButtonClick.bind(this);
    }

    async componentDidMount() {
        let configData = await getThresholdConfig(
            this.props.endUserSession,
            this.props.location.pathname,
            this.props.userDetails._id
        );

        if (configData === null) {
            return;
        }

        this.setState({
            testingAccuracy: configData.configData.testingAccuracy,
            trainingAccuracy: configData.configData.trainingAccuracy,
            trainingR2Score: configData.configData.trainingR2Score,
            testingR2Score: configData.configData.testingR2Score
        });
    }

    handleTestingAccuracyChange(value) {
        this.setState({ testingAccuracy: value });
    }

    handleTrainingAccuracyChange(value) {
        this.setState({ trainingAccuracy: value });
    }

    handleTrainingR2ScoreChange(value) {
        this.setState({ trainingR2Score: value });
    }

    handleTestingR2ScoreChange(value) {
        this.setState({ testingR2Score: value });
    }

    async handleSaveButtonClick() {
        if (
            this.state.testingAccuracy === "" ||
            this.state.trainingAccuracy === "" ||
            this.state.trainingR2Score === "" ||
            this.state.testingR2Score === ""
        ) {
            toaster.push(
                <Message type="error" showIcon closable duration={10000}>
                    {<text>All fields must be filled</text>}
                </Message>
            );
            return;
        }

        let configData = {
            testingAccuracy: parseFloat(this.state.testingAccuracy),
            trainingAccuracy: parseFloat(this.state.trainingAccuracy),
            trainingR2Score: parseFloat(this.state.trainingR2Score),
            testingR2Score: parseFloat(this.state.testingR2Score)
        };
        let result = await updateThresholdConfig(
            this.props.endUserSession,
            this.props.location.pathname,
            this.props.userDetails._id,
            configData
        );

        if (result) {
            toaster.push(
                <Message type="success" showIcon closable duration={10000}>
                    {<text>Saved</text>}
                </Message>
            );
        }
    }

    handleResetButtonClick() {
        this.setState({
            testingAccuracy: 0.5,
            trainingAccuracy: 0.5,
            trainingR2Score: 0,
            testingR2Score: 0
        });
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                <h3 style={{ color: "#120078" }}>Retrain Model Config</h3>
                <div className="body">
                    <div className="grid-container">
                        <div className="retrain-config-wrapper">
                            <div className="box">
                                Training Accuracy Threshold
                            </div>

                            <span>
                                <InputNumber
                                    value={this.state.trainingAccuracy}
                                    step={0.01}
                                    onChange={this.handleTrainingAccuracyChange}
                                />
                            </span>
                        </div>

                        <div className="retrain-config-wrapper">
                            <div className="box">
                                Testing Accuracy Threshold
                            </div>
                            <span>
                                <InputNumber
                                    value={this.state.testingAccuracy}
                                    step={0.01}
                                    onChange={this.handleTestingAccuracyChange}
                                />
                            </span>
                        </div>

                        <div className="retrain-config-wrapper">
                            <div className="box">
                                Training R2 Score Threshold
                            </div>
                            <span>
                                <InputNumber
                                    value={this.state.trainingR2Score}
                                    step={0.01}
                                    onChange={this.handleTrainingR2ScoreChange}
                                />
                            </span>
                        </div>

                        <div className="retrain-config-wrapper">
                            <div className="box">
                                Testing R2 Score Threshold
                            </div>
                            <span>
                                <InputNumber
                                    value={this.state.testingR2Score}
                                    step={0.01}
                                    onChange={this.handleTestingR2ScoreChange}
                                />
                            </span>
                        </div>
                    </div>
                    <div>
                        <Button
                            appearance="primary"
                            onClick={this.handleSaveButtonClick}
                            size="md"
                            style={{ margin: "20px" }}
                        >
                            Save
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={this.handleResetButtonClick}
                            size="md"
                            style={{ margin: "20px" }}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Config));
