import React, { Component } from "react";
import { Progress } from "rsuite";

const { Line } = Progress;

class ProcessEfficiencyLines extends Component {
    render() {
        return (
            <div
                style={{
                    display: "flex",
                    height: "calc(100% - 45px)",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom: "20px"
                }}
            >
                <div
                    style={{
                        width: "100px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        H<sub>2</sub>&nbsp;Process
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        O<sub>2</sub>&nbsp;Process
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        CO<sub>2</sub>&nbsp;Process
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        CO&nbsp;Process
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        N<sub>2</sub>&nbsp;Process
                    </div>
                </div>

                <div
                    style={{
                        height: "100%",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Line
                        percent={89}
                        strokeColor="#120078"
                        style={{ flex: 1 }}
                    />
                    <Line
                        percent={95}
                        strokeColor="#120078"
                        style={{ flex: 1 }}
                    />
                    <Line
                        percent={60}
                        strokeColor="orange"
                        style={{ flex: 1 }}
                    />
                    <Line
                        percent={85}
                        strokeColor="#120078"
                        style={{ flex: 1 }}
                    />
                    <Line
                        percent={45}
                        strokeColor="rgb(240, 79, 67)"
                        style={{ flex: 1 }}
                    />
                </div>
            </div>
        );
    }
}

export default ProcessEfficiencyLines;
