/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-02-26 14:21:50
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:18:01
 */

import { Schema } from "rsuite";

const { StringType, NumberType, ArrayType, ObjectType, DateType } =
    Schema.Types;

export const usernameValidation = StringType()
    .isRequired("Required")
    .rangeLength(5, 25, "Should have between 5 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_]){5,25}(?<![$!@_ ])$/i, "Invalid");

export const nameValidation = StringType()
    .isRequired("Required")
    .rangeLength(1, 25, "Should have between 1 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_ ]){1,25}(?<![$!@_ ])$/i, "Invalid");

export const emailValidation = StringType()
    .isRequired("Required")
    .pattern(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, "Invalid");

export const userTypeValidation = StringType().isRequired("Required");

export const roleValidation = StringType().isRequired("Required");

export const statusValidation = StringType()
    .isRequired("Required")
    .isOneOf(["active", "inactive"], "Must be either 'Active' or 'Inactive'");

export const clientIdValidation = StringType().isRequired("Required");

export const passwordValidation = StringType()
    .isRequired("Required")
    .rangeLength(8, 25, "Should have between 8 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_ ]){8,25}(?<![$!@_ ])$/i, "Invalid");

export const passwordReEnterValidation = StringType()
    .isRequired("Required")
    .addRule((value, data) => {
        return value === data.password;
    }, "The passwords do not match");

export const userIdValidation = StringType().isRequired("Required");

export const projectIDsValidation = ArrayType();

export const clientNameValidation = StringType()
    .isRequired("Required")
    .rangeLength(2, 25, "Should have between 2 and 25 characters")
    .pattern(/^([a-zA-Z]+[a-zA-Z0-9$!@_ ]*[a-zA-Z0-9]+){2,25}$/, "Invalid");

export const databaseNameValidation = StringType()
    .isRequired("Required")
    .rangeLength(2, 25, "Should have between 2 and 25 characters")
    .addRule((value, data) => {
        return value.toLowerCase() === value;
    }, "All letters must be lowercase")
    .pattern(/^([a-z]){2,25}$/, "Invalid");

export const descriptionValidation = StringType()
    .rangeLength(0, 500, "Should have between 0 and 500 characters")
    .pattern(/^(?![ ])([a-z0-9$!@_.,:()?/\\;"'% ]){0,500}(?<![ ])$/i);

export const pathValidation = StringType()
    .isRequired("Required")
    .pattern(/^\.?(\/[^/]+)+\/?$/, "Invalid Formatting");

export const functionalityNameValidation = StringType()
    .isRequired("Required")
    .rangeLength(5, 30, "Should have between 5 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_ ]){5,30}(?<![$!@_ ])$/i, "Invalid");

export const locationValidation = StringType()
    .isRequired("Required")
    .rangeLength(5, 25, "Should have between 5 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_]){5,25}(?<![$!@_ ])$/i, "Invalid");

export const projectNameValidation = StringType()
    .isRequired("Required")
    .rangeLength(5, 30, "Should have between 5 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_ ]){5,30}(?<![$!@_ ])$/i, "Invalid");

export const branchNameValidation = StringType()
    .isRequired("Required")
    .rangeLength(5, 30, "Should have between 5 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_ ]){5,30}(?<![$!@_ ])$/i, "Invalid");

export const targetTagValidation = StringType().isRequired("Required");

export const thresholdValidation = NumberType().isRequired("Required");

export const tagCommonNameValidation = StringType().isRequired("Required");

export const tagBrowseNameValidation = StringType().isRequired("Required");

export const tagDisplayNameValidation = StringType().isRequired("Required");

export const unitValidation = StringType().isRequired("Required");

export const tagHhLimitValidation = NumberType()
    .isRequired("Required")
    .addRule((value, form) => {
        return parseInt(value) >= parseInt(form.llLimit);
    }, "Smaller than low low limit");

export const tagHighLimitValidation = NumberType()
    .isRequired("Required")
    .addRule((value, form) => {
        let lowLimit = parseInt(form.lowLimit);
        let hhLimit = parseInt(form.hhLimit);
        value = parseInt(value);
        return lowLimit <= value && value <= hhLimit;
    }, "Not equal to or between low limit and high high limit");

export const tagLowLimitValidation = NumberType()
    .isRequired("Required")
    .addRule((value, form) => {
        let llLimit = parseInt(form.llLimit);
        let highLimit = parseInt(form.highLimit);
        value = parseInt(value);
        return llLimit <= value && value <= highLimit;
    }, "Not equal to or between low low limit and high limit");

export const tagLlLimitValidation = NumberType()
    .isRequired("Required")
    .addRule((value, form) => {
        return parseInt(value) <= parseInt(form.hhLimit);
    }, "Larger than high high limit");

export const deviceNameValidation = StringType()
    .isRequired("Required")
    .rangeLength(5, 30, "Should have between 5 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_ ]){5,30}(?<![$!@_ ])$/i, "Invalid");

export const deviceModeValidation = StringType()
    .isRequired("Required")
    .isOneOf(["r", "w", "b"], "Must be 'Read', 'Write' or 'Read/Write'");

export const deviceHostValidation = StringType()
    .isRequired("Required")
    .addRule((value, form) => {
        let regex = /^(\d{1,3}\.){3}\d{1,3}$/;
        if (value.match(regex)) {
            return true;
        }

        // If host is not ip address check that it is a valid domain name
        regex = /^([a-z]*\.)*[a-z]*$/i;
        return Boolean(value.match(regex));
    }, "Invalid");

export const portValidation = NumberType()
    .isRequired("Required")
    .isInteger("Integer required")
    .range(0, 65535, "Should be between 0 and 65535");

export const deviceAuthenticationTypeValidation = StringType()
    .isRequired("Required")
    .isOneOf(
        ["anonymous", "credentials", "keyFile"],
        "Must be 'Anonymous', 'User Authentication' or 'Key File'"
    );

export const deviceServerValidation = StringType()
    .isRequired("Required")
    .rangeLength(5, 25, "Should have between 5 and 25 characters")
    .pattern(/^(?![0-9$!@_ ])([a-z0-9$!@_. ]){5,25}(?<![$!@_ ])$/i, "Invalid");

export const keyFileValidation = ObjectType()
    .isRequired("Required")
    .addRule((value, form) => {
        let fileName = value[0].name;
        let splitIndex = fileName.lastIndexOf(".");
        if (splitIndex + 1 === fileName.length) {
            return false;
        }

        let fileType = fileName.substr(splitIndex + 1, fileName.length);

        return (
            fileType === "der" ||
            fileType === "pem" ||
            fileType === "cer" ||
            fileType === "crt"
        );
    }, "Wrong File Type. Accepting: '.der', '.pem', '.cer' and '.crt'");

export const datasetNameValidation = StringType()
    .isRequired("Required")
    .rangeLength(3, 50, "Name should be between 3 and 50 characters")
    .addRule((value, form) => {
        return value.slice(-4) !== "TEMP";
    }, "TEMP(all caps) is not allowed at the end of the name");

export const analysisNameValidation = StringType()
    .isRequired("Required")
    .rangeLength(3, 50, "Name should be between 3 and 50 characters");

export const startDateValidation = DateType()
    .isRequired("Required")
    .addRule((value, form) => {
        if (form.endDate === null) {
            return true;
        }

        return value <= form.endDate;
    }, "Start date is later than end date");

export const endDateValidation = DateType()
    .isRequired("Required")
    .addRule((value, form) => {
        if (form.startDate === null) {
            return true;
        }

        return value >= form.startDate;
    }, "End date is earlier than start date");

export const xAIStartDateRange1Validation = DateType()
    .isRequired("Required")
    .addRule((value, form) => {
        if (form.xAIEndDateRange1 === null) {
            return true;
        }

        return value <= form.xAIEndDateRange1;
    }, "Start date is later than end date");

export const xAIEndDateRange1Validation = DateType()
    .isRequired("Required")
    .addRule((value, form) => {
        if (form.xAIStartDateRange1 === null) {
            return true;
        }

        return value >= form.xAIStartDateRange1;
    }, "End date is earlier than start date");

export const xAIStartDateRange2Validation = DateType()
    .isRequired("Required")
    .addRule((value, form) => {
        if (form.xAIEndDateRange2 === null) {
            return true;
        }

        return value <= form.xAIEndDateRange2;
    }, "Start date is later than end date");

export const xAIEndDateRange2Validation = DateType()
    .isRequired("Required")
    .addRule((value, form) => {
        if (form.xAIStartDateRange2 === null) {
            return true;
        }

        return value >= form.xAIStartDateRange2;
    }, "End date is earlier than start date");
