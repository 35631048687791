/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-01-14 16:45:23
 */

import React, { Component } from "react";
import {
    Button,
    Form,
    Modal,
    Nav,
    SelectPicker,
    DateRangePicker,
    Schema
} from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import { createDataset } from "../../../services/dataset";
import { withRouter } from "react-router-dom";
import history from "../../../shared/history";
const { StringType } = Schema.Types;
import { applyPreprocessAndCreateDataset } from "../../../services/dataset";

class PreprocessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: {},
            formJSON: {
                name: ""
            },
            selectedPreprocessType: "granularity"
        };

        this.handlePreprocessNavSelect =
            this.handlePreprocessNavSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.renderPreprocessForm = this.renderPreprocessForm.bind(this);
    }

    handlePreprocessNavSelect(eventKey) {
        this.form.cleanErrors();
        this.setState({ selectedPreprocessType: eventKey, formJSON: {} });
    }

    async handleSubmit() {
        if (!this.form.check()) {
            return;
        }
        this.setState({ loading: true });

        let body = structuredClone(this.state.formJSON);
        body.preprocessType = this.state.selectedPreprocessType;

        let dataset = await applyPreprocessAndCreateDataset(
            this.props.endUserSession,
            this.props.location.pathname,
            body,
            this.props.dataset._id
        );
        if (dataset) {
            history.push({
                pathname:
                    this.props.location.pathname + "/datasets/" + dataset._id,
                state: { dataset: dataset }
            });
        } else {
            this.setState({ loading: false });
        }
    }

    handleModalOpen() {
        let formJSON = this.state.formJSON;
        for (let field in formJSON) {
            if (formJSON.hasOwnProperty(field)) {
                formJSON[field] = "";
            }
        }

        this.setState({
            formJSON: formJSON,
            errorMessages: {},
            loading: false
        });
    }

    renderPreprocessForm() {
        if (this.state.selectedPreprocessType === "granularity") {
            return (
                <Form
                    ref={(forum) => (this.form = forum)}
                    layout="horizontal"
                    formValue={this.state.formJSON}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ formJSON: newFormJSON });
                    }}
                >
                    <Form.Group>
                        <Form.ControlLabel>Granularity</Form.ControlLabel>
                        <Form.Control
                            name="granularity"
                            data={[
                                { label: "1m", value: "1m" },
                                { label: "30m", value: "30m" },
                                { label: "1h", value: "1h" }
                            ]}
                            accepter={SelectPicker}
                            rule={StringType().isRequired("Required")}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Dataset Name</Form.ControlLabel>
                        <Form.Control
                            name="name"
                            rule={validators.datasetNameValidation}
                        />
                    </Form.Group>
                </Form>
            );
        } else if (this.state.selectedPreprocessType === "range") {
            return (
                <Form
                    ref={(forum) => (this.form = forum)}
                    layout="horizontal"
                    formValue={this.state.formJSON}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ formJSON: newFormJSON });
                    }}
                >
                    <Form.Group>
                        <Form.ControlLabel>Range</Form.ControlLabel>
                        <Form.Control name="range" accepter={DateRangePicker} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Dataset Name</Form.ControlLabel>
                        <Form.Control
                            name="name"
                            rule={validators.datasetNameValidation}
                        />
                    </Form.Group>
                </Form>
            );
        } else if (this.state.selectedPreprocessType === "aggregation") {
            return (
                <Form
                    ref={(forum) => (this.form = forum)}
                    layout="horizontal"
                    formValue={this.state.formJSON}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ formJSON: newFormJSON });
                    }}
                >
                    <Form.Group>
                        <Form.ControlLabel>Aggregation</Form.ControlLabel>
                        <Form.Control
                            name="aggregation"
                            accepter={SelectPicker}
                            rule={StringType().isRequired("Required")}
                            data={[
                                { label: "1m", value: "1m" },
                                { label: "30m", value: "30m" },
                                { label: "1h", value: "1h" }
                            ]}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Dataset Name</Form.ControlLabel>
                        <Form.Control
                            name="name"
                            rule={validators.datasetNameValidation}
                        />
                    </Form.Group>
                </Form>
            );
        }

        return null;
    }

    render() {
        let preprocessForm = this.renderPreprocessForm();

        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
            >
                <Modal.Header closeButton={false}>
                    <Nav
                        activeKey={this.state.selectedPreprocessType}
                        appearance="subtle"
                        onSelect={this.handlePreprocessNavSelect}
                    >
                        <Nav.Item eventKey="granularity">
                            Set Granularity
                        </Nav.Item>
                        <Nav.Item eventKey="range">Set Range</Nav.Item>
                        <Nav.Item eventKey="aggregation">
                            Set Aggregation
                        </Nav.Item>
                    </Nav>
                </Modal.Header>

                <Modal.Body>{preprocessForm}</Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleSubmit}
                            appearance="primary"
                            type="submit"
                            loading={this.state.loading}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PreprocessModal)
);
