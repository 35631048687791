/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-15 11:07:50
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-21 19:37:07
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getLogDetails(endUserSession, clientPathName) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/logManagement/getLogDetails";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        } else if (!res.data.isValidData) {
            throw "The request is invalid.";
        } else if (!res.data.logDetails) {
            throw "The server has encountered an error.";
        }

        return res.data.logDetails;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get log details.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function downloadLog(endUserSession, clientPathName, data) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/logManagement/downloadLog";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data,
            responseType: "blob", // Important for file downloads
            validateStatus: function () {
                return true; // Prevent axios from throwing status error
            }
        });

        if (res.status === 300) {
            throw "The request is invalid.";
        }

        // Create a tag element and assign blob file to it. Then programmatically click it.
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const downloadElement = document.createElement("a");
        downloadElement.href = downloadUrl;
        downloadElement.setAttribute(
            "download",
            data.fileName + data.extension
        );
        document.body.appendChild(downloadElement);
        downloadElement.click();
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not start log download.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}
