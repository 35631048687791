/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-07-26 15:02:20
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 11:38:21
 */

import React, { Component } from "react";
import * as actions from "../../store/actions/auth";
import { connect } from "react-redux";
import Plot from "react-plotly.js";
import LegacyCheckIcon from "@rsuite/icons/legacy/Check";
import LegacyCloseIcon from "@rsuite/icons/legacy/Close";
import { Button, ButtonGroup, Loader } from "rsuite";
import { Scrollbars } from "react-custom-scrollbars";
import { refreshToken } from "../../shared/auth";
import {
    deleteTrainingProcess,
    getThresholdConfig,
    getTrendsData
} from "../../services/retrain";
import { withRouter } from "react-router-dom";

function addMonths(date, months) {
    date = new Date(date);
    let d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return Date.parse(date);
}

class ModalReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "Good",
            layout: {
                hovermode: "x unified",
                hoverdistance: 1,
                autosize: true,
                uirevision: false,
                xaxis: {
                    autorange: true,
                    type: "date"
                },
                yaxis: {
                    title: "P80",
                    fixedrange: false,
                    autorange: true
                },
                margin: {
                    t: 30,
                    l: 40,
                    r: 0,
                    b: 30
                },
                paper_bgcolor: "rgba(0,0,0,0)",
                modebar: {
                    bgcolor: "rgba(0,0,0,0)",
                    color: "black",
                    activecolor: "#2c1c94"
                }
            },
            loading: true,
            loadingPlot: false,
            trainingTraces: [
                {
                    x: [],
                    y: [],
                    name: "Actual",
                    mode: "lines",
                    line: { color: "green" }
                },
                {
                    x: [],
                    y: [],
                    name: "Predicted",
                    mode: "lines",
                    line: { color: "#120078" }
                }
            ],
            testingTraces: [
                {
                    x: [],
                    y: [],
                    name: "Actual",
                    mode: "lines",
                    line: { color: "green" }
                },
                {
                    x: [],
                    y: [],
                    name: "Predicted",
                    mode: "lines",
                    line: { color: "#120078" }
                }
            ],
            trainingPlotIndex: 0,
            testingPlotIndex: 0,
            currentPlotSection: "training",
            reportText: []
        };

        this.state.data = this.state.trainingTraces;

        this.getNewGraphSection = this.getNewGraphSection.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleTrainingClick = this.handleTrainingClick.bind(this);
        this.handleTestingClick = this.handleTestingClick.bind(this);
        this.handleDeleteModelClick = this.handleDeleteModelClick.bind(this);
        this.handleRightClick = this.handleRightClick.bind(this);
        this.handleLeftClick = this.handleLeftClick.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        let reportText = this.props.trainingProcess.result;

        let thresholdConfig = await getThresholdConfig(
            this.props.endUserSession,
            this.props.location.pathname,
            this.props.userDetails._id
        );

        if (thresholdConfig === null) {
            return;
        }
        thresholdConfig = thresholdConfig.configData;

        // Check if modal the modal meets thresholds and is good
        let status;
        if (
            parseFloat(reportText[0].split(":")[1]) >
                thresholdConfig.trainingAccuracy &&
            parseFloat(reportText[1].split(":")[1]) >
                thresholdConfig.testingAccuracy &&
            parseFloat(reportText[2].split(":")[1]) >
                thresholdConfig.trainingR2Score &&
            parseFloat(reportText[3].split(":")[1]) >
                thresholdConfig.testingR2Score
        ) {
            status = "Good";
        } else {
            status = "Bad";
        }

        let endDate = addMonths(
            this.props.trainingProcess.options.startDate,
            1
        );
        let body = {
            userID: this.props.userDetails._id,
            startDate: this.props.trainingProcess.options.startDate,
            endDate: endDate
        };
        let trendData = await getTrendsData(
            this.props.endUserSession,
            this.props.location.pathname,
            body
        );
        if (trendData === null) {
            return;
        }

        let trainingTraces = JSON.parse(
            JSON.stringify(this.state.trainingTraces)
        );

        trainingTraces[0].x = trendData.trainingOutput.date;
        trainingTraces[0].y = trendData.trainingOutput.actual_p80;

        trainingTraces[1].x = trendData.trainingOutput.date;
        trainingTraces[1].y = trendData.trainingOutput.prediction;

        // endDate = addMonths(this.props.trainingProcess.testStartDate, 1);
        // body = {
        //     userID: this.props.userDetails._id,
        //     startDate: this.props.trainingProcess.testStartDate,
        //     endDate: endDate
        // };
        // trendData = await getTrendsData(
        //     this.props.endUserSession,
        //     this.props.location.pathname,
        //     body
        // );
        // if (trendData === null) {
        //     return;
        // }

        let testingTraces = JSON.parse(
            JSON.stringify(this.state.testingTraces)
        );

        testingTraces[0].x = trendData.testingOutput.date;
        testingTraces[0].y = trendData.testingOutput.actual_p80;

        testingTraces[1].x = trendData.testingOutput.date;
        testingTraces[1].y = trendData.testingOutput.prediction;

        let layout = JSON.parse(JSON.stringify(this.state.layout));

        this.setState({
            loading: false,
            status: status,
            reportText: reportText,
            trainingTraces: trainingTraces,
            testingTraces: testingTraces,
            layout: layout
        });
        this.handleTrainingClick();
    }

    async getNewGraphSection(direction) {
        this.setState({ loadingPlot: true });

        let plotIndexOffset;
        if (direction === "right") {
            plotIndexOffset = 1;
        } else {
            plotIndexOffset = -1;
        }

        let layout = JSON.parse(JSON.stringify(this.state.layout));
        layout.xaxis.autorange = true;
        layout.yaxis.autorange = true;
        if (this.state.currentPlotSection === "training") {
            let trainingPlotIndex =
                this.state.trainingPlotIndex + plotIndexOffset;

            let startDate = addMonths(
                this.props.trainingProcess.options.startDate,
                trainingPlotIndex
            );
            let endDate = addMonths(startDate, 1);

            let body = {
                userID: this.props.userDetails._id,
                startDate: startDate,
                endDate: endDate
            };

            let trendData = await getTrendsData(
                this.props.endUserSession,
                this.props.location.pathname,
                body
            );
            if (trendData === null) {
                this.setState({
                    loadingPlot: false
                });
                return;
            }

            let trainingTraces = JSON.parse(
                JSON.stringify(this.state.trainingTraces)
            );

            trainingTraces[0].x = trendData.trainingOutput.date;
            trainingTraces[0].y = trendData.trainingOutput.actual_p80;

            trainingTraces[1].x = trendData.trainingOutput.date;
            trainingTraces[1].y = trendData.trainingOutput.prediction;

            this.setState({
                layout: layout,
                loadingPlot: false,
                trainingTraces: trainingTraces,
                trainingPlotIndex: trainingPlotIndex
            });
        } else {
            let testingPlotIndex =
                this.state.testingPlotIndex + plotIndexOffset;

            let startDate = addMonths(
                this.props.trainingProcess.testStartDate,
                testingPlotIndex
            );
            let endDate = addMonths(startDate, 1);

            let body = {
                userID: this.props.userDetails._id,
                startDate: startDate,
                endDate: endDate
            };

            let trendData = await getTrendsData(
                this.props.endUserSession,
                this.props.location.pathname,
                body
            );
            if (trendData === null) {
                this.setState({
                    loadingPlot: false
                });
                return;
            }

            let testingTraces = JSON.parse(
                JSON.stringify(this.state.testingTraces)
            );

            testingTraces[0].x = trendData.testingOutput.date;
            testingTraces[0].y = trendData.testingOutput.actual_p80;
            testingTraces[1].x = trendData.testingOutput.date;
            testingTraces[1].y = trendData.testingOutput.prediction;

            this.setState({
                layout: layout,
                loadingPlot: false,
                testingTraces: testingTraces,
                testingPlotIndex: testingPlotIndex
            });
        }
    }

    handleBackClick() {
        this.props.onStepChange(1);
    }

    handleTestingClick() {
        let layout = JSON.parse(JSON.stringify(this.state.layout));
        layout.xaxis.autorange = true;
        layout.yaxis.autorange = true;
        this.setState({
            layout: layout,
            currentPlotSection: "testing"
        });
    }

    handleTrainingClick() {
        let layout = JSON.parse(JSON.stringify(this.state.layout));
        layout.xaxis.autorange = true;
        layout.yaxis.autorange = true;
        this.setState({
            layout: layout,
            currentPlotSection: "training"
        });
    }

    async handleDeleteModelClick() {
        let result = await deleteTrainingProcess(
            this.props.endUserSession,
            this.props.location.pathname,
            this.props.userDetails._id
        );
        if (result) {
            this.props.onStepChange(0);
            this.props.onTrainingProcessChange(null);
        }
    }

    async handleRightClick() {
        await this.getNewGraphSection("right");
    }

    async handleLeftClick() {
        await this.getNewGraphSection("left");
    }

    render() {
        let iconDetails = {};
        let icon = null;
        if (this.state.status === "Good") {
            iconDetails.name = "check";
            iconDetails.color = "#00FF00";
            icon = <LegacyCheckIcon style={{ color: "#00FF00" }} />;
        } else {
            iconDetails.name = "close";
            iconDetails.color = "#f44336";
            icon = <LegacyCloseIcon style={{ color: "#f44336" }} />;
        }
        let reportsText = [];
        this.state.reportText.map((line) => {
            reportsText.push(<div>{line}</div>);
        });

        let trainingButtonAppearance;
        let testingButtonAppearance;
        let currentTraces;
        let disabledLeftButton;
        let disabledRightButton;
        if (this.state.currentPlotSection === "training") {
            currentTraces = this.state.trainingTraces;
            trainingButtonAppearance = "primary";
            testingButtonAppearance = "ghost";
            disabledLeftButton =
                this.state.trainingPlotIndex === 0 ||
                this.state.loadingPlot ||
                this.state.loading;
            disabledRightButton =
                currentTraces[0].x[currentTraces[0].x.length - 1] >=
                    this.props.trainingProcess.trainEndDate ||
                this.state.loadingPlot ||
                this.state.loading;
        } else {
            currentTraces = this.state.testingTraces;
            trainingButtonAppearance = "ghost";
            testingButtonAppearance = "primary";
            disabledLeftButton =
                this.state.testingPlotIndex === 0 ||
                this.state.loadingPlot ||
                this.state.loading;
            disabledRightButton =
                currentTraces[0].x[currentTraces[0].x.length - 1] >=
                    this.props.trainingProcess.test_end_date ||
                this.state.loadingPlot ||
                this.state.loading;
        }

        let modelStatusSection;
        if (!this.state.loading) {
            modelStatusSection = (
                <h4
                    style={{
                        marginBottom: "10px",
                        textAlign: "center"
                    }}
                >
                    Modal Status: {this.state.status}
                    {icon}
                </h4>
            );
        } else {
            modelStatusSection = (
                <h4
                    style={{
                        marginBottom: "10px",
                        textAlign: "center"
                    }}
                >
                    Modal Status: <Loader />
                </h4>
            );
        }

        return (
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <h3
                    style={{
                        color: "rgb(18, 0, 120)",
                        marginBottom: "30px"
                    }}
                >
                    Model Reports
                </h3>
                <ButtonGroup>
                    <Button
                        style={{
                            width: "150px"
                        }}
                        onClick={this.handleTrainingClick}
                        disabled={this.state.loading}
                        appearance={trainingButtonAppearance}
                    >
                        Training
                    </Button>
                    <Button
                        style={{
                            width: "150px",
                            marginLeft: "0px"
                        }}
                        onClick={this.handleTestingClick}
                        disabled={this.state.loading}
                        appearance={testingButtonAppearance}
                    >
                        Testing
                    </Button>
                </ButtonGroup>
                <div style={{ position: "relative" }}>
                    <Plot
                        useResizeHandler
                        data={currentTraces}
                        layout={this.state.layout}
                        config={{
                            displaylogo: false
                        }}
                        style={{
                            marginBottom: "10px",
                            height: "300px"
                        }}
                        onInitialized={this.testI}
                        onUpdate={this.testUpdate}
                    />
                    {this.state.loading || this.state.loadingPlot ? (
                        <Loader
                            size="lg"
                            style={{
                                position: "absolute",
                                left: "calc(50% - 64px)",
                                top: "calc(50% - 64px)"
                            }}
                        />
                    ) : null}
                </div>
                <div>
                    <Button
                        appearance="primary"
                        disabled={disabledLeftButton}
                        onClick={this.handleLeftClick}
                    >
                        {"<"}
                    </Button>
                    <Button
                        appearance="primary"
                        disabled={disabledRightButton}
                        onClick={this.handleRightClick}
                        style={{ float: "right", marginLeft: "10px" }}
                    >
                        {">"}
                    </Button>
                </div>

                <h5
                    style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                        paddingLeft: "5px"
                    }}
                >
                    Analysis
                </h5>
                <div
                    style={{
                        position: "relative",
                        flex: 1,
                        border: "3px solid rgb(18, 0, 120)",
                        borderRadius: "5px",
                        width: "100%",
                        minHeight: "191px",
                        marginBottom: "20px",
                        padding: "10px"
                    }}
                >
                    <Scrollbars>
                        <p1>{reportsText}</p1>
                    </Scrollbars>
                    {this.state.loading ? (
                        <Loader
                            size="lg"
                            style={{
                                position: "absolute",
                                left: "calc(50% - 64px)",
                                top: "calc(50% - 64px)"
                            }}
                        />
                    ) : null}
                </div>
                {modelStatusSection}
                <div>
                    <Button appearance="primary" onClick={this.handleBackClick}>
                        Retrain Model
                    </Button>
                    <Button
                        appearance="primary"
                        disabled={this.state.loading}
                        style={{ float: "right", marginLeft: "10px" }}
                    >
                        Deploy Model
                    </Button>
                    <Button
                        appearance="primary"
                        onClick={this.handleDeleteModelClick}
                        disabled={this.state.loading}
                        style={{ float: "right", marginLeft: "10px" }}
                    >
                        Delete Model
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ModalReport)
);
