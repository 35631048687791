/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2022-01-14 16:45:51
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-06-10 15:45:29
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getDataset(endUserSession, clientPathName, ID) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/datasets/" + ID;

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data === null) {
            return res.data;
        }

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not get the dataset.
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getDatasets(endUserSession, clientPathName) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/datasets";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not get list of datasets.
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function createDataset(endUserSession, clientPathName, dataset) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/datasets";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: dataset
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not create dataset.
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function applyPreprocessAndCreateDataset(
    endUserSession,
    clientPathName,
    dataset,
    ID
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/datasets/" +
            ID +
            "/applyPreprocess";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: dataset
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not preprocess current dataset and create a new
                        dataset.
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function mergeDatasets(endUserSession, clientPathName, body) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/datasets/merge";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: body
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not merge datasets.
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function updateDataset(
    endUserSession,
    clientPathName,
    dataset,
    ID
) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/datasets/" + ID;

        let res = await axios({
            method: "put",
            url: url,
            withCredentials: true,
            data: dataset
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not update dataset.
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function deleteDataset(endUserSession, clientPathName, ID) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/datasets/" + ID;

        let res = await axios({
            method: "delete",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not delete dataset.
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function startUploadData(
    endUserSession,
    clientPathName,
    ID,
    data
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/datasets/" +
            ID +
            "/uploadDataChunk/start";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not start data upload
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function continueUploadData(
    endUserSession,
    clientPathName,
    ID,
    data
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/datasets/" +
            ID +
            "/uploadDataChunk";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not upload data chunk
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function endUploadData(endUserSession, clientPathName, ID) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/datasets/" +
            ID +
            "/uploadDataChunk/end";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not finish data upload
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function getFirstDataChunk(endUserSession, clientPathName, ID) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/datasets/" +
            ID +
            "/firstDataChunk";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not get first data chunk
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getDataChunk(endUserSession, clientPathName, ID) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/datasets/" +
            ID +
            "/dataChunk";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={60000}>
                {
                    <text>
                        Could not get data chunk
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}
