/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-15 11:07:40
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-10 12:47:00
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getAllIndustrials(
    endUserSession,
    clientPathName,
    startDateTime,
    endDateTime
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/industrial?startEpoch=" +
            startDateTime +
            "&endEpoch=" +
            endDateTime;

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data.industrials;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={10000}>{<text>
                Could not get industrials.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function updateIndustrials(
    endUserSession,
    clientPathName,
    startDateTime,
    endDateTime,
    industrials
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/industrial?startEpoch=" +
            startDateTime +
            "&endEpoch=" +
            endDateTime;

        let res = await axios({
            method: "put",
            url: url,
            withCredentials: true,
            data: { industrials }
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data.industrials;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={10000}>{<text>
                Could not update industrials.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}
