/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:19:36
 */

import React, { Component } from "react";

import {
    IconButton,
    ButtonToolbar,
    Table,
    Checkbox,
    Whisper,
    Popover
} from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import LegacyMinusIcon from "@rsuite/icons/legacy/Minus";
import LegacyPencilIcon from "@rsuite/icons/legacy/Pencil";
import AddUserModal from "../components/modals/userManagement/addUserModal";
import EditUserModal from "../components/modals/userManagement/editUserModal";
import DeleteUserModal from "../components/modals/userManagement/deleteUserModal";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import { getAllRoles } from "../services/roleManagement";
import { getAllProjects } from "../services/projectManagement";
import { getAllClients } from "../services/clientManagement";
import { getAllUsers } from "../services/userManagement";

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingAddUserModel: false,
            showingVerifyDeletionModel: false,
            showingEditUserModal: false,
            rows: [],
            checkCount: 0,
            sideNavExpanded: true,
            roles: [],
            projects: [],
            clients: []
        };

        this.getTableData = this.getTableData.bind(this);
        this.getModalData = this.getModalData.bind(this);
        this.handleAddUserModalClose = this.handleAddUserModalClose.bind(this);
        this.handleEditUserModalClose =
            this.handleEditUserModalClose.bind(this);
        this.handleDeleteUserModalClose =
            this.handleDeleteUserModalClose.bind(this);
        this.handleAddUserButtonClick =
            this.handleAddUserButtonClick.bind(this);
        this.handleEditUserButtonClick =
            this.handleEditUserButtonClick.bind(this);
        this.handleDeleteUserButtonClick =
            this.handleDeleteUserButtonClick.bind(this);
        this.toggleCheckAll = this.toggleCheckAll.bind(this);
        this.toggleCheck = this.toggleCheck.bind(this);
        this.handleExpandToggle = this.handleExpandToggle.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.getTableData();
        await this.getModalData();
    }

    async getTableData() {
        let users = await getAllUsers(this.props.endUserSession);
        if (users == null) {
            users = [];
        }

        for (let user of users) {
            user.checked = false;
            if (
                user.hasOwnProperty("projectIDs") &&
                Array.isArray(user.projectIDs)
            ) {
                user.number = user.projectIDs.length;
            }
        }
        this.setState({ rows: users, checkCount: 0 });
    }

    async getModalData() {
        let roles = await getAllRoles(this.props.endUserSession);
        if (roles == null) {
            roles = [];
        }

        let projects = await getAllProjects(this.props.endUserSession);
        if (projects == null) {
            projects = [];
        }

        if (this.props.userDetails.userType !== "SuperAdmin") {
            this.setState({
                roles: roles,
                projects: projects
            });
            return;
        }

        let clients = await getAllClients(this.props.endUserSession);
        if (clients == null) {
            clients = [];
        }

        this.setState({
            roles: roles,
            projects: projects,
            clients: clients
        });
    }

    handleAddUserModalClose() {
        this.setState({ showingAddUserModel: false });
    }

    handleEditUserModalClose() {
        this.setState({ showingEditUserModal: false });
    }

    handleDeleteUserModalClose() {
        this.setState({ showingVerifyDeletionModel: false });
    }

    handleAddUserButtonClick() {
        this.setState({ showingAddUserModel: true });
    }

    handleEditUserButtonClick() {
        this.setState({ showingEditUserModal: true });
    }

    handleDeleteUserButtonClick() {
        this.setState({ showingVerifyDeletionModel: true });
    }

    toggleCheckAll(_, checked) {
        for (let row of this.state.rows) {
            row.checked = checked;
        }

        if (checked) {
            this.setState({ checkCount: this.state.rows.length });
        } else {
            this.setState({ checkCount: 0 });
        }
    }

    toggleCheck(rowData, checked) {
        rowData.checked = checked;
        let checkCount = this.state.checkCount;
        if (checked) {
            this.setState({ checkCount: checkCount + 1 });
        } else {
            this.setState({ checkCount: checkCount - 1 });
        }
    }

    handleExpandToggle(expanded) {
        this.setState({ sideNavExpanded: expanded });
    }

    rowClassName(rowData) {
        if (rowData === undefined) {
            return null;
        }
        if (rowData.status === "active") {
            return null;
        }
        return "inactive-row";
    }

    render() {
        const { Column, HeaderCell, Cell } = Table;

        let checkedUsers = [];
        for (let row of this.state.rows) {
            if (row.checked) {
                checkedUsers.push(row);
            }
        }

        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    User Management
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Here you can add, delete and edit users. You can
                                also map users to certain projects.
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        appearance="primary"
                    />
                </Whisper>

                <ButtonToolbar>
                    <IconButton
                        icon={<LegacyPlusIcon />}
                        appearance="primary"
                        circle
                        onClick={this.handleAddUserButtonClick}
                    />
                    <IconButton
                        icon={<LegacyMinusIcon />}
                        appearance="primary"
                        circle
                        onClick={this.handleDeleteUserButtonClick}
                        disabled={this.state.checkCount === 0}
                    />
                    <IconButton
                        icon={<LegacyPencilIcon />}
                        appearance="primary"
                        circle
                        onClick={this.handleEditUserButtonClick}
                        disabled={this.state.checkCount !== 1}
                    />
                </ButtonToolbar>

                <Table
                    bordered
                    cellBordered={true}
                    autoHeight
                    data={this.state.rows}
                    rowClassName={this.rowClassName}
                    style={{ marginTop: "20px" }}
                >
                    <Column
                        fixed
                        align="center"
                        verticalAlign="middle"
                        width={50}
                        style={{ padding: "0" }}
                    >
                        <HeaderCell>
                            <Checkbox
                                inline
                                defaultChecked={false}
                                checked={
                                    this.state.checkCount ===
                                        this.state.rows.length &&
                                    this.state.rows.length !== 0
                                }
                                indeterminate={
                                    this.state.checkCount <
                                        this.state.rows.length &&
                                    this.state.checkCount > 0
                                }
                                style={{ margin: "0" }}
                                onChange={this.toggleCheckAll}
                            />
                        </HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <Checkbox
                                    inline
                                    defaultChecked={false}
                                    style={{ margin: "0" }}
                                    checked={rowData.checked}
                                    value={rowData}
                                    onChange={this.toggleCheck}
                                />
                            )}
                        </Cell>
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            First Name
                        </HeaderCell>
                        <Cell dataKey="firstName" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Last Name
                        </HeaderCell>
                        <Cell dataKey="lastName" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Username
                        </HeaderCell>
                        <Cell dataKey="username" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            # of assigned Projects
                        </HeaderCell>
                        <Cell dataKey="number" />
                    </Column>
                </Table>

                <AddUserModal
                    open={this.state.showingAddUserModel}
                    roles={this.state.roles}
                    clients={this.state.clients}
                    projects={this.state.projects}
                    onClose={this.handleAddUserModalClose}
                    onSubmit={this.getTableData}
                />
                <EditUserModal
                    open={this.state.showingEditUserModal}
                    roles={this.state.roles}
                    clients={this.state.clients}
                    projects={this.state.projects}
                    onClose={this.handleEditUserModalClose}
                    onSubmit={this.getTableData}
                    user={checkedUsers[0]}
                />
                <DeleteUserModal
                    open={this.state.showingVerifyDeletionModel}
                    onClose={this.handleDeleteUserModalClose}
                    onSubmit={this.getTableData}
                    users={checkedUsers}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
