/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-04-22 11:29:23
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 12:12:21
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions/index";
import Plot from "react-plotly.js";
import InfluencersGraph from "../components/UI/InfluencerAnalysis/influencerAnalysis";
import {
    IconButton,
    Popover,
    Whisper,
    DatePicker,
    Toggle,
    Form,
    Button,
    Schema,
    Loader,
    SelectPicker
} from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import { refreshToken } from "../shared/auth";
import { getPredictionModelTargetTag } from "../services/tagManagement";
import {
    getPredictionTracesInDateRange,
    getLatestNPredictionTraces
} from "../services/predictionModel";
import {
    getInfluencersInDateRange,
    getLatestInfluencerGraphData
} from "../services/influencerAnalysis";
import * as validators from "../shared/modelValidators";
import { withRouter } from "react-router-dom";
import { isWithinInterval } from "date-fns";

class PredictionModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessages: {},
            formJSON: {
                enableLiveStream: false,
                startDate: new Date("2020-01-07T18:30:00.000Z"),
                endDate: new Date("2020-01-11T18:30:00.000Z")
            },
            selectedInfluencersMode: "overall_result",
            influencerModes: [
                {
                    label: "OverAll",
                    value: "overall_result"
                },
                {
                    label: "Correlation",
                    value: "corr_result"
                },
                {
                    label: "Mutual Information",
                    value: "mutual_info_result"
                },
                {
                    label: "Importance Score",
                    value: "imp_score_result"
                }
            ],
            influencersLatestTime: "",
            layout: {
                hovermode: "x unified",
                hoverdistance: 1,
                autosize: true,
                uirevision: true, // Allows updating of data while keeping zoom level and pan location
                xaxis: {
                    autorange: true,
                    rangeslider: {
                        visible: false,
                        yaxis: {
                            rangemode: "auto"
                        }
                    },
                    type: "date",
                    tickfont: {
                        size: 14
                    }
                },
                yaxis: {
                    title: "P80",
                    fixedrange: false,
                    autorange: true,
                    tickfont: {
                        size: 14
                    }
                },
                margin: {
                    l: 40,
                    t: 30
                },
                // paper_bgcolor: "rgba(0,0,0,0)",
                // modebar: {
                //     bgcolor: "rgba(0,0,0,0)",
                //     color: "black",
                //     activecolor: "#2c1c94"
                // },
                annotations: []
            },
            traces: [],
            influencersData: {},
            currentInfluencersGraph: [],
            p80Tag: null,
            loading: false,
            liveFlag: false,
            liveStreamDisable: true
        };

        this.getPredictions = this.getPredictions.bind(this);
        this.handleGetDataClick = this.handleGetDataClick.bind(this);
        this.handleLiveStreamToggleChange =
            this.handleLiveStreamToggleChange.bind(this);
        this.handlePlotRelayout = this.handlePlotRelayout.bind(this);
        // this.getInfluencers = this.getInfluencers.bind(this);
        this.handleInfluencerModeChange =
            this.handleInfluencerModeChange.bind(this);

        let schema = {
            startDate: validators.startDateValidation,
            endDate: validators.endDateValidation
        };

        this.validationModel = Schema.Model(schema);

        this.liveUpdateInterval = null;
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await refreshToken(this.props.endUserSession);
        await this.getPredictions(true);
        // await this.getInfluencers();
        this.setState({ loading: false });
        // this.liveUpdateInterval = setInterval(this.getPredictions, 60000);
        // this.influencersInterval = setInterval(this.getInfluencers, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.liveUpdateInterval);
        clearInterval(this.influencersInterval);
    }

    handleInfluencerModeChange = (value) => {
        if (this.state.liveFlag) {
            this.setState({
                selectedInfluencersMode: value,
                currentInfluencersGraph: this.state.influencersData[value]
            });
        } else {
            let graphData = Object.values(this.state.influencersData[value]);
            this.setState({
                selectedInfluencersMode: value,
                currentInfluencersGraph: graphData
            });
        }
    };

    async getInfluencers(useDateRange = false, liveStreamTriggeredOn = false) {
        // Get predictions
        let graphData;

        if (useDateRange) {
            let startDate = this.state.formJSON.startDate;
            let endDate = this.state.formJSON.endDate;

            graphData = await getInfluencersInDateRange(
                this.props.endUserSession,
                this.props.location.pathname,
                { startDate: startDate, endDate: endDate }
            );
        } else {
            graphData = await getLatestInfluencerGraphData(
                this.props.endUserSession,
                this.props.location.pathname
            );
        }

        if (graphData.dataExists) {
            let influencersLatestTime =
                graphData.graphData.influencersLatestTime;
            graphData = graphData.graphData.graphData;

            if (useDateRange) {
                let currentGraphData = Object.values(
                    graphData[this.state.selectedInfluencersMode]
                );
                this.setState({
                    influencersData: graphData,
                    influencersLatestTime: "",
                    currentInfluencersGraph: currentGraphData
                });
            } else {
                this.setState({
                    influencersData: graphData,
                    influencersLatestTime: influencersLatestTime,
                    currentInfluencersGraph:
                        graphData[this.state.selectedInfluencersMode]
                });
            }
            return;
        }
    }

    async getPredictions(useDateRange = false, liveStreamTriggeredOn = false) {
        // Get the p80 tag info from the state, otherwise make an API call to get it
        let p80Tag;
        if (this.state.p80Tag === null) {
            p80Tag = await getPredictionModelTargetTag(
                this.props.endUserSession
            );
            if (p80Tag === null) {
                return;
            }
        } else {
            p80Tag = this.state.p80Tag;
        }

        // Get predictions
        let traces;
        if (useDateRange) {
            let startDate = this.state.formJSON.startDate;
            let timeOffset = new Date().getTimezoneOffset() * 60000;

            let endDate = this.state.formJSON.endDate;
            if (timeOffset < 0) {
                startDate = Date.parse(startDate) - timeOffset;
                startDate = new Date(startDate).toISOString();
                endDate = Date.parse(endDate) - timeOffset;
                endDate = new Date(endDate).toISOString();
            } else {
                startDate = Date.parse(startDate) + timeOffset;
                startDate = new Date(startDate).toISOString();
                endDate = Date.parse(endDate) + timeOffset;
                endDate = new Date(endDate).toISOString();
            }

            traces = await getPredictionTracesInDateRange(
                this.props.endUserSession,
                this.props.location.pathname,
                { startDate: startDate, endDate: endDate }
            );
        } else {
            traces = await getLatestNPredictionTraces(
                this.props.endUserSession,
                this.props.location.pathname
            );
        }

        // Don't continue, if no data given
        if (!traces) {
            this.setState({
                traces: []
            });
            return;
        }

        // Don't continue, if empty trace list given
        if (traces.length === 0) {
            this.setState({
                traces: []
            });
            return;
        }

        // Get and use offset to counteract rsuite date picker and plotly graph internal date conversion
        let offset = new Date().getTimezoneOffset();
        offset = offset * 60 * 1000;
        for (let trace of traces) {
            for (let i = 0; i < trace.x.length; i++) {
                trace.x[i] = trace.x[i] + offset;
            }
        }

        let layout = JSON.parse(JSON.stringify(this.state.layout));
        layout.yaxis.title = "P80 (" + p80Tag.units + ")";

        // Set autorange to true so that the graph autoscales with the data
        // gathered from a date range. However, it should not be set to true
        // when live streaming, so that it is possible to keep the zoom level
        // and pan location from resetting every couple of seconds.
        if (useDateRange || liveStreamTriggeredOn) {
            layout.xaxis.rangeslider.autorange = true;
            layout.xaxis.autorange = true;
            layout.yaxis.autorange = true;
        }

        let formJSON = JSON.parse(JSON.stringify(this.state.formJSON));
        formJSON.startDate = new Date(traces[0].x[traces[0].x.length - 1]);
        formJSON.endDate = new Date(traces[0].x[0]);
        this.setState({
            traces: traces,
            formJSON: formJSON,
            layout: layout
        });
    }

    async handleGetDataClick() {
        if (this.form.check()) {
            this.setState({ loading: true });
            await this.getPredictions(true);
            // await this.getInfluencers(true);
            this.setState({ loading: false });
        }
    }

    async handleLiveStreamToggleChange(checked) {
        if (checked) {
            this.setState({ loading: true, liveFlag: true });
            this.setState({ errorMessages: { startDate: "", endDate: "" } });
            /**
             * if the flag is not set to "true" the there is issue with rangeslider
             * when we request for different time range and zoom in to certain portion
             * of graph and then toggling to LiveStream is giving issue
             * with range slider.
             */
            await this.getPredictions(false, true);
            // await this.getInfluencers(false, true);
            this.setState({ loading: false });
            this.liveUpdateInterval = setInterval(this.getPredictions, 60000);
            // this.influencersInterval = setInterval(this.getInfluencers, 60000);
        } else {
            this.setState({ liveFlag: false });
            clearInterval(this.liveUpdateInterval);
            // clearInterval(this.influencersInterval);
        }
    }

    handlePlotRelayout(data) {
        // If there is no data don't do anything
        if (this.state.traces.length === 0) {
            return;
        }

        // Continue if appropriate operation has occurred
        // "xaxis.range[0]" in data: pan
        // "xaxis.range" in data: range slider movement
        // "xaxis.autorange" in data: autoscale
        if (
            !(
                "xaxis.range[0]" in data ||
                "xaxis.range" in data ||
                "xaxis.autorange" in data
            )
        ) {
            return;
        }

        // The lines below are a work around for a range slider bug (https://github.com/plotly/plotly.js/issues/4417)
        let layout = JSON.parse(JSON.stringify(this.state.layout));
        let traces = this.state.traces;

        layout.xaxis.rangeslider.autorange = false;
        layout.xaxis.rangeslider.range = [
            traces[0].x[traces[0].x.length - 1],
            traces[0].x[0]
        ];
        this.setState({ layout: layout });
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Prediction Model
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Here you can see the effectiveness of the
                                prediction model.
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        size="sm"
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "12px",
                            marginLeft: "10px"
                        }}
                        color="primary"
                    />
                </Whisper>

                <Form
                    ref={(forum) => (this.form = forum)}
                    layout="inline"
                    formValue={this.state.formJSON}
                    model={this.validationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ formJSON: newFormJSON });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({ errorMessages: errorMessages });
                    }}
                >
                    <Form.Group>
                        <Form.ControlLabel style={{ fontSize: "16px" }}>
                            Live Stream:
                        </Form.ControlLabel>
                        <Form.Control
                            name="enableLiveStream"
                            accepter={Toggle}
                            errorMessage={
                                this.state.errorMessages.enableLiveStream
                            }
                            size="lg"
                            disabled={this.state.liveStreamDisable}
                            onChange={this.handleLiveStreamToggleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel style={{ fontSize: "16px" }}>
                            <b style={{ marginRight: "20px" }}>Or</b> Date
                            Range: Start
                        </Form.ControlLabel>
                        <Form.Control
                            name="startDate"
                            accepter={DatePicker}
                            errorMessage={this.state.errorMessages.startDate}
                            disabled={this.state.formJSON.enableLiveStream}
                            format="dd MMM yyyy hh:mm:ss"
                            disabledDate={(date) =>
                                !isWithinInterval(date, {
                                    start: new Date("2019-12-31T00:00:00.000Z"),
                                    end: new Date("2020-03-18T00:00:00.000Z")
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel style={{ fontSize: "16px" }}>
                            End
                        </Form.ControlLabel>
                        <Form.Control
                            name="endDate"
                            accepter={DatePicker}
                            errorMessage={this.state.errorMessages.endDate}
                            disabled={this.state.formJSON.enableLiveStream}
                            format="dd MMM yyyy hh:mm:ss"
                            disabledDate={(date) =>
                                !isWithinInterval(date, {
                                    start: new Date("2019-12-31T00:00:00.000Z"),
                                    end: new Date("2020-03-18T00:00:00.000Z")
                                })
                            }
                        />
                    </Form.Group>

                    <Button
                        appearance="primary"
                        disabled={
                            this.state.formJSON.enableLiveStream ||
                            this.state.loading
                        }
                        onClick={this.handleGetDataClick}
                    >
                        Get Data
                    </Button>
                </Form>
                <div style={{ position: "relative" }}>
                    <Plot
                        data={this.state.traces}
                        layout={this.state.layout}
                        config={{
                            displaylogo: false,
                            modeBarButtonsToRemove: ["select2d", "lasso2d"],
                            staticPlot: this.state.loading
                        }}
                        useResizeHandler
                        style={
                            this.state.loading
                                ? {
                                      width: "100% - 20px",
                                      height: "80vh",
                                      opacity: 0.5
                                  }
                                : {
                                      width: "100% - 20px",
                                      height: "80vh"
                                  }
                        }
                    />
                    {this.state.loading ? (
                        <Loader
                            size="lg"
                            style={{
                                position: "absolute",
                                left: "calc(50% - 64px)",
                                bottom: "calc(70% - 64px)"
                            }}
                        />
                    ) : null}
                </div>
                {/* <div style={{ position: "relative" }}>
                    Select Algorithm:
                    <SelectPicker
                        value={this.state.selectedInfluencersMode}
                        data={this.state.influencerModes}
                        searchable={false}
                        disabled={this.state.loading}
                        onChange={this.handleInfluencerModeChange}
                        style={{ marginLeft: "20px", width: "384px" }}
                    />
                    <span>
                        {" "}
                        Latest Time: {this.state.influencersLatestTime}
                    </span>
                </div>
                <div style={{ position: "relative" }}>
                    <InfluencersGraph
                        graphData={this.state.currentInfluencersGraph}
                        loading={this.state.loading}
                    ></InfluencersGraph>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PredictionModel)
);
