/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2021-09-06 20:05:23
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-21 20:13:48
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getExplanations(endUserSession, clientPathName, data) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/explainableAI/getExplanations";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw "The server has encountered an error.";
        }

        return res.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get explanations
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}
