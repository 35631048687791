/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-01-20 15:02:24
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { refreshToken } from "../../shared/auth";
import * as actions from "../../store/actions/auth";
import { withRouter } from "react-router-dom";
import history from "../../shared/history";
import { Uploader, Button, Input, Popover, Whisper, Schema } from "rsuite";
import { AgGridReact } from "ag-grid-react";
import {
    getDataset,
    startUploadData,
    continueUploadData,
    endUploadData,
    getFirstDataChunk,
    updateDataset
} from "../../services/dataset";
import DeleteDatasetModal from "../../components/modals/nDatum/deleteDatasetModal";
import * as validators from "../../shared/modelValidators";

class DatasetManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            localDatasetChunkRows: [],
            localDatasetChunkColumnDefs: [],
            datasetChunkRows: [],
            datasetChunkColumnDefs: [],
            files: [],
            localDataset: null,
            dataset: null,
            uploadingData: false,
            showingDeleteDatasetModel: false,
            errors: { name: null }
        };

        if (props.location.state !== undefined) {
            this.state.dataset = props.location.state.dataset;
            window.history.replaceState({}, document.title); // Clear state in case of page refresh
        }

        this.defaultColDef = {
            resizable: true,
            sortable: true,
            filter: true
        };

        let schema = {
            name: validators.datasetNameValidation
        };
        this.validationModel = Schema.Model(schema);

        this.pathParts = props.location.pathname.split("/");
        this.homeURL = "/" + this.pathParts[1] + "/" + this.pathParts[2];

        this.chunkSize = 100000;

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteDatasetModelClose =
            this.handleDeleteDatasetModelClose.bind(this);
        this.handleDeleteDatasetModalSubmit =
            this.handleDeleteDatasetModalSubmit.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);

        let dataset;
        if (this.state.dataset !== null) {
            dataset = this.state.dataset;
        } else {
            dataset = await getDataset(
                this.props.endUserSession,
                this.homeURL,
                this.pathParts[4]
            );

            if (dataset === null) {
                history.goBack();
                return;
            }
        }

        if (dataset.recordCount > 0) {
            let result = await getFirstDataChunk(
                this.props.endUserSession,
                this.homeURL,
                this.pathParts[4]
            );

            if (result === null) {
                this.setState({ dataset: dataset, localDataset: dataset });
                return;
            }

            let textChunk = result.textChunk;

            let [datasetChunkRows, datasetChunkColumnDefs] =
                this.convertTextChunkToTableData(textChunk, result.isFullText);
            this.setState({
                dataset: dataset,
                localDataset: dataset,
                datasetChunkRows: datasetChunkRows,
                datasetChunkColumnDefs: datasetChunkColumnDefs
            });
        } else {
            this.setState({ dataset: dataset, localDataset: dataset });
        }
    }

    convertTextChunkToTableData(textChunk, isFullText) {
        // Get the column headers
        let rows = textChunk.split(/\r?\n/);
        let columnHeaders = rows[0].split(",");
        let previewColumnDefs = [];
        for (let columnHeader of columnHeaders) {
            previewColumnDefs.push({ field: columnHeader });
        }

        /*Remove the last, most likely incomplete, row, if the first chunk
        doesn't have all the data */
        if (!isFullText) {
            rows.pop();
        }
        // Remove the header row
        rows.shift();

        // Build the preview table with the data available
        let previewRows = [];
        for (let row of rows) {
            if (row === "") {
                continue;
            }
            let columns = row.split(",");
            let previewRow = {};
            for (let i = 0; i < columns.length; i++) {
                previewRow[columnHeaders[i]] = columns[i];
            }
            previewRows.push(previewRow);
        }

        return [previewRows, previewColumnDefs];
    }

    async handleFileChange(files) {
        if (files.length === 0) {
            this.setState({
                files: []
            });
            return;
        }

        let latestFile = files[files.length - 1].blobFile;

        /* If the file is very big, grab the first chunk of it.
        This is used to avoid memory issues. */
        this.firstChunk = await latestFile.slice(0, this.chunkSize).text();
        this.secondChunk = await latestFile
            .slice(this.chunkSize, this.chunkSize * 2)
            .text();

        let [localDatasetChunkRows, localDatasetChunkColumnDefs] =
            this.convertTextChunkToTableData(
                this.firstChunk,
                this.secondChunk.length === 0
            );

        this.setState({
            files: [files[files.length - 1]],
            localDatasetChunkRows: localDatasetChunkRows,
            localDatasetChunkColumnDefs: localDatasetChunkColumnDefs
        });
    }

    async handleSaveClick() {
        if (this.state.localDataset.name !== this.state.dataset.name) {
            let checkResult = this.validationModel.check({
                name: this.state.localDataset.name
            });
            if (checkResult.name.hasError) {
                this.setState({
                    errors: { name: checkResult.name.errorMessage }
                });
                this.nameErrorWhisper.open();
                return;
            }
            this.nameErrorWhisper.close();

            let dataset = await updateDataset(
                this.props.endUserSession,
                this.homeURL,
                { name: this.state.localDataset.name },
                this.state.localDataset._id
            );

            if (dataset === null) {
                this.setState({ errors: { name: null } });
                return;
            }
            this.setState({
                dataset: dataset,
                localDataset: dataset,
                errors: { name: null }
            });
        }

        if (this.state.files.length === 0) {
            return;
        }

        this.setState({ uploadingData: true });

        let result = await startUploadData(
            this.props.endUserSession,
            this.homeURL,
            this.state.dataset._id,
            { textChunk: this.firstChunk }
        );

        if (!result) {
            this.setState({ uploadingData: false });
            return;
        }

        let currentChunk = this.secondChunk;
        let currentSplitIndex = 1;
        while (currentChunk.length !== 0) {
            result = await continueUploadData(
                this.props.endUserSession,
                this.homeURL,
                this.state.dataset._id,
                { textChunk: currentChunk }
            );

            if (!result) {
                this.setState({ uploadingData: false });
                return;
            }

            currentSplitIndex += 1;
            currentChunk = await this.state.files[0].blobFile
                .slice(
                    this.chunkSize * currentSplitIndex,
                    this.chunkSize * (currentSplitIndex + 1)
                )
                .text();
        }

        result = await endUploadData(
            this.props.endUserSession,
            this.homeURL,
            this.state.dataset._id
        );

        if (result) {
            this.setState({
                uploadingData: false,
                files: [],
                datasetChunkRows: this.state.localDatasetChunkRows,
                datasetChunkColumnDefs: this.state.localDatasetChunkColumnDefs
            });
        }
    }

    handleBackClick() {
        history.goBack();
    }

    handleNameChange(name) {
        let localDataset = JSON.parse(JSON.stringify(this.state.localDataset));
        localDataset.name = name;
        this.setState({ localDataset });
    }

    async handleDeleteClick() {
        this.setState({ showingDeleteDatasetModel: true });
    }

    handleDeleteDatasetModelClose() {
        this.setState({ showingDeleteDatasetModel: false });
    }

    handleDeleteDatasetModalSubmit() {
        history.replace(this.homeURL);
    }

    render() {
        let tableTopMargin;
        let rows;
        let columnDefs;
        if (this.state.files.length === 0) {
            tableTopMargin = "56px";
            rows = this.state.datasetChunkRows;
            columnDefs = this.state.datasetChunkColumnDefs;
        } else {
            rows = this.state.localDatasetChunkRows;
            columnDefs = this.state.localDatasetChunkColumnDefs;
            tableTopMargin = "10px";
        }

        let datasetModified = false;
        if (this.state.localDataset !== null && this.state.dataset !== null) {
            if (
                this.state.localDataset.name !== this.state.dataset.name ||
                this.state.files.length !== 0
            ) {
                datasetModified = true;
            }
        }

        return (
            <div style={{ margin: "20px" }}>
                <div style={{ display: "inline-block" }}>Name:</div>
                {this.state.localDataset !== null ? (
                    <Whisper
                        ref={(nameErrorWhisper) => {
                            this.nameErrorWhisper = nameErrorWhisper;
                        }}
                        placement="bottomStart"
                        trigger="none"
                        speaker={
                            <Popover
                                style={{
                                    color: "#f44336",
                                    margin: "0",
                                    paddingTop: "4px",
                                    paddingRight: "8px",
                                    paddingBottom: "4px",
                                    paddingLeft: "8px"
                                }}
                            >
                                {this.state.errors.name}
                            </Popover>
                        }
                    >
                        <Input
                            value={this.state.localDataset.name}
                            disabled={this.state.uploadingData}
                            onChange={this.handleNameChange}
                            style={{
                                display: "inline-block",
                                width: "200px",
                                marginLeft: "10px"
                            }}
                        />
                    </Whisper>
                ) : null}

                <Uploader
                    action={""}
                    autoUpload={false}
                    accept={".csv"}
                    draggable
                    multiple={false}
                    fileList={this.state.files}
                    onChange={this.handleFileChange}
                    disabled={this.state.uploadingData}
                    disabledFileItem={this.state.uploadingData}
                    removable={true}
                    style={{ marginTop: "20px" }}
                    onError={(error) => {
                        console.log(error);
                    }}
                >
                    <div>
                        To upload data, drag a csv file over or click here and
                        select a csv file. The csv file should have headers.
                    </div>
                </Uploader>

                <div
                    className="ag-theme-alpine"
                    style={{ height: 408, marginTop: tableTopMargin }}
                >
                    <AgGridReact
                        rowData={rows}
                        columnDefs={columnDefs}
                        defaultColDef={this.defaultColDef}
                        multiSortKey={"ctrl"}
                        paginationAutoPageSize
                        pagination
                        rowDragManaged // Allow drag and drop
                        animateRows // Allow animation when dragging
                    />
                </div>
                <div style={{ float: "right", marginTop: "20px" }}>
                    <Button
                        appearance={"primary"}
                        onClick={this.handleSaveClick}
                        loading={this.state.uploadingData}
                        disabled={!datasetModified}
                    >
                        Save
                    </Button>
                    <Button
                        appearance={"primary"}
                        onClick={this.handleDeleteClick}
                        style={{ marginLeft: "10px" }}
                        disabled={this.state.uploadingData}
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={this.handleBackClick}
                        style={{ marginLeft: "10px" }}
                        disabled={this.state.uploadingData}
                    >
                        Back
                    </Button>
                </div>

                <DeleteDatasetModal
                    open={this.state.showingDeleteDatasetModel}
                    onClose={this.handleDeleteDatasetModelClose}
                    onSubmit={this.handleDeleteDatasetModalSubmit}
                    dataset={this.state.dataset}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DatasetManager)
);
