/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-10 15:03:18
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-04-04 16:59:34
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions/auth";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Tree, CheckPicker } from "rsuite";

class stepOrganizer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSteps: [],
            functionalityTree: JSON.parse(
                JSON.stringify(props.functionalityTree)
            )
        };
        this.reformatFunctionalityTree(
            this.state.functionalityTree[0],
            this.state.selectedSteps
        );
        this.state.selectedFunctionality = this.state.functionalityTree[0];
        this.state.selectableSteps =
            this.state.functionalityTree[0].activeFunctionChildren;

        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.handleTreeSelect = this.handleTreeSelect.bind(this);
        this.handleAuthorizedRoleSelect =
            this.handleAuthorizedRoleSelect.bind(this);
        this.handleAuthorizedRoleClean =
            this.handleAuthorizedRoleClean.bind(this);

        this.getSelectedStepsCardStyle =
            this.getSelectedStepsCardStyle.bind(this);

        this.renderSelectableDraggable =
            this.renderSelectableDraggable.bind(this);
        this.renderSelectedDraggable = this.renderSelectedDraggable.bind(this);

        this.stopTransforms = false;
    }

    reformatFunctionalityTree(node, selectedSteps) {
        node.activeCategoryChildren = [];
        node.activeFunctionChildren = [];

        for (let child of node.children) {
            child.parent = node;
            if (child.data.type === "category") {
                if (child.status === "active") {
                    node.activeCategoryChildren.push(child);
                }
                this.reformatFunctionalityTree(child, selectedSteps);
            } else {
                if (this.props.initialSelectedSteps) {
                    let index = this.props.initialSelectedSteps.findIndex(
                        (step) => step.functionality_id === child.value
                    );
                    if (index > -1) {
                        selectedSteps[index] = Object.assign({}, child);
                        selectedSteps[index].authorizedRoles =
                            this.props.initialSelectedSteps[
                                index
                            ].authorizedRoles;
                    } else {
                        node.activeFunctionChildren.push(child);
                    }
                } else {
                    node.activeFunctionChildren.push(child);
                }
            }
        }

        if (node.activeCategoryChildren.length === 0) {
            delete node.activeCategoryChildren;
        }
    }

    determineModalDraggableOffset(provided, snapshot) {
        if (snapshot.isDragging) {
            let modalComputedStyle = window.getComputedStyle(
                document.getElementsByClassName("rs-modal")[0]
            );

            const offset = {
                x: modalComputedStyle.getPropertyValue("margin-left"),
                y: parseInt(modalComputedStyle.getPropertyValue("margin-top"))
            };
            let x = provided.draggableProps.style.left - offset.x;
            let y = provided.draggableProps.style.top - offset.y;
            provided.draggableProps.style.left = x;
            provided.draggableProps.style.top = y;
        }
    }

    getSelectedStepsCardStyle(draggablePropsStyle, snapshot, functionality) {
        if (
            snapshot.isDropAnimating &&
            snapshot.draggingOver !== "selectedSteps"
        ) {
            draggablePropsStyle.transition = null;
            draggablePropsStyle.opacity = 0;
            this.stopTransforms = true;
        }

        if (this.stopTransforms) {
            draggablePropsStyle.transform = null;
        }

        let finalStyle = {
            userSelect: "none",
            padding: 16,
            margin: "0 0 8px 0",
            minHeight: "50px",
            border: "2px solid #120078",
            backgroundColor: "white",
            ...draggablePropsStyle
        };

        if (functionality.status === "inactive") {
            finalStyle.color = "lightgrey";
        }

        return finalStyle;
    }

    getSelectableStepsCardStyle(draggablePropsStyle, functionality) {
        let finalStyle = {
            userSelect: "none",
            padding: 16,
            margin: "0 0 8px 0",
            minHeight: "50px",
            border: "2px solid #120078",
            backgroundColor: "white",
            ...draggablePropsStyle
        };

        if (functionality.status === "inactive") {
            finalStyle.color = "lightgrey";
        }

        return finalStyle;
    }

    handleDragEnd(result) {
        this.stopTransforms = false;
        const { source, destination } = result;
        let sourceSteps = this.state[source.droppableId];

        if (!result.destination) {
            if (source.droppableId === "selectedSteps") {
                const [removed] = sourceSteps.splice(source.index, 1);
                delete removed.authorizedRoles;
                removed.parent.activeFunctionChildren.push(removed);
                this.setState({ selectedSteps: sourceSteps });
                this.props.onStepChange(sourceSteps);
            }
            return;
        }

        let destinationSteps = this.state[destination.droppableId];
        if (source.droppableId !== destination.droppableId) {
            const [removed] = sourceSteps.splice(source.index, 1);
            removed.authorizedRoles = [];
            destinationSteps.splice(destination.index, 0, removed);
            this.setState({
                [source.droppableId]: sourceSteps,
                [destination.droppableId]: destinationSteps
            });
        } else {
            const [removed] = sourceSteps.splice(source.index, 1);
            sourceSteps.splice(destination.index, 0, removed);
            this.setState({ [source.droppableId]: sourceSteps });
        }

        if (source.droppableId === "selectedSteps") {
            this.props.onStepChange(sourceSteps);
        } else if (destination.droppableId === "selectedSteps") {
            this.props.onStepChange(destinationSteps);
        }
    }

    handleTreeSelect(activeNode, value, event) {
        this.setState({
            selectedFunctionality: activeNode,
            selectableSteps: activeNode.activeFunctionChildren
        });
    }

    handleAuthorizedRoleSelect(value, index) {
        let selectedSteps = [...this.state.selectedSteps];
        selectedSteps[index] = Object.assign({}, selectedSteps[index]);
        selectedSteps[index].authorizedRoles = value;
        this.setState({ selectedSteps: selectedSteps });
        this.props.onStepChange(selectedSteps);
    }

    handleAuthorizedRoleClean(index) {
        let selectedSteps = [...this.state.selectedSteps];
        selectedSteps[index] = Object.assign({}, selectedSteps[index]);
        selectedSteps[index].authorizedRoles = [];
        this.setState({ selectedSteps: selectedSteps });
        this.props.onStepChange(selectedSteps);
    }

    renderSelectableDraggable(item, index) {
        return (
            <Draggable
                key={item.value}
                draggableId={item.value}
                index={index}
                isDragDisabled={this.props.hasActiveJob}
            >
                {(provided, snapshot) => {
                    this.determineModalDraggableOffset(provided, snapshot);

                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getSelectableStepsCardStyle(
                                provided.draggableProps.style,
                                item
                            )}
                        >
                            {item.label}
                        </div>
                    );
                }}
            </Draggable>
        );
    }

    renderSelectedDraggable(item, index) {
        return (
            <Draggable
                key={item.value}
                draggableId={item.value}
                index={index}
                isDragDisabled={
                    this.props.userDetails.userType === "Admin" ||
                    this.props.hasActiveJob
                }
            >
                {(provided, snapshot) => {
                    this.determineModalDraggableOffset(provided, snapshot);

                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getSelectedStepsCardStyle(
                                provided.draggableProps.style,
                                snapshot,
                                item
                            )}
                        >
                            {item.label}
                            <CheckPicker
                                value={item.authorizedRoles}
                                data={this.props.roles}
                                valueKey="_id"
                                labelKey="name"
                                onSelect={(value) => {
                                    this.handleAuthorizedRoleSelect(
                                        value,
                                        index
                                    );
                                }}
                                onClean={() => {
                                    this.handleAuthorizedRoleClean(index);
                                }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    );
                }}
            </Draggable>
        );
    }

    render() {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "50vh",
                    userSelect: "none"
                }}
            >
                {this.props.userDetails.userType === "SuperAdmin" ? (
                    <div
                        style={{
                            height: "50vh - 50px",
                            flexGrow: "2",
                            overflow: "auto",
                            backgroundColor: "rgb(255, 255, 255)",
                            borderStyle: "solid",
                            borderLeftWidth: "1px",
                            borderTopWidth: "1px",
                            borderBottomWidth: "1px",
                            borderRightWidth: "0px",
                            borderColor: "rgb(229, 229, 234)",
                            borderBottomLeftRadius: "6px",
                            borderTopLeftRadius: "6px",
                            paddingLeft: "20px",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            paddingRight: "0px"
                        }}
                    >
                        <Tree
                            data={this.state.functionalityTree}
                            childrenKey="activeCategoryChildren"
                            value={this.state.selectedFunctionality.value}
                            onSelect={this.handleTreeSelect}
                            style={{ maxHeight: "none" }}
                        />
                    </div>
                ) : null}

                <DragDropContext onDragEnd={this.handleDragEnd}>
                    {this.props.userDetails.userType === "SuperAdmin" ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                borderStyle: "solid",
                                borderLeftWidth: "0px",
                                borderTopWidth: "1px",
                                borderBottomWidth: "1px",
                                borderRightWidth: "1px",
                                borderColor: "rgb(229, 229, 234)",
                                borderBottomRightRadius: "6px",
                                borderTopRightRadius: "6px",
                                paddingRight: "8px",
                                paddingLeft: "20px",
                                paddingBottom: "10px",
                                paddingTop: "38px"
                            }}
                            key={"selectableSteps"}
                        >
                            <div style={{ overflowY: "auto" }}>
                                <Droppable
                                    isDropDisabled={true}
                                    droppableId={"selectableSteps"}
                                    key={"selectableSteps"}
                                >
                                    {(provided, snapshot) => {
                                        return (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    padding: 4,
                                                    width: 250,
                                                    minHeight:
                                                        "calc(50vh - 58px)"
                                                }}
                                            >
                                                {this.state.selectableSteps.map(
                                                    this
                                                        .renderSelectableDraggable
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </div>
                        </div>
                    ) : null}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                        key={"selectedSteps"}
                    >
                        <label>Steps</label>
                        <div
                            style={{
                                margin: 8,
                                overflowY: "auto"
                            }}
                        >
                            <Droppable
                                droppableId={"selectedSteps"}
                                key={"selectedSteps"}
                            >
                                {(provided, snapshot) => {
                                    return (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{
                                                background:
                                                    snapshot.isDraggingOver
                                                        ? "#1a00ab"
                                                        : "#120078",
                                                padding: 4,
                                                width: 250,
                                                minHeight: "calc(50vh - 58px)"
                                            }}
                                        >
                                            {this.state.selectedSteps.map(
                                                this.renderSelectedDraggable
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    );
                                }}
                            </Droppable>
                        </div>
                    </div>
                </DragDropContext>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(stepOrganizer);
