/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-11 17:35:17
 */

import React, { Component } from "react";

import LegacyDatabaseIcon from "@rsuite/icons/legacy/Database";
import LegacyArrowDown2Icon from "@rsuite/icons/legacy/ArrowDown2";
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import "./stockpile.css";
import { getAllIndustrials } from "../../services/mineexpo-demo-services/auraIndustrial";
import AdvancedTable from "../../components/advancedTable";
import { Table, Button, ButtonGroup } from "rsuite";
import { refreshToken } from "../../shared/auth";
import { withRouter } from "react-router-dom";
import BaseURL from "../../api-common";
const { Column, HeaderCell, Cell } = Table;

function countDecimals(value) {
    if (Math.floor(value) === value) return 0;

    var str = value.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
}

function addNoise(value) {
    let decimalPlaces = countDecimals(value);
    let multiplier = Math.pow(10, decimalPlaces);
    return (
        Math.round(
            (value + value * 0.02 * (Math.random() - 0.5)) * multiplier
        ) / multiplier
    );
}

class Stockpile extends Component {
    constructor(props) {
        super(props);

        let projectionX = Array.from(Array(13).keys()).map((item) => {
            return item + 11;
        });
        let actualX = Array.from(Array(12).keys());

        this.state = {
            leftTableRows: [
                { columnTitle: "Mass" },
                { columnTitle: "Grade" },
                { columnTitle: "Lithology" }
            ],
            rightTableRows: [
                { columnTitle: "Productivity" },
                { columnTitle: "Grade" },
                { columnTitle: "Blend" },
                { columnTitle: "BWI" },
                { columnTitle: "Estimated\nRecovery" }
            ],
            stockpileTraceGroupName: "mass",
            millTraceGroupName: "productivity",
            stockpileTraceGroups: {
                mass: [
                    {
                        x: projectionX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "projection",
                        hoverinfo: "y",
                        marker: { color: "#2c1c94" },
                        line: { dash: "dot" }
                    },
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "actual",
                        hoverinfo: "y",
                        marker: { color: "green" }
                    },
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "as-is",
                        hoverinfo: "y",
                        marker: { color: "grey" }
                    }
                ],
                grade: [
                    {
                        x: projectionX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "projection",
                        hoverinfo: "y",
                        marker: { color: "#2c1c94" },
                        line: { dash: "dot" }
                    },
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "actual",
                        hoverinfo: "y",
                        marker: { color: "green" }
                    },
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "as-is",
                        hoverinfo: "y",
                        marker: { color: "grey" }
                    }
                ]
            },
            millTraceGroups: {
                productivity: [
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "actual",
                        hoverinfo: "y",
                        marker: { color: "green" }
                    }
                ],
                grade: [
                    {
                        x: projectionX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "projection",
                        hoverinfo: "y",
                        marker: { color: "#2c1c94" },
                        line: { dash: "dot" }
                    },
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "actual",
                        hoverinfo: "y",
                        marker: { color: "green" }
                    },
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "as-is",
                        hoverinfo: "y",
                        marker: { color: "grey" }
                    }
                ],
                bwi: [
                    {
                        x: projectionX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "projection",
                        hoverinfo: "y",
                        marker: { color: "#2c1c94" },
                        line: { dash: "dot" }
                    },
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "actual",
                        hoverinfo: "y",
                        marker: { color: "green" }
                    }
                ],
                estimatedRecovery: [
                    {
                        x: projectionX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "projection",
                        hoverinfo: "y",
                        marker: { color: "#2c1c94" },
                        line: { dash: "dot" }
                    },
                    {
                        x: actualX,
                        y: [],
                        type: "scatter",
                        mode: "lines+markers",
                        name: "actual",
                        hoverinfo: "y",
                        marker: { color: "green" }
                    }
                ]
            },
            startIndex: 0,
            mapImageURL: BaseURL.getBaseURL() + "/assets/satellite.png",
            activeMapOption: "satellite"
        };

        this.updateData = this.updateData.bind(this);
        this.handleStockpileTableRowClick =
            this.handleStockpileTableRowClick.bind(this);
        this.handleMillTableRowClick = this.handleMillTableRowClick.bind(this);
        this.handleMapOptionClick = this.handleMapOptionClick.bind(this);

        this.counter = setInterval(this.updateData, 4000);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        this.industrials = await getAllIndustrials(
            this.props.endUserSession,
            this.props.location.pathname
        );
        console.log(this.industrials);
        //await this.updateData();
    }

    componentWillUnmount() {
        clearInterval(this.counter);
    }

    async updateData() {
        let currentIndustrial = JSON.parse(
            JSON.stringify(this.industrials[this.state.startIndex])
        );
        let nextTwelveIndustrial =
            this.industrials[(this.state.startIndex + 12) % 48];
        let nextDayIndustrial =
            this.industrials[(this.state.startIndex + 24) % 48];

        for (let key in currentIndustrial) {
            currentIndustrial[key] = addNoise(currentIndustrial[key]);
        }

        let stockpileTraceGroups = JSON.parse(
            JSON.stringify(this.state.stockpileTraceGroups)
        );
        let millTraceGroups = JSON.parse(
            JSON.stringify(this.state.millTraceGroups)
        );

        if (stockpileTraceGroups.mass[0].y.length === 0) {
            for (let i = 36; i < 48; i++) {
                stockpileTraceGroups.mass[1].y.push(
                    this.industrials[i].stockpileMass
                );
                stockpileTraceGroups.mass[2].y.push(
                    this.industrials[i].stockpileMassAsIs
                );
                stockpileTraceGroups.grade[1].y.push(
                    this.industrials[i].stockpileGrade
                );
                stockpileTraceGroups.grade[2].y.push(
                    this.industrials[i].stockpileGradeAsIs
                );

                millTraceGroups.productivity[0].y.push(
                    this.industrials[i].millPrductivity
                );
                millTraceGroups.grade[1].y.push(this.industrials[i].millGrade);
                millTraceGroups.grade[2].y.push(
                    this.industrials[i].millGradeReported
                );
                millTraceGroups.bwi[1].y.push(this.industrials[i].millBWI);
                millTraceGroups.estimatedRecovery[1].y.push(
                    this.industrials[i].millRecovery
                );
            }
            for (let i = 0; i < 12; i++) {
                stockpileTraceGroups.mass[0].y.push(
                    this.industrials[i].stockpileMass
                );
                stockpileTraceGroups.grade[0].y.push(
                    this.industrials[i].stockpileGrade
                );

                millTraceGroups.grade[0].y.push(this.industrials[i].millGrade);
                millTraceGroups.bwi[0].y.push(this.industrials[i].millBWI);
                millTraceGroups.estimatedRecovery[0].y.push(
                    this.industrials[i].millRecovery
                );
            }

            stockpileTraceGroups.mass[0].y.unshift(
                stockpileTraceGroups.mass[1].y[
                    stockpileTraceGroups.mass[1].y.length - 1
                ]
            );
            stockpileTraceGroups.grade[0].y.unshift(
                stockpileTraceGroups.grade[1].y[
                    stockpileTraceGroups.grade[1].y.length - 1
                ]
            );

            millTraceGroups.grade[0].y.unshift(
                millTraceGroups.grade[1].y[
                    millTraceGroups.grade[1].y.length - 1
                ]
            );
            millTraceGroups.bwi[0].y.unshift(
                millTraceGroups.bwi[1].y[millTraceGroups.bwi[1].y.length - 1]
            );
            millTraceGroups.estimatedRecovery[0].y.unshift(
                millTraceGroups.estimatedRecovery[1].y[
                    millTraceGroups.estimatedRecovery[1].y.length - 1
                ]
            );
        }

        stockpileTraceGroups.mass[2].y.shift();
        stockpileTraceGroups.mass[1].y.shift();
        stockpileTraceGroups.mass[0].y.shift();
        stockpileTraceGroups.mass[0].y[0] = currentIndustrial.stockpileMass;
        stockpileTraceGroups.mass[2].y.push(
            currentIndustrial.stockpileMassAsIs
        );
        stockpileTraceGroups.mass[1].y.push(currentIndustrial.stockpileMass);
        stockpileTraceGroups.mass[0].y.push(nextTwelveIndustrial.stockpileMass);

        stockpileTraceGroups.grade[2].y.shift();
        stockpileTraceGroups.grade[1].y.shift();
        stockpileTraceGroups.grade[0].y.shift();
        stockpileTraceGroups.grade[0].y[0] = currentIndustrial.stockpileGrade;
        stockpileTraceGroups.grade[2].y.push(
            currentIndustrial.stockpileGradeAsIs
        );
        stockpileTraceGroups.grade[1].y.push(currentIndustrial.stockpileGrade);
        stockpileTraceGroups.grade[0].y.push(
            nextTwelveIndustrial.stockpileGrade
        );

        millTraceGroups.productivity[0].y.shift();
        millTraceGroups.productivity[0].y.push(
            currentIndustrial.millPrductivity
        );

        millTraceGroups.grade[2].y.shift();
        millTraceGroups.grade[1].y.shift();
        millTraceGroups.grade[0].y.shift();
        millTraceGroups.grade[0].y[0] = currentIndustrial.millGrade;
        millTraceGroups.grade[2].y.push(currentIndustrial.millGradeReported);
        millTraceGroups.grade[1].y.push(currentIndustrial.millGrade);
        millTraceGroups.grade[0].y.push(nextTwelveIndustrial.millGrade);

        millTraceGroups.bwi[1].y.shift();
        millTraceGroups.bwi[0].y.shift();
        millTraceGroups.bwi[0].y[0] = currentIndustrial.millBWI;
        millTraceGroups.bwi[1].y.push(currentIndustrial.millBWI);
        millTraceGroups.bwi[0].y.push(nextTwelveIndustrial.millBWI);

        millTraceGroups.estimatedRecovery[1].y.shift();
        millTraceGroups.estimatedRecovery[0].y.shift();
        millTraceGroups.estimatedRecovery[0].y[0] =
            currentIndustrial.millRecovery;
        millTraceGroups.estimatedRecovery[1].y.push(
            currentIndustrial.millRecovery
        );
        millTraceGroups.estimatedRecovery[0].y.push(
            nextTwelveIndustrial.millRecovery
        );

        let leftTableRows = JSON.parse(
            JSON.stringify(this.state.leftTableRows)
        );
        let rightTableRows = JSON.parse(
            JSON.stringify(this.state.rightTableRows)
        );

        leftTableRows[0].currentEstimate =
            currentIndustrial.stockpileMass + " tonnes";
        leftTableRows[0].projection =
            nextDayIndustrial.stockpileMass + " tonnes";
        leftTableRows[1].currentEstimate =
            currentIndustrial.stockpileGrade + " g/t";
        leftTableRows[1].projection = nextDayIndustrial.stockpileGrade + " g/t";
        leftTableRows[2].currentEstimate =
            currentIndustrial.stockpileLithologySandstone +
            "% sandstone\n" +
            currentIndustrial.stockpileLithologySchist +
            "% schist";
        leftTableRows[2].projection =
            nextDayIndustrial.stockpileLithologySandstone +
            "% sandstone\n" +
            nextDayIndustrial.stockpileLithologySchist +
            "% schist";

        rightTableRows[0].currentEstimate =
            currentIndustrial.millPrductivity + " tph";
        rightTableRows[1].currentEstimate =
            currentIndustrial.millGrade + " g/t";
        rightTableRows[1].projection = nextDayIndustrial.millGrade + " g/t";
        rightTableRows[2].currentEstimate =
            currentIndustrial.millBlendSandstone +
            "% sandstone\n" +
            currentIndustrial.millBlendSchist +
            "% schist";
        rightTableRows[2].projection =
            nextDayIndustrial.millBlendSandstone +
            "% sandstone\n" +
            nextDayIndustrial.millBlendSchist +
            "% schist";
        rightTableRows[3].currentEstimate =
            currentIndustrial.millBWI + " kWh/t";
        rightTableRows[3].projection = nextDayIndustrial.millBWI + " kWh/t";

        rightTableRows[4].currentEstimate =
            currentIndustrial.millRecovery + "%";
        rightTableRows[4].projection = nextDayIndustrial.millRecovery + "%";

        this.setState({
            startIndex: (this.state.startIndex + 1) % 48,
            stockpileTraceGroups: stockpileTraceGroups,
            millTraceGroups: millTraceGroups,
            leftTableRows: leftTableRows,
            rightTableRows: rightTableRows
        });
    }

    handleStockpileTableRowClick(rowData) {
        if (rowData.columnTitle === "Mass") {
            this.setState({ stockpileTraceGroupName: "mass" });
        } else if (rowData.columnTitle === "Grade") {
            this.setState({ stockpileTraceGroupName: "grade" });
        }
    }

    handleMillTableRowClick(rowData) {
        if (rowData.columnTitle === "Productivity") {
            this.setState({ millTraceGroupName: "productivity" });
        } else if (rowData.columnTitle === "Grade") {
            this.setState({ millTraceGroupName: "grade" });
        } else if (rowData.columnTitle === "BWI") {
            this.setState({ millTraceGroupName: "bwi" });
        } else if (rowData.columnTitle === "Estimated\nRecovery") {
            this.setState({ millTraceGroupName: "estimatedRecovery" });
        }
    }

    handleMapOptionClick(optionName) {
        this.setState({
            mapImageURL:
                BaseURL.getBaseURL() + "/assets/" + optionName + ".png",
            activeMapOption: optionName
        });
    }

    render() {
        let stockpileYTitle;
        if (this.state.stockpileTraceGroupName === "mass") {
            stockpileYTitle = "Mass [tonnes]";
        } else {
            stockpileYTitle = "Grade [g/t]";
        }

        let millYTitle;
        if (this.state.millTraceGroupName === "productivity") {
            millYTitle = "Productivity [tph]";
        } else if (this.state.millTraceGroupName === "grade") {
            millYTitle = "Grade [g/t]";
        } else if (this.state.millTraceGroupName === "bwi") {
            millYTitle = "BWI [kWh/t]";
        } else {
            millYTitle = "Estimated Recovery [%]";
        }

        return (
            <div id="stockpile-and-mill">
                <div className="database-visuals">
                    <div className="database-visual">
                        Block Model
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Mine Planning
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Survey
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Dispatch
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Sampling
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Production
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>
                </div>

                <div className="arrow">
                    <div className="top-left-trig" />
                    <div className="top-right-trig" />
                    <LegacyArrowDown2Icon
                        className="stem-arrow"
                        style={{
                            fontSize: "4em"
                        }} />
                </div>

                <div
                    style={{
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                        marginTop: "-5px",
                        marginBottom: "-5px"
                    }}
                >
                    <img
                        src={BaseURL.getBaseURL() + "/assets/brain.svg"}
                        alt="rock"
                        style={{
                            width: "100%",
                            maxWidth: "100%"
                        }}
                    />
                </div>

                <div className="arrow">
                    <div className="bottom-left-trig" />
                    <div className="bottom-right-trig" />
                    <LegacyArrowDown2Icon
                        className="stem-arrow"
                        style={{
                            fontSize: "4em"
                        }} />
                </div>

                <div className="grid">
                    <div className="card" style={{ overflow: "hidden" }}>
                        <div className="title">Stockpile</div>
                        <AdvancedTable
                            bordered
                            cellBordered
                            wordWrap
                            shouldUpdateScroll={false}
                            height={199}
                            rows={this.state.leftTableRows}
                            initialSortColumn="name"
                            initialSortType="desc"
                            onRowClick={this.handleStockpileTableRowClick}
                            style={{
                                marginLeft: "20px",
                                marginRight: "20px"
                            }}
                        >
                            <Column width={81} verticalAlign="middle">
                                <HeaderCell />
                                <Cell dataKey="columnTitle" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={118}
                                verticalAlign="middle"
                            >
                                <HeaderCell>Current</HeaderCell>
                                <Cell dataKey="currentEstimate" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={129}
                                verticalAlign="middle"
                            >
                                <HeaderCell>24H Projection</HeaderCell>
                                <Cell dataKey="projection" />
                            </Column>
                        </AdvancedTable>

                        <Plot
                            useResizeHandler
                            data={
                                this.state.stockpileTraceGroups[
                                    this.state.stockpileTraceGroupName
                                ]
                            }
                            layout={{
                                margin: { l: 60, r: 20, t: 20, b: 60 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                xaxis: {
                                    title: "Time",
                                    showticklabels: false,
                                    zeroline: false
                                },
                                yaxis: {
                                    title: stockpileYTitle
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "512px"
                            }}
                        />
                    </div>
                    <div className="card" style={{ overflow: "hidden" }}>
                        <div className="title" style={{ marginLeft: "0" }}>
                            Stockpile Map
                        </div>
                        <ButtonGroup>
                            <Button
                                active={
                                    this.state.activeMapOption === "satellite"
                                }
                                onClick={() => {
                                    this.handleMapOptionClick("satellite");
                                }}
                            >
                                Satellite
                            </Button>
                            <Button
                                active={this.state.activeMapOption === "grade"}
                                onClick={() => {
                                    this.handleMapOptionClick("grade");
                                }}
                            >
                                Grade
                            </Button>
                            <Button
                                active={
                                    this.state.activeMapOption === "lithology"
                                }
                                onClick={() => {
                                    this.handleMapOptionClick("lithology");
                                }}
                            >
                                Lithology
                            </Button>
                        </ButtonGroup>
                        <img
                            src={this.state.mapImageURL}
                            alt="rockSegmented"
                            style={{
                                width: "100%",
                                maxWidth: "100%",
                                maxHeight: "674.973px"
                            }}
                        />
                    </div>
                    <div className="card">
                        <div className="title">Mill Feed</div>
                        <AdvancedTable
                            bordered
                            cellBordered
                            wordWrap
                            shouldUpdateScroll={false}
                            height={311}
                            rows={this.state.rightTableRows}
                            initialSortColumn="name"
                            initialSortType="desc"
                            onRowClick={this.handleMillTableRowClick}
                            style={{
                                marginLeft: "20px",
                                marginRight: "20px"
                            }}
                        >
                            <Column width={99} verticalAlign="middle">
                                <HeaderCell />
                                <Cell dataKey="columnTitle" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={118}
                                verticalAlign="middle"
                            >
                                <HeaderCell>Current</HeaderCell>
                                <Cell dataKey="currentEstimate" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={129}
                                verticalAlign="middle"
                            >
                                <HeaderCell>24H Projection</HeaderCell>
                                <Cell dataKey="projection" />
                            </Column>
                        </AdvancedTable>

                        <Plot
                            useResizeHandler
                            data={
                                this.state.millTraceGroups[
                                    this.state.millTraceGroupName
                                ]
                            }
                            layout={{
                                margin: { l: 60, r: 20, t: 20, b: 60 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                xaxis: {
                                    title: "Time",
                                    showticklabels: false,
                                    zeroline: false
                                },
                                yaxis: {
                                    title: millYTitle
                                },
                                showlegend: true
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "400px"
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Stockpile)
);
