/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:43
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:25:29
 */

import React, { Component } from "react";
import { Button, Modal } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import { deleteClient } from "../../../services/clientManagement";

class DeleteClientModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
    }

    async handleDeleteClick() {
        this.setState({ loading: true });
        let result = await deleteClient(
            this.props.client._id,
            this.props.endUserSession
        );

        if (result) {
            this.props.onClose();
            this.props.onSubmit();
        } else {
            this.setState({ loading: false });
        }
    }

    handleModalOpen() {
        this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.props.open}
                    onOpen={this.handleModalOpen}
                    onClose={this.props.onClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Delete Client?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to delete the client?</Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.handleDeleteClick}
                            appearance="primary"
                            loading={this.state.loading}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientModal);
