/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2022-04-13 11:56:05
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-19 11:21:20
 */

import React, { Component } from "react";
import { IconButton, Whisper, Popover, Loader, Row, Col } from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import {
    getLatestLevelPrediction,
    getDemoLevelPredictionData
} from "../services/levelPrediction";
import { withRouter } from "react-router-dom";
import Plot from "react-plotly.js";
import BaseURL from "../api-common";
import noImage from "../assets/no_image.png";
import PredictionsSection from "../components/PredictionsSection";

let stylingObject = {
    gridStyle: {
        display: "grid",
        // gridTemplateColumns: "0.4fr 0.4fr 0.4fr",
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: "10px",
        rowGap: "1em",
        // gridTemplateRows: "auto auto auto",
        gridGap: "10px",
        height: "70vh"
    },
    h3: {
        marginTop: "1rem",
        textAlign: "center",
        color: "white"
    },
    img: {
        width: "100%",
        height: "50%",
        width: "inherit",
        objectFit: "fill",
        // mixBlendMode: "multiply",
        // backgroundImage: "url(" + noImage + ")",
        backgroundRepeat: "no-repeat"
    },
    cameraSection: {
        border: "1px solid rgb(18, 0, 120)",
        width: "100%",
        height: "100%"
    },
    paragraphStyle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "3px 3px 3px 3px"
    },
    fill: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        width: "100%",
        height: "100%"
    },
    fillImg: {
        flexShrink: 0,
        minWidth: "100%",
        minHeight: "100%",
        objectFit: "cover"
    }
};

class LevelPrediction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            image: null,
            skipCount: 0,
            delayTime: 500,
            layout: {
                hovermode: "x unified",
                // hoverdistance: 1,
                autosize: true,
                uirevision: true, // Allows updating of data while keeping zoom level and pan location
                xaxis: {
                    title: "TIME",
                    autorange: true,
                    type: "date"
                },
                yaxis: {
                    title: "Level Prediction (%)",
                    fixedrange: true,
                    autorange: false,
                    range: [-10, 100]
                },
                margin: {
                    l: 40,
                    r: 20,
                    t: 20,
                    b: 40
                },
                paper_bgcolor: "rgba(0,0,0,0)",
                plot_bgcolor: "rgba(0,0,0,0)",
                modebar: {
                    bgcolor: "rgba(0,0,0,0)",
                    color: "black",
                    activecolor: "#2c1c94"
                },
                showlegend: false
            },
            traces: [
                {
                    x: [],
                    y: [],
                    name: "Predicted",
                    mode: "lines+markers"
                    // line: { color: "blue" }
                }
            ],
            markerColors: [],
            qualityStatusRows: [
                {
                    window: "Window 1",
                    intensity: 0,
                    contrast: 0
                },
                {
                    window: "Window 2",
                    intensity: 0,
                    contrast: 0
                },
                {
                    window: "Window 3",
                    intensity: 0,
                    contrast: 0
                },
                {
                    window: "Window 4",
                    intensity: 0,
                    contrast: 0
                }
            ],
            predictions: {},
            liveDelay: 2000
        };

        this.getLatestLevelPrediction =
            this.getLatestLevelPrediction.bind(this);
        this.getDemoLevelPredictionData =
            this.getDemoLevelPredictionData.bind(this);
        this.handlePlotRelayout = this.handlePlotRelayout.bind(this);
        this.b64toBlob = this.b64toBlob.bind(this);
        this.generateBlob = this.generateBlob.bind(this);
        this.liveUpdateInterval = null;
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        // await this.getLatestLevelPrediction();
        // this.liveUpdateInterval = setInterval(
        //     this.getLatestLevelPrediction,
        //     this.state.liveDelay
        // );
        await this.getDemoLevelPredictionData();
        this.demoUpdateInterval = setInterval(
            this.getDemoLevelPredictionData,
            this.state.delayTime
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        clearInterval(this.liveUpdateInterval);
	    clearInterval(this.demoUpdateInterval);
    }

    handlePlotRelayout(data) {
        // If there is no data don't do anything
        if (this.state.traces.length === 0) {
            return;
        }

        // Continue if appropriate operation has occurred
        // "xaxis.range[0]" in data: pan
        // "xaxis.range" in data: range slider movement
        // "xaxis.autorange" in data: autoscale
        if (
            !(
                "xaxis.range[0]" in data ||
                "xaxis.range" in data ||
                "xaxis.autorange" in data
            )
        ) {
            return;
        }

        // The lines below are a work around for a range slider bug (https://github.com/plotly/plotly.js/issues/4417)
        let layout = JSON.parse(JSON.stringify(this.state.layout));
        this.setState({ layout: layout });
    }

    async getLatestLevelPrediction() {
        let latestLevelPrediction = await getLatestLevelPrediction(
            this.props.endUserSession,
            this.props.location.pathname
        );
        let traces = JSON.parse(JSON.stringify(this.state.traces));
        let markerColors = JSON.parse(JSON.stringify(this.state.markerColors));
        let predictions = {};
        let qualityStatusRows = [];
        let image = null;
        if (
            latestLevelPrediction !== undefined &&
            latestLevelPrediction !== null
        ) {
            if (latestLevelPrediction.hasOwnProperty("date")) {
                if (latestLevelPrediction.hasOwnProperty("rawLevel")) {
                    traces.map((trace) => {
                        trace.y.push(Number(latestLevelPrediction["rawLevel"]));
                        trace.x.push(latestLevelPrediction["date"]);
                        if (trace.x.length > 60) {
                            trace.x.shift();
                            trace.y.shift();
                            markerColors.shift();
                        }
                    });
                }
            }
            if (latestLevelPrediction.hasOwnProperty("predictions")) {
                predictions = latestLevelPrediction.predictions;
            }
            if (latestLevelPrediction.hasOwnProperty("qualityStatusRows")) {
                qualityStatusRows = latestLevelPrediction.qualityStatusRows;
            }
            if (latestLevelPrediction.hasOwnProperty("image")) {
                image = latestLevelPrediction.image;
            }
            if (latestLevelPrediction.hasOwnProperty("markerColor")) {
                markerColors.push(latestLevelPrediction["markerColor"]);
                traces[0].marker = {
                    color: markerColors
                };
            }
        }
        this.setState({
            markerColors: markerColors,
            traces: traces,
            predictions: predictions,
            image: BaseURL.getBaseURL() + "/" + image,
            qualityStatusRows: qualityStatusRows
        });
    }

    async getDemoLevelPredictionData() {
        let latestLevelPrediction = await getDemoLevelPredictionData(
            this.props.endUserSession,
            this.props.location.pathname,
            {
                skipCount: this.state.skipCount
            }
        );
        let traces = JSON.parse(JSON.stringify(this.state.traces));
        let markerColors = JSON.parse(JSON.stringify(this.state.markerColors));
        let predictions = {};
        let qualityStatusRows = [];
        let image = null;
        if (
            latestLevelPrediction !== undefined &&
            latestLevelPrediction !== null
        ) {
            if (latestLevelPrediction.hasOwnProperty("date")) {
                if (latestLevelPrediction.hasOwnProperty("rawLevel")) {
                    traces.map((trace) => {
                        trace.y.push(Number(latestLevelPrediction["rawLevel"]));
                        trace.x.push(latestLevelPrediction["date"]);
                        if (trace.x.length > 60) {
                            trace.x.shift();
                            trace.y.shift();
                            markerColors.shift();
                        }
                    });
                }
            }
            if (latestLevelPrediction.hasOwnProperty("predictions")) {
                predictions = latestLevelPrediction.predictions;
            }
            if (latestLevelPrediction.hasOwnProperty("qualityStatusRows")) {
                qualityStatusRows = latestLevelPrediction.qualityStatusRows;
            }
            if (latestLevelPrediction.hasOwnProperty("image")) {
                image = latestLevelPrediction.image;
            }
            if (latestLevelPrediction.hasOwnProperty("markerColor")) {
                markerColors.push(latestLevelPrediction["markerColor"]);
                traces[0].marker = {
                    color: markerColors
                };
            }
        }
        let skipCount = this.state.skipCount;
        if (skipCount > 1400) {
            skipCount = 0;
            markerColors = [];
            traces = [
                {
                    x: [],
                    y: [],
                    name: "Predicted",
                    mode: "lines+markers"
                    // line: { color: "blue" }
                }
            ];
            predictions = {};
        } else {
            skipCount++;
        }
        this.setState({
            markerColors: markerColors,
            traces: traces,
            predictions: predictions,
            image: BaseURL.getBaseURL() + "/" + image,
            qualityStatusRows: qualityStatusRows,
            skipCount: skipCount
        });
    }

    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    generateBlob(imageData) {
        let contentType = "image/png";

        let blob = this.b64toBlob(imageData, contentType);
        let blobUrl = URL.createObjectURL(blob);
        return blobUrl;
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Level Prediction
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>TODO</p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        color="primary"
                    />
                </Whisper>
                <div style={{ position: "relative" }}>
                    <Row>
                        <Col
                            md={16}
                            style={{
                                height: "90vh",
                                border: "1px solid #120078",
                                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                                transition: "0.3s",
                                background: "white"
                            }}
                        >
                            <div style={stylingObject["fill"]}>
                                <img
                                    id="live_stream_image"
                                    style={stylingObject["fillImg"]}
                                    src={this.state.image}
                                />
                            </div>
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={24}>
                                    <PredictionsSection
                                        rows={this.state.qualityStatusRows}
                                        predictions={this.state.predictions}
                                    ></PredictionsSection>
                                </Col>
                                <Col md={24}>
                                    <div
                                        style={{
                                            marginTop: "20px",
                                            alignContent: "center"
                                        }}
                                    >
                                        <b
                                            style={{
                                                color: "#120078"
                                            }}
                                        >
                                            Showing Raw Level (%) in Trend:
                                        </b>
                                        <div
                                            style={{
                                                marginTop: "20px",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-evenly"
                                            }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        backgroundColor: "red",
                                                        borderRadius: "50%",
                                                        display: "inline-block"
                                                    }}
                                                ></span>{" "}
                                                Quality BAD
                                            </div>
                                            <div>
                                                <span
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        backgroundColor:
                                                            "green",
                                                        borderRadius: "50%",
                                                        display: "inline-block"
                                                    }}
                                                ></span>{" "}
                                                Quality GOOD
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            position: "relative",
                                            backgroundColor: "white",
                                            border: "1px solid #c5c6c7",
                                            boxShadow:
                                                "0 4px 8px 0 rgba(0,0,0,0.2)",
                                            transition: "0.3s",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <Plot
                                            useResizeHandler
                                            data={this.state.traces}
                                            layout={this.state.layout}
                                            config={{
                                                displaylogo: false,
                                                modeBarButtonsToRemove: [
                                                    "select2d",
                                                    "lasso2d"
                                                ]
                                                // staticPlot: this.state.loading
                                            }}
                                            revision={this.state.startIndex}
                                            style={{
                                                width: "100%",
                                                height: "40vh",
                                                background: "white"
                                                // border: "2px solid grey"
                                            }}
                                            onRelayout={this.handlePlotRelayout}
                                        />
                                        {this.state.loading ? (
                                            <Loader
                                                size="lg"
                                                style={{
                                                    position: "absolute",
                                                    left: "calc(50% - 64px)",
                                                    bottom: "calc(70% - 64px)"
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LevelPrediction)
);
