/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-05-13 11:03:59
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:19:09
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";
import history from "../shared/history";

export async function authenticate(data) {
    try {
        let url = BaseURL.getBaseURL() + "/authentication/login";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (
            res.data.msg === "AUTHORIZED" ||
            res.data.msg === "ALREADY_AUTHORIZED"
        ) {
            return ["", res.data];
        } else if (res.data.msg === "UNAUTHORIZED") {
            return ["The username and/or password is incorrect", res.data];
        } else if (res.data.msg === "INVALID_POST") {
            return ["An invalid post request has been sent", res.data];
        } else {
            return ["An unknown error has occurred", res.data];
        }
    } catch (e) {
        return [e.toString(), null];
    }
}

export async function verifyToken(endUserSession) {
    try {
        let url = BaseURL.getBaseURL() + "/authentication/verifyToken";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
        }
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not verify token.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
    }
}

export async function requestPasswordChange(data) {
    try {
        let url =
            BaseURL.getBaseURL() + "/authentication/requestPasswordChange";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            return "The username does not exist";
        }
        return null;
    } catch (e) {
        return e.toString();
    }
}

export async function verifyPasswordChangeToken(data) {
    try {
        let url =
            BaseURL.getBaseURL() + "/authentication/verifyPasswordChangeToken";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            return false;
        } else if (!res.data.isValidToken) {
            return false;
        }
        return true;
    } catch (e) {
        return false;
    }
}

export async function changePassword(data) {
    try {
        let url = BaseURL.getBaseURL() + "/authentication/changePassword";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The password change request is invalid");
        } else if (!res.data.isValidToken) {
            history.replace("/");
            toaster.push(
                <Message type="error" showIcon closable duration={0}>
                    {<text>Password change request is invalid</text>}
                </Message>
            );
            return false;
        } else if (!res.data.isUpdated) {
            throw new Error("The password did not update in the database");
        }
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get clients.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}
