/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-02 14:14:05
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:18:57
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getFunctionality(endUserSession, id) {
    try {
        let url =
            BaseURL.getBaseURL() +
            "/functionalityManagement/getFunctionality/" +
            id;

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The functionality request is invalid.");
        }
        return res.data.functionality;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get the functionality.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getAllFunctionalities(endUserSession) {
    try {
        let url =
            BaseURL.getBaseURL() +
            "/functionalityManagement/getAllFunctionalities";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The functionality request is invalid.");
        }
        return res.data.allFunctionalities;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get the functionalities.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function updateFunctionalityStatus(endUserSession, data) {
    try {
        let url =
            BaseURL.getBaseURL() + "/functionalityManagement/updateStatus";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The update status request is invalid.");
        }
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        The status could not be updated.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function addFunction(endUserSession, data) {
    try {
        let url =
            BaseURL.getBaseURL() + "/functionalityManagement/createFunction";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The create function request is invalid.");
        } else if (res.data.isDuplicate) {
            throw new Error("A function with this name already exists");
        } else if (res.data.isDuplicatePath) {
            throw new Error("A function with this path already exists");
        } else if (!res.data.isInserted) {
            throw new Error(
                "The function could not be inserted into the database"
            );
        }
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not add function.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function addCategory(endUserSession, data) {
    try {
        let url =
            BaseURL.getBaseURL() + "/functionalityManagement/createCategory";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The create category request is invalid.");
        } else if (res.data.isDuplicate) {
            throw new Error("A category with this name already exists");
        } else if (!res.data.isInserted) {
            throw new Error(
                "The category could not be inserted into the database"
            );
        }
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not add category.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function deleteFunctionalities(endUserSession, data) {
    try {
        let url = BaseURL.getBaseURL() + "/functionalityManagement/delete";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The delete functionalities request is invalid.");
        } else if (!res.data.isDeleted) {
            throw new Error(
                "The functionalities could not be deleted from the database"
            );
        }
        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not delete functionalities.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}
