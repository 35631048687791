/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:24:08
 */

import React, { Component } from "react";
import {
    Button,
    Form,
    InputPicker,
    Modal,
    Schema,
    Divider,
    Whisper,
    Popover
} from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import {
    updateProject,
    updateProjectStepAuthorizedRoles
} from "../../../services/projectManagement";
import { getFunctionality } from "../../../services/functionalityManagement";
import StepOrganizer from "../../../components/stepOrganizer";

class EditProjectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: {},
            formJSON: {
                projectName: "",
                branch: "",
                location: "",
                status: "",
                clientId: ""
            }
        };
        this.steps = [];

        this.clearForm = this.clearForm.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);

        let schema = {
            //projectName: validators.projectNameValidation,
            branch: validators.branchNameValidation,
            location: validators.locationValidation,
            status: validators.statusValidation,
            clientId: validators.clientIdValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    clearForm() {
        let formJSON = this.state.formJSON;
        for (let field in formJSON) {
            if (formJSON.hasOwnProperty(field)) {
                formJSON[field] = "";
            }
        }

        this.setState({ formJSON: formJSON, errorMessages: {} });
    }

    async handleSubmitClick() {
        if (this.props.userDetails.userType === "SuperAdmin") {
            if (!this.form.check()) {
                return;
            }
            this.setState({ loading: true });

            let data = JSON.parse(JSON.stringify(this.state.formJSON));
            data.steps = [];

            for (let step of this.steps) {
                data.steps.push({
                    authorizedRoles: step.authorizedRoles,
                    functionality_id: step.data._id,
                    name: step.data.name,
                    stepCommonName: step.data.name
                });
            }

            let result = await updateProject(
                this.props.endUserSession,
                data,
                this.props.project._id
            );
            if (result) {
                this.props.onClose();
                this.props.onSubmit();
            } else {
                this.setState({ loading: false });
            }
        } else if (this.props.userDetails.userType === "Admin") {
            this.setState({ loading: true });

            let data = JSON.parse(JSON.stringify(this.state.formJSON));
            data = [];

            for (let step of this.steps) {
                data.push({
                    authorizedRoles: step.authorizedRoles,
                    functionality_id: step.data._id
                });
            }

            let steps = await updateProjectStepAuthorizedRoles(
                this.props.endUserSession,
                data,
                this.props.project._id
            );

            if (!steps) {
                this.setState({ loading: false });
            }

            let assignedProjects = JSON.parse(
                JSON.stringify(this.props.assignedProjects)
            );
            for (let assignedProject of assignedProjects) {
                if (this.props.project._id !== assignedProject._id) {
                    continue;
                }
                assignedProject.steps = [];
                for (let step of steps) {
                    if (
                        !step.authorizedRoles.includes(
                            this.props.userDetails.user_roleId
                        )
                    ) {
                        continue;
                    }
                    step.functionality = await getFunctionality(
                        this.props.endUserSession,
                        step.functionality_id
                    );
                    assignedProject.steps.push(step);
                }
            }

            this.props.updateAssignedProjects(assignedProjects);
            this.props.onClose();
            this.props.onSubmit();
        }
    }

    handleModalOpen() {
        this.clearForm();

        let formJSON = JSON.parse(JSON.stringify(this.props.project));
        formJSON.clientId = formJSON.project_clientId;
        delete formJSON.project_clientId;
        delete formJSON._id;

        this.setState({
            loading: false,
            formJSON: formJSON
        });
    }

    handleStepChange(newSteps) {
        this.steps = newSteps;
    }

    renderForm() {
        if (this.props.userDetails.userType !== "SuperAdmin") {
            return null;
        }
        return (
            <Form
                ref={(forum) => (this.form = forum)}
                layout="horizontal"
                formValue={this.state.formJSON}
                model={this.validationModel}
                checkTrigger="none"
                onChange={(newFormJSON) => {
                    this.setState({ formJSON: newFormJSON });
                }}
                onCheck={(errorMessages) => {
                    this.setState({ errorMessages: errorMessages });
                }}
            >
                {/*<Form.Group>
            <ControlLabel>Project Name</ControlLabel>
            <Form.Control
                name="projectName"
                errorMessage={
                    this.state.errorMessages.projectName
                }
            />
            </Form.Group>*/}
                <Form.Group>
                    <Form.ControlLabel>Branch</Form.ControlLabel>
                    <Form.Control
                        name="branch"
                        errorMessage={this.state.errorMessages.branch}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>Location</Form.ControlLabel>
                    <Form.Control
                        name="location"
                        errorMessage={this.state.errorMessages.location}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>Project Status</Form.ControlLabel>
                    <Form.Control
                        name="status"
                        accepter={InputPicker}
                        data={[
                            {
                                label: "Active",
                                value: "active",
                                role: "Master"
                            },
                            {
                                label: "Inactive",
                                value: "inactive",
                                role: "Master"
                            }
                        ]}
                        errorMessage={this.state.errorMessages.status}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>Client</Form.ControlLabel>
                    {this.props.hasUserDependents ? (
                        <Whisper
                            trigger="hover"
                            placement="rightStart"
                            speaker={
                                <Popover>
                                    <p>
                                        This project still has user dependents.
                                    </p>
                                </Popover>
                            }
                        >
                            <Form.Control
                                name="clientId"
                                accepter={InputPicker}
                                data={this.props.clients}
                                valueKey={"_id"}
                                labelKey={"clientName"}
                                errorMessage={this.state.errorMessages.clientId}
                                disabled={this.props.hasUserDependents}
                            />
                        </Whisper>
                    ) : (
                        <Form.Control
                            name="clientId"
                            accepter={InputPicker}
                            data={this.props.clients}
                            valueKey={"_id"}
                            labelKey={"clientName"}
                            errorMessage={this.state.errorMessages.clientId}
                            disabled={this.props.hasUserDependents}
                        />
                    )}
                </Form.Group>
            </Form>
        );
    }

    render() {
        let form = this.renderForm();
        let size;
        if (this.props.userDetails.userType === "SuperAdmin") {
            size = "lg";
        } else {
            size = "xs";
        }

        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
                size={size}
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Edit Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {form}
                    {this.props.userDetails.userType === "SuperAdmin" ? (
                        <div>
                            <Divider />
                            {this.props.hasActiveJob ? (
                                <div style={{ color: "orange" }}>
                                    Drag and drop disabled due to active job(s)
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {this.props.project ? (
                        <StepOrganizer
                            functionalityTree={this.props.functionalityTree}
                            roles={this.props.roles}
                            onStepChange={this.handleStepChange}
                            initialSelectedSteps={this.props.project.steps}
                            hasActiveJob={this.props.hasActiveJob}
                        />
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleSubmitClick}
                            appearance="primary"
                            type="submit"
                            loading={this.state.loading}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession()),
        updateAssignedProjects: (assignedProjects) =>
            dispatch(actions.updateAssignedProjects(assignedProjects))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectModal);
