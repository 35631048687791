/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:57:24
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:21:36
 */

import React, { Component } from "react";
import history from "../shared/history";
import * as validators from "../shared/modelValidators";
import { requestPasswordChange } from "../services/auth";
import { Form, Button, Schema, ButtonToolbar, toaster, Message } from "rsuite";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessages: {},
            formJSON: {
                username: ""
            },
            loading: false
        };

        this.handleChangePasswordClick =
            this.handleChangePasswordClick.bind(this);

        let schema = {
            username: validators.usernameValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    async handleChangePasswordClick() {
        if (this.form.check()) {
            this.setState({ loading: true });
            let errorMessage = await requestPasswordChange(this.state.formJSON);
            if (errorMessage) {
                this.setState({ loading: false });
                let errorMessages = JSON.parse(
                    JSON.stringify(this.state.errorMessages)
                );
                errorMessages.serverError = errorMessage;
                this.setState({ errorMessages: errorMessages });
            } else {
                history.push("/");
                toaster.push(
                    <Message type="success" showIcon closable>
                        The email, to change your password, has been sent
                    </Message>
                );
            }
        }
    }

    handleCancelClick() {
        history.push("/");
    }

    render() {
        return (
            <div
                style={{
                    border: "1px solid rgb(229, 229, 234)",
                    padding: "24px",
                    width: "100%",
                    maxWidth: "600px",
                    borderRadius: "6px",
                    backgroundColor: "white"
                }}
            >
                <h4>Forgot Password</h4>
                <Form
                    ref={(forum) => (this.form = forum)}
                    fluid
                    formValue={this.state.formJSON}
                    model={this.validationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({
                            formJSON: newFormJSON
                        });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({
                            errorMessages: errorMessages
                        });
                    }}
                    formError={this.state.errorMessages}
                    style={{ marginTop: "20px" }}
                >
                    <Form.Group>
                        <Form.Control name="username" placeholder="Username" />
                    </Form.Group>
                </Form>
                <ButtonToolbar>
                    <Button
                        disabled={this.state.loading}
                        onClick={this.handleCancelClick}
                        style={{ marginTop: "24px", width: "calc(50% - 3px)" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        appearance="primary"
                        loading={this.state.loading}
                        onClick={this.handleChangePasswordClick}
                        style={{ marginTop: "24px", width: "calc(50% - 2px)" }}
                    >
                        Change Password
                    </Button>
                </ButtonToolbar>

                {/*Place for server error messages*/}
                {this.state.errorMessages.serverError ? (
                    <div
                        style={{
                            marginTop: "20px",
                            textAlign: "center",
                            color: "#f44336"
                        }}
                    >
                        {this.state.errorMessages.serverError}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ForgotPassword;
