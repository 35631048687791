/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-11 17:35:11
 */

import React, { Component } from "react";

import LegacyDatabaseIcon from "@rsuite/icons/legacy/Database";
import LegacyArrowDown2Icon from "@rsuite/icons/legacy/ArrowDown2";
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import history from "../../shared/history";
import Timer from "../../components/timer";
import "./mineToMill.css";
import { getMillData } from "../../services/mineexpo-demo-services/millData";
import BaseURL from "../../api-common";
import AdvancedTable from "../../components/advancedTable";
import { Table } from "rsuite";
import { refreshToken } from "../../shared/auth";
import { getTempData } from "./tempData";
const { Column, HeaderCell, Cell, Pagination } = Table;

function addNoise(value) {
    return value + value * 0.015 * (Math.random() - 0.5);
}

class MineToMill extends Component {
    constructor(props) {
        super(props);

        let projectionX = Array.from(Array(13).keys()).map((item) => {
            return item + 11;
        });
        let actualX = Array.from(Array(12).keys());

        this.state = {
            leftTableRows: [
                { columnTitle: "Grade" },
                { columnTitle: "Bond Work Index" }
                /*{ columnTitle: "Mineralogy" },
                { columnTitle: "Contaminants" }*/
            ],
            middleTableRows: [
                { columnTitle: "Grade" },
                { columnTitle: "Bond Work Index" }
                /*{ columnTitle: "Mineralogy" },
                { columnTitle: "Contaminants" }*/
            ],
            rightTopTableRows: [
                { columnTitle: "Grinding" },
                { columnTitle: "Flotation" }
            ],
            rightBottomTableRows: [
                { columnTitle: "Recovery" },
                { columnTitle: "Flags" }
            ],
            stockpileTraces: [
                {
                    x: projectionX,
                    y: [
                        2.32, 2.32, 2.33, 2.33, 2.33, 2.33, 2.32, 2.31, 2.29,
                        2.28, 2.26, 2.24, 2.23
                    ],
                    type: "scatter",
                    mode: "lines+markers",
                    name: "projection",
                    hoverinfo: "y",
                    marker: { color: "#2c1c94" },
                    line: { dash: "dot" }
                },
                {
                    x: actualX,
                    y: [
                        2.34, 2.32, 2.31, 2.31, 2.3, 2.3, 2.3, 2.3, 2.3, 2.31,
                        2.31, 2.32
                    ].map((item) => {
                        return addNoise(item);
                    }),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "actual",
                    hoverinfo: "y",
                    marker: { color: "green" }
                }
            ],
            millFeedTraces: [
                {
                    x: projectionX,
                    y: [
                        2.16, 2.19, 2.22, 2.26, 2.31, 2.36, 2.41, 2.44, 2.47,
                        2.49, 2.49, 2.49
                    ],
                    type: "scatter",
                    mode: "lines+markers",
                    name: "projection",
                    hoverinfo: "y",
                    marker: { color: "#2c1c94" },
                    line: { dash: "dot" }
                },
                {
                    x: actualX,
                    y: [
                        2.34, 2.34, 2.34, 2.34, 2.33, 2.3, 2.28, 2.24, 2.21,
                        2.18, 2.16, 2.16
                    ].map((item) => {
                        return addNoise(item);
                    }),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "actual",
                    hoverinfo: "y",
                    marker: { color: "green" }
                }
            ],
            plantTraces: [
                {
                    x: projectionX,
                    y: [
                        92.5, 92.7, 91.9, 90, 88.7, 89.5, 90.8, 93, 93.7, 94.2,
                        93.2, 92.6
                    ],
                    type: "scatter",
                    mode: "lines+markers",
                    name: "projection",
                    hoverinfo: "y",
                    marker: { color: "#2c1c94" },
                    line: { dash: "dot" }
                },
                {
                    x: actualX,
                    y: [
                        91.1, 91.2, 92.7, 93.4, 93.8, 92.8, 92.4, 91.8, 92.3,
                        92.8, 92.8, 92.2
                    ].map((item) => {
                        return addNoise(item);
                    }),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "actual",
                    hoverinfo: "y",
                    marker: { color: "green" }
                }
            ],
            startIndex: 0
        };

        this.state.millFeedTraces[0].y.unshift(
            this.state.millFeedTraces[1].y[11]
        );
        this.state.stockpileTraces[0].y.unshift(
            this.state.stockpileTraces[1].y[11]
        );
        this.state.plantTraces[0].y.unshift(this.state.plantTraces[1].y[11]);

        this.updateData = this.updateData.bind(this);

        this.counter = setInterval(this.updateData, 4000);
        this.tempData = getTempData();
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.updateData();
    }

    componentWillUnmount() {
        clearInterval(this.counter);
    }

    async updateData() {
        let result = await getMillData(
            this.props.endUserSession,
            this.state.startIndex
        );

        if (result == null) {
            return;
        }

        let mineToMillData = result.mineToMill;

        let stockpileTraces = JSON.parse(
            JSON.stringify(this.state.stockpileTraces)
        );
        let millFeedTraces = JSON.parse(
            JSON.stringify(this.state.millFeedTraces)
        );
        let plantTraces = JSON.parse(JSON.stringify(this.state.plantTraces));
        stockpileTraces[1].y.shift();
        stockpileTraces[0].y.shift();
        stockpileTraces[0].y[0] = addNoise(stockpileTraces[0].y[0]);
        stockpileTraces[1].y.push(stockpileTraces[0].y[0]);
        stockpileTraces[0].y.push(mineToMillData.stockpileGrade);

        millFeedTraces[1].y.shift();
        millFeedTraces[0].y.shift();
        millFeedTraces[0].y[0] = addNoise(millFeedTraces[0].y[0]);
        millFeedTraces[1].y.push(millFeedTraces[0].y[0]);
        millFeedTraces[0].y.push(mineToMillData.millFeedGrade);

        plantTraces[1].y.shift();
        plantTraces[0].y.shift();
        plantTraces[0].y[0] = addNoise(plantTraces[0].y[0]);
        plantTraces[1].y.push(plantTraces[0].y[0]);
        plantTraces[0].y.push(mineToMillData.recovery);

        let leftTableRows = JSON.parse(
            JSON.stringify(this.state.leftTableRows)
        );
        let middleTableRows = JSON.parse(
            JSON.stringify(this.state.middleTableRows)
        );
        let rightTopTableRows = JSON.parse(
            JSON.stringify(this.state.rightTopTableRows)
        );

        let currentEstIndex = this.state.startIndex - 11;
        let currentIndex = this.state.startIndex - 12;
        if (currentEstIndex < 0) {
            currentEstIndex = currentEstIndex + 48;
        }
        if (currentIndex < 0) {
            currentIndex = currentIndex + 48;
        }

        leftTableRows[0].currentEstimate = stockpileTraces[0].y[1] + "%";
        leftTableRows[0].projection =
            this.tempData.stockpileGrade[(this.state.startIndex + 12) % 48] +
            "%";
        leftTableRows[1].currentEstimate =
            this.tempData.stockpileBondWorkIndex[currentEstIndex] + " kWh/t";
        leftTableRows[1].projection =
            this.tempData.stockpileBondWorkIndex[
                (this.state.startIndex + 12) % 48
            ] + " kWh/t";

        middleTableRows[0].currentEstimate = millFeedTraces[0].y[1] + "%";
        middleTableRows[0].projection =
            this.tempData.millFeedGrade[(this.state.startIndex + 12) % 48] +
            "%";
        middleTableRows[1].currentEstimate =
            this.tempData.millFeedBondWorkIndex[currentEstIndex] + " kWh/t";
        middleTableRows[1].projection =
            this.tempData.millFeedBondWorkIndex[
                (this.state.startIndex + 12) % 48
            ] + " kWh/t";

        rightTopTableRows[0].value =
            "SAG Water Addition: " +
            this.tempData.recommendedSAGWaterAdditionChange[currentIndex] +
            "%\nPumpbox Water Addition: " +
            this.tempData.recommendedPumpboxWaterAdditionChange[currentIndex] +
            "%";
        rightTopTableRows[1].value =
            "Frother Addition: " +
            this.tempData.recommendedFrotherAdditionChange[currentIndex] +
            "%\nCollector Addition: " +
            this.tempData.recommendedCollectorAdditionChange[currentIndex] +
            "%";

        this.setState({
            startIndex: (this.state.startIndex + 1) % 48,
            stockpileTraces: stockpileTraces,
            millFeedTraces: millFeedTraces,
            plantTraces: plantTraces,
            leftTableRows: leftTableRows,
            middleTableRows: middleTableRows,
            rightTopTableRows: rightTopTableRows
        });
    }

    handleTimerFinish() {
        history.push("millFeed");
    }

    render() {
        return (
            <div id="mine-to-mill">
                <div className="database-visuals">
                    <div className="database-visual">
                        Block Model
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Mine Planning
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Survey
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Dispatch
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Sampling
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>

                    <div className="database-visual">
                        Production
                        <LegacyDatabaseIcon
                            style={{
                                fontSize: "5em"
                            }} />
                    </div>
                </div>

                <div className="arrow">
                    <div className="top-left-trig" />
                    <div className="top-right-trig" />
                    <LegacyArrowDown2Icon
                        className="stem-arrow"
                        style={{
                            fontSize: "4em"
                        }} />
                </div>

                <div
                    style={{
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                        marginTop: "-5px",
                        marginBottom: "-5px"
                    }}
                >
                    <img
                        src={BaseURL.getBaseURL() + "/assets/brain.svg"}
                        alt="rock"
                        style={{
                            width: "100%",
                            maxWidth: "100%"
                        }}
                    />
                </div>

                <div className="arrow">
                    <div className="bottom-left-trig" />
                    <div className="bottom-right-trig" />
                    <LegacyArrowDown2Icon
                        className="stem-arrow"
                        style={{
                            fontSize: "4em"
                        }} />
                </div>

                <div className="grid">
                    <div className="card" style={{ overflow: "hidden" }}>
                        <div className="title">Stockpile</div>
                        <AdvancedTable
                            bordered
                            cellBordered
                            wordWrap
                            shouldUpdateScroll={false}
                            height={133}
                            rows={this.state.leftTableRows}
                            initialSortColumn="name"
                            initialSortType="desc"
                            onRowSelectionChange={this.handleRowSelectionChange}
                            style={{ marginLeft: "20px", marginRight: "20px" }}
                        >
                            <Column width={130} verticalAlign="middle">
                                <HeaderCell />
                                <Cell dataKey="columnTitle" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={108}
                                verticalAlign="middle"
                            >
                                <HeaderCell>Current Est.</HeaderCell>
                                <Cell dataKey="currentEstimate" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={129}
                                verticalAlign="middle"
                            >
                                <HeaderCell>24H Projection</HeaderCell>
                                <Cell dataKey="projection" />
                            </Column>
                        </AdvancedTable>

                        <Plot
                            useResizeHandler
                            data={this.state.stockpileTraces}
                            layout={{
                                margin: { l: 60, r: 20, t: 20, b: 60 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                xaxis: {
                                    title: "time",
                                    showticklabels: false,
                                    zeroline: false
                                },
                                yaxis: {
                                    title: "grade [%]"
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "400px"
                            }}
                        />
                    </div>
                    <div className="card" style={{ overflow: "hidden" }}>
                        <div className="title">Mill Feed</div>
                        <AdvancedTable
                            bordered
                            cellBordered
                            wordWrap
                            shouldUpdateScroll={false}
                            height={133}
                            rows={this.state.middleTableRows}
                            initialSortColumn="name"
                            initialSortType="desc"
                            onRowSelectionChange={this.handleRowSelectionChange}
                            style={{ marginLeft: "20px", marginRight: "20px" }}
                        >
                            <Column width={130} verticalAlign="middle">
                                <HeaderCell />
                                <Cell dataKey="columnTitle" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={108}
                                verticalAlign="middle"
                            >
                                <HeaderCell>Current Est.</HeaderCell>
                                <Cell dataKey="currentEstimate" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={129}
                                verticalAlign="middle"
                            >
                                <HeaderCell>24H Projection</HeaderCell>
                                <Cell dataKey="projection" />
                            </Column>
                        </AdvancedTable>
                        <Plot
                            useResizeHandler
                            data={this.state.millFeedTraces}
                            layout={{
                                margin: { l: 60, r: 20, t: 20, b: 60 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                xaxis: {
                                    title: "time",
                                    showticklabels: false,
                                    zeroline: false
                                },
                                yaxis: {
                                    title: "grade [%]"
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "400px"
                            }}
                        />
                    </div>
                    <div className="card">
                        <div className="title">Plant</div>
                        <AdvancedTable
                            bordered
                            cellBordered
                            shouldUpdateScroll={false}
                            showHeader={false}
                            height={92}
                            rows={this.state.rightTopTableRows}
                            initialSortColumn="name"
                            initialSortType="desc"
                            onRowSelectionChange={this.handleRowSelectionChange}
                            style={{
                                marginLeft: "20px",
                                marginRight: "20px",
                                marginBottom: "41px"
                            }}
                        >
                            <Column width={110} verticalAlign="middle">
                                <HeaderCell />
                                <Cell dataKey="columnTitle" />
                            </Column>

                            <Column
                                flexGrow={1}
                                minWidth={200}
                                verticalAlign="middle"
                            >
                                <HeaderCell>Values</HeaderCell>
                                <Cell dataKey="value" />
                            </Column>
                        </AdvancedTable>

                        {/*<AdvancedTable
                            bordered
                            cellBordered
                            wordWrap
                            showHeader={false}
                            height={92}
                            rows={this.state.rightBottomTableRows}
                            initialSortColumn="name"
                            initialSortType="desc"
                            onRowSelectionChange={this.handleRowSelectionChange}
                            style={{
                                marginLeft: "20px",
                                marginRight: "20px",
                            }}
                        >
                            <Column width={110} verticalAlign="middle">
                                <HeaderCell />
                                <Cell dataKey="columnTitle" />
                            </Column>

                            <Column flexGrow={1} verticalAlign="middle">
                                <HeaderCell>Values</HeaderCell>
                                <Cell dataKey="value" />
                            </Column>
                        </AdvancedTable>*/}

                        <Plot
                            useResizeHandler
                            data={this.state.plantTraces}
                            layout={{
                                margin: { l: 60, r: 20, t: 20, b: 60 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                xaxis: {
                                    title: "time",
                                    showticklabels: false,
                                    zeroline: false
                                },
                                yaxis: {
                                    title: "recovery [%]"
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "400px"
                            }}
                        />
                    </div>
                </div>

                {/*<Timer
                    className="counter"
                    startTime={30}
                    onFinish={this.handleTimerFinish}
                />*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MineToMill);
