/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:57:24
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-24 23:55:13
 */

import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { SelectPicker, Button, Input, InputGroup, List } from "rsuite";
import Plot from "react-plotly.js";
import "./analytics.css";
import history from "../../shared/history";
import { getDataChunk } from "../../services/dataset";

class NewAnalytics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allTagNames: [],
            tagPickerData: [],
            averages: {},
            precision: {},
            percentileL: {},
            percentileH: {},
            filteredData: [],
            queryObj: {},
            selectedXTag: "",
            selectedYTag: "",
            plottedTagData: [],
            xData: [],
            yData: [],
            stack: [],
            LR: {
                sl: null,
                off: null,
                r2: null
            },
            tempMinMax: {},
            selectedList: [],
            styleObj: {},
            listData: []
        };

        this.getPlotData = this.getPlotData.bind(this);
        this.updateMax = this.updateMax.bind(this);
        this.updateMin = this.updateMin.bind(this);
        this.xyPlotUpdate = this.xyPlotUpdate.bind(this);
        this.getLayout = this.getLayout.bind(this);
        this.reset = this.reset.bind(this);
        this.handleUndoClick = this.handleUndoClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);

        this.pathParts = props.location.pathname.split("/");
        this.homeURL = "/" + this.pathParts[1] + "/" + this.pathParts[2];
    }

    async componentDidMount() {
        let result = await getDataChunk(
            this.props.endUserSession,
            this.homeURL,
            this.pathParts[4]
        );

        this.setStack([]);
        await this.dataInit(result);
    }

    setStack(array) {
        if (array.length > 0) {
            this.setState({ stack: array });
        } else {
            //console.log("in");
            let arr = this.state.stack;
            let obj = {};
            obj["plotsData"] = [];
            obj["xyPlotData"] = [
                this.state.selectedXTag,
                this.state.selectedYTag
            ];
            arr.push(obj);
            this.setState({ stack: arr });
        }
    }

    convertTextChunkToTableData(textChunk, isFullText) {
        // Get the column headers
        let rows = textChunk.split(/\r?\n/);
        let columnHeaders = rows[0].split(",");
        let previewColumnDefs = [];
        for (let columnHeader of columnHeaders) {
            previewColumnDefs.push({ field: columnHeader });
        }

        /*Remove the last, most likely incomplete, row, if the first chunk
        doesn't have all the data */
        if (!isFullText) {
            rows.pop();
        }
        // Remove the header row
        rows.shift();

        // Build the preview table with the data available
        let previewRows = [];
        for (let row of rows) {
            if (row === "") {
                continue;
            }
            let columns = row.split(",");
            let previewRow = {};
            for (let i = 0; i < columns.length; i++) {
                previewRow[columnHeaders[i]] = columns[i];
            }
            previewRows.push(previewRow);
        }

        return [previewRows, previewColumnDefs];
    }

    createTagPickerData(allTagNames) {
        let obj = {};
        let arr = [];
        for (var i = 0; i < allTagNames.length; i++) {
            obj = {
                label: allTagNames[i],
                value: allTagNames[i],
                disabled: false
            };
            arr.push(obj);
        }
        this.setState({ tagPickerData: arr });
    }

    getTagNamesFromDict(data) {
        let arr = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i]["field"] !== "Date") {
                arr.push(data[i]["field"]);
            }
        }
        return arr;
    }

    removeDate(data) {
        for (var i = 0; i < data.length; i++) {
            delete data[i]["Date"];
        }
        return data;
    }

    dataInit(csvData) {
        let allTagNames = [];
        let filteredData = [];

        let jsonData = this.convertTextChunkToTableData(
            csvData.textChunk,
            csvData.isFullText
        );
        allTagNames = this.getTagNamesFromDict(jsonData[1]);
        filteredData = this.removeDate(jsonData[0]);
        this.createTagPickerData(allTagNames);
        this.setState({
            filteredData: filteredData,
            allTagNames: allTagNames
        });

        /*
        if (
            this.props.hasOwnProperty("location") &&
            this.props.location.hasOwnProperty("state") &&
            this.props.location.state.hasOwnProperty("dataset")
        ) {
            let dataset = this.props.location.state.dataset;
            if (dataset.hasOwnProperty("useDataSet")) {
                if (dataset.useDataSet) {
                    allTagNames = this.state.allTagNames2;
                    filteredData = jsonData2;
                    this.setState({
                        filteredData: jsonData2,
                        allTagNames: this.state.allTagNames2
                    });
                } else {
                    allTagNames = Object.keys(jsonData[0]);
                    filteredData = jsonData;
                    this.setState({
                        filteredData: jsonData,
                        allTagNames: Object.keys(jsonData[0])
                    });
                }
            }
        }
        */
        var key;
        let averages = {};
        // let allTagNames =
        for (var i = 0; i < allTagNames.length; i++) {
            key = allTagNames[i];
            var sum = 0;
            for (var j = 0; j < filteredData.length; j++) {
                sum += filteredData[j][key];
            }
            averages[key] = sum / filteredData.length;
        }

        // find bottom 0.001% and top 0.001% of each tag
        let percentileH = {};
        let percentileL = {};
        for (var k = 0; k < allTagNames.length; k++) {
            key = allTagNames[k];
            var sortedData = filteredData.sort((a, b) =>
                a[key] > b[key] ? 1 : -1
            );
            percentileL[key] =
                sortedData[Math.floor(0.001 * filteredData.length)][key];
            percentileH[key] =
                sortedData[Math.floor(0.999 * filteredData.length)][key];
        }

        // find precision for each tag
        var precision = {};
        Object.keys(averages).forEach(function (key) {
            precision[key] = Math.pow(
                10,
                Math.floor(Math.log10(averages[key])) - 2
            );
        });

        this.setState({
            percentileH,
            percentileL,
            precision,
            averages,
            listData: allTagNames
        });
    }

    randn_bm() {
        var u = 0,
            v = 0;
        while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
        while (v === 0) v = Math.random();
        return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
    }

    getMin(tag) {
        let value = parseFloat(
            (
                Math.floor(
                    this.state.percentileL[tag] / this.state.precision[tag]
                ) * this.state.precision[tag]
            ).toPrecision(1)
        );
        return value;
    }

    getMax(tag) {
        let value = parseFloat(
            (
                Math.ceil(
                    this.state.percentileH[tag] / this.state.precision[tag]
                ) * this.state.precision[tag]
            ).toPrecision(1)
        );
        return value;
    }

    async updateMin(value, rowData) {
        let arr = this.state.plottedTagData;
        let index = arr.indexOf(rowData);
        let val = Math.round(value * 10) / 10;
        arr[index]["min"] = val;

        this.setState({
            plottedTagData: arr
        });
    }

    async updateMax(value, rowData) {
        let arr = this.state.plottedTagData;
        let index = arr.indexOf(rowData);
        let val = Math.round(value * 10) / 10;
        arr[index]["max"] = val;

        this.setState({
            plottedTagData: arr
        });
    }

    handlePlotRangeSelect(item, eventData) {
        /*
         let arr = this.state.stack;
         var dummy = {
             currMin: item.min,
             currMax: item.max,
             item: item
         };
         arr.push(dummy);
         this.setState({ stack: arr });
         */
        let max = eventData["range"]["x"][1];
        let min = eventData["range"]["x"][0];
        this.updateMax(max, item);
        this.updateMin(min, item);
        this.xyPlotUpdate(this.state.selectedXTag, this.state.selectedYTag);
    }

    handleMinChange(value, item) {
        /*
          let arr = this.state.stack;
          var dummy = {
              currMin: item.min,
              currMax: item.max,
              item: item
          };
          console.log(dummy);
          arr.push(dummy);
          */
        //this.setState({ stack: arr });
        this.updateMin(value, item);
    }

    handleMaxChange(value, item) {
        /*
          let arr = this.state.stack;
          var dummy = {
              currMin: item.min,
              currMax: item.max,
              item: item
          };
          console.log(dummy);
          arr.push(dummy);
          */
        //this.setState({ stack: arr });
        this.updateMax(value, item);
    }

    handlePlots = (arr) => {
        let tagValue;
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
            tagValue = arr[i];
            let obj = {
                tag: tagValue,
                min: this.getMin(tagValue),
                max: this.getMax(tagValue),
                step: this.state.precision[tagValue]
            };
            newArr.push(obj);
        }

        this.xyPlotUpdate(this.state.selectedXTag, this.state.selectedYTag);
        this.setState({ plottedTagData: newArr, selectedList: arr });
    };

    /*
    containsObject(obj, list) {
        for (var i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return true;
            }
        }
        return false;
    }
    */

    reset() {
        let arr = this.state.plottedTagData;
        if (arr.length > 0) {
            for (var i = 0; i < arr.length; i++) {
                let tag = arr[i]["tag"];
                let min = this.getMin(tag);
                let max = this.getMax(tag);
                this.updateMax(max, arr[i]);
                this.updateMin(min, arr[i]);
            }
        }
        this.xyPlotUpdate(this.state.selectedXTag, this.state.selectedYTag);
        this.setStack([]);
    }

    /*
    resetPlot(item) {
        let tag = item["tag"];
        let min = this.getMin(tag);
        let max = this.getMax(tag);
        this.updateMax(max, item);
        this.updateMin(min, item);
        this.setState({ stack: [] });
    }
    */
    /*
      filterPlotData(item) {
          let filteredData = [];
          let data = jsonData;
          let arr = this.state.plottedTagData;
          for (let i = 0; i < data.length; i++) {
              let row = data[i];
              let conformFlag = true;
              if (row[item.tag] < item["min"] || row[item.tag] > item["max"]) {
                  conformFlag = false;
              }

              if (conformFlag) {
                  filteredData.push(row);
              }
          }
          let plotData = [];
          for (var j = 0; j < filteredData.length; j++) {
              plotData.push(filteredData[j][item.tag]);
          }
          return plotData;
      }
      */

    filterData() {
        // iterate over each row of data and check if the query is satisfied for each variable before deciding whether to push it to the filteredData array
        let filteredData = [];
        let data = this.state.filteredData;
        let arr = this.state.plottedTagData;
        for (let i = 0; i < data.length; i++) {
            let row = data[i];
            let conformFlag = true;
            for (let j = 0; j < arr.length; j++) {
                if (
                    row[arr[j].tag] < arr[j]["min"] ||
                    row[arr[j].tag] > arr[j]["max"]
                ) {
                    conformFlag = false;
                    break;
                }
            }
            if (conformFlag) {
                filteredData.push(row);
            }
        }
        return filteredData;
    }

    handleApplyClick() {
        let arr = this.state.stack;
        let tempArr = [];
        let dummy = {};
        for (var i = 0; i < this.state.plottedTagData.length; i++) {
            dummy = {
                tag: this.state.plottedTagData[i].tag,
                min: this.state.plottedTagData[i].min,
                max: this.state.plottedTagData[i].max,
                step: this.state.plottedTagData[i].step
            };
            tempArr.push(dummy);
        }
        let obj = {};
        obj["plotsData"] = tempArr;
        obj["xyPlotData"] = [this.state.selectedXTag, this.state.selectedYTag];

        arr.push(obj);
        this.setStack(arr);
    }

    haveSameData(array, obj2) {
        let newArr = [];
        for (var i = 0; i < array.length; i++) {
            newArr.push(array[i]["tag"]);
        }
        return newArr.includes(obj2);
    }

    handleUndoClick() {
        let arr = this.state.stack;
        if (arr.length > 0) {
            var e = arr.pop();
            let selectedXTag = e["xyPlotData"][0];
            let selectedYTag = e["xyPlotData"][1];
            for (var i = 0; i < this.state.plottedTagData.length; i++) {
                if (
                    !this.haveSameData(
                        e.plotsData,
                        this.state.plottedTagData[i]["tag"]
                    )
                ) {
                    this.handleListItemClick(
                        this.state.plottedTagData[i]["tag"]
                    );
                }
            }
            this.setState({
                plottedTagData: e.plotsData,
                selectedXTag,
                selectedYTag
            });
            this.filterData();
            this.xyPlotUpdate(selectedXTag, selectedYTag);
        }
        this.setStack(arr);
    }

    xyPlotUpdate(selectedXTag, selectedYTag) {
        let filteredData = this.filterData();
        // call tag extraction function that includes filtration

        // update the xy plot with the new data
        if (selectedXTag !== "-" && selectedYTag !== "-") {
            if (selectedXTag !== "" && selectedYTag !== "") {
                let x = filteredData.map((row) => row[selectedXTag]);
                let y = filteredData.map((row) => row[selectedYTag]);
                let LR = this.linearRegression(x, y);
                this.setState({
                    xData: x,
                    yData: y,
                    LR: LR,
                    selectedXTag,
                    selectedYTag
                });
            } else {
                this.setState({
                    selectedXTag,
                    selectedYTag,
                    xData: [],
                    yData: [],
                    LR: {}
                });
            }
        }
    }

    linearRegression(x, y) {
        var lr = {};
        var n = y.length;
        var sum_x = 0;
        var sum_y = 0;
        var sum_xy = 0;
        var sum_xx = 0;
        var sum_yy = 0;

        for (var i = 0; i < y.length; i++) {
            sum_x += x[i];
            sum_y += y[i];
            sum_xy += x[i] * y[i];
            sum_xx += x[i] * x[i];
            sum_yy += y[i] * y[i];
        }

        lr["sl"] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
        lr["off"] = (sum_y - lr.sl * sum_x) / n;
        lr["r2"] = Math.pow(
            (n * sum_xy - sum_x * sum_y) /
                Math.sqrt(
                    (n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)
                ),
            2
        );

        return lr;
    }

    getPlotData(index) {
        var item = this.state.plottedTagData[index];
        var histPlotData = {
            x: [],
            type: "histogram",
            name: item.tag,
            mode: "none",
            marker: {
                color: "rgb(18,0,120)"
            }
        };

        let filteredData = this.filterData();
        // call tag extraction function that includes filtration

        for (var j = 0; j < filteredData.length; j++) {
            histPlotData["x"].push(filteredData[j][item.tag]);
        }

        return [histPlotData];
    }

    getLayout() {
        var histLayout = {
            font: { size: 10 },
            margin: {
                l: 10,
                r: 10,
                b: 5,
                t: 5,
                pad: 0
            },
            dragmode: "select",
            selectdirection: "h",
            yaxis: {
                automargin: true,
                autorange: true
            },
            xaxis: {
                automargin: true,
                autorange: true
            },
            hovermode: false,
            paper_bgcolor: "#FFFFFF",
            plot_bgcolor: "#FFFFFF",
            autosize: true
        };
        return histLayout;
    }

    getTrend(x, y) {
        var linReg = this.state.LR;
        var trendXStart = Math.min(...x);
        var trendXEnd = Math.max(...x);
        var trendYStart = trendXStart * linReg.sl + linReg.off;
        var trendYEnd = trendXEnd * linReg.sl + linReg.off;

        if (trendYStart < Math.min(...y)) {
            trendYStart = Math.min(...y);
            trendXStart = (trendYStart - linReg.off) / linReg.sl;
        }

        if (trendYEnd > Math.max(...y)) {
            trendYEnd = Math.max(...y);
            trendXEnd = (trendYEnd - linReg.off) / linReg.sl;
        }

        return [trendXStart, trendXEnd, trendYStart, trendYEnd];
    }

    getClassName() {
        if (this.state.plottedTagData.length <= 4) {
            return "plotsGrid-4";
        } else if (this.state.plottedTagData.length > 4) {
            return "plotsGrid-4andAbove";
        }
    }

    handleSearchBarChange(e) {
        let arr = this.state.allTagNames;
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].includes(e)) {
                newArr.push(arr[i]);
            }
        }
        this.setState({ listData: newArr });
    }

    handleListItemClick(item) {
        let arr = this.state.selectedList;
        let styleObj = this.state.styleObj;
        if (arr.includes(item)) {
            let index = arr.indexOf(item);
            arr.splice(index, 1);
            styleObj[item] = {};
        } else {
            arr.push(item);
            styleObj[item] = {
                backgroundColor: "#f2f2ff",
                color: "#150070",
                fontWeight: "bold"
            };
        }
        this.setState({ selectedList: arr, styleObj });
        this.handlePlots(arr);
    }

    /*
    handleTempMaxValue(max, item) {
        let obj = this.state.tempMinMax;
        obj[item.tag] = { max: max, min: item.min };
    }
    */

    async handleBackClick() {
        history.goBack();
    }

    render() {
        let trendData = this.getTrend(this.state.xData, this.state.yData);
        let x = [];
        let y = [];
        if (
            this.state.selectedXTag !== "-" &&
            this.state.selectedYTag !== "-"
        ) {
            if (
                this.state.selectedXTag !== "" &&
                this.state.selectedYTag !== ""
            ) {
                x = this.state.xData;
                y = this.state.yData;
            }
        }
        var xyPlotData = [
            {
                x: x,
                y: y,
                name: " ",
                ncontours: 10,
                colorscale: "Greys",
                reversescale: true,
                // YlOrRd
                showscale: false,
                type: "histogram2dcontour"
            },
            {
                x: x,
                y: y,
                name: " ",
                marker: {
                    size: 1.3,
                    opacity: 0.4,
                    color: "blue"
                },
                mode: "markers",
                type: "scatter"
            },
            {
                // trendline
                x: [trendData[0], trendData[1]],
                y: [trendData[2], trendData[3]],
                mode: "lines",
                line: {
                    width: 0.5,
                    opacity: 0.4,
                    color: "green"
                },
                type: "scatter"
            }
        ];

        var xyLayout = {
            font: { size: 10 },
            margin: {
                l: 20,
                r: 20,
                b: 10,
                t: 20,
                pad: 0
            },
            yaxis: {
                automargin: true
            },
            xaxis: {
                automargin: true
            },
            title: {
                // text: plotData['name'],
                font: {
                    size: 10
                }
            },
            dragmode: "none",
            hovermode: false,
            paper_bgcolor: "#FFFFFF",
            plot_bgcolor: "#FFFFFF",
            marginBottom: "10px",
            marginLeft: "10px",
            modeBarButtonsToRemove: ["lassoSelect"]
        };

        return (
            <div
                className="grid"
                style={{
                    padding: "15px"
                }}
            >
                <div className="Col2" style={{ maxHeight: "100%" }}>
                    <div className="TagSelection">
                        <div
                            className="wrapper"
                            style={{
                                width: "100%",
                                marginLeft: "10px"
                            }}
                        >
                            Data Filtration
                            <br />
                        </div>
                        <div style={{ padding: "10px" }}>
                            <InputGroup>
                                <Input
                                    onChange={(e) => {
                                        this.handleSearchBarChange(e);
                                    }}
                                    placeholder="Type here to filter tags"
                                />
                            </InputGroup>
                            <List className="body" style={{ height: "250px" }}>
                                {this.state.listData.map((item, index) => (
                                    <List.Item
                                        key={index}
                                        index={index}
                                        onClick={() => {
                                            this.handleListItemClick(item);
                                        }}
                                        style={this.state.styleObj[item]}
                                    >
                                        {item}
                                    </List.Item>
                                ))}
                            </List>
                        </div>
                    </div>
                    <div className="XYPlot" style={{ marginTop: "10px" }}>
                        <div
                            className="wrapper"
                            style={{
                                width: "100%",
                                marginLeft: "10px"
                            }}
                        >
                            X-Y Density Plot
                            <br />
                        </div>
                        <label style={{ marginLeft: "15px" }}>X-Tags: </label>
                        <SelectPicker
                            onChange={(value) => {
                                this.xyPlotUpdate(
                                    value,
                                    this.state.selectedYTag
                                );
                            }}
                            placeholder="X-tag"
                            data={this.state.tagPickerData}
                            style={{ width: 125 }}
                            value={this.state.selectedXTag}
                        />
                        <label style={{ marginLeft: "15px" }}>Y-Tags: </label>
                        <SelectPicker
                            onChange={(value) => {
                                this.xyPlotUpdate(
                                    this.state.selectedXTag,
                                    value
                                );
                            }}
                            placeholder="Y-tag"
                            data={this.state.tagPickerData}
                            style={{ width: 125 }}
                            value={this.state.selectedYTag}
                        />
                        <Plot
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                height: "430px",
                                width: "100%"
                            }}
                            config={{
                                displaylogo: false
                            }}
                            useResizeHandler
                            data={xyPlotData}
                            layout={xyLayout}
                        />
                        <div className="wrapper" style={{ marginLeft: "10px" }}>
                            slope: {this.state.LR.sl}
                            <br />
                            Intercept: {this.state.LR.off}
                            <br />
                            r2: {this.state.LR.r2}
                            <br />
                        </div>
                    </div>
                </div>
                <div
                    className="plots"
                    style={{
                        maxHeight: "914.516px",
                        overflowY: "scroll"
                    }}
                >
                    <div className="heading">
                        <label
                            className="wrapper"
                            style={{ marginLeft: "10px" }}
                        >
                            Historical Distributions
                        </label>
                        <div className="buttonDiv">
                            <Button
                                appearance="primary"
                                onClick={this.handleBackClick}
                                style={{ width: "100px" }}
                                size="xs"
                            >
                                Go Back
                            </Button>
                            <Button
                                onClick={() => {
                                    this.handleApplyClick();
                                }}
                                style={{ marginLeft: "3px", width: "100px" }}
                                appearance="primary"
                                size="xs"
                            >
                                Save
                            </Button>
                            <Button
                                style={{ marginLeft: "3px", width: "100px" }}
                                onClick={this.handleUndoClick}
                                appearance="primary"
                                size="xs"
                            >
                                Previous Save
                            </Button>
                            <Button
                                style={{ marginLeft: "3px", width: "100px" }}
                                appearance="primary"
                                size="xs"
                                onClick={this.reset}
                            >
                                Reset Plots
                            </Button>
                        </div>
                    </div>

                    <div
                        className={this.getClassName()}
                        style={{ padding: "10px" }}
                    >
                        {this.state.plottedTagData.map((item, index) => (
                            <div className="graphWrapper" key={index}>
                                <div
                                    className="wrapper"
                                    style={{
                                        marginLeft: "10px"
                                    }}
                                >
                                    {item.tag}
                                </div>

                                <Plot
                                    useResizeHandler
                                    className="plotCol"
                                    config={{
                                        displaylogo: false
                                    }}
                                    onSelected={(eventData) => {
                                        this.handlePlotRangeSelect(
                                            item,
                                            eventData
                                        );
                                    }}
                                    style={{
                                        height: "calc(100% - 73.4px)"
                                    }}
                                    data={this.getPlotData(index)}
                                    layout={this.getLayout(item)}
                                />

                                <div className="wrapper">
                                    {!isNaN(item.min) && !isNaN(item.max) ? (
                                        <div className="minMax">
                                            <label
                                                style={{
                                                    textAlign: "center"
                                                }}
                                            >
                                                Min:
                                            </label>
                                            <Input
                                                style={{
                                                    paddingLeft: "5px",
                                                    paddingRight: "5px"
                                                }}
                                                onChange={(minValue) =>
                                                    this.updateMin(
                                                        minValue,
                                                        item
                                                    )
                                                }
                                                value={item.min}
                                                size="xs"
                                            />
                                            <label
                                                style={{
                                                    textAlign: "center"
                                                }}
                                            >
                                                Max:
                                            </label>
                                            <Input
                                                style={{
                                                    paddingLeft: "5px",
                                                    paddingRight: "5px"
                                                }}
                                                onChange={(maxValue) =>
                                                    this.updateMax(
                                                        maxValue,
                                                        item
                                                    )
                                                }
                                                value={item.max}
                                                size="xs"
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NewAnalytics)
);
