/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:25:20
 */

import React, { Component } from "react";
import {
    Button,
    Form,
    InputPicker,
    Modal,
    Nav,
    Schema,
    Whisper,
    Popover
} from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import {
    addFunction,
    addCategory
} from "../../../services/functionalityManagement";
import Editor from "@monaco-editor/react";

class AddFunctionalityModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            functionErrorMessages: {},
            categoryErrorMessages: {},
            functionFormJSON: {
                name: "",
                commonName: "",
                description: "",
                status: "",
                location: ""
            },
            categoryFormJSON: {
                name: "",
                description: "",
                status: ""
            },
            configurationJSON: "{}",
            activeNavKey: "function",
            statusPickerOptions: [
                {
                    label: "Inactive",
                    value: "inactive",
                    role: "Master"
                }
            ]
        };

        this.clearForm = this.clearForm.bind(this);
        this.validateConfiguration = this.validateConfiguration.bind(this);
        this.handleSubmitButtonClick = this.handleSubmitButtonClick.bind(this);
        this.handleNavSelect = this.handleNavSelect.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);

        this.functionValidationModel = Schema.Model({
            name: validators.functionalityNameValidation,
            commonName: validators.functionalityNameValidation,
            description: validators.descriptionValidation,
            status: validators.statusValidation,
            location: validators.pathValidation
        });

        this.categoryValidationModel = Schema.Model({
            name: validators.functionalityNameValidation,
            description: validators.descriptionValidation,
            status: validators.statusValidation
        });
    }

    clearForm() {
        let functionFormJSON = this.state.functionFormJSON;
        for (let field in functionFormJSON) {
            if (functionFormJSON.hasOwnProperty(field)) {
                functionFormJSON[field] = "";
            }
        }

        let categoryFormJSON = this.state.categoryFormJSON;
        for (let field in categoryFormJSON) {
            if (categoryFormJSON.hasOwnProperty(field)) {
                categoryFormJSON[field] = "";
            }
        }

        this.setState({
            functionFormJSON: functionFormJSON,
            categoryFormJSON: categoryFormJSON,
            configurationJSON: "{}",
            functionErrorMessages: {},
            categoryErrorMessages: {}
        });
    }

    validateConfiguration() {
        try {
            JSON.parse(this.state.configurationJSON);
            this.editorWhisper.close();
            return true;
        } catch (e) {
            this.editorWhisper.open();
            return false;
        }
    }

    async handleSubmitButtonClick() {
        let result;
        if (this.state.activeNavKey === "function") {
            let formCheckResult = this.functionForm.check();
            if (this.validateConfiguration() && formCheckResult) {
                this.setState({ loading: true });
                let data = this.state.functionFormJSON;
                data.type = "function";
                data.parentName = this.props.parentName;
                data.parentId = this.props.parentId;
                data.defaultConfig = JSON.parse(this.state.configurationJSON);
                result = await addFunction(this.props.endUserSession, data);
            }
        } else {
            if (this.categoryForm.check()) {
                this.setState({ loading: true });
                let data = this.state.categoryFormJSON;
                data.type = "category";
                data.parentName = this.props.parentName;
                data.parentId = this.props.parentId;
                result = await addCategory(this.props.endUserSession, data);
            }
        }

        if (result) {
            this.props.onClose();
            this.props.onSubmit();
        } else {
            this.setState({ loading: false });
        }
    }

    handleNavSelect(activeNavKey) {
        this.setState({ activeNavKey: activeNavKey });
    }

    handleEditorChange(newJSON) {
        this.setState({
            configurationJSON: newJSON
        });
    }

    handleModalOpen() {
        this.clearForm();
        let statusPickerOptions;
        if (this.props.parentStatus === "inactive") {
            statusPickerOptions = [
                {
                    label: "Inactive",
                    value: "inactive",
                    role: "Master"
                }
            ];
        } else {
            statusPickerOptions = [
                {
                    label: "Active",
                    value: "active",
                    role: "Master"
                },
                {
                    label: "Inactive",
                    value: "inactive",
                    role: "Master"
                }
            ];
        }
        this.setState({
            loading: false,
            statusPickerOptions: statusPickerOptions
        });
    }

    render() {
        let form;
        if (this.state.activeNavKey === "function") {
            form = (
                <div>
                    <Form
                        ref={(forum) => (this.functionForm = forum)}
                        layout="horizontal"
                        formValue={this.state.functionFormJSON}
                        model={this.functionValidationModel}
                        checkTrigger="none"
                        onChange={(newFormJSON) => {
                            this.setState({ functionFormJSON: newFormJSON });
                        }}
                        onCheck={(errorMessages) => {
                            this.setState({
                                functionErrorMessages: errorMessages
                            });
                        }}
                        style={{ marginTop: "20px" }}
                    >
                        <Form.Group>
                            <Form.ControlLabel>Name</Form.ControlLabel>
                            <Form.Control
                                name="name"
                                errorMessage={
                                    this.state.functionErrorMessages.name
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Common Name</Form.ControlLabel>
                            <Form.Control
                                name="commonName"
                                errorMessage={
                                    this.state.functionErrorMessages.commonName
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Description</Form.ControlLabel>
                            <Form.Control
                                name="description"
                                rows={5}
                                as="textarea"
                                errorMessage={
                                    this.state.functionErrorMessages.description
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Status</Form.ControlLabel>
                            <Form.Control
                                name="status"
                                accepter={InputPicker}
                                data={this.state.statusPickerOptions}
                                errorMessage={
                                    this.state.functionErrorMessages.status
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Location</Form.ControlLabel>
                            <Form.Control
                                name="location"
                                errorMessage={
                                    this.state.functionErrorMessages.location
                                }
                            />
                        </Form.Group>
                    </Form>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        Default Config
                    </div>
                    <Whisper
                        ref={(editorWhisper) => {
                            this.editorWhisper = editorWhisper;
                        }}
                        placement="bottomStart"
                        trigger="none"
                        speaker={
                            <Popover
                                style={{
                                    color: "#f44336",
                                    margin: "0",
                                    paddingTop: "4px",
                                    paddingRight: "8px",
                                    paddingBottom: "4px",
                                    paddingLeft: "8px"
                                }}
                            >
                                Invalid JSON
                            </Popover>
                        }
                    >
                        <div
                            style={{
                                border: "2px solid #b4b4b4",
                                height: "250px"
                            }}
                        >
                            <Editor
                                defaultLanguage="json"
                                options={{
                                    minimap: {
                                        enabled: false
                                    },
                                    automaticLayout: true,
                                    scrollBeyondLastLine: false
                                }}
                                defaultValue={this.state.configurationJSON}
                                onChange={this.handleEditorChange}
                            />
                        </div>
                    </Whisper>
                </div>
            );
        } else {
            form = (
                <Form
                    ref={(forum) => (this.categoryForm = forum)}
                    layout="horizontal"
                    formValue={this.state.categoryFormJSON}
                    model={this.categoryValidationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ categoryFormJSON: newFormJSON });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({ categoryErrorMessages: errorMessages });
                    }}
                    style={{ marginTop: "20px" }}
                >
                    <Form.Group>
                        <Form.ControlLabel>Name</Form.ControlLabel>
                        <Form.Control
                            name="name"
                            errorMessage={this.state.categoryErrorMessages.name}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Description</Form.ControlLabel>
                        <Form.Control
                            name="description"
                            rows={5}
                            as="textarea"
                            errorMessage={
                                this.state.categoryErrorMessages.description
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Status</Form.ControlLabel>
                        <Form.Control
                            name="status"
                            accepter={InputPicker}
                            data={this.state.statusPickerOptions}
                            errorMessage={
                                this.state.categoryErrorMessages.status
                            }
                        />
                    </Form.Group>
                </Form>
            );
        }

        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Add Functionality</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Nav
                        appearance="tabs"
                        activeKey={this.state.activeNavKey}
                        onSelect={this.handleNavSelect}
                    >
                        <Nav.Item
                            eventKey="function"
                            style={{ width: "100px" }}
                        >
                            Function
                        </Nav.Item>
                        <Nav.Item
                            eventKey="category"
                            style={{ width: "100px" }}
                        >
                            Category
                        </Nav.Item>
                    </Nav>
                    {form}
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleSubmitButtonClick}
                            appearance="primary"
                            type="submit"
                            loading={this.state.loading}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFunctionalityModal);
