/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-11 18:14:36
 */

import React, { Component } from "react";
import { Progress } from "rsuite";
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import history from "../../shared/history";
import Timer from "../../components/timer";
import "./flotation.css";
import { getMillData } from "../../services/mineexpo-demo-services/millData";
import BaseURL from "../../api-common";
import { refreshToken } from "../../shared/auth";

class Flotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
            pictureIndex: 0,
            bubbleSizeTraces: [
                {
                    x: [],
                    y: [],
                    type: "bar",
                    marker: { color: "#2c1c94" }
                }
            ],
            recoveryTraces: [
                {
                    x: Array.from(Array(20).keys()),
                    y: [
                        93.9, 92.8, 95.6, 95.0, 91.6, 95.0, 91.9, 91.1, 93.4,
                        94.5, 93.9, 91.2, 94.0, 90.2, 91.3, 94.8, 93.4, 90.6,
                        91.7, 95.7
                    ],
                    type: "scatter",
                    mode: "lines+markers",
                    hoverinfo: "y",
                    marker: { color: "#2c1c94" }
                }
            ]
        };

        this.updateData = this.updateData.bind(this);

        this.counter = setInterval(this.updateData, 4000);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.updateData();
    }

    componentWillUnmount() {
        clearInterval(this.counter);
    }

    async updateData() {
        let result = await getMillData(
            this.props.endUserSession,
            this.state.startIndex
        );

        let bubbleSizeTraces = JSON.parse(
            JSON.stringify(this.state.bubbleSizeTraces)
        );
        let recoveryTraces = JSON.parse(
            JSON.stringify(this.state.recoveryTraces)
        );

        if (result == null) {
            return;
        }

        let flotationData = result.flotation;

        bubbleSizeTraces[0].x = flotationData.bubbleSizeFrequency.x;
        bubbleSizeTraces[0].y = flotationData.bubbleSizeFrequency.y;
        recoveryTraces[0].y.push(flotationData.recovery);

        if (recoveryTraces[0].y.length > 20) {
            recoveryTraces[0].y.shift();
        }

        return this.setState({
            bubbleSizeTraces: bubbleSizeTraces,
            recoveryTraces: recoveryTraces,
            pictureIndex: (this.state.pictureIndex + 1) % 20,
            startIndex: (this.state.startIndex + 1) % 121
        });
    }

    handleTimerFinish() {
        history.push("mineToMill");
    }

    render() {
        return (
            <div id="flotation">
                <div className="grid">
                    <div className="vert" style={{ overflow: "hidden" }}>
                        <div className="title">Froth Source Image</div>
                        <div
                            style={{
                                height: "calc(100% - 45px)",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <img
                                src={
                                    BaseURL.getBaseURL() +
                                    "/assets/bubble_crop_" +
                                    this.state.pictureIndex +
                                    "_90.jpg"
                                }
                                alt="rock"
                                style={{
                                    width: "50%",
                                    maxWidth: "50%",
                                    maxHeight: "100%",
                                    marginRight: "10px"
                                }}
                            />
                            <img
                                src={
                                    BaseURL.getBaseURL() +
                                    "/assets/bubble_crop_" +
                                    this.state.pictureIndex +
                                    "_segment_90.jpg"
                                }
                                alt="rockSegmented"
                                style={{
                                    width: "50%",
                                    maxWidth: "50%",
                                    maxHeight: "100%"
                                }}
                            />
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">Bubble Size Distribution</div>
                        <Plot
                            useResizeHandler
                            data={this.state.bubbleSizeTraces}
                            layout={{
                                margin: { l: 40, r: 20, t: 20, b: 40 },
                                autosize: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                showlegend: false,
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                bargap: 0.0
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "400px"
                            }}
                        />
                    </div>
                    <div className="card">
                        <div className="title">Recovery Prediction [%]</div>
                        <Plot
                            useResizeHandler
                            data={this.state.recoveryTraces}
                            layout={{
                                margin: { l: 40, r: 20, t: 20, b: 40 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                xaxis: {
                                    showticklabels: false,
                                    zeroline: false
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "400px"
                            }}
                        />
                    </div>
                </div>

                {/*<Timer
                    className="counter"
                    startTime={30}
                    onFinish={this.handleTimerFinish}
                />*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flotation);
