/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:26:23
 */

import React, { Component } from "react";
import { Checkbox, Table, Pagination } from "rsuite";
import PropTypes from "prop-types";
const { Column, HeaderCell, Cell } = Table;

function sortRows(rows, sortColumn, sortType) {
    rows.sort((a, b) => {
        let aIsEmpty = a[sortColumn] === "" || a[sortColumn] === undefined;
        let bIsEmpty = b[sortColumn] === "" || b[sortColumn] === undefined;
        if ((aIsEmpty && bIsEmpty) || aIsEmpty) {
            return 1;
        }

        if (bIsEmpty) {
            return -1;
        }

        if (sortType === "desc") {
            if (a[sortColumn] < b[sortColumn]) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a[sortColumn] > b[sortColumn]) {
                return 1;
            } else {
                return -1;
            }
        }
    });
    return rows;
}

function determinePaginationDisplayedRows(rows, displayLength, page) {
    return rows.filter((v, i) => {
        const start = displayLength * (page - 1);
        const end = start + displayLength;
        return i >= start && i < end;
    });
}

class AdvancedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: props.rows,
            revision: props.revision,
            displayedRows: [], // Mainly used for pagination. Rows that should be displayed in the table should be inside.
            selectedRows: [], // The rows that the user has selected. Determined with the help of state.rows[i].checked
            paginationDisplayLength: 10, // When pagination is used, this is the number of rows displayed in one page
            paginationPage: 1, // When pagination is used, this keeps track of the current page number.
            loading: false,
            sortType: props.initialSortType,
            sortColumn: props.initialSortColumn,
            internalChange: false // Set this to true everytime this.setState is called
        };

        this.handleSortColumn = this.handleSortColumn.bind(this);
        this.handleMasterCheckboxChange =
            this.handleMasterCheckboxChange.bind(this);
        this.handleRowCheckboxChange = this.handleRowCheckboxChange.bind(this);
        this.handlePaginationChangeLength =
            this.handlePaginationChangeLength.bind(this);
        this.handlePaginationChangePage =
            this.handlePaginationChangePage.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        // Don't do anything if an internal state change occurred
        if (state.internalChange) {
            return {
                internalChange: false
            };
        }

        // Do nothing if the revision prop has an int value and has not changed
        if (props.revision != null && state.revision === props.revision) {
            return {};
        }

        // Assign all the rows the checked property. This helps keep track of which rows have been selected.
        let rows = props.rows;
        for (let row of rows) {
            row.checked = false;
        }

        // Sort the new rows
        rows = sortRows(rows, state.sortColumn, state.sortType);

        let paginationPage = state.paginationPage;
        if (props.rows.length < state.rows.length) {
            paginationPage = 1; // reset current page
        }

        // If the table is separated into pages, get the rows to display
        let displayedRows;
        if (props.paginate) {
            displayedRows = determinePaginationDisplayedRows(
                rows,
                state.paginationDisplayLength,
                paginationPage
            );
        } else {
            displayedRows = rows;
        }

        if (props.revision == null) {
            return {
                rows: rows,
                selectedRows: [],
                displayedRows: displayedRows,
                paginationPage: paginationPage
            };
        } else {
            return {
                rows: rows,
                selectedRows: [],
                revision: props.revision,
                displayedRows: displayedRows,
                paginationPage: paginationPage
            };
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Perform an update since an internal state change occurred
        if (this.state.internalChange) {
            return true;
        }

        if (
            nextProps.revision != null &&
            this.state.revision === nextProps.revision
        ) {
            return false;
        } else {
            return true;
        }
    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true,
            internalChange: true
        });

        let rows = sortRows(this.state.rows, sortColumn, sortType);

        let displayedRows;
        if (this.props.paginate) {
            displayedRows = determinePaginationDisplayedRows(
                rows,
                this.state.paginationDisplayLength,
                this.state.paginationPage
            );
        } else {
            displayedRows = rows;
        }

        this.setState({
            sortColumn: sortColumn,
            sortType: sortType,
            loading: false,
            rows: rows,
            displayedRows: displayedRows,
            internalChange: true
        });
    }

    handleMasterCheckboxChange(_, checked) {
        for (let row of this.state.rows) {
            row.checked = checked;
        }

        if (checked) {
            if (this.props.onRowSelectionChange) {
                this.props.onRowSelectionChange(this.state.rows);
            }
            this.setState({
                selectedRows: this.state.rows,
                internalChange: true
            });
        } else {
            if (this.props.onRowSelectionChange) {
                this.props.onRowSelectionChange([]);
            }

            this.setState({ selectedRows: [], internalChange: true });
        }
    }

    handleRowCheckboxChange(rowData, checked) {
        rowData.checked = checked;

        let selectedRows = [];
        for (let row of this.state.rows) {
            if (row.checked) {
                selectedRows.push(row);
            }
        }

        if (this.props.onRowSelectionChange) {
            this.props.onRowSelectionChange(selectedRows);
        }
        this.setState({ selectedRows: selectedRows, internalChange: true });
    }

    handlePaginationChangePage(paginationPage) {
        let displayedRows;
        if (this.props.paginate) {
            displayedRows = determinePaginationDisplayedRows(
                this.state.rows,
                this.state.paginationDisplayLength,
                paginationPage
            );
        } else {
            displayedRows = this.state.rows;
        }

        this.setState({
            paginationPage: paginationPage,
            displayedRows: displayedRows,
            internalChange: true
        });
    }

    handlePaginationChangeLength(paginationDisplayLength) {
        let paginationPage = 1;
        let displayedRows;
        if (this.props.paginate) {
            displayedRows = determinePaginationDisplayedRows(
                this.state.rows,
                paginationDisplayLength,
                paginationPage
            );
        } else {
            displayedRows = this.state.rows;
        }

        this.setState({
            paginationPage: paginationPage,
            paginationDisplayLength: paginationDisplayLength,
            displayedRows: displayedRows,
            internalChange: true
        });
    }

    render() {
        return (
            <div>
                <Table
                    affixHeader={this.props.affixHeader}
                    affixHorizontalScrollbar={
                        this.props.affixHorizontalScrollbar
                    }
                    autoHeight={this.props.autoHeight}
                    ref={this.props.ref}
                    bordered={this.props.bordered}
                    cellBordered={this.props.cellBordered}
                    data={this.state.displayedRows}
                    defaultExpandAllRows={this.props.defaultExpandAllRows}
                    defaultExpandedRowKeys={this.props.defaultExpandedRowKeys}
                    defaultSortType={this.props.defaultSortType}
                    expandedRowKeys={this.props.expandedRowKeys}
                    headerHeight={this.props.headerHeight}
                    height={this.props.height}
                    hover={this.props.hover}
                    isTree={this.props.isTree}
                    loading={this.props.loading || this.state.loading}
                    minHeight={this.props.minHeight}
                    onExpandChange={this.props.onExpandChange}
                    onRowClick={this.props.onRowClick}
                    onScroll={this.props.onScroll}
                    onSortColumn={this.handleSortColumn}
                    renderEmpty={this.props.renderEmpty}
                    renderLoading={this.props.renderLoading}
                    renderRowExpanded={this.props.renderRowExpanded}
                    renderTreeToggle={this.props.renderTreeToggle}
                    rowClassName={this.props.rowClassName}
                    rowExpandedHeight={this.props.rowExpandedHeight}
                    rowHeight={this.props.rowHeight}
                    rowKey={this.props.rowKey}
                    shouldUpdateScroll={this.props.shouldUpdateScroll}
                    showHeader={this.props.showHeader}
                    sortColumn={this.state.sortColumn}
                    sortType={this.state.sortType}
                    virtualized={this.props.virtualized}
                    width={this.props.width}
                    wordWrap={this.props.wordWrap}
                    style={this.props.style}
                >
                    {this.props.selectable ? (
                        <Column
                            fixed
                            align="center"
                            verticalAlign="middle"
                            width={50}
                            style={{ padding: "0" }}
                        >
                            <HeaderCell>
                                <Checkbox
                                    inline
                                    defaultChecked={false}
                                    checked={
                                        this.state.selectedRows.length ===
                                            this.state.rows.length &&
                                        this.state.rows.length !== 0
                                    }
                                    indeterminate={
                                        this.state.selectedRows.length <
                                            this.state.rows.length &&
                                        this.state.selectedRows.length > 0
                                    }
                                    style={{ margin: "0" }}
                                    onChange={this.handleMasterCheckboxChange}
                                />
                            </HeaderCell>
                            <Cell>
                                {(rowData) => (
                                    <Checkbox
                                        inline
                                        defaultChecked={false}
                                        style={{ margin: "0" }}
                                        checked={rowData.checked}
                                        value={rowData}
                                        onChange={this.handleRowCheckboxChange}
                                    />
                                )}
                            </Cell>
                        </Column>
                    ) : null}

                    {this.props.children}
                </Table>
                {this.props.paginate ? (
                    <Pagination
                        activePage={this.state.paginationPage}
                        boundaryLinks={this.props.paginationBoundaryLinks}
                        classPrefix={this.props.paginationClassPrefix}
                        disabled={
                            this.props.paginationDisabled || this.props.loading
                        }
                        ellipsis={this.props.paginationEllipsis}
                        first={this.props.paginationFirst}
                        next={this.props.paginationNext}
                        prev={this.props.paginationPrev}
                        last={this.props.paginationLast}
                        layout={this.props.paginationLayout}
                        limit={this.state.paginationDisplayLength}
                        limitOptions={this.props.paginationLimitOptions}
                        linkAs={this.props.paginationLinkAs}
                        linkProps={this.props.paginationLinkProps}
                        locale={this.props.paginationLocale}
                        maxButtons={this.props.paginationMaxButtons}
                        onChangeLimit={this.handlePaginationChangeLength}
                        onChangePage={this.handlePaginationChangePage}
                        total={this.state.rows.length}
                        style={{ marginTop: "5px" }}
                    />
                ) : null}
            </div>
        );
    }
}

AdvancedTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    initialSortColumn: PropTypes.string,
    initialSortType: PropTypes.string,
    revision: PropTypes.number,
    selectable: PropTypes.bool,
    onRowSelectionChange: PropTypes.func
};

AdvancedTable.defaultProps = {
    rows: [],
    initialSortColumn: "",
    initialSortType: "desc",
    revision: null,
    selectable: false,
    onRowSelectionChange: () => {}
};

export default AdvancedTable;
