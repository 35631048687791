/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:43
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-04-12 16:04:33
 */

import React, { Component } from "react";
import { Button, Modal } from "rsuite";
import { restoreArchive } from "../../../services/databaseArchiveManagement";
import * as actions from "../../../store/actions/auth";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class RestoreArchiveModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };

        this.handleRestoreClick = this.handleRestoreClick.bind(this);
        this.handleModalOnHide = this.handleModalOnHide.bind(this);
    }

    async handleRestoreClick() {
        this.setState({ loading: true });
        let result = await restoreArchive(
            this.props.endUserSession,
            this.props.location.pathname,
            {
                fileName: this.props.fileName
            }
        );
        this.setState({ loading: false });

        if (result) {
            this.props.onHide();
            this.props.onSubmit();
        }
    }

    handleModalOnHide() {
        if (!this.state.loading) {
            this.props.onHide();
        }
    }

    render() {
        return (
            <div>
                <Modal show={this.props.show} onHide={this.handleModalOnHide}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Restore Archive?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to restore this archive? Any changes made to
                        the database since the last backup will be lost
                        permanently.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.handleRestoreClick}
                            appearance="primary"
                            loading={this.state.loading}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={this.props.onHide}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RestoreArchiveModal)
);
