/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2021-06-09 16:21:24
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-02 15:15:36
 */

import React from "react";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Plot from "react-plotly.js";

const influencersGraph = (props) => {
    return (
        <Plot
            data={props.graphData}
            useResizeHandler
            config={{
                displaylogo: false,
                staticPlot: props.loading,
                responsive: true
            }}
            style={
                props.loading
                    ? {
                          width: "100% - 20px",
                          height: "300px",
                          opacity: 0.5
                      }
                    : {
                          width: "100% - 20px",
                          height: "300px"
                      }
            }
        ></Plot>
    );
};

export default influencersGraph;
