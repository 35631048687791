/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-06-13 13:46:49
 */

import React, { Component } from "react";

import { Button, Table, DatePicker, Input, toaster, Message } from "rsuite";
import AdvancedTable from "../components/advancedTable";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import { getAllIndustrials, updateIndustrials } from "../services/industrial";
import { withRouter } from "react-router-dom";
import { getTarget, updateTarget } from "../services/target";
import PopulateConfirmation from "../components/modals/populatorConfirmation";
import SaveIcon from "@rsuite/icons/legacy/Save";
import PencilIcon from "@rsuite/icons/legacy/Pencil";
import BombIcon from "@rsuite/icons/legacy/Bomb";
import CloseIcon from "@rsuite/icons/Close";

const { Column, HeaderCell, Cell, ColumnGroup } = Table;

function deepObjectEquals(x, y) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue;
        // if they have the same strict value or identity then they are equal

        if (typeof x[p] !== "object") return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!deepObjectEquals(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }

    for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    // allows x[ p ] to be set to undefined

    return true;
}

class Floatation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainBodyWidth: window.innerWidth,
            industrials: [],
            target: {},
            editing: false,
            editingRows: [],
            rows: [],
            editingTimeColumnHeaders: [],
            timeColumnHeaders: [],
            startDateTime: new Date(1633698000000), // Fri Oct 08 2021 07:00:00 GMT-0600 (Mountain Daylight Time)
            endDateTime: new Date(1633784399999), // Sat Oct 09 2021 06:59:59 GMT-0600 (Mountain Daylight Time),
            showingPopulateConfirmation: false
        };

        this.tableNames = {
            jameson: ["PH", "xanthate", "AF6697", "Frother"],
            cell1: ["PH", "xanthate", "AF6697", "Frother"],
            cell2: ["xanthate"],
            cell3: ["xanthate", "AF6697", "Frother"],
            cell5: ["xanthate", "AF6697"],
            preleach: ["ZLeach"]
        };

        this.defaultCellEditingStatus = [];
        for (let rowIndex = 0; rowIndex < 15; rowIndex++) {
            let element = {};
            for (let timeIndex = 0; timeIndex < 12; timeIndex++) {
                element["time" + timeIndex] = "good";
            }
            element["target"] = "good";
            this.defaultCellEditingStatus.push(element);
        }

        this.defaultHeaderEditingStatus = {};
        for (let timeIndex = 0; timeIndex < 12; timeIndex++) {
            this.defaultHeaderEditingStatus["time" + timeIndex] = "good";
        }

        this.state.cellEditingStatus = this.defaultCellEditingStatus;
        this.state.headerEditingStatus = this.defaultHeaderEditingStatus;

        this.refreshTableData = this.refreshTableData.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleLoopTabClick = this.handleLoopTabClick.bind(this);
        this.handleTablesTabClick = this.handleTablesTabClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelEditClick = this.handleCancelEditClick.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handlePopulateButtonClick =
            this.handlePopulateButtonClick.bind(this);
        this.handlePopulateModalSubmit =
            this.handlePopulateModalSubmit.bind(this);
        this.handlePopulateModalExit = this.handlePopulateModalExit.bind(this);
        this.handleEditCellBlur = this.handleEditCellBlur.bind(this);
        this.handleEditTimeHeaderBlur =
            this.handleEditTimeHeaderBlur.bind(this);
        this.renderTableTimeColumns = this.renderTableTimeColumns.bind(this);
        this.renderTableTargetColumn = this.renderTableTargetColumn.bind(this);
        this.EditCell = this.EditCell.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        let industrials = await getAllIndustrials(
            this.props.endUserSession,
            this.props.location.pathname,
            this.state.startDateTime.getTime(),
            this.state.endDateTime.getTime()
        );

        let target = await getTarget(
            this.props.endUserSession,
            this.props.location.pathname,
            this.state.startDateTime.getTime()
        );
        let rows = this.convertIndustrialsToRows(industrials, target);
        let timeColumnHeaders =
            this.convertIndustrialsToTimeColumnHeaders(industrials);

        window.addEventListener("resize", this.handleResize);
        this.refreshInterval = setInterval(this.refreshTableData, 10000);

        this.setState({
            rows: rows,
            timeColumnHeaders: timeColumnHeaders,
            industrials: industrials,
            target: target
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        clearInterval(this.refreshInterval);
    }

    convertIndustrialsToRows(industrials, target) {
        let rows = [];
        for (let primaryName in this.tableNames) {
            if (primaryName === "_id" || primaryName === "date") {
                continue;
            }

            let primaryNameRowSpan = this.tableNames[primaryName].length;
            let firstGroupRow = true;
            for (let secondaryName of this.tableNames[primaryName]) {
                let row = {};
                row.primaryName = primaryName;
                row.secondaryName = secondaryName;
                if (firstGroupRow) {
                    row.primaryNameRowSpan = primaryNameRowSpan;
                    firstGroupRow = false;
                }

                if (target === null) {
                    row["target"] = null;
                } else {
                    row["target"] = target[primaryName][secondaryName];
                }

                let sum = null;
                let validPointsCount = 0;
                for (let timeIndex = 0; timeIndex < 12; timeIndex++) {
                    if (timeIndex >= industrials.length) {
                        row["time" + timeIndex] = null;
                        continue;
                    }

                    let value =
                        industrials[timeIndex][primaryName][secondaryName];
                    if (value !== null) {
                        sum += value;
                        validPointsCount += 1;
                    }

                    row["time" + timeIndex] =
                        industrials[timeIndex][primaryName][secondaryName];
                }

                if (validPointsCount !== 0) {
                    row.average = Math.round(sum / validPointsCount);
                }
                rows.push(row);
            }
        }

        return rows;
    }

    convertIndustrialsToTimeColumnHeaders(industrials) {
        let timeColumnHeaders = [];
        for (let timeIndex = 0; timeIndex < 12; timeIndex++) {
            let timeColumnHeader;
            if (timeIndex < industrials.length) {
                let date = new Date(industrials[timeIndex].date);
                timeColumnHeader = date.toLocaleString("en-CA", {
                    hour: "numeric",
                    minute: "2-digit",
                    hourCycle: "h23",
                    timeZone: "America/Edmonton"
                });
            } else {
                timeColumnHeader = null;
            }
            timeColumnHeaders.push(timeColumnHeader);
        }
        return timeColumnHeaders;
    }

    convertRowsToIndustrials(
        rows,
        timeColumnHeaders,
        startDateTime,
        endDateTime
    ) {
        let industrials = [];
        for (let timeIndex = 0; timeIndex < 12; timeIndex++) {
            let industrial = {};
            let timeString = timeColumnHeaders[timeIndex];
            if (timeString === null) {
                continue;
            } else if (Number(timeString[0] + timeString[1]) >= 7) {
                let dateString = startDateTime.toLocaleString("en-CA", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    timeZone: "America/Edmonton"
                });
                industrial.date = new Date(
                    dateString + " " + timeString
                ).getTime();
            } else {
                let dateString = endDateTime.toLocaleString("en-CA", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    timeZone: "America/Edmonton"
                });
                industrial.date = new Date(
                    dateString + " " + timeString
                ).getTime();
            }

            for (let row of rows) {
                industrial[row.primaryName] = {};
            }
            for (let row of rows) {
                let newValue = row["time" + timeIndex];
                if (newValue !== null) {
                    newValue = Number(newValue);
                }
                industrial[row.primaryName][row.secondaryName] = newValue;
            }
            industrials.push(industrial);
        }

        return industrials;
    }

    convertRowsToTarget(rows, startDateTime) {
        let target = {};

        target.date = startDateTime.getTime();
        for (let rowIndex = 0; rowIndex < 15; rowIndex++) {
            let row = rows[rowIndex];
            if (target[row["primaryName"]] === undefined) {
                target[row["primaryName"]] = {};
            }

            let newValue = row["target"];
            if (newValue !== null) {
                newValue = Number(newValue);
            }
            target[row["primaryName"]][row["secondaryName"]] = newValue;
        }

        return target;
    }

    async refreshTableData() {
        if (this.state.editing) {
            return;
        }

        let industrials = await getAllIndustrials(
            this.props.endUserSession,
            this.props.location.pathname,
            this.state.startDateTime.getTime(),
            this.state.endDateTime.getTime()
        );
        let target = await getTarget(
            this.props.endUserSession,
            this.props.location.pathname,
            this.state.startDateTime.getTime()
        );

        if (
            deepObjectEquals(this.state.industrials, industrials) &&
            deepObjectEquals(this.state.target, target)
        ) {
            return;
        }

        let rows = this.convertIndustrialsToRows(industrials, target);
        let timeColumnHeaders =
            this.convertIndustrialsToTimeColumnHeaders(industrials);

        toaster.push(
            <Message type="info" showIcon closable duration={30000}>
                {<text>The table values have changed</text>}
            </Message>
        );

        this.setState({
            rows: rows,
            timeColumnHeaders: timeColumnHeaders,
            industrials: industrials,
            target: target
        });
    }

    handleResize() {
        //let height = window.innerHeight - 224;
        let mainBodyWidth =
            document.getElementById("flotation-screen").offsetWidth;
        this.setState({ mainBodyWidth: mainBodyWidth });
    }

    handleLoopTabClick() {
        this.setState({ currentTab: "loop" });
    }

    handleTablesTabClick() {
        this.setState({ currentTab: "table" });
    }

    async handleEditClick() {
        await this.refreshTableData();
        this.setState({
            editing: true,
            editingRows: this.state.rows,
            editingTimeColumnHeaders: this.state.timeColumnHeaders,
            cellEditingStatus: this.defaultCellEditingStatus,
            headerEditingStatus: this.defaultHeaderEditingStatus
        });
    }

    async handleSaveClick() {
        if (this.props.userDetails.role === "Operator") {
            let dataIsValid = true;
            let cellEditingStatus = JSON.parse(
                JSON.stringify(this.state.cellEditingStatus)
            );
            let headerEditingStatus = JSON.parse(
                JSON.stringify(this.state.headerEditingStatus)
            );

            let columnWithValues = new Array(12).fill(false);

            for (let rowIndex = 0; rowIndex < 15; rowIndex++) {
                for (let timeIndex = 0; timeIndex < 12; timeIndex++) {
                    let dataKey = "time" + timeIndex;
                    let newValue = this.state.editingRows[rowIndex][dataKey];

                    if (newValue === null) {
                        cellEditingStatus[rowIndex][dataKey] = "good";
                    } else if (isNaN(Number(newValue))) {
                        cellEditingStatus[rowIndex][dataKey] = "bad";
                        columnWithValues[timeIndex] = true;
                        dataIsValid = false;
                    } else {
                        cellEditingStatus[rowIndex][dataKey] = "good";
                        columnWithValues[timeIndex] = true;
                    }
                }
            }

            for (let timeIndex = 0; timeIndex < 12; timeIndex++) {
                if (this.state.editingTimeColumnHeaders[timeIndex] == null) {
                    if (columnWithValues[timeIndex]) {
                        headerEditingStatus["time" + timeIndex] = "bad";
                        dataIsValid = false;
                    } else {
                        headerEditingStatus["time" + timeIndex] = "good";
                    }
                } else if (
                    this.state.editingTimeColumnHeaders[timeIndex].match(
                        /^([01]\d|2[0-3]):([0-5]\d)$/
                    )
                ) {
                    headerEditingStatus["time" + timeIndex] = "good";
                } else {
                    headerEditingStatus["time" + timeIndex] = "bad";
                    dataIsValid = false;
                }
            }

            if (!dataIsValid) {
                toaster.push(
                    <Message type="error" showIcon closable duration={5000}>
                        {<text>At least one cell is invalid</text>}
                    </Message>
                );
                this.setState({
                    cellEditingStatus: cellEditingStatus,
                    headerEditingStatus: headerEditingStatus
                });

                return;
            }

            let newIndustrials = this.convertRowsToIndustrials(
                this.state.editingRows,
                this.state.editingTimeColumnHeaders,
                this.state.startDateTime,
                this.state.endDateTime
            );

            let industrials = await updateIndustrials(
                this.props.endUserSession,
                this.props.location.pathname,
                this.state.startDateTime.getTime(),
                this.state.endDateTime.getTime(),
                newIndustrials
            );

            if (industrials === null) {
                return;
            }

            let rows = this.convertIndustrialsToRows(
                industrials,
                this.state.target
            );
            let timeColumnHeaders =
                this.convertIndustrialsToTimeColumnHeaders(industrials);

            toaster.push(
                <Message type="success" showIcon closable duration={5000}>
                    {<text>Saved</text>}
                </Message>
            );
            this.setState({
                editing: false,
                rows: rows,
                timeColumnHeaders: timeColumnHeaders,
                industrials: industrials
            });
        } else {
            let dataIsValid = true;
            let cellEditingStatus = JSON.parse(
                JSON.stringify(this.state.cellEditingStatus)
            );

            for (let rowIndex = 0; rowIndex < 15; rowIndex++) {
                let newValue = this.state.editingRows[rowIndex]["target"];

                if (newValue === null) {
                    cellEditingStatus[rowIndex]["target"] = "good";
                } else if (isNaN(Number(newValue))) {
                    cellEditingStatus[rowIndex]["target"] = "bad";
                    dataIsValid = false;
                } else {
                    cellEditingStatus[rowIndex]["target"] = "good";
                }
            }

            if (!dataIsValid) {
                toaster.push(
                    <Message type="error" showIcon closable duration={5000}>
                        {<text>At least one cell is invalid</text>}
                    </Message>
                );
                this.setState({
                    cellEditingStatus: cellEditingStatus
                });

                return;
            }

            let newTarget = this.convertRowsToTarget(
                this.state.editingRows,
                this.state.startDateTime
            );

            let target = await updateTarget(
                this.props.endUserSession,
                this.props.location.pathname,
                this.state.startDateTime.getTime(),
                newTarget
            );

            let rows = this.convertIndustrialsToRows(
                this.state.industrials,
                target
            );

            toaster.push(
                <Message type="success" showIcon closable duration={5000}>
                    {<text>Saved</text>}
                </Message>
            );
            this.setState({
                editing: false,
                rows: rows,
                target: target
            });
        }
    }

    handleCancelEditClick() {
        this.setState({ editing: false });
    }

    async handleDateChange(startDateTime) {
        startDateTime.setHours(7, 0, 0, 0); // Make sure the date starts at 7
        let startEpoch = startDateTime.getTime();
        let endDateTime = new Date(startEpoch + 24 * 60 * 60 * 1000 - 1);

        let industrials = await getAllIndustrials(
            this.props.endUserSession,
            this.props.location.pathname,
            startDateTime.getTime(),
            endDateTime.getTime()
        );

        let target = await getTarget(
            this.props.endUserSession,
            this.props.location.pathname,
            startDateTime.getTime()
        );

        let rows = this.convertIndustrialsToRows(industrials, target);
        let timeColumnHeaders =
            this.convertIndustrialsToTimeColumnHeaders(industrials);

        this.setState({
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            rows: rows,
            industrials: industrials,
            target: target,
            timeColumnHeaders: timeColumnHeaders
        });
    }

    handlePopulateButtonClick() {
        this.setState({ showingPopulateConfirmation: true });
    }

    async handlePopulateModalSubmit() {
        console.log("hello");
        let industrials = await getAllIndustrials(
            this.props.endUserSession,
            this.props.location.pathname,
            this.state.startDateTime.getTime(),
            this.state.endDateTime.getTime()
        );
        console.log(industrials);
        let target = await getTarget(
            this.props.endUserSession,
            this.props.location.pathname,
            this.state.startDateTime.getTime()
        );
        console.log(target);
        let rows = this.convertIndustrialsToRows(industrials, target);
        let timeColumnHeaders =
            this.convertIndustrialsToTimeColumnHeaders(industrials);

        this.setState({
            rows: rows,
            industrials: industrials,
            target: target,
            timeColumnHeaders: timeColumnHeaders
        });
    }

    async handlePopulateModalExit() {
        this.setState({ showingPopulateConfirmation: false });
    }

    handleEditCellBlur(rowData, dataKey, rowIndex, newValue) {
        newValue = newValue.trim();
        if (newValue === "") {
            newValue = null;
        }

        let editingRows = JSON.parse(JSON.stringify(this.state.editingRows));
        editingRows[rowIndex][dataKey] = newValue;

        this.setState({
            editingRows: editingRows
        });
    }

    handleEditTimeHeaderBlur(timeColumnHeaderIndex, newTimeColumnHeader) {
        if (newTimeColumnHeader === "") {
            newTimeColumnHeader = null;
        }

        let editingTimeColumnHeaders = JSON.parse(
            JSON.stringify(this.state.editingTimeColumnHeaders)
        );
        editingTimeColumnHeaders[timeColumnHeaderIndex] = newTimeColumnHeader;

        this.setState({
            editingTimeColumnHeaders: editingTimeColumnHeaders
        });
    }

    renderTableTimeColumns() {
        let columns = [];
        for (let timeIndex = 0; timeIndex < 12; timeIndex++) {
            if (
                this.props.userDetails.role === "Operator" &&
                this.state.editing
            ) {
                let cellEditingStatus =
                    this.state.headerEditingStatus["time" + timeIndex];
                let headerColor;
                let textColor;
                if (cellEditingStatus === "good") {
                    headerColor = "rgb(236, 236, 236)";
                } else if (cellEditingStatus === "bad") {
                    headerColor = "rgb(240, 79, 67)";
                    textColor = "white";
                } else {
                    headerColor = "green";
                    textColor = "white";
                }
                columns.push(
                    <Column width={84}>
                        <HeaderCell
                            style={{
                                paddingLeft: 7,
                                paddingRight: 7,
                                fontWeight: "bold"
                            }}
                        >
                            <Input
                                defaultValue={
                                    this.state.editingTimeColumnHeaders[
                                        timeIndex
                                    ]
                                }
                                onBlur={(event) => {
                                    this.handleEditTimeHeaderBlur(
                                        timeIndex,
                                        event.target.value
                                    );
                                }}
                                style={{
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    backgroundColor: headerColor,
                                    color: textColor,
                                    fontSize: "12px"
                                }}
                            />
                        </HeaderCell>
                        <this.EditCell
                            dataKey={"time" + timeIndex}
                            onBlur={this.handleEditCellBlur}
                        />
                    </Column>
                );
            } else {
                columns.push(
                    <Column width={84}>
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            {this.state.timeColumnHeaders[timeIndex]}
                        </HeaderCell>
                        <Cell dataKey={"time" + timeIndex} />
                    </Column>
                );
            }
        }

        return columns;
    }

    renderTableTargetColumn() {
        if (
            this.props.userDetails.role === "Metallurgist" &&
            this.state.editing
        ) {
            return (
                <Column width={100} verticalAlign="middle">
                    <HeaderCell style={{ fontWeight: "bold" }}>
                        Target
                    </HeaderCell>
                    <this.EditCell
                        dataKey="target"
                        onBlur={this.handleEditCellBlur}
                    />
                </Column>
            );
        } else {
            return (
                <Column width={100} verticalAlign="middle">
                    <HeaderCell style={{ fontWeight: "bold" }}>
                        Target
                    </HeaderCell>
                    <Cell dataKey="target" />
                </Column>
            );
        }
    }

    EditCell({ rowData, dataKey, rowIndex, onChange, onBlur, ...props }) {
        let cellEditingStatus = this.state.cellEditingStatus[rowIndex][dataKey];
        let cellColor;
        let textColor;
        if (cellEditingStatus === "good") {
            cellColor = "rgb(236, 236, 236)";
        } else if (cellEditingStatus === "bad") {
            cellColor = "rgb(240, 79, 67)";
            textColor = "white";
        } else {
            cellColor = "green";
            textColor = "white";
        }

        return (
            <Cell {...props} style={{ paddingLeft: 7, paddingRight: 7 }}>
                <Input
                    defaultValue={rowData[dataKey]}
                    onChange={(event) => {
                        onChange &&
                            onChange(rowData.id, dataKey, event.target.value);
                    }}
                    onBlur={(event) => {
                        onBlur &&
                            onBlur(
                                rowData,
                                dataKey,
                                rowIndex,
                                event.target.value
                            );
                    }}
                    style={{
                        paddingLeft: 2,
                        paddingRight: 2,
                        backgroundColor: cellColor,
                        color: textColor
                    }}
                />
            </Cell>
        );
    }

    render() {
        let timeColumns = this.renderTableTimeColumns();
        let targetColumn = this.renderTableTargetColumn();

        let rows;
        if (this.state.editing) {
            rows = this.state.editingRows;
        } else {
            rows = this.state.rows;
        }

        let primaryButton;
        let secondaryButton;
        if (!this.state.editing) {
            primaryButton = (
                <Button
                    circle
                    appearance="primary"
                    onClick={this.handleEditClick}
                    style={{ marginLeft: "10px" }}
                >
                    <PencilIcon />
                </Button>
            );
            secondaryButton = (
                <Button
                    circle
                    color="red"
                    appearance="primary"
                    onClick={this.handlePopulateButtonClick}
                    style={{ marginLeft: "10px" }}
                >
                    <BombIcon />
                </Button>
            );
        } else {
            primaryButton = (
                <Button
                    circle
                    appearance="primary"
                    onClick={this.handleSaveClick}
                    style={{ marginLeft: "10px" }}
                >
                    <SaveIcon />
                </Button>
            );
            secondaryButton = (
                <Button
                    circle
                    appearance="primary"
                    onClick={this.handleCancelEditClick}
                    style={{
                        marginLeft: "10px"
                    }}
                >
                    <CloseIcon />
                </Button>
            );
        }

        return (
            <div
                id="flotation-screen"
                style={{
                    margin: "20px",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div style={{ width: "100%", maxWidth: "1408px" }}>
                    <h3
                        style={{
                            display: "inline-block",
                            color: "#120078"
                        }}
                    >
                        Flotation
                    </h3>

                    <div style={{ display: "flex", marginTop: "20px" }}>
                        {primaryButton}
                        {secondaryButton}
                    </div>

                    <AdvancedTable
                        bordered
                        cellBordered
                        autoHeight
                        shouldUpdateScroll={false}
                        loading={this.state.loading}
                        revision={
                            JSON.stringify(this.state.cellEditingStatus) +
                            JSON.stringify(this.state.headerEditingStatus) +
                            JSON.stringify(this.state.rows) +
                            this.state.editing +
                            this.state.startDateTime.getTime() +
                            this.state.mainBodyWidth
                        }
                        rows={rows}
                        headerHeight={110}
                        style={{
                            marginTop: "20px",
                            backgroundColor: "white"
                        }}
                    >
                        <ColumnGroup
                            fixed
                            header="Floatation Variable"
                            verticalAlign="middle"
                            style={{ textAlign: "center" }}
                        >
                            <Column
                                width={100}
                                verticalAlign="middle"
                                colSpan={2}
                                rowSpan={(rowData) => {
                                    return rowData.primaryNameRowSpan;
                                }}
                            >
                                <HeaderCell style={{ fontWeight: "bold" }} />
                                <Cell dataKey="primaryName" />
                            </Column>

                            <Column width={100} verticalAlign="middle">
                                <HeaderCell
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                />
                                <Cell dataKey="secondaryName" />
                            </Column>
                        </ColumnGroup>

                        <ColumnGroup
                            header={
                                <div>
                                    Date:
                                    <DatePicker
                                        cleanable={false}
                                        plaintext={this.state.editing}
                                        value={this.state.startDateTime}
                                        onChange={this.handleDateChange}
                                        style={{ marginLeft: "10px" }}
                                    />
                                </div>
                            }
                            verticalAlign="middle"
                            style={{ textAlign: "center" }}
                        >
                            {timeColumns}
                        </ColumnGroup>

                        <Column width={100} verticalAlign="middle">
                            <HeaderCell style={{ fontWeight: "bold" }}>
                                Average
                            </HeaderCell>
                            <Cell dataKey="average" />
                        </Column>

                        {targetColumn}
                    </AdvancedTable>
                </div>

                <PopulateConfirmation
                    show={this.state.showingPopulateConfirmation}
                    onExit={this.handlePopulateModalExit}
                    onSubmit={this.handlePopulateModalSubmit}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Floatation)
);
