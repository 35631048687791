/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:43
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-04-12 16:04:57
 */

import React, { Component } from "react";
import { Button, Modal } from "rsuite";
import { deleteArchive } from "../../../services/databaseArchiveManagement";
import * as actions from "../../../store/actions/auth";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class DeleteArchiveModal extends Component {
    constructor(props) {
        super(props);

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    async handleDeleteClick() {
        let result = await deleteArchive(
            this.props.endUserSession,
            this.props.location.pathname,
            {
                fileName: this.props.fileName
            }
        );

        if (result) {
            this.props.onHide();
            this.props.onSubmit();
        }
    }

    render() {
        return (
            <div>
                <Modal show={this.props.show} onHide={this.props.onHide}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Delete Archive?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete the archive? The deletion will be
                        permanent.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.handleDeleteClick}
                            appearance="primary"
                        >
                            Yes
                        </Button>
                        <Button onClick={this.props.onHide} appearance="subtle">
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DeleteArchiveModal)
);
