/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-15 11:07:40
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-06-13 14:11:04
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function populate(endUserSession, clientPathName) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/populate";
        let res = await axios({
            method: "put",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not populate
                        <br />
                        {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}
