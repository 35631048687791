/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-15 11:07:40
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-06 11:16:58
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getTargets(
    endUserSession,
    clientPathName,
    startDateTime,
    endDateTime
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/target?startEpoch=" +
            startDateTime +
            "&endEpoch=" +
            endDateTime;

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data.targets;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={10000}>{<text>
                Could not get targets.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function getTarget(endUserSession, clientPathName, date) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/target/" + date;

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data.target;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={10000}>{<text>
                Could not get target.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}

export async function updateTarget(
    endUserSession,
    clientPathName,
    date,
    target
) {
    try {
        let url = BaseURL.getBaseURL() + clientPathName + "/target/" + date;

        let res = await axios({
            method: "put",
            url: url,
            withCredentials: true,
            data: { target: target }
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        }

        return res.data.target;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={10000}>{<text>
                Could not get/update target.
                <br />
                {e.toString()}
            </text>}</Message>);
        return null;
    }
}
