/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-07-26 15:02:20
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 11:45:07
 */

import React, { Component } from "react";
import { InputPicker, Button, Form, Steps, Loader, Schema } from "rsuite";
import LegacyCheckCircleIcon from "@rsuite/icons/legacy/CheckCircle";
import LegacyCloseCircleIcon from "@rsuite/icons/legacy/CloseCircle";
import LegacyHourGlass2Icon from "@rsuite/icons/legacy/Hourglass2";
import { Scrollbars } from "react-custom-scrollbars";
import { refreshToken } from "../../shared/auth";
import * as actions from "../../store/actions/auth";
import { connect } from "react-redux";
import {
    getTrainingProcess,
    startTrainingProcess
} from "../../services/retrain";
import { withRouter } from "react-router-dom";

const { StringType } = Schema.Types;

class ModelTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formJSON: {
                testTrainSplit: "",
                algorithm: "",
                dataFilterOptions: ""
            },
            errorMessages: {
                testTrainSplit: "",
                algorithm: "",
                dataFilterOptions: ""
            }
        };

        this.getLatestTrainingProcessVersion =
            this.getLatestTrainingProcessVersion.bind(this);
        this.handleTrainClick = this.handleTrainClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSeeReportClick = this.handleSeeReportClick.bind(this);

        let schema = {
            testTrainSplit: StringType().isRequired("Required"),
            algorithm: StringType().isRequired("Required"),
            dataFilterOptions: StringType().isRequired("Required")
        };
        this.validationModel = Schema.Model(schema);

        if (
            props.trainingProcess.trainingStatus !== -1 &&
            props.trainingProcess.trainingStatus !== 4
        ) {
            this.updateTrainingModelInterval = setInterval(
                this.getLatestTrainingProcessVersion,
                500
            );
            this.state.formJSON = props.trainingProcess.options;
        } else {
            this.updateTrainingModelInterval = null;
        }

        if (props.trainingProcess.trainingStatus === 4) {
            this.state.formJSON = {
                testTrainSplit: props.trainingProcess.options.testTrainSplit,
                algorithm: props.trainingProcess.options.algorithm,
                dataFilterOptions:
                    props.trainingProcess.options.dataFilterOptions
            };
        }
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
    }

    componentWillUnmount() {
        clearInterval(this.updateTrainingModelInterval);
        this.updateTrainingModelInterval = null;
    }

    async getLatestTrainingProcessVersion() {
        let trainingProcess = await getTrainingProcess(
            this.props.endUserSession,
            this.props.location.pathname,
            this.props.userDetails._id
        );

        if (trainingProcess === null) {
            clearInterval(this.updateTrainingModelInterval);
            this.updateTrainingModelInterval = null;
        } else if (
            trainingProcess.trainingStatus === 4 ||
            trainingProcess.errorOutput !== null
        ) {
            clearInterval(this.updateTrainingModelInterval);
            this.updateTrainingModelInterval = null;
        }

        this.props.onTrainingProcessChange(trainingProcess);
    }

    async handleTrainClick() {
        //Runs script and updates training status
        if (this.form.check()) {
            let trainingProcess = this.props.trainingProcess;
            trainingProcess.trainingStatus = 0;
            trainingProcess.output = [];
            trainingProcess.errorOutput = null;
            this.props.onTrainingProcessChange(trainingProcess);

            let result = await startTrainingProcess(
                this.props.endUserSession,
                this.props.location.pathname,
                {
                    options: {
                        targetTag: this.props.trainingProcess.options.targetTag,
                        startDate: this.props.trainingProcess.options.startDate,
                        endDate: this.props.trainingProcess.options.endDate,
                        ...this.state.formJSON
                    },
                    userID: this.props.userDetails._id,
                    documentCount: this.props.trainingProcess.documentCount
                }
            );
            if (result) {
                await this.getLatestTrainingProcessVersion();
                this.updateTrainingModelInterval = setInterval(
                    this.getLatestTrainingProcessVersion,
                    500
                );
            }
        }
    }

    //Reset parameters and training output
    handleResetClick() {}

    handleBackClick() {
        this.props.onStepChange(0);
    }

    handleSeeReportClick() {
        this.props.onStepChange(2);
    }

    renderTrainingSteps(trainingProcess) {
        let steps = [];
        let trainingStatus = trainingProcess.trainingStatus;
        for (let i = 0; i <= 3; i++) {
            if (i < trainingStatus) {
                steps.push({
                    title: "Completed",
                    icon: (
                        <LegacyCheckCircleIcon
                            size="2x"
                            style={{ color: "#378f17" }}
                        />
                    ),
                    status: "finish"
                });
            } else if (i === trainingStatus) {
                if (trainingProcess.errorOutput === null) {
                    steps.push({
                        title: "In Progress",
                        icon: <Loader speed="slow" />,
                        status: "process"
                    });
                } else {
                    steps.push({
                        title: "Error",
                        icon: (
                            <LegacyCloseCircleIcon
                                size="2x"
                                style={{ color: "red" }}
                            />
                        ),
                        status: "error"
                    });
                }
            } else {
                steps.push({
                    title: "Waiting",
                    icon: <LegacyHourGlass2Icon size="lg" />,
                    status: "waiting"
                });
            }
        }

        return (
            <Steps>
                <Steps.Item description="Test Train Split" {...steps[0]} />
                <Steps.Item description="Data Processing" {...steps[1]} />
                <Steps.Item description="Model Training" {...steps[2]} />
                <Steps.Item description="Analysis" {...steps[3]} />
            </Steps>
        );
    }

    render() {
        let trainingProcess = this.props.trainingProcess;
        /*if (trainingProcess === null) {
            trainingProcess = { output: [], trainingStatus: -1 };
        }*/

        let trainingSteps = this.renderTrainingSteps(trainingProcess);
        let trainButtonText;
        if (trainingProcess.trainingStatus === -1) {
            trainButtonText = "Train Model";
        } else {
            trainButtonText = "Retrain Model";
        }

        return (
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <h3
                    style={{
                        color: "#120078"
                    }}
                >
                    Model Training
                </h3>

                <h5 style={{ paddingTop: "30px", paddingBottom: "10px" }}>
                    Training Options
                </h5>
                <Form
                    ref={(forum) => (this.form = forum)}
                    layout="inline"
                    formValue={this.state.formJSON}
                    model={this.validationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ formJSON: newFormJSON });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({ errorMessages: errorMessages });
                    }}
                >
                    <Form.Group>
                        <Form.Control
                            name="testTrainSplit"
                            accepter={InputPicker}
                            data={this.props.config.testTrainSplits}
                            disabled={
                                trainingProcess.trainingStatus !== 4 &&
                                trainingProcess.trainingStatus !== -1 &&
                                trainingProcess.errorOutput == null
                            }
                            errorMessage={
                                this.state.errorMessages.testTrainSplit
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            name="algorithm"
                            accepter={InputPicker}
                            data={this.props.config.algorithms}
                            disabled={
                                trainingProcess.trainingStatus !== 4 &&
                                trainingProcess.trainingStatus !== -1 &&
                                trainingProcess.errorOutput == null
                            }
                            errorMessage={this.state.errorMessages.algorithm}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            name="dataFilterOptions"
                            accepter={InputPicker}
                            data={this.props.config.filterOptions}
                            disabled={
                                trainingProcess.trainingStatus !== 4 &&
                                trainingProcess.trainingStatus !== -1 &&
                                trainingProcess.errorOutput == null
                            }
                            errorMessage={
                                this.state.errorMessages.dataFilterOptions
                            }
                        />
                    </Form.Group>

                    <Button
                        appearance="primary"
                        disabled={
                            trainingProcess.trainingStatus !== 4 &&
                            trainingProcess.trainingStatus !== -1 &&
                            trainingProcess.errorOutput == null
                        }
                        onClick={this.handleTrainClick}
                    >
                        {trainButtonText}
                    </Button>
                    <Button
                        appearance="primary"
                        disabled={
                            trainingProcess.trainingStatus !== 4 &&
                            trainingProcess.trainingStatus !== -1 &&
                            trainingProcess.errorOutput == null
                        }
                        onClick={this.handleResetClick}
                    >
                        Reset
                    </Button>
                </Form>

                <h5 style={{ paddingBottom: "10px" }}>Training Status</h5>

                {trainingSteps}

                <h5 style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                    Training Output
                </h5>
                <div
                    style={{
                        flex: 1,
                        overflow: "auto",
                        borderRadius: "5px",
                        borderStyle: "solid",
                        borderColor: "#120078",
                        padding: "10px",
                        marginBottom: "20px",
                        minHeight: "200px"
                    }}
                >
                    <Scrollbars>
                        {trainingProcess.output.map((line) => {
                            if (line === "") {
                                return <br />;
                            } else {
                                return <div>{line}</div>;
                            }
                        })}
                        <div style={{ color: "red" }}>
                            {trainingProcess.errorOutput}
                        </div>
                    </Scrollbars>
                </div>

                <div>
                    <Button
                        appearance="primary"
                        disabled={
                            trainingProcess.trainingStatus !== 4 &&
                            trainingProcess.trainingStatus !== -1 &&
                            trainingProcess.errorOutput == null
                        }
                        onClick={this.handleBackClick}
                    >
                        Go Back
                    </Button>
                    <Button
                        appearance="primary"
                        style={{ float: "right" }}
                        disabled={trainingProcess.trainingStatus !== 4}
                        onClick={this.handleSeeReportClick}
                    >
                        See Modal Report
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ModelTraining)
);
