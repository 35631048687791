/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-08-24 17:35:45
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:21:11
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions/auth";
import { refreshToken } from "../shared/auth";

class GenericNotFound extends Component {
    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
    }

    render() {
        return (
            <div>
                <h2 style={{ textAlign: "center" }}>404 Not Found</h2>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericNotFound);
