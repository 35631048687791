/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-15 11:07:40
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:19:02
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function getAllClients(endUserSession) {
    try {
        let url = BaseURL.getBaseURL() + "/clientManagement/getAllClients";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The client request is invalid.");
        }
        return res.data.allClients;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get clients.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function addClient(data, endUserSession) {
    try {
        let url = BaseURL.getBaseURL() + "/clientManagement/createClient";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The client request is invalid.");
        } else if (res.data.isDuplicateDatabase) {
            throw new Error("The database name already exists.");
        } else if (!res.data.isInserted) {
            throw new Error(
                "The client could not be inserted into the database."
            );
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Client could not be added.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function deleteClient(id, endUserSession) {
    try {
        let url =
            BaseURL.getBaseURL() +
            "/clientManagement/deleteClient/" +
            id.toString();

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The client delete request is invalid.");
        } else if (!res.data.isDeleted) {
            throw new Error(
                "The client could not be removed from the database"
            );
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Client(s) could not be deleted.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}
