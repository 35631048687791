/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2022-03-29 13:27:14
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-14 12:10:26
 */

const aux = (props) => props.children;

export default aux;
