import React from "react";

export function getTempData() {
    return {
        stockpileGrade: [
            2.23,
            2.24,
            2.26,
            2.28,
            2.31,
            2.35,
            2.38,
            2.42,
            2.45,
            2.47,
            2.49,
            2.5,
            2.5,
            2.49,
            2.49,
            2.47,
            2.46,
            2.44,
            2.43,
            2.41,
            2.39,
            2.38,
            2.36,
            2.35,
            2.34,
            2.32,
            2.31,
            2.31,
            2.3,
            2.3,
            2.3,
            2.3,
            2.3,
            2.31,
            2.31,
            2.32,
            2.32,
            2.33,
            2.33,
            2.33,
            2.33,
            2.32,
            2.31,
            2.29,
            2.28,
            2.26,
            2.24,
            2.23
        ],
        millFeedGrade: [
            2.47,
            2.45,
            2.42,
            2.39,
            2.36,
            2.33,
            2.31,
            2.29,
            2.27,
            2.26,
            2.24,
            2.23,
            2.23,
            2.23,
            2.23,
            2.24,
            2.26,
            2.27,
            2.28,
            2.3,
            2.31,
            2.32,
            2.33,
            2.33,
            2.34,
            2.34,
            2.34,
            2.34,
            2.33,
            2.3,
            2.28,
            2.24,
            2.21,
            2.18,
            2.16,
            2.16,
            2.16,
            2.19,
            2.22,
            2.26,
            2.31,
            2.36,
            2.41,
            2.44,
            2.47,
            2.49,
            2.49,
            2.49
        ],
        stockpileBondWorkIndex: [
            10.6,
            10.6,
            10.92,
            11.24,
            11.72,
            12.36,
            12.84,
            13.32,
            13.64,
            13.64,
            13.64,
            13.48,
            13.16,
            12.84,
            12.52,
            12.2,
            11.88,
            11.56,
            11.56,
            11.72,
            12.04,
            12.52,
            12.84,
            13,
            12.84,
            12.52,
            12.04,
            11.56,
            11.24,
            11.08,
            11.08,
            11.4,
            11.88,
            12.52,
            13,
            13.32,
            13.48,
            13.64,
            13.48,
            13.32,
            13,
            12.68,
            12.2,
            11.72,
            11.24,
            10.92,
            10.76,
            10.6
        ],
        millFeedBondWorkIndex: [
            15.2,
            14.88,
            14.56,
            14.56,
            14.72,
            15.04,
            15.52,
            15.84,
            16,
            15.84,
            15.52,
            15.04,
            14.56,
            14.24,
            14.08,
            14.08,
            14.4,
            14.88,
            15.52,
            16,
            16.32,
            16.48,
            16.64,
            16.48,
            16.32,
            16,
            15.68,
            15.2,
            14.72,
            14.24,
            13.92,
            13.76,
            13.6,
            13.6,
            13.6,
            13.92,
            14.24,
            14.72,
            15.36,
            15.84,
            16.32,
            16.64,
            16.64,
            16.64,
            16.48,
            16.16,
            15.84,
            15.52
        ],
        recommendedSAGWaterAdditionChange: [
            9,
            8.5,
            8.6,
            8.4,
            8.2,
            7.3,
            5.1,
            1.2,
            -1.7,
            -2.7,
            -0.7,
            1,
            2.6,
            2.1,
            2.6,
            2.8,
            4.3,
            4.5,
            4.5,
            2.3,
            0.5,
            -1.8,
            -2.4,
            -3.4,
            -3.6,
            -4.6,
            -3.9,
            -2.6,
            0.2,
            1,
            -0.2,
            -3.9,
            -6.4,
            -6.4,
            -3.4,
            0.1,
            2.5,
            1.9,
            -1.1,
            -4.6,
            -5.9,
            -4.1,
            -0.5,
            2.1,
            2.8,
            1.7,
            0.6,
            0
        ],
        recommendedPumpboxWaterAdditionChange: [
            9.9,
            8.4,
            7.8,
            5.2,
            1.6,
            -1.1,
            -1.4,
            0,
            1.6,
            2.9,
            3.1,
            2.9,
            1.2,
            -1.1,
            -3.4,
            -4.5,
            -4.2,
            -2.9,
            -1.1,
            -0.3,
            -0.4,
            -2.2,
            -2.8,
            -2.8,
            -1.2,
            -0.2,
            0.4,
            0.5,
            1,
            2.6,
            4.7,
            6.6,
            7.6,
            7.4,
            6,
            2.9,
            -0.4,
            -2.1,
            -1.4,
            0.9,
            3.6,
            6.3,
            8.4,
            8.7,
            7.1,
            4.2,
            1.7,
            0.4
        ],
        recommendedFrotherAdditionChange: [
            15.1,
            12.3,
            8.4,
            2.1,
            -4.4,
            -8.1,
            -7.9,
            -5.2,
            -7,
            -8.3,
            -8.3,
            0.3,
            8.6,
            15.8,
            19.4,
            21.2,
            19,
            8.6,
            -5.2,
            -18.1,
            -23.6,
            -23.5,
            -20.8,
            -18.1,
            -18.5,
            -19.9,
            -21.5,
            -17.5,
            -10.2,
            -1.2,
            2.2,
            -2.6,
            -13.8,
            -25.4,
            -29.8,
            -27.7,
            -19.3,
            -10.1,
            -0.5,
            7.6,
            15.3,
            21.2,
            22.8,
            19.8,
            13.2,
            7.3,
            2.7,
            0.8
        ],
        recommendedCollectorAdditionChange: [
            -7.1,
            -11.7,
            -17.8,
            -19.2,
            -16.5,
            -9.4,
            -7.7,
            -12.2,
            -18.6,
            -18.6,
            -13.2,
            -7.4,
            -5.8,
            -6.5,
            -7.5,
            -4,
            2.4,
            7.7,
            4.6,
            -3.9,
            -11.4,
            -12.1,
            -12.1,
            -12.2,
            -14.4,
            -12.3,
            -12.6,
            -14.5,
            -20,
            -19.8,
            -12.8,
            -3.6,
            1.3,
            -1.7,
            -3.4,
            -3.2,
            2.4,
            6.1,
            9.9,
            13.2,
            15.8,
            14.7,
            10,
            5.2,
            3.1,
            2.2,
            1.4,
            0.4
        ],
        recovery: [
            92.9,
            91.1,
            89.8,
            89.9,
            91,
            91.6,
            91.8,
            91.9,
            92.2,
            92.4,
            93.1,
            93.3,
            94.2,
            93.8,
            93.3,
            92.1,
            91.8,
            92.1,
            92.1,
            92.2,
            92.6,
            93.3,
            93.1,
            91.9,
            91.1,
            91.2,
            92.7,
            93.4,
            93.8,
            92.8,
            92.4,
            91.8,
            92.3,
            92.8,
            92.8,
            92.2,
            92.5,
            92.7,
            91.9,
            90,
            88.7,
            89.5,
            90.8,
            93,
            93.7,
            94.2,
            93.2,
            92.6
        ]
    };
}
