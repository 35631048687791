/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2022-06-10 14:01:37
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-10 19:25:17
 */

import React, { Component } from "react";
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import history from "../../shared/history";
import { refreshToken } from "../../shared/auth";
import {
    getAllGHGSavings,
    getLastWeekData,
    getLastMonthData,
    getLastThreeMonthsData,
    getLastYearData
} from "../../services/GHG-Savings/ghgSavings";
import { SelectPicker } from "rsuite";
import "./ghgSavings.css";

function checkTime(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

class GHGSavings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTime: new Date().toTimeString(),
            allGHGSavingsInfo: {},
            ghgSavingsByElements: [],
            rangeData: [
                {
                    label: "Last Week",
                    value: "Last Week"
                },
                {
                    label: "Last Month",
                    value: "Last Month"
                },
                {
                    label: "Last 3 Months",
                    value: "Last 3 Months"
                },
                {
                    label: "Last Year",
                    value: "Last Year"
                }
            ],
            selectedRange: "Last Week",
            graphData: [],
            ghgScalesInfo: [
                // {
                //     values: [0, 0, 0],
                //     labels: ["Scale 1", "Scale 2", "Scale 3"],
                //     text: "CO2",
                //     textposition: "inside",
                //     domain: { column: 1 },
                //     // name: "CO2 Emissions",
                //     hoverinfo: "label+percent+name",
                //     hole: 0.4,
                //     type: "pie"
                // }
                {
                    type: "pie",
                    title: {
                        text: "CO<sub>2</sub> eq",
                        font: { size: "40", color: "#120078", weight: 500 }
                    },
                    values: [0, 0, 0],
                    labels: ["Scope 1", "Scope 2", "Scope 3"],
                    hoverinfo: "value",
                    hole: 0.85,
                    domain: {
                        row: 0,
                        column: 0
                    },
                    marker: {
                        colors: ["#120078", "rgb(240, 79, 67)", "orange"]
                    },
                    currentTime: new Date().toTimeString()
                }
            ],
            layout: {
                hovermode: "x unified",
                hoverdistance: 1,
                autosize: true,
                uirevision: true, // Allows updating of data while keeping zoom level and pan location,
                plot_bgcolor: "rgba(0,0,0,0)",
                paper_bgcolor: "rgba(0,0,0,0)",
                modebar: {
                    bgcolor: "rgba(0,0,0,0)",
                    color: "black",
                    activecolor: "#2c1c94"
                },
                xaxis: {
                    autorange: true,
                    rangeslider: {
                        visible: false,
                        yaxis: {
                            rangemode: "auto"
                        }
                    },
                    type: "date",
                    tickfont: {
                        size: 14
                    }
                },
                yaxis: {
                    title: "GHG (t CO<sub>2</sub> per t Ni+Co)",
                    fixedrange: false,
                    autorange: true,
                    tickfont: {
                        size: 14
                    }
                },
                margin: {
                    l: 70,
                    t: 0,
                    r: 20,
                    b: 60
                },
                annotations: []
            },
            pieChartHoleLayout: {
                showlegend: true,
                margin: { t: 30, b: 20, l: 20, r: 20 },
                plot_bgcolor: "rgba(0,0,0,0)",
                paper_bgcolor: "rgba(0,0,0,0)",
                modebar: {
                    bgcolor: "rgba(0,0,0,0)",
                    color: "black",
                    activecolor: "#2c1c94"
                }
            }
        };
        setInterval(() => {
            let today = new Date();
            let h = today.getHours();
            let m = today.getMinutes();
            let s = today.getSeconds();
            // add a zero in front of numbers<10
            m = checkTime(m);
            s = checkTime(s);
            this.setState({ currentTime: h + ":" + m + ":" + s });
        }, 1000);

        this.getAllGHGSavings = this.getAllGHGSavings.bind(this);
        this.handleRangeSelect = this.handleRangeSelect.bind(this);
        this.handleBarClickEvent = this.handleBarClickEvent.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.getAllGHGSavings();
        this.handleRangeSelect("Last Week");
    }

    componentWillUnmount() {}

    async getAllGHGSavings() {
        let data = await getAllGHGSavings(
            this.props.endUserSession,
            this.props.location.pathname
        );
        this.setState({
            ghgSavingsByElements: [
                {
                    x: ["Acid", "Steam", "Lime", "H2S"],
                    y: [0, 0, 0, 0],
                    marker: {
                        color: ["#253f9b", "#0075ff", "#01c8f6", "#19e6b6"]
                    },
                    type: "bar",
                    width: [0.5, 0.5, 0.5, 0.5]
                }
            ],
            allGHGSavingsInfo: data,
            graphData: []
        });
    }

    async handleRangeSelect(value) {
        let output;

        switch (value) {
            case "Last Week":
                output = await getLastWeekData(
                    this.props.endUserSession,
                    this.props.location.pathname
                );
                break;
            case "Last Month":
                output = await getLastMonthData(
                    this.props.endUserSession,
                    this.props.location.pathname
                );
                break;
            case "Last 3 Months":
                output = await getLastThreeMonthsData(
                    this.props.endUserSession,
                    this.props.location.pathname
                );
                break;
            case "Last Year":
                output = await getLastYearData(
                    this.props.endUserSession,
                    this.props.location.pathname
                );
                break;
        }
        // Get and use offset to counteract rsuite date picker and plotly graph internal date conversion
        let offset = new Date().getTimezoneOffset();
        offset = offset * 60 * 1000;
        let traces = output.graphData;
        for (let trace of traces) {
            for (let i = 0; i < trace.x.length; i++) {
                trace.x[i] = trace.x[i] + offset;
            }
        }

        this.setState({
            selectedRange: value,
            ghgSavingsByElements: output.elementsInfo,
            graphData: traces
        });
    }

    handleBarClickEvent(event) {
        let ghgScalesInfo = JSON.parse(
            JSON.stringify(this.state.ghgScalesInfo)
        );
        ghgScalesInfo[0].values[0] = Math.abs(event.points[0].value);
        console.log(ghgScalesInfo);
        this.setState({
            ghgScalesInfo: ghgScalesInfo
        });
    }

    render() {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    backgroundColor: "#ececec",
                    height: "100vh"
                }}
            >
                <div id="ghg-savings">
                    <SelectPicker
                        value={this.state.selectedRange}
                        data={this.state.rangeData}
                        searchable={false}
                        cleanable={false}
                        // onSelect={(value) => {
                        //     this.handleRangeSelect(value);
                        // }}
                        onChange={(value) => {
                            this.handleRangeSelect(value);
                        }}
                        style={{ marginTop: "20px", width: 224 }}
                    />
                    <div className="grid">
                        <div>
                            <div
                                id="a"
                                style={{
                                    backgroundColor: "#ffffff",
                                    textAlign: "center",
                                    borderRadius: "6px",
                                    minWidth: "0",
                                    minHeight: "0",
                                    overflow: "hidden",
                                    marginBottom: "20px"
                                }}
                            >
                                <div className="title">
                                    Total GHG Reduction (1 Y)
                                </div>
                                <div
                                    className="bigUnit"
                                    style={{ marginBottom: "20px" }}
                                >
                                    {this.state.allGHGSavingsInfo.lastYear}{" "}
                                    <span style={{ fontSize: "18px" }}>
                                        t CO
                                        <sub>2</sub> per t Ni+Co
                                    </span>
                                </div>
                            </div>
                            <div
                                id="b"
                                style={{
                                    backgroundColor: "#ffffff",
                                    textAlign: "center",
                                    borderRadius: "6px",
                                    minWidth: "0",
                                    minHeight: "0",
                                    overflow: "hidden",
                                    marginBottom: "20px"
                                }}
                            >
                                <div className="title">
                                    Total GHG Reduction (1 M)
                                </div>
                                <div
                                    className="bigUnit"
                                    style={{ marginBottom: "20px" }}
                                >
                                    {this.state.allGHGSavingsInfo.lastMonth}{" "}
                                    <span style={{ fontSize: "18px" }}>
                                        t CO
                                        <sub>2</sub> per t Ni+Co
                                    </span>
                                </div>
                            </div>
                            <div
                                id="c"
                                style={{
                                    backgroundColor: "#ffffff",
                                    textAlign: "center",
                                    borderRadius: "6px",
                                    minWidth: "0",
                                    minHeight: "0",
                                    overflow: "hidden"
                                }}
                            >
                                <div className="title">
                                    Total GHG Reduction (7 D)
                                </div>
                                <div
                                    className="bigUnit"
                                    style={{ marginBottom: "20px" }}
                                >
                                    {this.state.allGHGSavingsInfo.lastWeek}{" "}
                                    <span style={{ fontSize: "18px" }}>
                                        t CO
                                        <sub>2</sub> per t Ni+Co
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{ height: "495px" }}>
                            <div className="title">Avg. GHG Reduction</div>
                            <Plot
                                useResizeHandler
                                data={this.state.graphData}
                                layout={this.state.layout}
                                config={{
                                    displaylogo: false
                                }}
                                style={{
                                    width: "100%",
                                    height: "calc(100% - 45px)"
                                }}
                            />
                        </div>
                        <div className="card" style={{ height: "495px" }}>
                            <div className="title">
                                CO<sub>2</sub> Reduction Breakdown
                            </div>
                            <Plot
                                useResizeHandler
                                data={this.state.ghgSavingsByElements}
                                layout={{
                                    margin: {
                                        l: 70,
                                        t: 0,
                                        r: 20,
                                        b: 60
                                    },
                                    autosize: true,
                                    uirevision: true,
                                    plot_bgcolor: "rgba(0,0,0,0)",
                                    paper_bgcolor: "rgba(0,0,0,0)",
                                    showlegend: false,
                                    modebar: {
                                        bgcolor: "rgba(0,0,0,0)",
                                        color: "black",
                                        activecolor: "#2c1c94"
                                    },
                                    xaxis: {
                                        tickfont: {
                                            size: 16
                                        }
                                    },
                                    yaxis: {
                                        title: "GHG (t CO<sub>2</sub> per t Ni+Co)"
                                    }
                                }}
                                onClick={(value, event) => {
                                    console.log(value, event);
                                    this.handleBarClickEvent(value, event);
                                }}
                                config={{
                                    displaylogo: false
                                }}
                                style={{
                                    width: "100%",
                                    height: "calc(100% - 45px)"
                                }}
                            />
                        </div>
                        <div className="card" style={{ height: "495px" }}>
                            <div className="title">Categories</div>
                            <Plot
                                useResizeHandler
                                data={this.state.ghgScalesInfo}
                                layout={this.state.pieChartHoleLayout}
                                config={{
                                    displaylogo: false
                                }}
                                style={{
                                    width: "100%",
                                    height: "calc(100% - 45px)"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GHGSavings);
