/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 13:09:54
 */

import React, { Component } from "react";
import { IconButton, Table, Whisper, Popover, ButtonGroup } from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import LegacyDownloadIcon from "@rsuite/icons/legacy/Download";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import { getLogDetails, downloadLog } from "../services/logManagement";
import AdvancedTable from "../components/advancedTable";
import { withRouter } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;

class LogManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedRows: [],
            loading: true,
            tableHeight: 100
        };

        this.getTableData = this.getTableData.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowDownloadButtonClick =
            this.handleRowDownloadButtonClick.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.getTableData();

        let height = window.innerHeight - 188;
        this.setState({
            loading: false,
            tableHeight: height
        });

        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    async getTableData() {
        let rows = await getLogDetails(
            this.props.endUserSession,
            this.props.location.pathname
        );
        console.log(rows);

        if (!rows) {
            rows = [];
        }
        console.log(rows);
        this.setState({ data: rows });
    }

    handleResize() {
        let height = window.innerHeight - 224;
        this.setState({ tableHeight: height });
    }

    async handleRowDownloadButtonClick(rowData) {
        await downloadLog(
            this.props.endUserSession,
            this.props.location.pathname,
            {
                fileName: rowData.name,
                extension: rowData.extension
            }
        );
    }

    render() {
        console.log(this.state.data);
        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Log Management
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>TODO</p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        color="primary"
                    />
                </Whisper>

                <AdvancedTable
                    bordered
                    cellBordered
                    paginate
                    paginationPrev
                    paginationLast
                    paginationNext
                    paginationFirst
                    paginationLimitOptions={[5, 10, 15, 20, 25]}
                    paginationLayout={["total", "-", "limit", "|", "pager"]}
                    height={this.state.tableHeight}
                    loading={this.state.loading}
                    rows={this.state.data}
                    initialSortColumn="name"
                    initialSortType="desc"
                    style={{ marginTop: "20px", backgroundColor: "white" }}
                >
                    <Column
                        minWidth={200}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Name
                        </HeaderCell>
                        <Cell dataKey="name" />
                    </Column>

                    <Column
                        minWidth={100}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Size
                        </HeaderCell>
                        <Cell dataKey="size" />
                    </Column>

                    <Column width={68} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Action
                        </HeaderCell>
                        <Cell>
                            {(rowData) => {
                                return (
                                    <ButtonGroup>
                                        <IconButton
                                            icon={<LegacyDownloadIcon />}
                                            appearance="subtle"
                                            disabled={this.state.loading}
                                            onClick={() => {
                                                this.handleRowDownloadButtonClick(
                                                    rowData
                                                );
                                            }}
                                        />
                                    </ButtonGroup>
                                );
                            }}
                        </Cell>
                    </Column>
                </AdvancedTable>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LogManagement)
);
