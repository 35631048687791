/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2021-08-30 11:15:19
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import "./timer.css";

class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: props.startTime,
            paused: false,
            circleStyle: {
                transition: "stroke-dashoffset " + props.startTime + "s linear"
            }
        };

        this.updateTimer = this.updateTimer.bind(this);
        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                circleStyle: {
                    strokeDashoffset: "145px",
                    transition: this.state.circleStyle.transition
                }
            });
        }, 1);

        this.countdown = setInterval(this.updateTimer, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.countdown);
    }

    updateTimer() {
        let timer = this.state.timer - 1;
        if (timer >= 0) {
            this.setState({ timer: timer });
        }
    }

    handleTransitionEnd() {
        clearInterval(this.countdown);
        this.setState({ timer: 0 });
        this.props.onFinish();
    }

    handleMouseEnter() {}

    handleMouseLeave() {}

    handleClick() {
        if (this.state.paused) {
            this.countdown = setInterval(this.updateTimer, 1000);
            this.setState({
                paused: false,
                circleStyle: {
                    strokeDashoffset: "145px",
                    transition:
                        "stroke-dashoffset " + this.state.timer + "s linear"
                }
            });
        } else {
            let circle = document.getElementsByClassName("testA")[0];
            let computedStyle = window.getComputedStyle(circle),
                strokeDashoffset = computedStyle.getPropertyValue(
                    "stroke-dashoffset"
                );
            clearInterval(this.countdown);
            this.setState({
                paused: true,
                circleStyle: { strokeDashoffset: strokeDashoffset }
            });
        }
    }

    render() {
        let timerText = "";
        if (this.state.paused) {
            timerText = "►";
        } else {
            timerText = this.state.timer;
        }

        return (
            <div
                id="countdown"
                style={this.props.style}
                className={this.props.className}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleClick}
            >
                <div id="countdown-number">{timerText}</div>
                <svg>
                    <circle
                        className="testB"
                        r="23"
                        cx="25"
                        cy="25"
                        shapeRendering="geometricPrecision"
                    />
                    <circle
                        className="testA"
                        r="23"
                        cx="25"
                        cy="25"
                        style={this.state.circleStyle}
                        onTransitionEnd={this.handleTransitionEnd}
                        shapeRendering="geometricPrecision"
                    />
                </svg>
            </div>
        );
    }
}

Timer.propTypes = {
    startTime: PropTypes.number,
    onFinish: PropTypes.func
};

Timer.defaultProps = {
    startTime: 10,
    onFinish: () => {}
};

export default Timer;
