/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 13:14:11
 */

import React, { Component } from "react";
import {
    IconButton,
    ButtonGroup,
    Table,
    Button,
    Whisper,
    Popover
} from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import LegacyCloseIcon from "@rsuite/icons/legacy/Close";
import LegacyHistoryIcon from "@rsuite/icons/legacy/History";
import LegacyDownloadIcon from "@rsuite/icons/legacy/Download";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import {
    getArchiveDetails,
    downloadArchive,
    createArchive
} from "../services/databaseArchiveManagement";
import AdvancedTable from "../components/advancedTable";
import { withRouter } from "react-router-dom";
import DeleteArchiveModal from "../components/modals/DatabaseArchiveManagement/deleteArchiveModal";
import RestoreArchiveModal from "../components/modals/DatabaseArchiveManagement/restoreArchiveModal";

const { Column, HeaderCell, Cell, Pagination } = Table;

class DatabaseArchiveManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            selectedRows: [],
            loading: true,
            tableHeight: 100,
            showingRestoreArchiveModal: false,
            showingDeleteArchiveModal: false,
            selectedFileName: ""
        };

        this.getTableData = this.getTableData.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRestoreArchiveModalHide =
            this.handleRestoreArchiveModalHide.bind(this);
        this.handleDeleteArchiveModalHide =
            this.handleDeleteArchiveModalHide.bind(this);
        this.handleRowDownloadButtonClick =
            this.handleRowDownloadButtonClick.bind(this);
        this.handleRowRestoreButtonClick =
            this.handleRowRestoreButtonClick.bind(this);
        this.handleRowDeleteButtonClick =
            this.handleRowDeleteButtonClick.bind(this);
        this.handleArchiveNowClick = this.handleArchiveNowClick.bind(this);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        let rows = await this.getTableData();

        let height = window.innerHeight - 224;
        this.setState({
            rows: rows,
            loading: false,
            tableHeight: height
        });

        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    async getTableData() {
        let rows = await getArchiveDetails(
            this.props.endUserSession,
            this.props.location.pathname
        );
        if (!rows) {
            rows = [];
        }
        return rows;
    }

    handleResize() {
        let height = window.innerHeight - 224;
        this.setState({ tableHeight: height });
    }

    handleRestoreArchiveModalHide() {
        this.setState({ showingRestoreArchiveModal: false });
    }

    handleDeleteArchiveModalHide() {
        this.setState({ showingDeleteArchiveModal: false });
    }

    async handleRowDownloadButtonClick(rowData) {
        await downloadArchive(
            this.props.endUserSession,
            this.props.location.pathname,
            {
                fileName: rowData.name
            }
        );
    }

    handleRowRestoreButtonClick(rowData) {
        this.setState({
            showingRestoreArchiveModal: true,
            selectedFileName: rowData.name
        });
    }

    handleRowDeleteButtonClick(rowData) {
        this.setState({
            showingDeleteArchiveModal: true,
            selectedFileName: rowData.name
        });
    }

    async handleArchiveNowClick() {
        this.setState({ loading: true });
        let created = await createArchive(
            this.props.endUserSession,
            this.props.location.pathname
        );
        if (!created) {
            this.setState({ loading: false });
            return;
        }
        let rows = await this.getTableData();
        this.setState({ rows: rows, loading: false });
    }

    async handleModalSubmit() {
        this.setState({ loading: true });
        let rows = await this.getTableData();
        this.setState({ rows: rows, loading: false });
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Database Archive Management
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Here you can create a new database archive. You
                                can also download, delete, or revert to the
                                respective archive
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        color="primary"
                    />
                </Whisper>

                <Button
                    appearance="primary"
                    onClick={this.handleArchiveNowClick}
                    style={{ display: "block" }}
                    disabled={this.state.loading}
                >
                    Create Archive Now
                </Button>

                <AdvancedTable
                    bordered
                    cellBordered
                    paginate
                    paginationPrev
                    paginationLast
                    paginationNext
                    paginationFirst
                    paginationLimitOptions={[5, 10, 15, 20, 25]}
                    paginationLayout={["total", "-", "limit", "|", "pager"]}
                    height={this.state.tableHeight}
                    loading={this.state.loading}
                    rows={this.state.rows}
                    initialSortColumn="name"
                    initialSortType="desc"
                    style={{ marginTop: "20px", backgroundColor: "white" }}
                >
                    <Column
                        minWidth={200}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Name
                        </HeaderCell>
                        <Cell dataKey="name" />
                    </Column>

                    <Column
                        minWidth={100}
                        flexGrow={1}
                        verticalAlign="middle"
                        sortable
                    >
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Size
                        </HeaderCell>
                        <Cell dataKey="size" />
                    </Column>

                    <Column width={147} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Actions
                        </HeaderCell>
                        <Cell>
                            {(rowData) => {
                                return (
                                    <ButtonGroup>
                                        <IconButton
                                            icon={<LegacyDownloadIcon />}
                                            appearance="subtle"
                                            disabled={this.state.loading}
                                            onClick={() => {
                                                this.handleRowDownloadButtonClick(
                                                    rowData
                                                );
                                            }}
                                        />
                                        <IconButton
                                            icon={<LegacyHistoryIcon />}
                                            appearance="subtle"
                                            disabled={this.state.loading}
                                            onClick={() => {
                                                this.handleRowRestoreButtonClick(
                                                    rowData
                                                );
                                            }}
                                        />
                                        <IconButton
                                            icon={<LegacyCloseIcon />}
                                            appearance="subtle"
                                            disabled={this.state.loading}
                                            onClick={() => {
                                                this.handleRowDeleteButtonClick(
                                                    rowData
                                                );
                                            }}
                                        />
                                    </ButtonGroup>
                                );
                            }}
                        </Cell>
                    </Column>
                </AdvancedTable>
                <RestoreArchiveModal
                    show={this.state.showingRestoreArchiveModal}
                    onHide={this.handleRestoreArchiveModalHide}
                    onSubmit={this.handleModalSubmit}
                    fileName={this.state.selectedFileName}
                />

                <DeleteArchiveModal
                    show={this.state.showingDeleteArchiveModal}
                    onHide={this.handleDeleteArchiveModalHide}
                    onSubmit={this.handleModalSubmit}
                    fileName={this.state.selectedFileName}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DatabaseArchiveManagement)
);
