/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:20:53
 */

import React, { Component } from "react";

import { IconButton, ButtonToolbar, Table, Whisper, Popover } from "rsuite";
import LegacyCloseIcon from "@rsuite/icons/legacy/Close";
import LegacyPencilIcon from "@rsuite/icons/legacy/Pencil";
import LegacySuitcaseIcon from "@rsuite/icons/legacy/Suitcase";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import AddProjectModal from "../components/modals/projectManagement/addProjectModal";
import DeleteProjectModal from "../components/modals/projectManagement/deleteProjectModal";
import EditProjectModal from "../components/modals/projectManagement/editProjectModal";
import ManageJobsModal from "../components/modals/projectManagement/manageJobsModal";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import { getAllClients } from "../services/clientManagement";
import {
    getAllProjectJobs,
    getAllProjects,
    getProjectDetails
} from "../services/projectManagement";
import { getAllRoles } from "../services/roleManagement";
import { getAllFunctionalities } from "../services/functionalityManagement";
import { getAllUsers } from "../services/userManagement";

class ProjectManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            showingAddProjectModel: false,
            showingDeleteProjectModel: false,
            showingEditProjectModal: false,
            showingManageJobsModal: false,
            functionalityTree: [],
            clients: [],
            roles: [],
            users: [],
            projectDependencyMap: null,
            selectedProject: null
        };

        this.handleAddProjectButtonClick =
            this.handleAddProjectButtonClick.bind(this);
        this.handleDeleteProjectButtonClick =
            this.handleDeleteProjectButtonClick.bind(this);
        this.handleEditProjectButtonClick =
            this.handleEditProjectButtonClick.bind(this);
        this.handleManageJobsButtonClick =
            this.handleManageJobsButtonClick.bind(this);
        this.handleAddProjectModalClose =
            this.handleAddProjectModalClose.bind(this);
        this.handleDeleteProjectModalClose =
            this.handleDeleteProjectModalClose.bind(this);
        this.handleEditProjectModalClose =
            this.handleEditProjectModalClose.bind(this);
        this.handleManageJobsModalClose =
            this.handleManageJobsModalClose.bind(this);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.renderRowActions = this.renderRowActions.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);

        let clients = await getAllClients(this.props.endUserSession);
        if (clients === null) {
            return;
        }

        let roles = await getAllRoles(this.props.endUserSession);
        if (roles === null) {
            return;
        }

        let projects = await getAllProjects(this.props.endUserSession);
        if (projects === null) {
            return;
        }

        let users = await getAllUsers(this.props.endUserSession);
        if (users === null) {
            return;
        }

        await this.buildFunctionalityTree();

        let projectDependencyMap = {};
        for (let project of projects) {
            projectDependencyMap[project._id] = {
                users: [],
                hasActiveJob: false
            };

            let jobs = await getAllProjectJobs(
                this.props.endUserSession,
                project._id
            );
            if (jobs === null) {
                this.setState({ projectDependencyMap: null });
                return;
            }

            let jobIdToJobMap = {};
            for (let job of jobs) {
                jobIdToJobMap[job._id] = job;
                if (job.status === "active") {
                    projectDependencyMap[project._id].hasActiveJob = true;
                }
            }
            if (
                project !== undefined &&
                project.hasOwnProperty("steps") &&
                project.steps !== null &&
                project.steps.length
            ) {
                for (let step of project.steps) {
                    step.jobs = [];
                    if (step.hasOwnProperty("jobIDs") && step.jobIDs.length) {
                        for (let jobID of step.jobIDs) {
                            step.jobs.push(jobIdToJobMap[jobID]);
                        }
                    }
                }
            }
        }

        for (let user of users) {
            if (user.projectIDs !== undefined) {
                for (let projectID of user.projectIDs) {
                    projectDependencyMap[projectID]["users"].push(user);
                }
            }
        }

        this.projectUpdateInterval = setInterval(async () => {
            if (
                this.state.selectedProject == null ||
                this.showingManageJobsModal == false
            ) {
                return;
            }

            let selectedProject = await getProjectDetails(
                this.props.endUserSession,
                this.state.selectedProject._id
            );

            if (selectedProject === null) {
                return;
            }

            let jobs = await getAllProjectJobs(
                this.props.endUserSession,
                selectedProject._id
            );

            if (jobs === null) {
                return;
            }

            let jobIdToJobMap = {};
            if (jobs.length) {
                for (let job of jobs) {
                    jobIdToJobMap[job._id] = job;
                }
            }
            if (
                selectedProject.hasOwnProperty("steps") &&
                selectedProject.steps !== null &&
                selectedProject.steps.length
            ) {
                for (let step of selectedProject.steps) {
                    step.jobs = [];
                    if (step.hasOwnProperty("jobIDs") && step.jobIDs.length) {
                        for (let jobID of step.jobIDs) {
                            step.jobs.push(jobIdToJobMap[jobID]);
                        }
                    }
                }
            }

            this.setState({
                selectedProject: selectedProject
            });
        }, 1000);

        this.setState({
            rows: projects,
            clients: clients,
            roles: roles,
            users: users,
            projectDependencyMap: projectDependencyMap
        });
    }

    componentWillUnmount() {
        clearInterval(this.projectUpdateInterval);
    }

    async buildFunctionalityTree() {
        let functionalities = await getAllFunctionalities();
        if (!functionalities) {
            return;
        }
        let functionalityTree = [
            {
                label: "",
                value: "",
                status: "active",
                children: []
            }
        ];

        let buildingTree = true;
        let unusedFunctionalities = functionalities;
        let currentParents = [functionalityTree[0]];
        while (buildingTree) {
            let newUnusedFunctionalities = [];
            let newCurrentParents = [];
            for (let functionality of unusedFunctionalities) {
                let functionalityUsed = false;
                for (let currentParent of currentParents) {
                    if (functionality.parentName === currentParent.label) {
                        if (functionality.type === "function") {
                            currentParent.children.push({
                                label: functionality.commonName,
                                value: functionality._id,
                                status: functionality.status,
                                data: functionality
                            });
                        } else {
                            currentParent.children.push({
                                label: functionality.name,
                                value: functionality._id,
                                status: functionality.status,
                                data: functionality,
                                children: []
                            });
                            newCurrentParents.push(
                                currentParent.children[
                                    currentParent.children.length - 1
                                ]
                            );
                        }
                        functionalityUsed = true;
                    }
                }
                if (!functionalityUsed) {
                    newUnusedFunctionalities.push(functionality);
                }
            }

            if (newCurrentParents.length === 0) {
                buildingTree = false;
            } else {
                unusedFunctionalities = newUnusedFunctionalities;
                currentParents = newCurrentParents;
            }
        }

        functionalityTree[0].label = "Root";
        this.setState({
            functionalityTree: functionalityTree
        });
    }

    handleAddProjectButtonClick() {
        this.setState({ showingAddProjectModel: true });
    }

    handleDeleteProjectButtonClick(project) {
        this.setState({
            showingDeleteProjectModel: true,
            selectedProject: project
        });
    }

    handleEditProjectButtonClick(project) {
        this.setState({
            showingEditProjectModal: true,
            selectedProject: project
        });
    }

    handleManageJobsButtonClick(project) {
        this.setState({
            showingManageJobsModal: true,
            selectedProject: project
        });
    }

    handleAddProjectModalClose() {
        this.setState({ showingAddProjectModel: false });
    }

    handleDeleteProjectModalClose() {
        this.setState({
            showingDeleteProjectModel: false,
            selectedProject: null
        });
    }

    handleEditProjectModalClose() {
        this.setState({
            showingEditProjectModal: false,
            selectedProject: null
        });
    }

    handleManageJobsModalClose() {
        this.setState({ showingManageJobsModal: false, selectedProject: null });
    }

    async handleModalSubmit() {
        let projects = await getAllProjects();
        if (projects === null) {
            this.setState({ projectDependencyMap: null });
            return;
        }

        let projectDependencyMap = {};
        for (let project of projects) {
            projectDependencyMap[project._id] = {
                users: [],
                hasActiveJob: false
            };

            let jobs = await getAllProjectJobs(
                this.props.endUserSession,
                project._id
            );
            if (jobs === null) {
                this.setState({ projectDependencyMap: null });
                return;
            }

            let jobIdToJobMap = {};
            for (let job of jobs) {
                jobIdToJobMap[job._id] = job;
                if (job.status === "active") {
                    projectDependencyMap[project._id].hasActiveJob = true;
                }
            }
            if (
                project !== undefined &&
                project.hasOwnProperty("steps") &&
                project.steps !== null &&
                project.steps.length
            ) {
                for (let step of project.steps) {
                    step.jobs = [];
                    if (step.hasOwnProperty("jobIDs") && step.jobIDs.length) {
                        for (let jobID of step.jobIDs) {
                            step.jobs.push(jobIdToJobMap[jobID]);
                        }
                    }
                }
            }
        }

        for (let user of this.state.users) {
            if (user.projectIDs !== undefined) {
                for (let projectID of user.projectIDs) {
                    projectDependencyMap[projectID]["users"].push(user);
                }
            }
        }

        this.setState({
            rows: projects,
            projectDependencyMap: projectDependencyMap
        });
    }

    renderTableRow() {
        console.log("this should work");
        return <div>Hello</div>;
    }

    rowClassName(rowData) {
        if (rowData === undefined) {
            return null;
        }
        if (rowData.status === "active") {
            return null;
        }
        return "inactive-row";
    }

    renderRowActions(project) {
        if (!(project._id in this.state.projectDependencyMap)) {
            return null;
        }

        let usersDependents =
            this.state.projectDependencyMap[project._id]["users"];
        let hasActiveJob =
            this.state.projectDependencyMap[project._id].hasActiveJob;

        if (usersDependents.length > 0 || hasActiveJob) {
            return (
                <ButtonToolbar>
                    {this.props.userDetails.userType === "SuperAdmin" ? (
                        <Whisper
                            trigger="hover"
                            placement="leftStart"
                            speaker={
                                <Popover title="">
                                    <div style={{ whiteSpace: "pre-line" }}>
                                        This project still has user dependents
                                        or some project jobs are active.
                                    </div>
                                </Popover>
                            }
                        >
                            <div
                                style={{
                                    display: "inline-block",
                                    width: "36px"
                                }}
                            >
                                <IconButton
                                    icon={<LegacyCloseIcon />}
                                    style={{
                                        pointerEvents: "none"
                                    }}
                                    onClick={() => {
                                        this.handleDeleteClientButtonClick(
                                            project
                                        );
                                    }}
                                    disabled={true}
                                />
                            </div>
                        </Whisper>
                    ) : null}
                    <IconButton
                        icon={<LegacyPencilIcon />}
                        onClick={() => {
                            this.handleEditProjectButtonClick(project);
                        }}
                    />

                    <IconButton
                        icon={<LegacySuitcaseIcon />}
                        onClick={() => {
                            this.handleManageJobsButtonClick(project);
                        }}
                    />
                </ButtonToolbar>
            );
        }

        return (
            <ButtonToolbar>
                {this.props.userDetails.userType === "SuperAdmin" ? (
                    <IconButton
                        icon={<LegacyCloseIcon />}
                        onClick={() => {
                            this.handleDeleteProjectButtonClick(project);
                        }}
                    />
                ) : null}

                <IconButton
                    icon={<LegacyPencilIcon />}
                    onClick={() => {
                        this.handleEditProjectButtonClick(project);
                    }}
                />

                <IconButton
                    icon={<LegacySuitcaseIcon />}
                    onClick={() => {
                        this.handleManageJobsButtonClick(project);
                    }}
                />
            </ButtonToolbar>
        );
    }

    render() {
        const { Column, HeaderCell, Cell } = Table;

        if (this.state.projectDependencyMap === null) {
            return null;
        }

        let selectedProjectHasDependents = null;
        let selectedProjectHasActiveJob = null;
        if (this.state.selectedProject !== null) {
            selectedProjectHasDependents =
                this.state.projectDependencyMap[this.state.selectedProject._id][
                    "users"
                ].length > 0;
            selectedProjectHasActiveJob =
                this.state.projectDependencyMap[this.state.selectedProject._id]
                    .hasActiveJob;
        }

        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Project Management
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>Here you can add and delete projects.</p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        appearance="primary"
                    />
                </Whisper>

                <ButtonToolbar>
                    {this.props.userDetails.userType === "SuperAdmin" ? (
                        <IconButton
                            icon={<LegacyPlusIcon />}
                            appearance="primary"
                            circle
                            onClick={this.handleAddProjectButtonClick}
                        />
                    ) : null}
                </ButtonToolbar>

                <Table
                    bordered
                    cellBordered
                    autoHeight
                    data={this.state.rows}
                    rowClassName={this.rowClassName}
                    style={{ marginTop: "20px" }}
                >
                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Project Name
                        </HeaderCell>
                        <Cell dataKey="projectName" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Branch
                        </HeaderCell>
                        <Cell dataKey="branch" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Location
                        </HeaderCell>
                        <Cell dataKey="location" />
                    </Column>
                    <Column width={148} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Actions
                        </HeaderCell>
                        <Cell>
                            {(project) => this.renderRowActions(project)}
                        </Cell>
                    </Column>
                </Table>

                {this.props.userDetails.userType === "SuperAdmin" ? (
                    <div>
                        <AddProjectModal
                            open={this.state.showingAddProjectModel}
                            onClose={this.handleAddProjectModalClose}
                            onSubmit={this.handleModalSubmit}
                            functionalityTree={this.state.functionalityTree}
                            roles={this.state.roles}
                            clients={this.state.clients}
                        />
                        <DeleteProjectModal
                            open={this.state.showingDeleteProjectModel}
                            onClose={this.handleDeleteProjectModalClose}
                            onSubmit={this.handleModalSubmit}
                            project={this.state.selectedProject}
                        />
                    </div>
                ) : null}

                <EditProjectModal
                    open={this.state.showingEditProjectModal}
                    onClose={this.handleEditProjectModalClose}
                    onSubmit={this.handleModalSubmit}
                    functionalityTree={this.state.functionalityTree}
                    roles={this.state.roles}
                    clients={this.state.clients}
                    project={this.state.selectedProject}
                    hasUserDependents={selectedProjectHasDependents}
                    hasActiveJob={selectedProjectHasActiveJob}
                />
                <ManageJobsModal
                    open={this.state.showingManageJobsModal}
                    onClose={this.handleManageJobsModalClose}
                    onSubmit={this.handleModalSubmit}
                    project={this.state.selectedProject}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);
