/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:53
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:22:46
 */

import React, { Component } from "react";
import { Button, CheckPicker, Form, InputPicker, Modal, Schema } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import { updateUser } from "../../../services/userManagement";

class EditUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: {},
            formJSON: {
                firstName: "",
                lastName: "",
                email: "",
                userType: "",
                role: "",
                status: "",
                projectIDs: []
            },
            rolePickerOptions: [],
            userTypePickerOptions: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);

        let schema = {
            firstName: validators.nameValidation,
            lastName: validators.nameValidation,
            email: validators.emailValidation,
            userType: validators.userTypeValidation,
            role: validators.roleValidation,
            status: validators.statusValidation,
            projectIDs: validators.projectIDsValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    componentDidMount() {
        let userTypePickerOptions;
        if (this.props.userDetails.userType === "SuperAdmin") {
            userTypePickerOptions = [
                {
                    label: "User",
                    value: "User",
                    role: "Master"
                },
                {
                    label: "Admin",
                    value: "Admin",
                    role: "Master"
                }
            ];
        } else {
            userTypePickerOptions = [
                {
                    label: "User",
                    value: "User",
                    role: "Master"
                }
            ];
        }
        this.setState({
            userTypePickerOptions: userTypePickerOptions
        });
    }

    async handleSubmit() {
        if (this.form.check()) {
            this.setState({ loading: true });
            let formJSON = { ...this.state.formJSON };
            formJSON.roleId = formJSON.role;
            delete formJSON.passwordReEnter;
            delete formJSON.role;

            let result = await updateUser(this.props.endUserSession, formJSON);
            if (result) {
                this.props.onClose();
                this.props.onSubmit();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    handleModalOpen() {
        let rolePickerOptions = [];
        for (let role of this.props.roles) {
            rolePickerOptions.push({
                label: role.name,
                value: role._id,
                role: "Master"
            });
        }

        let formJSON = { ...this.state.formJSON };

        formJSON.username = this.props.user.username;
        formJSON.firstName = this.props.user.firstName;
        formJSON.lastName = this.props.user.lastName;
        formJSON.email = this.props.user.email;
        formJSON.userType = this.props.user.userType;
        for (let rolePickerOption of rolePickerOptions) {
            if (rolePickerOption.label === this.props.user.role) {
                formJSON.role = rolePickerOption.value;
            }
        }
        formJSON.status = this.props.user.status;
        formJSON.projectIDs = this.props.user.projectIDs;

        this.setState({
            loading: false,
            rolePickerOptions: rolePickerOptions,
            formJSON: formJSON,
            errorMessages: {}
        });
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        ref={(forum) => (this.form = forum)}
                        layout="horizontal"
                        formValue={this.state.formJSON}
                        model={this.validationModel}
                        checkTrigger="none"
                        onChange={(newFormJSON) => {
                            this.setState({ formJSON: newFormJSON });
                        }}
                        onCheck={(errorMessages) => {
                            this.setState({ errorMessages: errorMessages });
                        }}
                    >
                        <Form.Group>
                            <Form.ControlLabel>First Name</Form.ControlLabel>
                            <Form.Control
                                name="firstName"
                                errorMessage={
                                    this.state.errorMessages.firstName
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Last Name</Form.ControlLabel>
                            <Form.Control
                                name="lastName"
                                errorMessage={this.state.errorMessages.lastName}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Email</Form.ControlLabel>
                            <Form.Control
                                name="email"
                                type="email"
                                errorMessage={this.state.errorMessages.email}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>User Type</Form.ControlLabel>
                            <Form.Control
                                name="userType"
                                accepter={InputPicker}
                                data={this.state.userTypePickerOptions}
                                errorMessage={this.state.errorMessages.userType}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Role</Form.ControlLabel>
                            <Form.Control
                                name="role"
                                accepter={InputPicker}
                                data={this.state.rolePickerOptions}
                                errorMessage={this.state.errorMessages.role}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>User Status</Form.ControlLabel>
                            <Form.Control
                                name="status"
                                accepter={InputPicker}
                                data={[
                                    {
                                        label: "Active",
                                        value: "active",
                                        role: "Master"
                                    },
                                    {
                                        label: "Inactive",
                                        value: "inactive",
                                        role: "Master"
                                    }
                                ]}
                                errorMessage={this.state.errorMessages.status}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Projects</Form.ControlLabel>
                            <Form.Control
                                name="projectIDs"
                                accepter={CheckPicker}
                                data={this.props.projects}
                                errorMessage={
                                    this.state.errorMessages.projectIDs
                                }
                                searchable={false}
                                renderMenuItem={(label, project) => {
                                    if (project.status === "active") {
                                        return label;
                                    } else {
                                        return (
                                            <div style={{ color: "lightgrey" }}>
                                                {label}
                                            </div>
                                        );
                                    }
                                }}
                                valueKey="_id"
                                labelKey="projectName"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleSubmit}
                            appearance="primary"
                            type="submit"
                            loading={this.state.loading}
                        >
                            Update
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);
