/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-09-01 09:42:42
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-11 17:31:45
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../../api-common";

export async function getMillData(endUserSession, index) {
    try {
        let url = BaseURL.getBaseURL() + "/production/millData?index=" + index;

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The create function request is invalid.");
        }

        return res.data.millData;
    } catch (e) {
        toaster.push(<Message type="error" showIcon closable duration={0}>{<text>
                Could not insert function.
                <br />
                Error: {e.toString()}
            </text>}</Message>);
        return null;
    }
}
