/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:23:54
 */

import React, { Component } from "react";
import { Button, Form, Modal, Schema, Toggle } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import { activateJob, deactivateJob } from "../../../services/jobManagement";

class ManageJobsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        this.steps = [];

        this.clearForm = this.clearForm.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.handleDoneClick = this.handleDoneClick.bind(this);

        let schema = {
            //projectName: validators.projectNameValidation,
            branch: validators.branchNameValidation,
            location: validators.locationValidation,
            status: validators.statusValidation,
            clientId: validators.clientIdValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    clearForm() {
        let formJSON = this.state.formJSON;
        for (let field in formJSON) {
            if (formJSON.hasOwnProperty(field)) {
                formJSON[field] = "";
            }
        }

        this.setState({ formJSON: formJSON, errorMessages: {} });
    }

    async handleSubmitClick() {
        console.log("submit");
    }

    handleModalOpen() {
        this.clearForm();

        let formJSON = JSON.parse(JSON.stringify(this.props.project));
        formJSON.clientId = formJSON.project_clientId;
        delete formJSON.project_clientId;
        delete formJSON._id;

        this.setState({
            loading: false,
            formJSON: formJSON
        });
    }

    async handleToggleChange(checked, job) {
        this.setState({ loading: true });
        let result;
        if (checked) {
            result = await activateJob(this.props.endUserSession, job._id);
        } else {
            result = await deactivateJob(this.props.endUserSession, job._id);
        }

        if (result !== null) {
            job.status = result.newStatus;
            job.message = result.newMessage;
        }

        this.setState({ loading: false });
    }

    handleDoneClick() {
        this.props.onSubmit();
        this.props.onClose();
    }

    renderJobRows() {
        if (this.props.project === null) {
            return;
        }
        let blocks = [];
        if (
            this.props.project.hasOwnProperty("steps") &&
            this.props.project.steps !== null &&
            this.props.project.steps.length
        ) {
            for (let step of this.props.project.steps) {
                if (step.jobs.length === 0) {
                    continue;
                }
                let rows = [];
                if (step.hasOwnProperty("jobs")) {
                    for (let job of step.jobs) {
                        if (job !== undefined) {
                            let errorMessageDiv = null;
                            let toggleStyle = {};
                            if (job.status === "error") {
                                errorMessageDiv = (
                                    <div style={{ color: "red" }}>
                                        {"Error:" + JSON.stringify(job.message)}
                                    </div>
                                );
                                toggleStyle.backgroundColor = "red";
                            } else if (job.status === "initializationError") {
                                errorMessageDiv = (
                                    <div style={{ color: "#985d62" }}>
                                        {"Error:" + JSON.stringify(job.message)}
                                    </div>
                                );
                                toggleStyle.backgroundColor = "#985d62";
                            }
                            rows.push(
                                <div
                                    key={job.name}
                                    style={{ marginBottom: "20px" }}
                                >
                                    <Toggle
                                        checked={
                                            job.status === "active" ||
                                            job.status === "error"
                                        }
                                        onChange={(checked) => {
                                            this.handleToggleChange(
                                                checked,
                                                job
                                            );
                                        }}
                                        disabled={
                                            job.status === "initializationError"
                                        }
                                        style={toggleStyle}
                                    />

                                    <div
                                        style={{
                                            display: "inline",
                                            marginLeft: "10px"
                                        }}
                                    >
                                        {job.name
                                            .replace(/([A-Z]+)/g, " $1")
                                            .replace(/([A-Z][a-z])/g, " $1")}
                                    </div>

                                    {errorMessageDiv}
                                </div>
                            );
                        }
                    }
                }
                blocks.push(
                    <div key={step.name} style={{ marginBottom: "20px" }}>
                        {step.stepCommonName}
                        {rows}
                    </div>
                );
            }
        }
        return blocks;
    }

    render() {
        let rows = this.renderJobRows();

        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
                size="xs"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Manage Jobs</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingBottom: "0px" }}>{rows}</Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleDoneClick}
                            appearance="primary"
                            disabled={this.state.loading}
                        >
                            Done
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession()),
        updateAssignedProjects: (assignedProjects) =>
            dispatch(actions.updateAssignedProjects(assignedProjects))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageJobsModal);
