/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-06 14:45:21
 */

import React, { Component } from "react";

import { DateRangePicker, InputPicker, Button, toaster, Message } from "rsuite";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import { withRouter } from "react-router-dom";
import { getAllIndustrials } from "../services/industrial";
import { getTargets } from "../services/target";
import Plot from "react-plotly.js";

class Analysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryName: null,
            firstSecondaryName: null,
            secondSecondaryName: null,
            dateRange: [new Date(1633698000000), new Date(1633784399999)],
            traces: [],
            xAxisTitle: "",
            yAxisTitle: ""
        };

        this.primaryNamePickerOptions = [
            { label: "Jameson", value: "jameson" },
            { label: "Cell 1", value: "cell1" },
            { label: "Cell 2", value: "cell2" },
            { label: "Cell 3", value: "cell3" },
            { label: "Cell 5", value: "cell5" },
            { label: "Preleach", value: "preleach" }
        ];

        this.secondaryNamePickerOptionsDict = {
            jameson: [
                { label: "PH", value: "PH" },
                { label: "xanthate", value: "xanthate" },
                { label: "AF6697", value: "AF6697" },
                { label: "Frother", value: "Frother" }
            ],
            cell1: [
                { label: "PH", value: "PH" },
                { label: "xanthate", value: "xanthate" },
                { label: "AF6697", value: "AF6697" },
                { label: "Frother", value: "Frother" }
            ],
            cell2: [{ label: "xanthate", value: "xanthate" }],
            cell3: [
                { label: "xanthate", value: "xanthate" },
                { label: "AF6697", value: "AF6697" },
                { label: "Frother", value: "Frother" }
            ],
            cell5: [
                { label: "xanthate", value: "xanthate" },
                { label: "AF6697", value: "AF6697" }
            ],
            preleach: [{ label: "ZLeach", value: "ZLeach" }]
        };

        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleDateRangeClean = this.handleDateRangeClean.bind(this);
        this.handleNamePickerChange = this.handleNamePickerChange.bind(this);
        this.handleNamePickerClean = this.handleNamePickerClean.bind(this);
        this.handleGeneratePlotClick = this.handleGeneratePlotClick.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
    }

    handleDateRangeChange(value) {
        this.setState({ dateRange: value });
    }

    handleDateRangeClean() {
        this.setState({ dateRange: [null, null] });
    }

    handleNamePickerChange(value, item, event, variableName) {
        if (variableName === "primaryName") {
            this.setState({
                primaryName: value,
                firstSecondaryName: null,
                secondSecondaryName: null
            });
        } else if (
            variableName === "firstSecondaryName" ||
            variableName === "secondSecondaryName"
        ) {
            this.setState({ [variableName]: value });
        }
    }

    handleNamePickerClean(event, variableName) {
        if (variableName === "primaryName") {
            this.setState({
                primaryName: null,
                firstSecondaryName: null,
                secondSecondaryName: null
            });
        } else if (
            variableName === "firstSecondaryName" ||
            variableName === "secondSecondaryName"
        ) {
            this.setState({ [variableName]: null });
        }
    }

    async handleGeneratePlotClick() {
        let traces = [];
        let xAxisTitle;
        let yAxisTitle;
        let industrials = await getAllIndustrials(
            this.props.endUserSession,
            this.props.location.pathname,
            this.state.dateRange[0].getTime(),
            this.state.dateRange[1].getTime()
        );

        if (
            this.state.firstSecondaryName === null ||
            this.state.secondSecondaryName === null
        ) {
            let trace = {
                x: [],
                y: [],
                type: "scatter",
                marker: { color: "#2c1c94" },
                name: "Recorded Value"
            };

            let secondaryName;
            if (this.state.firstSecondaryName !== null) {
                secondaryName = this.state.firstSecondaryName;
            } else {
                secondaryName = this.state.secondSecondaryName;
            }
            xAxisTitle = "Time";
            yAxisTitle = secondaryName;

            for (let industrial of industrials) {
                if (
                    industrial[this.state.primaryName][secondaryName] === null
                ) {
                    continue;
                }

                trace.x.push(new Date(industrial.date));
                trace.y.push(industrial[this.state.primaryName][secondaryName]);
            }
            traces.push(trace);

            let targets = await getTargets(
                this.props.endUserSession,
                this.props.location.pathname,
                this.state.dateRange[0].getTime() - 24 * 60 * 60 * 1000 + 1,
                this.state.dateRange[1].getTime()
            );

            trace = {
                x: [],
                y: [],
                type: "scatter",
                marker: { color: "green" },
                line: {
                    dash: "dot"
                },
                name: "Target"
            };
            for (let i = 0; i < targets.length; i++) {
                if (targets[i][this.state.primaryName][secondaryName] == null) {
                    continue;
                }

                // Determine if the lines should have a gap
                if (i > 0) {
                    if (
                        targets[i].date !==
                            targets[i - 1].date + 24 * 60 * 60 * 1000 ||
                        targets[i - 1][this.state.primaryName][
                            secondaryName
                        ] === null
                    ) {
                        trace.x.push(null);
                        trace.y.push(null);
                    }
                }

                trace.x.push(new Date(targets[i].date));
                trace.y.push(targets[i][this.state.primaryName][secondaryName]);
                trace.x.push(new Date(targets[i].date + 24 * 60 * 60 * 1000));
                trace.y.push(targets[i][this.state.primaryName][secondaryName]);
            }
            traces.push(trace);
        } else {
            let trace = {
                x: [],
                y: [],
                mode: "markers",
                type: "scatter",
                marker: { color: "#2c1c94" }
            };

            xAxisTitle = this.state.firstSecondaryName;
            yAxisTitle = this.state.secondSecondaryName;

            for (let industrial of industrials) {
                if (
                    industrial[this.state.primaryName][
                        this.state.firstSecondaryName
                    ] === null ||
                    industrial[this.state.primaryName][
                        this.state.secondSecondaryName
                    ] === null
                ) {
                    continue;
                }

                trace.x.push(
                    industrial[this.state.primaryName][
                        this.state.firstSecondaryName
                    ]
                );
                trace.y.push(
                    industrial[this.state.primaryName][
                        this.state.secondSecondaryName
                    ]
                );
            }
            traces.push(trace);
        }

        let enoughData = false;
        for (let trace of traces) {
            if (trace.x.length > 0 && trace.y.length > 0) {
                enoughData = true;
            }
        }

        if (!enoughData) {
            toaster.push(
                <Message type="info" showIcon closable duration={5000}>{<text>No Data Found</text>}</Message>
            );
            this.setState({
                traces: [],
                xAxisTitle: "",
                yAxisTitle: ""
            });
        } else {
            this.setState({
                traces: traces,
                xAxisTitle: xAxisTitle,
                yAxisTitle: yAxisTitle
            });
        }
    }

    render() {
        let disableSecondaryNamePickers;
        let secondaryNamePickerOptions;
        if (this.state.primaryName === null) {
            disableSecondaryNamePickers = true;
            secondaryNamePickerOptions = [];
        } else {
            disableSecondaryNamePickers = false;
            secondaryNamePickerOptions =
                this.secondaryNamePickerOptionsDict[this.state.primaryName];
        }

        let disableButton;
        if (
            (this.state.firstSecondaryName === null &&
                this.state.secondSecondaryName === null) ||
            this.state.dateRange[0] === null
        ) {
            disableButton = true;
        } else {
            disableButton = false;
        }

        return (
            <div
                id="analysis-screen"
                style={{
                    margin: "20px",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div style={{ width: "100%", maxWidth: "1408px" }}>
                    <h3
                        style={{
                            display: "block",
                            color: "#120078",
                            marginBottom: "20px"
                        }}
                    >
                        Analysis
                    </h3>

                    <div
                        style={{
                            display: "inline-block",
                            marginBottom: "20px"
                        }}
                    >
                        <div style={{ marginBottom: "5px" }}>Date Range</div>
                        <DateRangePicker
                            format="yyyy-MM-dd HH:mm:ss"
                            value={this.state.dateRange}
                            onChange={this.handleDateRangeChange}
                            onClean={this.handleDateRangeClean}
                        />
                    </div>
                    <div
                        style={{
                            display: "inline-block",
                            marginLeft: "10px",
                            marginBottom: "20px"
                        }}
                    >
                        <div style={{ marginBottom: "5px" }}>
                            Variable Group
                        </div>
                        <InputPicker
                            data={this.primaryNamePickerOptions}
                            value={this.state.primaryName}
                            onChange={(value, item, event) => {
                                this.handleNamePickerChange(
                                    value,
                                    item,
                                    event,
                                    "primaryName"
                                );
                            }}
                            onClean={(event) => {
                                this.handleNamePickerClean(
                                    event,
                                    "primaryName"
                                );
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: "inline-block",
                            marginLeft: "10px",
                            marginBottom: "20px"
                        }}
                    >
                        <div style={{ marginBottom: "5px" }}>Variable 1</div>
                        <InputPicker
                            data={secondaryNamePickerOptions}
                            value={this.state.firstSecondaryName}
                            disabled={disableSecondaryNamePickers}
                            onChange={(value, item, event) => {
                                this.handleNamePickerChange(
                                    value,
                                    item,
                                    event,
                                    "firstSecondaryName"
                                );
                            }}
                            onClean={(event) => {
                                this.handleNamePickerClean(
                                    event,
                                    "firstSecondaryName"
                                );
                            }}
                            style={{
                                width: "280px"
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "inline-block",
                            marginLeft: "10px",
                            marginBottom: "20px"
                        }}
                    >
                        <div style={{ marginBottom: "5px" }}>Variable 2</div>
                        <InputPicker
                            data={secondaryNamePickerOptions}
                            value={this.state.secondSecondaryName}
                            disabled={disableSecondaryNamePickers}
                            onChange={(value, item, event) => {
                                this.handleNamePickerChange(
                                    value,
                                    item,
                                    event,
                                    "secondSecondaryName"
                                );
                            }}
                            onClean={(event) => {
                                this.handleNamePickerClean(
                                    event,
                                    "secondSecondaryName"
                                );
                            }}
                            style={{
                                width: "280px"
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "inline-block",
                            marginLeft: "10px",
                            marginBottom: "20px"
                        }}
                    >
                        <Button
                            appearance="primary"
                            disabled={disableButton}
                            onClick={this.handleGeneratePlotClick}
                        >
                            Generate Plot
                        </Button>
                    </div>

                    <Plot
                        useResizeHandler
                        data={this.state.traces}
                        layout={{
                            margin: { l: 40, r: 20, t: 20, b: 40 },
                            responsive: true,
                            uirevision: true,
                            plot_bgcolor: "rgba(0,0,0,0)",
                            paper_bgcolor: "rgba(0,0,0,0)",
                            modebar: {
                                bgcolor: "rgba(0,0,0,0)",
                                color: "black",
                                activecolor: "#2c1c94"
                            },
                            xaxis: {
                                title: this.state.xAxisTitle
                            },
                            yaxis: {
                                title: this.state.yAxisTitle
                            }
                        }}
                        config={{
                            displaylogo: false,
                            staticPlot: !Boolean(this.state.traces.length)
                        }}
                        style={{
                            width: "100%",
                            height: "600px",
                            opacity: Boolean(this.state.traces.length) ? 1 : 0.3
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Analysis)
);
