/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:23:17
 */

import React, { Component } from "react";
import { Button, Form, InputPicker, Modal, Schema, CheckPicker } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import { addUser } from "../../../services/userManagement";

class AddUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: {},
            formJSON: {
                username: "",
                firstName: "",
                lastName: "",
                email: "",
                userType: "",
                role: "",
                status: "",
                projectIDs: [],
                password: "",
                passwordReEnter: ""
            },
            rolePickerOptions: [],
            userTypePickerOptions: [],
            clientPickerOptions: [],
            selectableProjects: []
        };

        this.clearForm = this.clearForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);

        let schema = {
            username: validators.usernameValidation,
            firstName: validators.nameValidation,
            lastName: validators.nameValidation,
            email: validators.emailValidation,
            userType: validators.userTypeValidation,
            role: validators.roleValidation,
            status: validators.statusValidation,
            projectIDs: validators.projectIDsValidation,
            password: validators.passwordValidation,
            passwordReEnter: validators.passwordReEnterValidation
        };

        if (this.props.userDetails.userType === "SuperAdmin") {
            this.state.formJSON.client = "";
            schema.client = validators.clientIdValidation;
        }

        this.validationModel = Schema.Model(schema);
    }

    componentDidMount() {
        let userTypePickerOptions;
        if (this.props.userDetails.userType === "SuperAdmin") {
            userTypePickerOptions = [
                {
                    label: "User",
                    value: "User",
                    role: "Master"
                },
                {
                    label: "Admin",
                    value: "Admin",
                    role: "Master"
                }
            ];
        } else {
            userTypePickerOptions = [
                {
                    label: "User",
                    value: "User",
                    role: "Master"
                }
            ];
        }

        if (this.props.userDetails.userType === "SuperAdmin") {
            this.setState({
                userTypePickerOptions: userTypePickerOptions
            });
        }

        this.setState({
            userTypePickerOptions: userTypePickerOptions
        });
    }

    clearForm() {
        let formJSON = this.state.formJSON;
        for (let field in formJSON) {
            if (formJSON.hasOwnProperty(field)) {
                if (field === "projectIDs") {
                    formJSON[field] = [];
                } else {
                    formJSON[field] = "";
                }
            }
        }

        this.setState({ formJSON: formJSON, errorMessages: {} });
    }

    async handleSubmit() {
        if (this.form.check()) {
            this.setState({ loading: true });
            let formJSON = { ...this.state.formJSON };

            if (this.props.userDetails.userType === "SuperAdmin") {
                formJSON.clientId = formJSON.client;
                delete formJSON.client;
            }
            formJSON.roleId = formJSON.role;
            delete formJSON.passwordReEnter;
            delete formJSON.role;

            let result = await addUser(this.props.endUserSession, formJSON);
            if (result) {
                this.props.onClose();
                this.props.onSubmit();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    handleFormChange(newFormJSON) {
        // Do make modifications to projectPickerOptions if there is no client
        // form element, or if the client has not been changed
        if (
            this.state.formJSON.client === newFormJSON.client ||
            this.props.userDetails.userType === "Admin"
        ) {
            this.setState({ formJSON: newFormJSON });
            return;
        }
        newFormJSON.projectIDs = [];

        let selectableProjects = [];
        for (let project of this.props.projects) {
            if (project.project_clientId === newFormJSON.client) {
                selectableProjects.push(project);
            }
        }

        this.setState({
            selectableProjects: selectableProjects,
            formJSON: newFormJSON
        });
    }

    handleModalOpen() {
        this.clearForm();

        let rolePickerOptions = [];
        for (let role of this.props.roles) {
            rolePickerOptions.push({
                label: role.name,
                value: role._id
            });
        }

        let clientPickerOptions = [];
        for (let client of this.props.clients) {
            clientPickerOptions.push({
                label: client.clientName,
                value: client._id
            });
        }

        if (this.props.userDetails.userType === "Admin") {
            let selectableProjects = [];
            for (let project of this.props.projects) {
                selectableProjects.push(project);
            }

            this.setState({
                loading: false,
                rolePickerOptions: rolePickerOptions,
                clientPickerOptions: clientPickerOptions,
                selectableProjects: selectableProjects
            });
        } else {
            this.setState({
                loading: false,
                rolePickerOptions: rolePickerOptions,
                clientPickerOptions: clientPickerOptions
            });
        }
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        ref={(forum) => (this.form = forum)}
                        layout="horizontal"
                        formValue={this.state.formJSON}
                        model={this.validationModel}
                        checkTrigger="none"
                        onChange={this.handleFormChange}
                        onCheck={(errorMessages) => {
                            this.setState({ errorMessages: errorMessages });
                        }}
                    >
                        <Form.Group>
                            <Form.ControlLabel>Username</Form.ControlLabel>
                            <Form.Control
                                name="username"
                                errorMessage={this.state.errorMessages.username}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>First Name</Form.ControlLabel>
                            <Form.Control
                                name="firstName"
                                errorMessage={
                                    this.state.errorMessages.firstName
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Last Name</Form.ControlLabel>
                            <Form.Control
                                name="lastName"
                                errorMessage={this.state.errorMessages.lastName}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Email</Form.ControlLabel>
                            <Form.Control
                                name="email"
                                type="email"
                                errorMessage={this.state.errorMessages.email}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>User Type</Form.ControlLabel>
                            <Form.Control
                                name="userType"
                                accepter={InputPicker}
                                data={this.state.userTypePickerOptions}
                                errorMessage={this.state.errorMessages.userType}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Role</Form.ControlLabel>
                            <Form.Control
                                name="role"
                                accepter={InputPicker}
                                data={this.state.rolePickerOptions}
                                errorMessage={this.state.errorMessages.role}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>User Status</Form.ControlLabel>
                            <Form.Control
                                name="status"
                                accepter={InputPicker}
                                data={[
                                    {
                                        label: "Active",
                                        value: "active",
                                        role: "Master"
                                    },
                                    {
                                        label: "Inactive",
                                        value: "inactive",
                                        role: "Master"
                                    }
                                ]}
                                errorMessage={this.state.errorMessages.status}
                            />
                        </Form.Group>
                        {this.props.userDetails.userType === "SuperAdmin" ? (
                            <Form.Group>
                                <Form.ControlLabel>Client</Form.ControlLabel>
                                <Form.Control
                                    name="client"
                                    accepter={InputPicker}
                                    data={this.state.clientPickerOptions}
                                    errorMessage={
                                        this.state.errorMessages.client
                                    }
                                />
                            </Form.Group>
                        ) : null}
                        <Form.Group>
                            <Form.ControlLabel>Projects</Form.ControlLabel>
                            <Form.Control
                                name="projectIDs"
                                accepter={CheckPicker}
                                data={this.state.selectableProjects}
                                errorMessage={
                                    this.state.errorMessages.projectIDs
                                }
                                searchable={false}
                                renderMenuItem={(label, project) => {
                                    if (project.status === "active") {
                                        return label;
                                    } else {
                                        return (
                                            <div style={{ color: "lightgrey" }}>
                                                {label}
                                            </div>
                                        );
                                    }
                                }}
                                valueKey="_id"
                                labelKey="projectName"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Password</Form.ControlLabel>
                            <Form.Control
                                name="password"
                                type="password"
                                errorMessage={this.state.errorMessages.password}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>
                                Re-enter Password
                            </Form.ControlLabel>
                            <Form.Control
                                name="passwordReEnter"
                                type="password"
                                errorMessage={
                                    this.state.errorMessages.passwordReEnter
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleSubmit}
                            appearance="primary"
                            type="submit"
                            loading={this.state.loading}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
