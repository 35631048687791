/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:58:02
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:17:38
 */

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules, fieldName) => {
    let msg = "";
    if (!rules) {
        return [true, false, msg];
    }
    switch (fieldName) {
        case "username":
            return validateUsername(value);
        case "password":
            return validatePassword(value);
        case "deviceType":
            return validateDeviceType(value);
        case "authenticationType":
            return validateAuthenticationType(value);
        case "deviceName":
            return validateDeviceName(value);
        case "host":
            return validateHost(value);
        case "port":
            return validatePort(value);
        case "targetTag":
            return validateTargetTag(value);
        case "startDateTime":
            return validateStartDateTime(value);
        case "endDateTime":
            return validateEndDateTime(value);
        case "compareDateDiff":
            return validateDateDiff(value[0], value[1]);
        default:
            return false;
    }
};

export const validateUsername = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    let regex = /^(?![0-9$!@_ ])([a-z0-9$!@_]){5,25}(?<![$!@_ ])$/i;
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "username can't be empty";
    } else {
        if (Boolean(value.match(regex))) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper username";
            if (value.length > 25) {
                msg = "max length is 25";
            }
        }
    }

    return [isValid, isInValid, msg];
};

export const validatePassword = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    let regex = /^(?![0-9$!@_ ])([a-z0-9$!@_ ]){8,25}(?<![$!@_ ])$/i;
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "password can't be empty";
    } else {
        if (Boolean(value.match(regex))) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper password";
            if (value.length > 25) {
                msg = "min length is 8. max length is 25";
            }
        }
    }

    return [isValid, isInValid, msg];
};

export const validateDeviceType = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "device type can't be empty";
    } else {
        isValid = true;
        isInValid = false;
        msg = "";
    }

    return [isValid, isInValid, msg];
};

export const validateAuthenticationType = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "authentication type can't be empty";
    } else {
        isValid = true;
        isInValid = false;
        msg = "";
    }

    return [isValid, isInValid, msg];
};

export const validateDeviceName = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    let regex = /^(?![0-9$!@_ ])([a-z0-9$!@_ ]){4,25}(?<![$!@_ ])$/i;
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "device name can't be empty";
    } else {
        if (Boolean(value.match(regex))) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper device name";
            if (value.length > 25) {
                msg = "min length is 4. max length is 25";
            }
        }
    }

    return [isValid, isInValid, msg];
};

export const validateHost = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";

    let regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "host can't be empty";
        return [isValid, isInValid, msg];
    } else {
        // validate that the host is a valid ip address
        if (value.match(regex)) {
            isValid = true;
            isInValid = false;
            msg = "";
            return [isValid, isInValid, msg];
        }
        // If host is not ip address check that it is a valid domain name
        regex = /^([a-z]*\.)*[a-z]*$/i;
        if (Boolean(value.match(regex))) {
            isValid = true;
            isInValid = false;
            msg = "";
            return [isValid, isInValid, msg];
        }

        isValid = false;
        isInValid = true;
        msg = "please enter proper host information";
        return [isValid, isInValid, msg];
    }
};

export const validatePort = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "port can't be empty";
    } else {
        if (Boolean(Number.isInteger(Number(value)))) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper port information";
        }
    }
    return [isValid, isInValid, msg];
};

export const validateTargetTag = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "target tag can't be empty";
    } else {
        isValid = true;
        isInValid = false;
        msg = "";
    }
    return [isValid, isInValid, msg];
};

export const validateStartDateTime = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "start time can't be empty";
    } else {
        if (value instanceof Date) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper datetime";
        }
    }
    return [isValid, isInValid, msg];
};

export const validateEndDateTime = (value) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "end time can't be empty";
    } else {
        if (value instanceof Date) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper datetime";
        }
    }
    return [isValid, isInValid, msg];
};

export const validateDateDiff = (startDateTime, endDateTime) => {
    let isValid = false;
    let isInValid = false;
    let msg = "";
    let diff = endDateTime - startDateTime;
    if (diff < 0) {
        isValid = false;
        isInValid = true;
        msg = "end datetime should greater than start datetime";
    } else if (diff / (1000 * 60 * 60 * 24 * 7 * 4) > 1.1) {
        isValid = false;
        isInValid = true;
        msg = "time range shouldn't exceed 1 month";
    } else {
        isValid = true;
        isInValid = false;
        msg = "";
    }
    return [isValid, isInValid, msg];
};

export const validateFirstName = (value) => {
    let isValid;
    let isInValid;
    let msg;
    let regex = /^(?![0-9$!@_ ])([a-z0-9$!@_ ]){1,25}(?<![$!@_ ])$/i;
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "first name can't be empty";
    } else {
        if (Boolean(value.match(regex))) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper first name";
        }
    }

    return [isValid, isInValid, msg];
};

export const validateLastName = (value) => {
    let isValid;
    let isInValid;
    let msg;
    let regex = /^(?![0-9$!@_ ])([a-z0-9$!@_ ]){1,25}(?<![$!@_ ])$/i;
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "last name can't be empty";
    } else {
        if (Boolean(value.match(regex))) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper last name";
        }
    }

    return [isValid, isInValid, msg];
};

export const validateEmail = (value) => {
    let isValid;
    let isInValid;
    let msg;
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (value.length === 0) {
        isValid = false;
        isInValid = true;
        msg = "email can't be empty";
    } else {
        if (Boolean(value.match(regex))) {
            isValid = true;
            isInValid = false;
            msg = "";
        } else {
            isValid = false;
            isInValid = true;
            msg = "please enter proper email";
        }
    }

    return [isValid, isInValid, msg];
};
export const validateUserType = (value) => {
    if (!value) {
        return [false, true, "user type can't be empty"];
    }

    const validTypes = ["Admin", "User"];
    let isValid = validTypes.includes(value);

    let msg = "";
    if (!isValid) {
        msg = "please enter proper user type";
    }
    return [isValid, !isValid, msg];
};

export const validateRole = (value) => {
    if (!value) {
        return [false, true, "role can't be empty"];
    }
    return [true, false, ""];
};

export const validateClient = (value) => {
    if (!value) {
        return [false, true, "client can't be empty"];
    }
    return [true, false, ""];
};

export const validateStatus = (value) => {
    if (!value) {
        return [false, true, "user status can't be empty"];
    }

    const validStatuses = ["active", "inactive"];
    let isValid = validStatuses.includes(value);

    let msg = "";
    if (!isValid) {
        msg = "please enter proper user status";
    }
    return [isValid, !isValid, msg];
};

export const validateNotEmpty = (value) => {
    if (!value) {
        return [false, true, "this can't be empty"];
    }
    return [true, false, ""];
};
