/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2021-09-06 17:57:56
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 12:25:39
 */

import React, { Component } from "react";
import Plot from "react-plotly.js";
import {
    IconButton,
    Button,
    Whisper,
    Popover,
    Loader,
    Form,
    Toggle,
    Schema,
    DatePicker
} from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import { getExplanations } from "../services/explainableAI";
import * as actions from "../store/actions/auth";
import * as validators from "../shared/modelValidators";
import { getPredictionModelTargetTag } from "../services/tagManagement";
import {
    getPredictionTracesInDateRange,
    getLatestNPredictionTraces
} from "../services/predictionModel";
import { withRouter } from "react-router-dom";
import { isBefore, isAfter, isWithinInterval } from "date-fns";

class ExplainableAI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessages: {},
            formJSON: {
                enableLiveStream: false,
                startDate: new Date("2020-01-07T18:30:00.000Z"),
                endDate: new Date("2020-01-11T18:30:00.000Z")
            },
            autoGenerateDates: [],
            datesForCalendarDisable: {
                startDate: null,
                endDate: null
            },
            xAIFormJSON: {
                xAIStartDateRange1: null,
                xAIStartDateRange2: null,
                xAIEndDateRange1: null,
                xAIEndDateRange2: null
            },
            layout: {
                hovermode: "x unified",
                hoverdistance: 1,
                autosize: true,
                uirevision: true, // Allows updating of data while keeping zoom level and pan location
                xaxis: {
                    autorange: true,
                    rangeslider: {
                        visible: false,
                        yaxis: {
                            rangemode: "auto"
                        }
                    },
                    type: "date",
                    tickfont: {
                        size: 14
                    }
                },
                yaxis: {
                    title: "P80",
                    fixedrange: false,
                    autorange: true,
                    tickfont: {
                        size: 14
                    }
                },
                margin: {
                    l: 40,
                    t: 30
                },
                // paper_bgcolor: "rgba(0,0,0,0)",
                modebar: {
                    bgcolor: "rgba(0,0,0,0)",
                    color: "black",
                    activecolor: "#2c1c94"
                },
                annotations: []
            },
            traces: [],
            shapes: [],
            showModal: false,
            predictionLoading: true,
            loading: true,
            graphData: [],
            p80Tag: null,
            liveStreamDisable: true
        };

        this.pathParts = props.location.pathname.split("/");
        this.homeURL = "/" + this.pathParts[1];

        this.getPredictions = this.getPredictions.bind(this);
        this.handlePredictionsDataClick =
            this.handlePredictionsDataClick.bind(this);

        this.handleXAICompareDataClick =
            this.handleXAICompareDataClick.bind(this);
        this.handleLiveStreamToggleChange =
            this.handleLiveStreamToggleChange.bind(this);
        this.getExplanations = this.getExplanations.bind(this);
        this.handleSelectBoxChange = this.handleSelectBoxChange.bind(this);

        let schema = {
            startDate: validators.startDateValidation,
            endDate: validators.endDateValidation
        };
        let xAISchema = {
            xAIStartDateRange1: validators.xAIStartDateRange1Validation,
            xAIStartDateRange2: validators.xAIStartDateRange2Validation,
            xAIEndDateRange1: validators.xAIEndDateRange1Validation,
            xAIEndDateRange2: validators.xAIEndDateRange2Validation
        };

        this.validationModel = Schema.Model(schema);
        this.xAIValidationModel = Schema.Model(xAISchema);
        this.liveUpdateInterval = null;
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.getPredictions(true);
        this.setState({ loading: false, predictionLoading: false });
    }

    componentWillUnmount() {
        clearInterval(this.liveUpdateInterval);
    }

    async getPredictions(useDateRange = false, liveStreamTriggeredOn = false) {
        // Get the p80 tag info from the state, otherwise make an API call to get it
        let p80Tag;
        this.setState({
            shapes: [],
            xAIFormJSON: {
                xAIStartDateRange1: null,
                xAIStartDateRange2: null,
                xAIEndDateRange1: null,
                xAIEndDateRange2: null
            },
            autoGenerateDates: [],
            graphData: []
        });
        if (this.state.p80Tag === null) {
            p80Tag = await getPredictionModelTargetTag(
                this.props.endUserSession,
                this.homeURL + "/predictionModel"
            );
            if (p80Tag === null) {
                return;
            }
        } else {
            p80Tag = this.state.p80Tag;
        }

        // Get predictions
        let traces;
        if (useDateRange) {
            let startDate = this.state.formJSON.startDate;
            let endDate = this.state.formJSON.endDate;
            let timeOffset = new Date().getTimezoneOffset() * 60000;
            if (timeOffset < 0) {
                startDate = Date.parse(startDate) - timeOffset;
                startDate = new Date(startDate).toISOString();
                endDate = Date.parse(endDate) - timeOffset;
                endDate = new Date(endDate).toISOString();
            } else {
                startDate = Date.parse(startDate) + timeOffset;
                startDate = new Date(startDate).toISOString();
                endDate = Date.parse(endDate) + timeOffset;
                endDate = new Date(endDate).toISOString();
            }
            this.setState({
                datesForCalendarDisable: {
                    startDate: startDate,
                    endDate: endDate
                }
            });

            traces = await getPredictionTracesInDateRange(
                this.props.endUserSession,
                this.homeURL + "/predictionModel",
                { startDate: startDate, endDate: endDate }
            );
        } else {
            traces = await getLatestNPredictionTraces(
                this.props.endUserSession,
                this.props.location.pathname
            );
        }

        // Don't continue, if no data given
        if (!traces) {
            this.setState({
                traces: []
            });
            return;
        }

        // Don't continue, if empty trace list given
        if (traces.length === 0) {
            this.setState({
                traces: []
            });
            return;
        }

        // Get and use offset to counteract rsuite date picker and plotly graph internal date conversion
        let offset = new Date().getTimezoneOffset();
        offset = offset * 60 * 1000;
        for (let trace of traces) {
            for (let i = 0; i < trace.x.length; i++) {
                trace.x[i] = trace.x[i] + offset;
            }
        }

        let layout = JSON.parse(JSON.stringify(this.state.layout));
        layout.yaxis.title = "P80 (" + p80Tag.units + ")";
        layout.shapes = [];

        // Set autorange to true so that the graph autoscales with the data
        // gathered from a date range. However, it should not be set to true
        // when live streaming, so that it is possible to keep the zoom level
        // and pan location from resetting every couple of seconds.
        if (useDateRange || liveStreamTriggeredOn) {
            layout.xaxis.rangeslider.autorange = true;
            layout.xaxis.autorange = true;
            layout.yaxis.autorange = true;
        }

        let formJSON = JSON.parse(JSON.stringify(this.state.formJSON));
        formJSON.startDate = new Date(traces[0].x[traces[0].x.length - 1]);
        formJSON.endDate = new Date(traces[0].x[0]);

        this.setState({
            traces: traces,
            formJSON: formJSON,
            layout: layout
        });
    }

    async handlePredictionsDataClick() {
        if (this.form.check()) {
            this.setState({ predictionLoading: true });
            await this.getPredictions(true);
            // await this.getInfluencers(true);
            this.setState({ predictionLoading: false });
        }
    }

    async getExplanations(useDateRange = false) {
        let result;
        let timeOffset = new Date().getTimezoneOffset();
        timeOffset = timeOffset * 60 * 1000;
        if (useDateRange) {
            let startDateRange1 = this.state.xAIFormJSON.xAIStartDateRange1;
            let endDateRange1 = this.state.xAIFormJSON.xAIEndDateRange1;
            let startDateRange2 = this.state.xAIFormJSON.xAIStartDateRange2;
            let endDateRange2 = this.state.xAIFormJSON.xAIEndDateRange2;
            if (timeOffset < 0) {
                startDateRange1 = Date.parse(startDateRange1) - timeOffset;
                startDateRange1 = new Date(startDateRange1).toISOString();
                endDateRange1 = Date.parse(endDateRange1) - timeOffset;
                endDateRange1 = new Date(endDateRange1).toISOString();
                startDateRange2 = Date.parse(startDateRange2) - timeOffset;
                startDateRange2 = new Date(startDateRange2).toISOString();
                endDateRange2 = Date.parse(endDateRange2) - timeOffset;
                endDateRange2 = new Date(endDateRange2).toISOString();
            } else {
                startDateRange1 = Date.parse(startDateRange1) + timeOffset;
                startDateRange1 = new Date(startDateRange1).toISOString();
                endDateRange1 = Date.parse(endDateRange1) + timeOffset;
                endDateRange1 = new Date(endDateRange1).toISOString();
                startDateRange2 = Date.parse(startDateRange2) + timeOffset;
                startDateRange2 = new Date(startDateRange2).toISOString();
                endDateRange2 = Date.parse(endDateRange2) + timeOffset;
                endDateRange2 = new Date(endDateRange2).toISOString();
            }

            result = await getExplanations(
                this.props.endUserSession,
                this.props.location.pathname,
                {
                    startDateRange1: startDateRange1,
                    endDateRange1: endDateRange1,
                    startDateRange2: startDateRange2,
                    endDateRange2: endDateRange2
                }
            );
        } else {
            result = await getExplanations(
                this.props.endUserSession,
                this.props.location.pathname,
                {}
            );
        }
        if (!result) {
            this.setState({ loading: false });
            return;
        }
        let graphData = result.graphData;
        let layout = JSON.parse(JSON.stringify(this.state.layout));
        // let formJSON = JSON.parse(JSON.stringify(this.state.formJSON));
        // formJSON.xAIStartDateRange1 = new Date(
        //     this.state.formJSON.xAIStartDateRange1
        // );
        // formJSON.xAIEndDateRange1 = new Date(
        //     this.state.formJSON.xAIEndDateRange1
        // );
        // formJSON.xAIStartDateRange2 = new Date(
        //     this.state.formJSON.xAIStartDateRange2
        // );
        // formJSON.xAIEndDateRange2 = new Date(
        //     this.state.formJSON.xAIEndDateRange2
        // );
        let shapes = result.shapes;
        shapes.map((d) => {
            d.x0 = d.x0 + timeOffset;
            d.x1 = d.x1 + timeOffset;
        });
        layout.shapes = result.shapes;

        this.setState({
            graphData: graphData,
            shapes: result.shapes,
            loading: false,
            layout: layout
            // formJSON: formJSON
        });
    }
    async handleXAICompareDataClick() {
        if (this.xAIForm.check()) {
            this.setState({ loading: true });
            await this.getExplanations(true);
            this.setState({ loading: false });
        }
    }

    async handleLiveStreamToggleChange(checked) {
        if (checked) {
            this.setState({ loading: true, liveFlag: true });
            this.setState({
                errorMessages: {
                    startDate: "",
                    endDate: "",
                    xAIStartDateRange1: "",
                    xAIStartDateRange2: "",
                    xAIEndDateRange1: "",
                    xAIEndDateRange2: ""
                }
            });
            /**
             * if the flag is not set to "true" the there is issue with rangeslider
             * when we request for different time range and zoom in to certain portion
             * of graph and then toggling to LiveStream is giving issue
             * with range slider.
             */
            await this.getExplanations();
            this.setState({ loading: false });
            this.liveUpdateInterval = setInterval(
                this.getExplanations(),
                60000
            );
        } else {
            this.setState({ liveFlag: false });
            clearInterval(this.liveUpdateInterval);
        }
    }

    handlePlotRelayout(data) {
        // If there is no data don't do anything
        if (this.state.traces.length === 0) {
            return;
        }

        // Continue if appropriate operation has occurred
        // "xaxis.range[0]" in data: pan
        // "xaxis.range" in data: range slider movement
        // "xaxis.autorange" in data: autoscale
        if (
            !(
                "xaxis.range[0]" in data ||
                "xaxis.range" in data ||
                "xaxis.autorange" in data
            )
        ) {
            return;
        }

        // The lines below are a work around for a range slider bug (https://github.com/plotly/plotly.js/issues/4417)
        let layout = JSON.parse(JSON.stringify(this.state.layout));
        let traces = this.state.traces;

        layout.xaxis.rangeslider.autorange = false;
        layout.xaxis.rangeslider.range = [
            traces[0].x[traces[0].x.length - 1],
            traces[0].x[0]
        ];
        this.setState({ layout: layout });
    }

    handleSelectBoxChange(data) {
        console.log(data);
        let autoGenerateDates = JSON.parse(
            JSON.stringify(this.state.autoGenerateDates)
        );
        let xAIFormJSON = JSON.parse(JSON.stringify(this.state.xAIFormJSON));
        let selectedRange = data.range.x;
        let startDate = Date.parse(
            selectedRange[0].replace(" ", "T").split(".")[0] + ".000Z"
        );
        let endDate = Date.parse(
            selectedRange[1].replace(" ", "T").split(".")[0] + ".000Z"
        );
        let timeOffset = new Date().getTimezoneOffset() * 60000;
        // if (timeOffset < 0) {
        //     startDate = startDate - timeOffset;
        //     endDate = endDate - timeOffset;
        // } else {
        //     startDate = startDate + timeOffset;
        //     endDate = endDate + timeOffset;
        // }
        startDate = startDate + timeOffset;
        endDate = endDate + timeOffset;
        console.log(startDate, endDate);
        switch (autoGenerateDates.length) {
            case 0:
                autoGenerateDates.push(startDate, endDate);
                xAIFormJSON.xAIStartDateRange1 = new Date(startDate);
                xAIFormJSON.xAIEndDateRange1 = new Date(endDate);
                break;
            case 2:
                autoGenerateDates.push(startDate, endDate);
                xAIFormJSON.xAIStartDateRange1 = new Date(
                    xAIFormJSON.xAIStartDateRange1
                );
                xAIFormJSON.xAIEndDateRange1 = new Date(
                    xAIFormJSON.xAIEndDateRange1
                );
                xAIFormJSON.xAIStartDateRange2 = new Date(startDate);
                xAIFormJSON.xAIEndDateRange2 = new Date(endDate);
                break;
            case 4:
                autoGenerateDates[0] = startDate;
                autoGenerateDates[1] = endDate;
                xAIFormJSON.xAIStartDateRange1 = new Date(startDate);
                xAIFormJSON.xAIEndDateRange1 = new Date(endDate);
                xAIFormJSON.xAIStartDateRange2 = new Date(
                    xAIFormJSON.xAIStartDateRange2
                );
                xAIFormJSON.xAIEndDateRange2 = new Date(
                    xAIFormJSON.xAIEndDateRange2
                );
                break;
        }
        console.log(xAIFormJSON);
        this.setState({
            autoGenerateDates: autoGenerateDates,
            xAIFormJSON: xAIFormJSON
        });
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Explainable AI
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Here you can see the explanations based on the
                                tags which are influencing more
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        size="sm"
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "12px",
                            marginLeft: "10px"
                        }}
                        color="primary"
                    />
                </Whisper>
                <Form
                    ref={(forum) => (this.form = forum)}
                    layout="inline"
                    formValue={this.state.formJSON}
                    model={this.validationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ formJSON: newFormJSON });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({ errorMessages: errorMessages });
                    }}
                >
                    <Form.Group>
                        <Form.ControlLabel style={{ fontSize: "16px" }}>
                            Live Stream:
                        </Form.ControlLabel>
                        <Form.Control
                            name="enableLiveStream"
                            accepter={Toggle}
                            errorMessage={
                                this.state.errorMessages.enableLiveStream
                            }
                            size="lg"
                            disabled={this.state.liveStreamDisable}
                            onChange={this.handleLiveStreamToggleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel style={{ fontSize: "16px" }}>
                            <b style={{ marginRight: "20px" }}>Or</b> Date
                            Range: Start
                        </Form.ControlLabel>
                        <Form.Control
                            name="startDate"
                            accepter={DatePicker}
                            errorMessage={this.state.errorMessages.startDate}
                            format="dd MMM yyyy hh:mm:ss"
                            disabledDate={(date) =>
                                !isWithinInterval(date, {
                                    start: new Date("2019-12-31T00:00:00.000Z"),
                                    end: new Date("2020-03-18T00:00:00.000Z")
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel style={{ fontSize: "16px" }}>
                            End
                        </Form.ControlLabel>
                        <Form.Control
                            name="endDate"
                            accepter={DatePicker}
                            errorMessage={this.state.errorMessages.endDate}
                            format="dd MMM yyyy hh:mm:ss"
                            disabledDate={(date) =>
                                !isWithinInterval(date, {
                                    start: new Date("2019-12-31T00:00:00.000Z"),
                                    end: new Date("2020-03-18T00:00:00.000Z")
                                })
                            }
                        />
                    </Form.Group>

                    <Button
                        appearance="primary"
                        disabled={
                            this.state.formJSON.enableLiveStream ||
                            this.state.predictionLoading
                        }
                        onClick={this.handlePredictionsDataClick}
                    >
                        Get Data
                    </Button>
                </Form>
                <div style={{ position: "relative" }}>
                    <Plot
                        data={this.state.traces}
                        layout={this.state.layout}
                        config={{
                            displaylogo: false,
                            modeBarButtonsToRemove: ["lasso2d"],
                            staticPlot: this.state.predictionLoading
                        }}
                        useResizeHandler
                        style={
                            this.state.predictionLoading
                                ? {
                                      width: "100% - 20px",
                                      height: "40vh",
                                      opacity: 0.5
                                  }
                                : {
                                      width: "100% - 20px",
                                      height: "40vh"
                                  }
                        }
                        onSelected={(data) => {
                            this.handleSelectBoxChange(data);
                        }}
                    />
                    {this.state.predictionLoading ? (
                        <Loader
                            size="lg"
                            style={{
                                position: "absolute",
                                left: "calc(50% - 64px)",
                                bottom: "calc(70% - 64px)"
                            }}
                        />
                    ) : null}
                </div>
                <Form
                    style={{ marginTop: "4px", marginBottom: "-20px" }}
                    ref={(forum) => (this.xAIForm = forum)}
                    layout="inline"
                    formValue={this.state.xAIFormJSON}
                    model={this.xAIValidationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({ xAIFormJSON: newFormJSON });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({ errorMessages: errorMessages });
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            background: "#ffffff",
                            marginRight: "0%"
                            // justifyContent: "space-between"
                        }}
                    >
                        <div
                            style={{
                                background: "#ffffff",
                                padding: "10px",
                                marginRight: "0.25%"
                            }}
                        >
                            <p
                                style={{
                                    color: "#3A5BA0",
                                    paddingBottom: "5px",
                                    fontSize: "16px"
                                }}
                            >
                                <b>Select Dataset 1 Time Range</b>
                            </p>
                            {/* !isWithinInterval(date, {
                                            start: new Date(
                                                this.state.datesForCalendarDisable.startDate
                                            ),
                                            end: new Date(
                                                Date.parse(
                                                    new Date(
                                                        this.state.datesForCalendarDisable.endDate
                                                    )
                                                ) +
                                                    24 * 60 * 60000
                                            )
                                        }) */}
                            <Form.Group>
                                <Form.ControlLabel style={{ fontSize: "16px" }}>
                                    Start Time
                                </Form.ControlLabel>
                                <Form.Control
                                    name="xAIStartDateRange1"
                                    accepter={DatePicker}
                                    errorMessage={
                                        this.state.errorMessages
                                            .xAIStartDateRange1
                                    }
                                    format="dd MMM yyyy hh:mm:ss"
                                    disabledDate={(date) =>
                                        !isWithinInterval(date, {
                                            start: this.state.formJSON
                                                .startDate,
                                            end: this.state.formJSON.endDate
                                        })
                                    }
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel
                                    style={{
                                        marginLeft: "10px",
                                        fontSize: "16px"
                                    }}
                                >
                                    End Time
                                </Form.ControlLabel>
                                <Form.Control
                                    name="xAIEndDateRange1"
                                    accepter={DatePicker}
                                    errorMessage={
                                        this.state.errorMessages
                                            .xAIEndDateRange1
                                    }
                                    format="dd MMM yyyy hh:mm:ss"
                                    disabledDate={(date) =>
                                        !isWithinInterval(date, {
                                            start: this.state.formJSON
                                                .startDate,
                                            end: this.state.formJSON.endDate
                                        })
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div
                            style={{
                                background: "#ffffff",
                                borderLeft: "thick solid #3A5BA0",
                                padding: "10px"
                            }}
                        >
                            <p
                                style={{
                                    color: "#FFA500",
                                    paddingBottom: "5px",
                                    fontSize: "16px"
                                }}
                            >
                                <b>Select Dataset 2 Time Range</b>
                            </p>
                            <Form.Group>
                                <Form.ControlLabel style={{ fontSize: "16px" }}>
                                    Start Time
                                </Form.ControlLabel>
                                <Form.Control
                                    name="xAIStartDateRange2"
                                    accepter={DatePicker}
                                    errorMessage={
                                        this.state.errorMessages
                                            .xAIStartDateRange2
                                    }
                                    format="dd MMM yyyy hh:mm:ss"
                                    disabledDate={(date) =>
                                        !isWithinInterval(date, {
                                            start: this.state.formJSON
                                                .startDate,
                                            end: this.state.formJSON.endDate
                                        })
                                    }
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel
                                    style={{
                                        marginLeft: "10px",
                                        fontSize: "16px"
                                    }}
                                >
                                    End Time
                                </Form.ControlLabel>
                                <Form.Control
                                    name="xAIEndDateRange2"
                                    accepter={DatePicker}
                                    errorMessage={
                                        this.state.errorMessages
                                            .xAIEndDateRange2
                                    }
                                    format="dd MMM yyyy hh:mm:ss"
                                    disabledDate={(date) =>
                                        !isWithinInterval(date, {
                                            start: this.state.formJSON
                                                .startDate,
                                            end: this.state.formJSON.endDate
                                        })
                                    }
                                />
                            </Form.Group>
                            <Button
                                style={{ marginLeft: "20px" }}
                                appearance="primary"
                                disabled={this.state.loading}
                                onClick={this.handleXAICompareDataClick}
                            >
                                Compare
                            </Button>
                        </div>
                    </div>
                </Form>
                <div
                    className="custom-flex-column"
                    style={{
                        position: "relative",
                        width: "100%",
                        marginTop: "10px"
                    }}
                >
                    <Plot
                        data={this.state.graphData}
                        layout={{
                            barmode: "group",
                            hovermode: "x unified",
                            hoverdistance: 1,
                            title: {
                                text: "P80 Explainable AI"
                                // x: 0.1
                                // xanchor: "middle"
                            },
                            xaxis: {
                                ticks: "outside",
                                tickfont: {
                                    size: 14
                                }
                            },
                            yaxis: {
                                ticks: "outside",
                                title: "Influence (-1 to 1)",
                                fixedrange: true,
                                range: [-1, 1],
                                tickfont: {
                                    size: 14
                                }
                            },
                            margin: {
                                t: 50
                                // b: 150
                            }
                        }}
                        config={{
                            displaylogo: false,
                            transition: {
                                duration: 5000,
                                easing: "cubic-in-out"
                            }
                        }}
                        useResizeHandler
                        style={{
                            width: "100% - 20px",
                            height: "35vh"
                        }}
                        transition={{
                            duration: 5000,
                            easing: "cubic-in-out"
                        }}
                    />
                    {this.state.loading ? <Loader size="lg" center /> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        authRedirectPath: state.auth.authRedirectPath,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ExplainableAI)
);
