/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-07-26 13:57:48
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 11:31:49
 */

import "./retraining.css";
import React, { Component } from "react";
import { Steps } from "rsuite";
import LegacyTableIcon from "@rsuite/icons/legacy/Table";
import LegacyFlowIcon from "@rsuite/icons/legacy/Flow";
import LegacyFileTextOIcon from "@rsuite/icons/legacy/FileTextO";
import DataGeneration from "./dataGeneration";
import ModalReport from "./modalReport";
import ModelTraining from "./modelTraining";
import { getTrainingConfig, getTrainingProcess } from "../../services/retrain";
import * as actions from "../../store/actions/auth";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Retraining extends Component {
    constructor(props) {
        super(props);

        this.state = { currentStep: null, config: null, trainingProcess: null };

        this.handleStepChange = this.handleStepChange.bind(this);
        this.handleTrainingProcessChange =
            this.handleTrainingProcessChange.bind(this);
    }

    async componentDidMount() {
        const config = await getTrainingConfig(
            this.props.endUserSession,
            this.props.location.pathname
        );
        let trainingProcess = await getTrainingProcess(
            this.props.endUserSession,
            this.props.location.pathname,
            this.props.userDetails._id
        );

        let currentStep;
        if (trainingProcess === null || trainingProcess === undefined) {
            currentStep = 0;
        } else if (
            trainingProcess.trainingStatus !== 4 &&
            trainingProcess.result === null
        ) {
            currentStep = 1;
        } else {
            currentStep = 2;
        }

        this.setState({
            config: config,
            currentStep: currentStep,
            trainingProcess: trainingProcess
        });
    }

    handleStepChange(step) {
        this.setState({ currentStep: step });
    }

    handleTrainingProcessChange(trainingProcess) {
        this.setState({ trainingProcess: trainingProcess });
    }

    getSubScreen(currentStep) {
        // Show the appropriate screen based off the current step
        if (currentStep === 0 && this.state.config) {
            return (
                <DataGeneration
                    trainingProcess={this.state.trainingProcess}
                    config={this.state.config.dataGeneration}
                    onStepChange={this.handleStepChange}
                    onTrainingProcessChange={this.handleTrainingProcessChange}
                />
            );
        } else if (currentStep === 1 && this.state.config) {
            return (
                <ModelTraining
                    trainingProcess={this.state.trainingProcess}
                    config={this.state.config.modelTraining}
                    onStepChange={this.handleStepChange}
                    onTrainingProcessChange={this.handleTrainingProcessChange}
                />
            );
        } else if (currentStep === 2 && this.state.config) {
            return (
                <ModalReport
                    trainingProcess={this.state.trainingProcess}
                    onStepChange={this.handleStepChange}
                    onTrainingProcessChange={this.handleTrainingProcessChange}
                    config={this.state.config.modalReport}
                />
            );
        } else {
            return null;
        }
    }

    render() {
        let subScreen = this.getSubScreen(this.state.currentStep);

        return (
            <div
                id="retrain-modal-screen"
                style={{
                    margin: "15px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Steps
                    id="retraining-steps"
                    current={this.state.currentStep}
                    style={{ marginBottom: "30px" }}
                >
                    <Steps.Item
                        title="Data Generation"
                        icon={<LegacyTableIcon size="2x" />}
                    />
                    <Steps.Item
                        title="Model Training"
                        icon={<LegacyFlowIcon size="lg" />}
                    />
                    <Steps.Item
                        title="Model Report"
                        icon={<LegacyFileTextOIcon size="lg" />}
                    />
                </Steps>
                {subScreen}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Retraining)
);
