/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:24:20
 */

import React, { Component } from "react";
import { Button, Form, InputPicker, Modal, Schema, Divider } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import { addProject } from "../../../services/projectManagement";
import StepOrganizer from "../../../components/stepOrganizer";

class AddProjectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: {},
            formJSON: {
                projectName: "",
                branch: "",
                location: "",
                status: "",
                clientId: ""
            }
        };
        this.steps = [];

        this.clearForm = this.clearForm.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);

        let schema = {
            projectName: validators.projectNameValidation,
            branch: validators.branchNameValidation,
            location: validators.locationValidation,
            status: validators.statusValidation,
            clientId: validators.clientIdValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    clearForm() {
        let formJSON = this.state.formJSON;
        for (let field in formJSON) {
            if (formJSON.hasOwnProperty(field)) {
                formJSON[field] = "";
            }
        }

        this.setState({ formJSON: formJSON, errorMessages: {} });
    }

    async handleSubmitClick() {
        if (this.form.check()) {
            this.setState({ loading: true });

            let data = JSON.parse(JSON.stringify(this.state.formJSON));
            data.steps = [];

            for (let step of this.steps) {
                data.steps.push({
                    authorizedRoles: step.authorizedRoles,
                    functionality_id: step.data._id,
                    name: step.data.name,
                    stepCommonName: step.data.name
                });
            }

            let result = await addProject(this.props.endUserSession, data);
            if (result) {
                this.props.onClose();
                this.props.onSubmit();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    handleModalOpen() {
        this.clearForm();

        this.setState({
            loading: false
        });
    }

    handleStepChange(newSteps) {
        this.steps = newSteps;
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Add Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        ref={(forum) => (this.form = forum)}
                        layout="horizontal"
                        formValue={this.state.formJSON}
                        model={this.validationModel}
                        checkTrigger="none"
                        onChange={(newFormJSON) => {
                            this.setState({ formJSON: newFormJSON });
                        }}
                        onCheck={(errorMessages) => {
                            this.setState({ errorMessages: errorMessages });
                        }}
                    >
                        <Form.Group>
                            <Form.ControlLabel>Project Name</Form.ControlLabel>
                            <Form.Control
                                name="projectName"
                                errorMessage={
                                    this.state.errorMessages.projectName
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Branch</Form.ControlLabel>
                            <Form.Control
                                name="branch"
                                errorMessage={this.state.errorMessages.branch}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Location</Form.ControlLabel>
                            <Form.Control
                                name="location"
                                errorMessage={this.state.errorMessages.location}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>
                                Project Status
                            </Form.ControlLabel>
                            <Form.Control
                                name="status"
                                accepter={InputPicker}
                                data={[
                                    {
                                        label: "Active",
                                        value: "active",
                                        role: "Master"
                                    },
                                    {
                                        label: "Inactive",
                                        value: "inactive",
                                        role: "Master"
                                    }
                                ]}
                                errorMessage={this.state.errorMessages.status}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Client</Form.ControlLabel>
                            <Form.Control
                                name="clientId"
                                accepter={InputPicker}
                                data={this.props.clients}
                                valueKey={"_id"}
                                labelKey={"clientName"}
                                errorMessage={this.state.errorMessages.clientId}
                            />
                        </Form.Group>
                    </Form>
                    <Divider />
                    <StepOrganizer
                        functionalityTree={this.props.functionalityTree}
                        roles={this.props.roles}
                        onStepChange={this.handleStepChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleSubmitClick}
                            appearance="primary"
                            type="submit"
                            loading={this.state.loading}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectModal);
