/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2021-01-18 18:04:54
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:22:32
 */

import React from "react";
import building from "../assets/building.svg";

let stylingObject = {
    card: {
        width: "300px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        paddingBottom: "20px",
        backgroundImage: "linear-gradient(white, #120078)",
        overflow: "hidden",
        cursor: "pointer"
    },
    "card-wrapper": {
        width: "100%",
        padding: 0,
        margin: 0,
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 300px)",
        gap: "20px"
    },
    h3: {
        marginTop: "1rem",
        textAlign: "center",
        color: "white"
    },
    img: {
        width: "100%",
        height: "300px",
        objectFit: "fill",
        mixBlendMode: "multiply",
        backgroundImage: "url(" + building + ")",
        backgroundRepeat: "no-repeat"
    }
};
const projectCard = (props) => {
    let listConstruct = [];
    props.assignedProjects.forEach((project, index) => {
        listConstruct.push(
            <div
                key={project.projectName}
                style={stylingObject["card"]}
                onClick={() => {
                    props.handleProjectClick(project);
                }}
            >
                <div className="building" style={stylingObject["img"]} />
                <h4 style={stylingObject["h3"]}>{project.projectName}</h4>
            </div>
        );
    });
    return <div style={stylingObject["card-wrapper"]}>{listConstruct}</div>;
};

export default projectCard;
