/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:25:46
 */

import React, { Component } from "react";
import { Button, Form, Modal, Schema } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import { addClient } from "../../../services/clientManagement";

class AddClientModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: {},
            formJSON: {
                clientName: "",
                databaseName: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);

        let schema = {
            clientName: validators.clientNameValidation,
            databaseName: validators.databaseNameValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    async handleSubmit() {
        if (this.form.check()) {
            this.setState({ loading: true });

            let result = await addClient(
                this.state.formJSON,
                this.props.endUserSession
            );
            if (result) {
                this.props.onClose();
                this.props.onSubmit();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    handleModalOpen() {
        let formJSON = this.state.formJSON;
        for (let field in formJSON) {
            if (formJSON.hasOwnProperty(field)) {
                formJSON[field] = "";
            }
        }

        this.setState({
            formJSON: formJSON,
            errorMessages: {},
            loading: false
        });
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Add Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        ref={(forum) => (this.form = forum)}
                        layout="horizontal"
                        formValue={this.state.formJSON}
                        model={this.validationModel}
                        checkTrigger="none"
                        onChange={(newFormJSON) => {
                            this.setState({ formJSON: newFormJSON });
                        }}
                        onCheck={(errorMessages) => {
                            this.setState({ errorMessages: errorMessages });
                        }}
                    >
                        <Form.Group>
                            <Form.ControlLabel>Client Name</Form.ControlLabel>
                            <Form.Control
                                name="clientName"
                                errorMessage={
                                    this.state.errorMessages.clientName
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Database Name</Form.ControlLabel>
                            <Form.Control
                                name="databaseName"
                                errorMessage={
                                    this.state.errorMessages.databaseName
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleSubmit}
                            appearance="primary"
                            type="submit"
                            loading={this.state.loading}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClientModal);
