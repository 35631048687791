/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:21:43
 */

import React, { Component } from "react";

import { IconButton, Button, Whisper, Popover, toaster, Message } from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import { connect } from "react-redux";
import Editor from "@monaco-editor/react";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import { getFunctionality } from "../services/functionalityManagement";
import { updateProjectStepConifg } from "../services/projectManagement";

class Configuration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sideNavExpanded: true,
            configurationJSON: JSON.stringify({}, null, 4)
        };

        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleExpandToggle = this.handleExpandToggle.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        this.setState({
            configurationJSON: JSON.stringify(this.props.step.config, null, 4)
        });
    }

    handleResetClick() {
        this.setState({
            configurationJSON: JSON.stringify(
                this.props.step.functionality.defaultConfig,
                null,
                4
            )
        });
    }

    async handleSaveClick() {
        let newConfig;
        // Used to check for JSON errors as the editor cannot be depended on to report errors to this component
        try {
            newConfig = JSON.parse(this.state.configurationJSON);
        } catch (e) {
            toaster.push(
                <Message type="error" showIcon closable duration={5000}>
                    The JSON below is invalid
                </Message>
            );
            return;
        }

        let data = {
            functionality_id: this.props.step.functionality_id,
            config: newConfig
        };
        let steps = await updateProjectStepConifg(
            this.props.endUserSession,
            data,
            this.props.project._id
        );

        if (steps === null) {
            return;
        }

        let assignedProjects = JSON.parse(
            JSON.stringify(this.props.assignedProjects)
        );

        let targetProjectIndex = assignedProjects.findIndex(
            (assignedProject) => assignedProject._id === this.props.project._id
        );

        let targetStepIndex = assignedProjects[
            targetProjectIndex
        ].steps.findIndex(
            (step) => step.functionality_id === this.props.step.functionality_id
        );

        assignedProjects[targetProjectIndex].steps[targetStepIndex].config =
            newConfig;

        this.props.updateAssignedProjects(assignedProjects);
        toaster.push(
            <Message type="success" showIcon closable>
                Saved
            </Message>
        );
    }

    handleEditorChange(newJSON) {
        this.setState({
            configurationJSON: newJSON
        });
    }

    handleExpandToggle(expanded) {
        this.setState({ sideNavExpanded: expanded });
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                <h2
                    style={{
                        height: "50px",
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    {this.props.step.functionality.name}
                </h2>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Here is where you can edit the configuration for
                                this step.
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{ marginBottom: "17px", marginLeft: "20px" }}
                        appearance="primary"
                    />
                </Whisper>

                <div
                    style={{
                        border: "2px solid #b4b4b4",
                        height: "500px"
                    }}
                >
                    <Editor
                        defaultLanguage="json"
                        options={{
                            minimap: {
                                enabled: false
                            },
                            automaticLayout: true,
                            scrollBeyondLastLine: false
                        }}
                        value={this.state.configurationJSON}
                        onChange={this.handleEditorChange}
                    />
                </div>

                <div
                    style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Button appearance="primary" onClick={this.handleSaveClick}>
                        Save
                    </Button>
                    <Button
                        appearance="ghost"
                        onClick={this.handleResetClick}
                        style={{ marginLeft: "20px" }}
                    >
                        Reset
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession()),
        updateAssignedProjects: (assignedProjects) =>
            dispatch(actions.updateAssignedProjects(assignedProjects))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
