/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-03-15 11:07:50
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:18:41
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../api-common";

export async function addProject(endUserSession, data) {
    try {
        let url = BaseURL.getBaseURL() + "/projectManagement/createProject";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The request is invalid.");
        } else if (res.data.isDuplicate) {
            throw new Error("A project with this name already exists");
        } else if (!res.data.isInserted) {
            throw new Error(
                "The project could not be inserted into the database."
            );
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not be add project.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function updateProject(endUserSession, data, id) {
    try {
        let url =
            BaseURL.getBaseURL() + "/projectManagement/updateProject/" + id;

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The request is invalid.");
        } else if (res.data.isDuplicate) {
            throw new Error("A project with this name already exists");
        } else if (!res.data.isUpdated) {
            throw new Error(
                "The project could not be inserted into the database."
            );
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not update project.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function getAllProjects(endUserSession) {
    try {
        let url = BaseURL.getBaseURL() + "/projectManagement/getAllProjects";

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The request is invalid.");
        }

        return res.data.allProjects;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get all projects.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getProjectDetails(endUserSession, id) {
    try {
        let url =
            BaseURL.getBaseURL() + "/projectManagement/getProjectDetails/" + id;

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The request is invalid.");
        }

        return res.data.projectDetails;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get the project details.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function deleteProject(endUserSession, id) {
    try {
        let url =
            BaseURL.getBaseURL() + "/projectManagement/deleteProject/" + id;

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The request is invalid.");
        } else if (!res.data.isDeleted) {
            throw new Error("The project was not deleted from the database");
        }

        return true;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Project(s) could not be deleted.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return false;
    }
}

export async function updateProjectStepAuthorizedRoles(
    endUserSession,
    data,
    id
) {
    try {
        let url =
            BaseURL.getBaseURL() +
            "/projectManagement/updateProjectStepAuthorizedRoles/" +
            id;

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The request is invalid.");
        } else if (res.data.isDuplicate) {
            throw new Error("A project with this name already exists");
        } else if (!res.data.isUpdated) {
            throw new Error(
                "The project could not be inserted into the database."
            );
        }

        return res.data.steps;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not update project step role authorizations.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function updateProjectStepConifg(endUserSession, data, id) {
    try {
        let url =
            BaseURL.getBaseURL() +
            "/projectManagement/updateProjectStepConfig/" +
            id;

        let res = await axios({
            method: "post",
            url: url,
            withCredentials: true,
            data: data
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The request is invalid.");
        } else if (res.data.isDuplicate) {
            throw new Error("A project with this name already exists");
        } else if (!res.data.isUpdated) {
            throw new Error(
                "The project could not be inserted into the database."
            );
        }

        return res.data.steps;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not update config authorizations.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getAllProjectJobs(endUserSession, id) {
    try {
        let url = BaseURL.getBaseURL() + "/projectManagement/" + id + "/job";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The request is invalid.");
        }

        return res.data.jobs;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        Could not get the project jobs.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}
