import React, { Component } from "react";
import { IconButton, Whisper, Popover, CheckTreePicker, Button } from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import { getProjectsForRoleMapping } from "../services/roleManagement";

class RoleManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectsInfo: [],
            uncheckableItemValues: [],
            loading: false,
            projectStepsAccessMapping: {},
            defaultCheckedValues: []
        };

        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        this.getAllProjectsForRoleMapping();
    }

    async getAllProjectsForRoleMapping() {
        let projectsWithMapping = await getProjectsForRoleMapping(
            this.props.endUserSession
        );
        if (projectsWithMapping.hasOwnProperty("projectsInfo")) {
            let projectsInfo = projectsWithMapping.projectsInfo;
            if (
                projectsInfo !== undefined &&
                projectsInfo !== null &&
                projectsInfo.length
            ) {
                this.setState({
                    projectsInfo: projectsInfo
                });
            }
        }
        if (projectsWithMapping.hasOwnProperty("uncheckableItemValues")) {
            this.setState({
                uncheckableItemValues: projectsWithMapping.uncheckableItemValues
            });
        }
        if (projectsWithMapping.hasOwnProperty("defaultCheckedValues")) {
            this.setState({
                defaultCheckedValues: projectsWithMapping.defaultCheckedValues
            });
        }
    }

    async handleSelect(dataItemType, value, e) {
        console.log(dataItemType, value, e);
    }

    async handleSubmitClick() {
        console.log("save changes");
        console.log(this.state.projectsInfo);
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Role Management
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Here you can manage role assignment to project's
                                business process
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        appearance="primary"
                    />
                </Whisper>
                <div>
                    <CheckTreePicker
                        data={this.state.projectsInfo}
                        defaultValue={this.state.defaultCheckedValues}
                        uncheckableItemValues={this.state.uncheckableItemValues}
                        // style={{ width: 280 }}
                        cascade={false}
                        size="lg"
                        placeholder="Select which users has access to different business process steps"
                        style={{ display: "block" }}
                        onSelect={this.handleSelect}
                    />

                    <Button
                        onClick={this.handleSubmitClick}
                        appearance="primary"
                        type="submit"
                        loading={this.state.loading}
                        style={{ marginTop: "5px" }}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement);
