/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-11 17:34:56
 */

import React, { Component } from "react";
import { Progress } from "rsuite";
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import history from "../../shared/history";
import Timer from "../../components/timer";
import "./grinding.css";
import { getMillData } from "../../services/mineexpo-demo-services/millData";
import { refreshToken } from "../../shared/auth";
import BaseURL from "../../api-common";

// TODO: micro
const { Circle } = Progress;

class Grinding extends Component {
    constructor(props) {
        super(props);
        let x = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20
        ];
        this.state = {
            pictureIndex: 0,
            startIndex: 0,
            F80Traces: [
                {
                    x: x,
                    y: [
                        13.5, 13.3, 13.3, 13.3, 13.3, 13.4, 13, 13.2, 12.9,
                        12.9, 13, 13.1, 13.2, 13.1, 12.9, 13.3, 13.1, 12.9,
                        12.8, 12.9
                    ],
                    type: "scatter",
                    mode: "lines+markers",
                    hoverinfo: "y",
                    marker: { color: "#2c1c94" }
                }
            ],
            P80Traces: [
                {
                    x: x,
                    y: [
                        126.6, 126.6, 125.4, 120.2, 122.2, 119, 119.4, 119,
                        118.2, 116.2, 119.8, 118.2, 119, 117, 113, 115.4, 113.4,
                        113.8, 106.2, 112.2
                    ],
                    type: "scatter",
                    mode: "lines+markers",
                    hoverinfo: "y",
                    marker: { color: "#2c1c94" }
                }
            ],
            SAGFeedRate: "",
            waterAddition: "",
            pumpboxWaterAddition: "",
            pumpSpeed: "",
            pressure: "",
            density: "",
            recommendedSAGFeedRate: "",
            recommendedSAGWaterAddition: "",
            recommendedPumpboxWaterAddition: ""
        };

        this.updateData = this.updateData.bind(this);

        this.counter = setInterval(this.updateData, 4000);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.updateData();
    }

    componentWillUnmount() {
        clearInterval(this.counter);
    }

    async updateData() {
        let result = await getMillData(
            this.props.endUserSession,
            this.state.startIndex
        );

        let F80Traces = JSON.parse(JSON.stringify(this.state.F80Traces));
        let P80Traces = JSON.parse(JSON.stringify(this.state.P80Traces));

        if (result == null) {
            return;
        }

        let grindingData = result.grinding;

        F80Traces[0].y.push(grindingData.F80Predictions.y);
        P80Traces[0].y.push(grindingData.P80Predictions.y);

        if (P80Traces[0].y.length > 20) {
            F80Traces[0].y.shift();
            P80Traces[0].y.shift();
        }

        return this.setState({
            loading: false,
            F80Traces: F80Traces,
            P80Traces: P80Traces,
            SAGFeedRate: grindingData.SAGFeedRate,
            waterAddition: grindingData.waterAddition,
            pumpboxWaterAddition: grindingData.pumpboxWaterAddition,
            pumpSpeed: grindingData.pumpSpeed,
            pressure: grindingData.pressure,
            density: grindingData.density,
            recommendedSAGFeedRate: grindingData.recommendedSAGFeedRate,
            recommendedSAGWaterAddition: grindingData.recommendedWaterAddition,
            recommendedPumpboxWaterAddition:
                grindingData.recommendedPumpboxWaterAddition,
            startIndex: (this.state.startIndex + 1) % 121,
            pictureIndex: (this.state.pictureIndex + 1) % 20
        });
    }

    handleTimerFinish() {
        history.push("flotation");
    }

    render() {
        let F80 =
            this.state.F80Traces[0].y[this.state.F80Traces[0].y.length - 1];
        let P80 =
            this.state.P80Traces[0].y[this.state.P80Traces[0].y.length - 1];

        return (
            <div id="grinding">
                <div className="top-grid">
                    <div className="card" style={{ overflow: "hidden" }}>
                        <div className="title">
                            F80 Estimation - Conveyor Belt
                        </div>
                        <div
                            style={{
                                height: "calc(100% - 45px)",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}
                        >
                            <img
                                src={
                                    BaseURL.getBaseURL() +
                                    "/assets/crop_" +
                                    this.state.pictureIndex +
                                    ".jpg"
                                }
                                alt="rock"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "50%",
                                    marginBottom: "10px"
                                }}
                            />
                            <img
                                src={
                                    BaseURL.getBaseURL() +
                                    "/assets/crop_" +
                                    this.state.pictureIndex +
                                    "_segmented.jpg"
                                }
                                alt="rockSegmented"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "50%"
                                }}
                            />
                        </div>
                    </div>
                    <div className="card" style={{ overflow: "hidden" }}>
                        <div className="title">F80 Predictions [cm]</div>
                        <Plot
                            useResizeHandler
                            data={this.state.F80Traces}
                            layout={{
                                margin: { l: 40, r: 20, t: 20, b: 40 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                xaxis: {
                                    showticklabels: false,
                                    zeroline: false
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "400px"
                            }}
                        />
                    </div>
                    <div className="stat-grid">
                        <div className="stat-card">
                            <div className="title">
                                Recommended SAG Feed&nbsp;Rate
                            </div>
                            <div className="big-unit">
                                {this.state.recommendedSAGFeedRate}&nbsp;t/h
                            </div>
                        </div>
                        <div className="stat-card">
                            <div className="title">
                                Recommended SAG Water Addition
                            </div>
                            <div className="big-unit">
                                {this.state.recommendedSAGWaterAddition}&nbsp;m
                                <sup>3</sup>/h
                            </div>
                        </div>
                        <div className="stat-card">
                            <div className="title">
                                Recommended Pumpbox Water Addition
                            </div>
                            <div className="big-unit">
                                {this.state.recommendedPumpboxWaterAddition}
                                &nbsp;m
                                <sup>3</sup>/h
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-grid">
                    <div className="card">
                        <div className="title">Grinding Circuit</div>
                        <div
                            style={{
                                height: "calc(100% - 55px)",
                                width: "100%",
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}
                        >
                            <img
                                src={
                                    BaseURL.getBaseURL() +
                                    "/assets/circuit_diagram.svg"
                                }
                                alt="rockSegmented"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    padding: "40px"
                                }}
                            />

                            <div className="left-circuit-information">
                                SAG Feed: {this.state.SAGFeedRate} t/h
                                <br />
                                SAG Water Add: {this.state.waterAddition} m
                                <sup>3</sup>h
                                <br />
                                F80: {F80} cm
                            </div>

                            <div className="right-circuit-information">
                                P80: {P80} µm
                                <br />
                                Pressure: {this.state.pressure} psi
                                <br />
                                Density: {this.state.density}%
                            </div>

                            <div className="bottom-circuit-information">
                                Pump Speed: {this.state.pumpSpeed}%
                            </div>

                            <div className="bottom-left-circuit-information">
                                PB Water Add: {this.state.pumpboxWaterAddition}{" "}
                                m<sup>3</sup>h
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">P80 Predictions [µm]</div>
                        <Plot
                            useResizeHandler
                            data={this.state.P80Traces}
                            layout={{
                                margin: { l: 40, r: 20, t: 20, b: 40 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                xaxis: {
                                    showticklabels: false,
                                    zeroline: false
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            style={{
                                width: "100%",
                                height: "400px"
                            }}
                        />
                    </div>
                </div>
                {/*<Timer
                    className="counter"
                    startTime={30}
                    onFinish={this.handleTimerFinish}
                />*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Grinding);
