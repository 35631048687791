/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-02-03 16:31:06
 */

import React, { Component } from "react";
import "./analysisConfiguration.css";
import { SelectPicker, Button, Input, InputNumber, Form, Schema } from "rsuite";
import { connect } from "react-redux";
import { refreshToken } from "../../shared/auth";
import * as actions from "../../store/actions/auth";
import { AgGridReact } from "ag-grid-react";
import { withRouter } from "react-router-dom";
import history from "../../shared/history";
import { getDatasets, getFirstDataChunk } from "../../services/dataset";
import {
    getAnalysis,
    resetAnalysis,
    startAnalysis,
    updateAnalysis
} from "../../services/analysis";
import DeleteAnalysisModal from "../../components/modals/nDatum/deleteAnalysisModal";
import * as validators from "../../shared/modelValidators";

class AnalysisConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewRows: [],
            previewColumnDefs: [],
            datasets: [],
            step: 1,
            showingDeleteAnalysisModel: false,
            addingExcludedString: false,
            formJSON: {
                name: props.analysis.name,
                algorithm: props.analysis.algorithm,
                datasetID: props.analysis.datasetID
            },
            errorMessages: {}
        };

        for (let firstKey in props.analysis.options) {
            for (let secondKey in props.analysis.options[firstKey]) {
                this.state.formJSON["options." + firstKey + "." + secondKey] =
                    props.analysis.options[firstKey][secondKey];
            }
        }

        this.columns = null;

        this.pathParts = props.location.pathname.split("/");
        this.homeURL = "/" + this.pathParts[1] + "/" + this.pathParts[2];

        let schema = {
            name: validators.analysisNameValidation
        };

        this.validationModel = Schema.Model(schema);

        this.determineColumnClass = this.determineColumnClass.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleDatasetPickerChange =
            this.handleDatasetPickerChange.bind(this);
        this.handleAlgorithmPickerChange =
            this.handleAlgorithmPickerChange.bind(this);
        this.handleGridReady = this.handleGridReady.bind(this);
        this.handleGridRowDataChanged =
            this.handleGridRowDataChanged.bind(this);
        this.handleCellClicked = this.handleCellClicked.bind(this);
        this.handleStartClick = this.handleStartClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleDeleteAnalysisModelClose =
            this.handleDeleteAnalysisModelClose.bind(this);
        this.handleDeleteAnalysisModalSubmit =
            this.handleDeleteAnalysisModalSubmit.bind(this);
        this.renderSteps = this.renderSteps.bind(this);

        this.defaultColDef = {
            headerClass: this.determineColumnClass,
            cellClass: this.determineColumnClass,
            resizable: true,
            sortable: true,
            filter: true
        };
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);

        let datasets = await getDatasets(
            this.props.endUserSession,
            this.homeURL
        );

        if (datasets === null) {
            return;
        }

        if (this.props.analysis.datasetID === null) {
            this.setState({ datasets: datasets });
            return;
        }

        let result = await getFirstDataChunk(
            this.props.endUserSession,
            this.homeURL,
            this.props.analysis.datasetID
        );

        if (result === null) {
            return;
        }

        let textChunk = result.textChunk;

        let [previewRows, previewColumnDefs] =
            this.convertTextChunkToPreviewTableData(
                textChunk,
                result.isFullText
            );

        this.setState({
            datasets: datasets,
            previewRows: previewRows,
            previewColumnDefs: previewColumnDefs
        });
    }

    convertTextChunkToPreviewTableData(textChunk, isFullText) {
        // Get the column headers
        let rows = textChunk.split(/\r?\n/);
        let columnHeaders = rows[0].split(",");
        let previewColumnDefs = [];
        for (let columnHeader of columnHeaders) {
            previewColumnDefs.push({ field: columnHeader });
        }

        /*Remove the last, most likely incomplete, row, if the first chunk
        doesn't have all the data */
        if (!isFullText) {
            rows.pop();
        }
        // Remove the header row
        rows.shift();

        // Build the preview table with the data available
        let previewRows = [];
        for (let row of rows) {
            if (row === "") {
                continue;
            }
            let columns = row.split(",");
            let previewRow = {};
            for (let i = 0; i < columns.length; i++) {
                previewRow[columnHeaders[i]] = columns[i];
            }
            previewRows.push(previewRow);
        }

        return [previewRows, previewColumnDefs];
    }

    determineColumnClass(event) {
        if (
            this.state.formJSON["options.dataset.dateIndex"] === undefined ||
            this.state.formJSON["options.dataset.dateIndex"] === null ||
            this.gridColumnAPI === undefined ||
            this.columns === null
        ) {
            return "";
        }

        let column = this.gridColumnAPI.getColumn(event.colDef.field);
        if (column === null || column === undefined) {
            return "";
        }

        if (
            event.colDef.field ===
            this.columns[this.state.formJSON["options.dataset.dateIndex"]]
                .colDef.field
        ) {
            return "column-selection";
        } else {
            return "";
        }
    }

    async handleFormChange(newFormJSON) {
        if (newFormJSON.algorithm !== this.state.formJSON.algorithm) {
            if (newFormJSON.algorithm === null) {
                newFormJSON = {
                    name: this.state.formJSON.name,
                    algorithm: newFormJSON.algorithm,
                    datasetID: null
                };
                this.setState({
                    formJSON: newFormJSON,
                    previewRows: [],
                    previewColumnDefs: []
                });
            } else {
                newFormJSON = {
                    name: this.state.formJSON.name,
                    algorithm: newFormJSON.algorithm,
                    datasetID: newFormJSON.datasetID
                };
                if (newFormJSON.algorithm === "Data Quality") {
                    newFormJSON["options.dataset.topCategoryCount"] = null;
                    newFormJSON["options.preprocess.excludedStrings"] = "";
                } else if (newFormJSON.algorithm === "L1") {
                    newFormJSON["options.preprocess.excludedStrings"] = "";
                    newFormJSON["options.dataset.dateIndex"] = null;
                }
                this.setState({ formJSON: newFormJSON }, () => {
                    if (
                        this.gridAPI === undefined ||
                        this.gridColumnAPI === undefined
                    ) {
                        return;
                    }
                    this.gridAPI.refreshHeader();
                    this.gridAPI.refreshCells({ force: true });
                });
            }
            return;
        } else if (newFormJSON.datasetID !== this.state.formJSON.datasetID) {
            if (newFormJSON.datasetID === null) {
                this.setState({
                    formJSON: newFormJSON,
                    previewRows: [],
                    previewColumnDefs: []
                });
                return;
            }

            let result = await getFirstDataChunk(
                this.props.endUserSession,
                this.homeURL,
                newFormJSON.datasetID
            );

            if (result === null) {
                this.setState({
                    previewRows: [],
                    previewColumnDefs: []
                });
                return;
            }

            let textChunk = result.textChunk;

            let [previewRows, previewColumnDefs] =
                this.convertTextChunkToPreviewTableData(
                    textChunk,
                    result.isFullText
                );

            if (newFormJSON.algorithm === "L1") {
                newFormJSON["options.dataset.dateIndex"] = null;
            }

            this.setState(
                {
                    formJSON: newFormJSON,
                    previewRows: previewRows,
                    previewColumnDefs: previewColumnDefs
                },
                () => {
                    if (
                        this.gridAPI === undefined ||
                        this.gridColumnAPI === undefined
                    ) {
                        return;
                    }
                    this.gridAPI.refreshHeader();
                    this.gridAPI.refreshCells({ force: true });
                }
            );
        }

        this.setState({ formJSON: newFormJSON });
    }

    async handleDatasetPickerChange(datasetID, event) {}

    async handleAlgorithmPickerChange(algorithm, event) {
        let localAnalysis = JSON.parse(
            JSON.stringify(this.state.localAnalysis)
        );
        localAnalysis.algorithm = algorithm;
        localAnalysis.datasetID = null;
        localAnalysis.options.algorithm = {};
        localAnalysis.options.preprocess = { excludedStrings: [] };

        this.setState({ localAnalysis: localAnalysis });
    }

    handleGridReady(params) {
        this.gridAPI = params.api;
        this.gridColumnAPI = params.columnApi;
    }

    handleGridRowDataChanged(event) {
        if (
            this.gridAPI === undefined ||
            this.gridColumnAPI === undefined ||
            this.state.previewRows.length === 0
        ) {
            return;
        }

        this.gridAPI.sizeColumnsToFit();
        this.gridColumnAPI.autoSizeAllColumns();

        this.columns = event.columnApi.getAllColumns();

        if (this.state.formJSON["options.dataset.dateIndex"] !== undefined) {
            this.gridAPI.refreshHeader();
            this.gridAPI.refreshCells({ force: true });
        }
    }

    handleCellClicked(event) {
        if (this.state.formJSON.algorithm !== "L1") {
            return;
        }

        let formJSON = JSON.parse(JSON.stringify(this.state.formJSON));

        this.columns = this.gridColumnAPI.getAllColumns();
        for (let i = 0; i < this.columns.length; i++) {
            if (event.colDef.field === this.columns[i].colDef.field) {
                if (
                    new Date(
                        this.state.previewRows[0][event.colDef.field]
                    ).toString() === "Invalid Date"
                ) {
                    return;
                }
                formJSON["options.dataset.dateIndex"] = i;
                break;
            }
        }

        this.setState({ formJSON: formJSON }, () => {
            this.gridAPI.refreshHeader();
            this.gridAPI.refreshCells({ force: true });
        });
    }

    async handleStartClick() {
        if (!this.form.check()) {
            return;
        }

        let data = {
            name: this.state.formJSON.name,
            algorithm: this.state.formJSON.algorithm,
            datasetID: this.state.formJSON.datasetID,
            options: {}
        };

        if (data.datasetID === undefined) {
            data.datasetID = null;
        }

        for (let key in this.state.formJSON) {
            if (key.match(/^options\..*/g)) {
                let parts = key.split(".");
                let currentObject = data;
                for (let [index, part] of parts.entries()) {
                    if (index === parts.length - 1) {
                        currentObject[part] = this.state.formJSON[key];
                    } else if (currentObject[part] === undefined) {
                        currentObject[part] = {};
                    }
                    currentObject = currentObject[part];
                }
            }
        }

        if (this.props.analysisRestarted) {
            let analysis = await resetAnalysis(
                this.props.endUserSession,
                this.homeURL,
                this.props.analysis._id
            );

            if (analysis === null) {
                return;
            }
        }

        let analysis = await updateAnalysis(
            this.props.endUserSession,
            this.homeURL,
            data,
            this.props.analysis._id
        );

        if (analysis === null) {
            return;
        }

        analysis = await startAnalysis(
            this.props.endUserSession,
            this.homeURL,
            this.props.analysis._id
        );

        if (analysis === null) {
            return;
        }

        this.props.onAnalysisChange(analysis);
    }

    async handleSaveClick() {
        if (!this.form.check()) {
            return;
        }

        let data = {
            name: this.state.formJSON.name,
            algorithm: this.state.formJSON.algorithm,
            datasetID: this.state.formJSON.datasetID,
            options: {}
        };

        if (data.datasetID === undefined) {
            data.datasetID = null;
        }

        for (let key in this.state.formJSON) {
            if (key.match(/^options\..*/g)) {
                let parts = key.split(".");
                let currentObject = data;
                for (let [index, part] of parts.entries()) {
                    if (index === parts.length - 1) {
                        currentObject[part] = this.state.formJSON[key];
                    } else if (currentObject[part] === undefined) {
                        currentObject[part] = {};
                    }
                    currentObject = currentObject[part];
                }
            }
        }

        let analysis = await updateAnalysis(
            this.props.endUserSession,
            this.homeURL,
            data,
            this.props.analysis._id
        );

        if (analysis === null) {
            return;
        }

        this.props.onAnalysisChange(analysis);
    }

    handleDeleteClick() {
        this.setState({ showingDeleteAnalysisModel: true });
    }

    async handleBackClick() {
        if (this.props.analysisRestarted) {
            let analysis = await getAnalysis(
                this.props.endUserSession,
                this.homeURL,
                this.props.analysis._id
            );

            if (analysis === null) {
                return;
            }

            this.props.onAnalysisChange(analysis);
        } else {
            history.goBack();
        }
    }

    handleDeleteAnalysisModelClose() {
        this.setState({ showingDeleteAnalysisModel: false });
    }

    handleDeleteAnalysisModalSubmit() {
        history.replace(this.homeURL);
    }

    renderSteps() {
        let steps = [];

        steps.push(
            <Form.Group
                key={"Name"}
                style={{ marginTop: "10px", marginBottom: "10px" }}
            >
                <Form.ControlLabel
                    style={{
                        display: "inline-block",
                        marginRight: "10px"
                    }}
                >
                    Analysis Name:
                </Form.ControlLabel>
                <Form.Control name="name" accepter={Input} />
            </Form.Group>
        );

        steps.push(
            <b
                key="Step 1 Title"
                style={{
                    display: "block",
                    marginBottom: "10px",
                    marginTop: "20px"
                }}
            >
                Step 1: Select the Algorithm
            </b>
        );

        steps.push(
            <Form.Group
                key={"Step 1"}
                style={{ marginBottom: "10px", marginTop: "10px" }}
            >
                <Form.Control
                    name="algorithm"
                    accepter={SelectPicker}
                    data={[
                        { label: "Data Quality", value: "Data Quality" },
                        { label: "L1", value: "L1" },
                        { label: "L2", value: "L2" }
                    ]}
                    placeholder="Select Algorithm"
                />
            </Form.Group>
        );

        if (this.state.formJSON.algorithm === null) {
            return steps;
        }

        steps.push(
            <b
                key="Step 2 Title"
                style={{
                    display: "block",
                    marginBottom: "10px",
                    marginTop: "20px"
                }}
            >
                Step 2: Select Data
            </b>
        );

        steps.push(
            <Form.Group
                key={"Step 2"}
                style={{ marginTop: "10px", marginBottom: "10px" }}
            >
                <Form.Control
                    name="datasetID"
                    accepter={SelectPicker}
                    data={this.state.datasets}
                    placeholder="Select Imported Dataset"
                    labelKey={"name"}
                    valueKey={"_id"}
                />
            </Form.Group>
        );

        if (this.state.formJSON.datasetID === null) {
            return steps;
        } else if (this.state.formJSON.algorithm === "L1") {
            steps.push(<div>Select Time Column:</div>);
            /*
            <RadioGroup
                    name="radioList"
                    inline
                    appearance="picker"
                    defaultValue="target"
                >
                    <Radio value="target">Target</Radio>
                    <Radio value="B">Time</Radio>
                    <Radio value="C">Influencer</Radio>
                </RadioGroup>
             */
        }

        steps.push(
            <div
                key={"Step 2 Table"}
                className="ag-theme-alpine"
                style={{ height: 408, marginTop: "10px" }}
            >
                <AgGridReact
                    rowData={this.state.previewRows}
                    columnDefs={this.state.previewColumnDefs}
                    defaultColDef={this.defaultColDef}
                    frameworkComponents={this.frameworkComponents}
                    multiSortKey={"ctrl"}
                    paginationAutoPageSize
                    pagination
                    rowDragManaged // Allow drag and drop
                    animateRows // Allow animation when dragging
                    onGridReady={this.handleGridReady}
                    onRowDataChanged={this.handleGridRowDataChanged}
                    onCellClicked={this.handleCellClicked}
                />
            </div>
        );

        if (this.state.formJSON.algorithm === "Data Quality") {
            steps.push(
                <Form.Group
                    key={"Step 1 A"}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                    <Form.ControlLabel
                        style={{
                            display: "inline-block",
                            marginRight: "10px"
                        }}
                    >
                        Top # of Categories to Display
                    </Form.ControlLabel>
                    <Form.Control
                        name="options.dataset.topCategoryCount"
                        accepter={InputNumber}
                        min={1}
                        style={{ width: "100px" }}
                    />
                    <Form.HelpText tooltip>
                        Enter the number of top categories to display. To
                        display all categories leave this empty.
                    </Form.HelpText>
                </Form.Group>
            );
        } else if (this.state.formJSON.algorithm === "L1") {
            if (this.state.formJSON["options.dataset.dateIndex"] === null) {
                return steps;
            }
        } else if (this.state.formJSON.algorithm === "L2") {
            return steps;
        }

        steps.push(
            <b
                key="Step 3 Title"
                style={{
                    display: "block",
                    marginBottom: "10px",
                    marginTop: "20px"
                }}
            >
                Step 3: Select Preprocessing Options
            </b>
        );

        if (
            this.state.formJSON.algorithm === "Data Quality" ||
            this.state.formJSON.algorithm === "L1"
        ) {
            steps.push(
                <Form.Group
                    key={"Step 3 A"}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                    <Form.ControlLabel
                        style={{
                            display: "inline-block",
                            marginRight: "10px"
                        }}
                    >
                        Strings to remove:
                    </Form.ControlLabel>
                    <Form.Control
                        name="options.preprocess.excludedStrings"
                        accepter={Input}
                    />
                    <Form.HelpText tooltip>
                        Enter all the words to be removed here. The words should
                        be separated by commas and any space between the comma
                        and word will be removed.
                    </Form.HelpText>
                </Form.Group>
            );
        }

        /*
        if (this.state.localAnalysis.datasetID === null) {
            return steps;
        }

        steps.push(
            <div key={"Step 3"} style={{ marginTop: "20px" }}>
                <b style={{ display: "block" }}>Step 3: Verify File Content</b>
                {/*<div style={{ marginTop: "10px" }}>Selecting:</div>
                <RadioGroup
                    name="radioList"
                    inline
                    appearance="picker"
                    defaultValue="target"
                >
                    <Radio value="target">Target</Radio>
                    <Radio value="B">Time</Radio>
                    <Radio value="C">Influencer</Radio>
                </RadioGroup>*/
        return steps;
    }

    render() {
        let steps = this.renderSteps();

        let startButton = (
            <Button appearance={"primary"} onClick={this.handleStartClick}>
                Start
            </Button>
        );
        if (this.state.formJSON.datasetID === null) {
            startButton = null;
        } else if (this.state.formJSON.algorithm === "L1") {
            if (this.state.formJSON["options.dataset.dateIndex"] === null) {
                startButton = null;
            }
        }

        let saveButton = null;
        if (!this.props.analysisRestarted) {
            saveButton = (
                <Button
                    appearance={"primary"}
                    onClick={this.handleSaveClick}
                    style={{ marginLeft: "10px" }}
                >
                    Save
                </Button>
            );
        }

        let deleteButton = null;
        if (!this.props.analysisRestarted) {
            deleteButton = (
                <Button
                    appearance={"primary"}
                    onClick={this.handleDeleteClick}
                    style={{ marginLeft: "10px" }}
                >
                    Delete
                </Button>
            );
        }

        return (
            <div id={"analysis-configuration"} style={{ margin: "20px" }}>
                <Form
                    ref={(forum) => (this.form = forum)}
                    formValue={this.state.formJSON}
                    model={this.validationModel}
                    checkTrigger="none"
                    onChange={this.handleFormChange}
                    onCheck={(errorMessages) => {
                        this.setState({ errorMessages: errorMessages });
                    }}
                >
                    {steps}
                </Form>

                <div style={{ float: "right", marginTop: "20px" }}>
                    {startButton}
                    {saveButton}
                    {deleteButton}
                    <Button
                        onClick={this.handleBackClick}
                        style={{ marginLeft: "10px" }}
                    >
                        {this.props.analysisRestarted ? "Cancel" : "Back"}
                    </Button>
                </div>

                <DeleteAnalysisModal
                    open={this.state.showingDeleteAnalysisModel}
                    onClose={this.handleDeleteAnalysisModelClose}
                    onSubmit={this.handleDeleteAnalysisModalSubmit}
                    analysis={this.props.analysis}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AnalysisConfiguration)
);
