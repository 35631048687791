/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-07-23 16:33:10
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:27:53
 */

import React, { Component } from "react";
import "./App.css";
import Login from "./containers/auth";
import NotFound from "./containers/notFound";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { verifyToken } from "./services/auth";
import * as actions from "./store/actions/index";
import UserManagement from "./containers/userManagement";
import ClientManagement from "./containers/clientManagement";
import ProjectManagement from "./containers/projectManagement";
import FunctionalityManagement from "./containers/functionalityManagement";
import DatabaseArchiveManagement from "./containers/databaseArchiveManagement";
import LogManagement from "./containers/logManagement";
import PredictionModel from "./containers/predictionModel";
import Overview from "./containers/Overview/Overview";
import MyProjects from "./containers/myProjects";
import Profile from "./containers/profile/profile";
import Configuration from "./containers/configuration";
import RoleManagement from "./containers/roleManagement";
import Analytics from "./containers/analytics/analytics";
import ExplainableAI from "./containers/explainableAI";

import RetrainModel from "./containers/Retraining/retraining";
import RetrainModelConfig from "./containers/Retraining/config";

import ForgotPassword from "./containers/forgotPassword";
import ChangePassword from "./containers/changePassword";
import { Container, Sidebar } from "rsuite";
import SideNavBar from "./containers/sideNavBar/sideNavBar";
import MethaneDemo from "./containers/Methane-Demo/Overview";
import MillFeed from "./containers/Mineexpo-Demo/millFeed";
import Grinding from "./containers/Mineexpo-Demo/grinding";
import Flotation from "./containers/Mineexpo-Demo/flotation";
import MineToMill from "./containers/Mineexpo-Demo/mineToMill";
import Stockpile from "./containers/Mineexpo-Demo/stockpile";
import GHGSavings from "./containers/GHG-Savings/ghgSavings";
import Analysis from "./containers/analysis";
import Floatation from "./containers/floatation";

import sideBackground from "./assets/background.svg";
import logo from "./assets/logo.svg";
import building from "./assets/building.svg";

import BDAOverview from "./containers/nDatum/overview";
import BDADatasetManager from "./containers/nDatum/datasetManager";
import BDAAnalysisScreenManager from "./containers/nDatum/analysisScreenManager";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./App.css";
import LevelPrediction from "./containers/levelPrediction";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sideNavExpanded: true
        };

        this.productionContainers = {
            BDAOverview: BDAOverview,
            BDADatasetManager: BDADatasetManager,
            BDAAnalysisScreenManager: BDAAnalysisScreenManager,
            Profile: Profile,
            Analytics: Analytics,
            MethaneDemo: MethaneDemo,
            MillFeed: MillFeed,
            Grinding: Grinding,
            Flotation: Flotation,
            MineToMill: MineToMill,
            Stockpile: Stockpile,
            GHGSavings: GHGSavings,
            Overview: Overview,
            PredictionModel: PredictionModel,
            DatabaseArchiveManagement: DatabaseArchiveManagement,
            LogManagement: LogManagement,
            // RetrainModel: RetrainModel,
            // RetrainModelConfig: RetrainModelConfig,
            ExplainableAI: ExplainableAI,
            RetrainModel: RetrainModel,
            RetrainModelConfig: RetrainModelConfig,
            Analysis: Analysis,
            Floatation: Floatation,
            LevelPrediction: LevelPrediction
        };

        this.handleExpandToggle = this.handleExpandToggle.bind(this);
        this.generateConfigurationRoutes =
            this.generateConfigurationRoutes.bind(this);
        this.generateProductionRoutes =
            this.generateProductionRoutes.bind(this);
    }

    componentDidMount() {
        this.sessionCheckInterval = setInterval(async () => {
            if (this.props.isUserAuthenticated) {
                await verifyToken(this.props.endUserSession);
            }
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.sessionCheckInterval);
    }

    handleExpandToggle(expanded) {
        this.setState({ sideNavExpanded: expanded });

        // Dispatch resize event so that plotly graphs resize after sidenav collapse or expand animation
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 500);
    }

    generateConfigurationRoutes() {
        // Generate a configuration route for every step from each project
        if (this.props.userDetails.userType !== "Admin") {
            return [];
        }
        let configurationRoutes = [];
        for (let project of this.props.assignedProjects) {
            for (let step of project.steps) {
                let path =
                    "/" +
                    project.projectName +
                    step.functionality.pathTree.path +
                    "/configuration";
                configurationRoutes.push(
                    <Route
                        key={path}
                        path={path}
                        exact
                        component={() => (
                            <Configuration
                                project={project}
                                step={step}
                                steps={project.steps}
                            />
                        )}
                    />
                );
            }
        }
        return configurationRoutes;
    }

    generateProductionRoutes() {
        // Generate a configuration route for every step from each project
        if (this.props.userDetails.userType !== "User") {
            return [];
        }

        let productionRoutes = [];
        for (let project of this.props.assignedProjects) {
            for (let step of project.steps) {
                this.generateParseTreeRoutes(
                    productionRoutes,
                    "/" + project.projectName,
                    step.functionality.pathTree
                );
            }
        }
        return productionRoutes;
    }

    generateParseTreeRoutes(productionRoutes, basePath, node) {
        if (node !== undefined) {
            let path = basePath + node.path;
            productionRoutes.push(
                <Route
                    key={path}
                    path={path}
                    exact
                    component={
                        this.productionContainers[node.componentVariableName]
                    }
                />
            );

            if (!("children" in node)) {
                return;
            }

            for (let child of node.children) {
                this.generateParseTreeRoutes(
                    productionRoutes,
                    basePath + node.path,
                    child
                );
            }
        }
    }

    render() {
        let routes;
        if (this.props.isUserAuthenticated) {
            let configurationRoutes = this.generateConfigurationRoutes();
            let productionRoutes = this.generateProductionRoutes();

            routes = (
                <Container style={{ minHeight: "100vh" }}>
                    <Sidebar
                        width={this.state.sideNavExpanded ? 250 : 56}
                        collapsible
                        style={{
                            backgroundColor: "#120078"
                        }}
                    >
                        <SideNavBar onExpandToggle={this.handleExpandToggle} />
                    </Sidebar>
                    <Container
                        style={{
                            minWidth: "0" //Important for preventing resizing issues
                        }}
                    >
                        <Switch>
                            {["SuperAdmin", "Admin"].includes(
                                this.props.userDetails.userType
                            ) ? (
                                <Route
                                    path="/userManagement"
                                    exact
                                    component={UserManagement}
                                />
                            ) : null}

                            {["SuperAdmin", "Admin"].includes(
                                this.props.userDetails.userType
                            ) ? (
                                <Route
                                    path="/roleManagement"
                                    exact
                                    component={RoleManagement}
                                />
                            ) : null}

                            {this.props.userDetails.userType ===
                            "SuperAdmin" ? (
                                <Route
                                    path="/clientManagement"
                                    exact
                                    component={ClientManagement}
                                />
                            ) : null}

                            {["SuperAdmin", "Admin"].includes(
                                this.props.userDetails.userType
                            ) ? (
                                <Route
                                    path="/projectManagement"
                                    exact
                                    component={ProjectManagement}
                                />
                            ) : null}

                            {this.props.userDetails.userType ===
                            "SuperAdmin" ? (
                                <Route
                                    path="/functionalityManagement"
                                    exact
                                    component={FunctionalityManagement}
                                />
                            ) : null}

                            {["Admin", "User"].includes(
                                this.props.userDetails.userType
                            ) ? (
                                <Route
                                    path="/myProjects"
                                    exact
                                    component={MyProjects}
                                />
                            ) : null}

                            {configurationRoutes}

                            {productionRoutes}

                            <Route path="/profile" exact component={Profile} />
                            <Route
                                path="/analytics"
                                exact
                                component={Analytics}
                            />

                            <Route
                                path="/analytics"
                                exact
                                component={Analytics}
                            />

                            <Route component={NotFound} />
                        </Switch>
                    </Container>
                </Container>
            );
        } else {
            routes = (
                <div
                    id="guest-user-template"
                    style={{
                        display: "flex",
                        backgroundColor: "rgb(236, 236, 236)",
                        height: "100vh"
                    }}
                >
                    <div
                        className="sidebar"
                        style={{
                            backgroundImage: "url(" + sideBackground + ")"
                        }}
                    >
                        <div
                            className="logo"
                            style={{
                                backgroundImage: "url(" + logo + ")"
                            }}
                        />
                        <div
                            className="building"
                            style={{
                                backgroundImage: "url(" + building + ")"
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flex: "66",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Switch>
                            <Route path="/" exact component={Login} />
                            <Route
                                path="/forgotPassword"
                                exact
                                component={ForgotPassword}
                            />
                            <Route
                                path="/changePassword/:username/:token"
                                exact
                                component={ChangePassword}
                            />

                            <Route component={Login} />
                        </Switch>
                    </div>
                </div>
            );
        }

        return <div>{routes}</div>;
    }
}
const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails,
        assignedProjects: state.auth.assignedProjects,
        stepsData: state.auth.stepsData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
