/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-11 17:34:43
 */

import React, { Component } from "react";
import { Progress } from "rsuite";
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import history from "../../shared/history";
import { getMillData } from "../../services/mineexpo-demo-services/millData";
import Timer from "../../components/timer";
import "./millFeed.css";
import { refreshToken } from "../../shared/auth";
const { Line } = Progress;

class MillFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spectraTraces: [
                {
                    x: [],
                    y: [],
                    type: "scatter",
                    mode: "lines+markers",
                    marker: { color: "#2c1c94", size: 4 }
                }
            ],
            PCATraces: [
                {
                    x: [],
                    y: [],
                    type: "scatter",
                    mode: "markers+text",
                    text: ["A", "B", "C", "D"],
                    textposition: "bottom",
                    marker: { color: "green", symbol: "x", size: 23 }
                },
                {
                    x: [],
                    y: [],
                    type: "scatter",
                    mode: "markers",
                    marker: {
                        color: [
                            "rgba(44,28,148,1.00)",
                            "rgba(44,28,148,0.90)",
                            "rgba(44,28,148,0.80)",
                            "rgba(44,28,148,0.70)",
                            "rgba(44,28,148,0.60)",
                            "rgba(44,28,148,0.50)",
                            "rgba(44,28,148,0.40)",
                            "rgba(44,28,148,0.30)",
                            "rgba(44,28,148,0.20)",
                            "rgba(44,28,148,0.10)"
                        ],
                        size: 10
                    }
                }
            ],
            colorHistogramTraces: [
                {
                    x: Array.from(Array(256).keys()),
                    y: [],
                    type: "bar",
                    hoverinfo: "none",
                    marker: { color: "rgba(255, 0, 0, 0.5)" }
                },
                {
                    x: Array.from(Array(256).keys()),
                    y: [],
                    type: "bar",
                    hoverinfo: "none",
                    marker: { color: "rgba(0, 255, 0, 0.5)" }
                },
                {
                    x: Array.from(Array(256).keys()),
                    y: [],
                    type: "bar",
                    hoverinfo: "none",
                    marker: { color: "rgba(0, 0, 255, 0.5)" }
                }
            ],
            percentFeedType: { a: null, b: null, c: null, d: null },
            recovery: 93.12,
            recommended: {
                SAGFeedRate: "",
                SAGWaterAddition: "",
                pumpboxWaterAddition: "",
                frotherAddition: "",
                collectorAddition: ""
            },
            nextPageCounter: 0,
            loading: true,
            startIndex: 0
        };

        this.updateData = this.updateData.bind(this);

        this.counter = setInterval(this.updateData, 4000);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.updateData(true);
    }

    componentWillUnmount() {
        clearInterval(this.counter);
    }

    async updateData(updatePurePCA = false) {
        let result = await getMillData(
            this.props.endUserSession,
            this.state.startIndex
        );

        let spectraTraces = JSON.parse(
            JSON.stringify(this.state.spectraTraces)
        );
        let PCATraces = JSON.parse(JSON.stringify(this.state.PCATraces));
        let colorHistogramTraces = JSON.parse(
            JSON.stringify(this.state.colorHistogramTraces)
        );

        if (result == null) {
            return;
        }

        let millData = result.millFeed;

        spectraTraces[0].x = millData.spectra.x;
        spectraTraces[0].y = millData.spectra.y;

        PCATraces[1].x.unshift(millData.PCA.x);
        PCATraces[1].y.unshift(millData.PCA.y);

        if (PCATraces[1].x.length > 10) {
            PCATraces[1].x.pop();
            PCATraces[1].y.pop();
        }

        if (updatePurePCA) {
            PCATraces[0].x = millData.purePCA.x;
            PCATraces[0].y = millData.purePCA.y;
        }

        colorHistogramTraces[0].y = millData.colorHistogram.r;
        colorHistogramTraces[1].y = millData.colorHistogram.g;
        colorHistogramTraces[2].y = millData.colorHistogram.b;

        colorHistogramTraces[0].y[255] = colorHistogramTraces[0].y[254];
        colorHistogramTraces[1].y[0] = colorHistogramTraces[1].y[1];
        colorHistogramTraces[2].y[0] = colorHistogramTraces[2].y[1];

        return this.setState({
            loading: false,
            spectraTraces: spectraTraces,
            PCATraces: PCATraces,
            colorHistogramTraces: colorHistogramTraces,
            percentFeedType: millData.percentFeedType,
            recovery: millData.recovery,
            recommended: millData.recommended,
            startIndex: (this.state.startIndex + 1) % 121
        });
    }

    handleTimerFinish() {
        history.push("grinding");
    }

    render() {
        return (
            <div id="mill-feed">
                <div className="grid">
                    <div className="card" style={{ height: "495px" }}>
                        <div className="title">Current Feed Blend XRF Data</div>
                        <Plot
                            useResizeHandler
                            data={this.state.spectraTraces}
                            layout={{
                                margin: { l: 70, r: 20, t: 20, b: 70 },
                                responsive: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                yaxis: {
                                    title: "Intensity",
                                    range: [0, 65]
                                },
                                xaxis: {
                                    title: "Energy, keV"
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            revision={this.state.startIndex}
                            style={{
                                width: "100%",
                                height: "calc(100% - 45px)"
                            }}
                        />
                    </div>
                    <div className="card" style={{ height: "495px" }}>
                        <div className="title">
                            Two-Dimensional Representation of XRF Spectra
                        </div>
                        <Plot
                            useResizeHandler
                            data={this.state.PCATraces}
                            layout={{
                                margin: { l: 40, r: 20, t: 20, b: 40 },
                                autosize: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                showlegend: false,
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                }
                            }}
                            config={{
                                displaylogo: false
                            }}
                            revision={this.state.startIndex}
                            style={{
                                width: "100%",
                                height: "calc(100% - 45px)"
                            }}
                        />
                    </div>
                    <div className="card" style={{ height: "269px" }}>
                        <div className="title">
                            Current Feed Colour Information
                        </div>
                        <Plot
                            useResizeHandler
                            data={this.state.colorHistogramTraces}
                            layout={{
                                margin: { l: 40, r: 20, t: 20, b: 40 },
                                autosize: true,
                                uirevision: true,
                                plot_bgcolor: "rgba(0,0,0,0)",
                                paper_bgcolor: "rgba(0,0,0,0)",
                                showlegend: false,
                                modebar: {
                                    bgcolor: "rgba(0,0,0,0)",
                                    color: "black",
                                    activecolor: "#2c1c94"
                                },
                                bargap: 0.0,
                                barmode: "overlay"
                            }}
                            config={{
                                displaylogo: false
                            }}
                            revision={0}
                            style={{
                                width: "100%",
                                height: "calc(100% - 45px)"
                            }}
                        />
                    </div>
                    <div className="card" style={{ height: "269px" }}>
                        <div className="title">
                            Current Feed Blend Makeup Estimation
                        </div>
                        <div className="bar-grid">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                Type&nbsp;A
                                <Line percent={this.state.percentFeedType.a} />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                Type&nbsp;B
                                <Line percent={this.state.percentFeedType.b} />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                Type&nbsp;C
                                <Line percent={this.state.percentFeedType.c} />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                Type&nbsp;D
                                <Line percent={this.state.percentFeedType.d} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"stat-grid"}>
                    <div className="card" style={{ paddingBottom: "20px" }}>
                        <div className="title">Theoretical Recovery</div>
                        <div className="big-unit">{this.state.recovery}%</div>
                    </div>
                    <div className="card" style={{ paddingBottom: "20px" }}>
                        <div className="title">Recommended SAG Feed Rate</div>
                        <div className="big-unit">
                            {this.state.recommended.SAGFeedRate}&nbsp;t/h
                        </div>
                    </div>
                    <div className="card" style={{ paddingBottom: "20px" }}>
                        <div className="title">
                            Recommended SAG Water Addition
                        </div>
                        <div className="big-unit">
                            {this.state.recommended.SAGWaterAddition}&nbsp;m
                            <sup>3</sup>/h
                        </div>
                    </div>
                    <div className="card" style={{ paddingBottom: "20px" }}>
                        <div className="title">
                            Recommended Pumpbox Water Addition
                        </div>
                        <div className="big-unit">
                            {this.state.recommended.pumpboxWaterAddition}&nbsp;m
                            <sup>3</sup>/h
                        </div>
                    </div>
                    <div className="card" style={{ paddingBottom: "20px" }}>
                        <div className="title">
                            Recommended Frother Addition
                        </div>
                        <div className="big-unit">
                            {this.state.recommended.frotherAddition}&nbsp;g/t
                        </div>
                    </div>
                    <div className="card" style={{ paddingBottom: "20px" }}>
                        <div className="title">
                            Recommended Collector Addition
                        </div>
                        <div className="big-unit">
                            {this.state.recommended.collectorAddition}&nbsp;g/t
                        </div>
                    </div>
                </div>

                {/*<Timer
                    className="counter"
                    startTime={30}
                    onFinish={this.handleTimerFinish}
                />*/}
                {/*
                <Circle
                    className="counter"
                    percent={(this.state.nextPageCounter / 30) * 100}
                    showInfo={false}
                />*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MillFeed);
