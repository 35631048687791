/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2021-02-26 22:39:54
 * @Last Modified by: Hari Bheesetti
 * @Last Modified time: 2022-05-06 09:53:28
 */

import "leaflet/dist/leaflet.css";

import "./Overview.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import Plot from "react-plotly.js";
import { IconButton, Button, Table, Modal } from "rsuite";
import LegacyLineChartIcon from "@rsuite/icons/legacy/LineChart";
import * as actions from "../../store/actions/auth";
import "./Overview.css";
import Leaflet from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import LeakIncidentTimeline from "./LeakIncidentTimeline";
import ProcessEfficiencyLines from "./ProcessEfficiencyLines";
import AdvancedTable from "../../components/advancedTable";
const { Column, HeaderCell, Cell, Pagination } = Table;
Leaflet.Icon.Default.imagePath = "../node_modules/leaflet";
delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("../../assets/marker.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

function checkTime(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

class Overview extends Component {
    constructor() {
        super();
        this.state = {
            showWorkorderModal: false,
            data: {
                date: new Date(),
                rows: [
                    {
                        id: "1",
                        startTime: "20:50:02 01/01/21",
                        endTime: "21:04:02 01/01/21",
                        gasName: "CO2"
                    },
                    {
                        id: "2",
                        startTime: "20:50:02 05/02/21",
                        endTime: "21:04:02 06/02/21",
                        gasName: "O2"
                    },
                    {
                        id: "3",
                        startTime: "15:10:02 01/05/21",
                        endTime: "15:10:30 01/05/21",
                        gasName: "CO"
                    },
                    {
                        id: "4",
                        startTime: "05:50:02 01/05/21 2021",
                        endTime: "12:04:02 06/05/21 2021",
                        gasName: "N2"
                    },
                    {
                        id: "5",
                        startTime: "13:23:10 23/06/21 2021",
                        endTime: "13:53:24 23/06/21 2021",
                        gasName: "H2"
                    }
                ]
            }
        };

        this.linePlotData = [
            {
                x: [
                    new Date("01/01/21"),
                    new Date("01/02/21"),
                    new Date("01/03/21"),
                    new Date("01/04/21"),
                    new Date("01/05/21"),
                    new Date("01/06/21"),
                    new Date("01/07/21"),
                    new Date("01/08/21"),
                    new Date("01/09/21"),
                    new Date("01/10/21"),
                    new Date("01/11/21"),
                    new Date("01/12/21"),
                    new Date("01/13/21"),
                    new Date("01/14/21"),
                    new Date("01/15/21"),
                    new Date("01/16/21"),
                    new Date("01/17/21"),
                    new Date("01/18/21"),
                    new Date("01/19/21"),
                    new Date("01/20/21")
                ],
                y: [
                    10, 15, 13, 17, 30, 15, 20, 11, 14, 18, 19, 15, 10, 2, 8, 4,
                    10, 15, 18, 19
                ],
                type: "scatter",
                line: {
                    shape: "spline",
                    smoothing: 1.3,
                    color: "#120078"
                },
                fill: "tozeroy",
                fillcolor: "rgba(230, 236, 250, 0.5)"
            }
        ];

        this.linePlotLayout = {
            xaxis: {
                autorange: true
            },
            yaxis: {
                autorange: true
            },
            margin: { t: 10, b: 40, l: 40, r: 20 }
        };

        this.pieChartHoleTraces = [
            {
                type: "pie",
                title: {
                    text: "210",
                    font: { size: "40", color: "#120078", weight: 500 }
                },
                values: [42, 53, 21, 31, 63],
                labels: [
                    "C0<sub>2</sub>",
                    "O<sub>2</sub>",
                    "CO",
                    "N<sub>2</sub>",
                    "H<sub>2</sub>"
                ],
                hoverinfo: "value",
                hole: 0.55,
                domain: {
                    row: 0,
                    column: 0
                },
                marker: {
                    colors: ["#120078", "#1e00c6", "rgb(240, 79, 67)", "orange"]
                },
                currentTime: new Date().toTimeString()
            }
        ];
        this.pieChartHoleLayout = {
            showlegend: true,
            margin: { t: 20, b: 20, l: 20, r: 20 }
        };

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);

        setInterval(() => {
            let today = new Date();
            let h = today.getHours();
            let m = today.getMinutes();
            let s = today.getSeconds();
            // add a zero in front of numbers<10
            m = checkTime(m);
            s = checkTime(s);
            this.setState({ currentTime: h + ":" + m + ":" + s });
        }, 1000);
    }

    componentDidMount() {}
    closeModal() {
        this.setState({ showWorkorderModal: false });
    }

    openModal() {
        this.setState({ showWorkorderModal: true });
    }

    render() {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}
            >
                <div id="topGridContainer">
                    <div id="a">
                        <div className="title">Gasses Processed (1 Y)</div>
                        <div className="bigUnit">
                            39,585 m<sup>3</sup>
                        </div>
                    </div>
                    <div id="b">
                        <div className="title">Gasses Lost (1 Y)</div>
                        <div className="bigUnit">
                            423 m<sup>3</sup>
                        </div>
                    </div>
                    <div id="c">
                        <div className="title">Average Repair Time (7 D)</div>
                        <div className="bigUnit">26 h</div>
                    </div>
                    <div id="d">
                        <div className="title">Current Time</div>
                        <div className="bigUnit">{this.state.currentTime}</div>
                    </div>
                </div>
                <div id="bottomGridContainer">
                    <div id="timeline">
                        <div className="title">Leak Incident Timeline</div>
                        <LeakIncidentTimeline />
                    </div>
                    <div id="history">
                        <div className="title">Leak Incident History</div>
                        <Plot
                            data={this.linePlotData}
                            layout={this.linePlotLayout}
                            config={{ displaylogo: false }}
                            useResizeHandler
                            className="plot"
                        />
                    </div>
                    <div id="temperature">
                        <div className="title">Temperature (&#8451;)</div>
                        <Plot
                            data={[
                                {
                                    value: 120,
                                    type: "indicator",
                                    mode: "gauge+number",
                                    gauge: {
                                        axis: { range: [0, 200] },
                                        bar: { color: "#120078" },
                                        steps: [
                                            {
                                                range: [175, 200],
                                                color: "rgb(240, 79, 67)"
                                            },
                                            {
                                                range: [150, 175],
                                                color: "orange"
                                            }
                                        ]
                                    },
                                    number: { font: { color: "#120078" } }
                                }
                            ]}
                            layout={{
                                margin: { t: 20, b: 20, l: 40, r: 40 }
                            }}
                            config={{ displaylogo: false }}
                            useResizeHandler
                            className="plot"
                        />
                    </div>
                    <div id="pressure">
                        <div className="title">Pressure (kPa)</div>
                        <Plot
                            data={[
                                {
                                    domain: { x: [0, 1], y: [0, 1] },
                                    value: 30,
                                    type: "indicator",
                                    mode: "gauge+number",
                                    gauge: {
                                        axis: { range: [14, 50] },
                                        bar: { color: "#120078" },
                                        steps: [
                                            {
                                                range: [40, 50],
                                                color: "rgb(240, 79, 67)"
                                            },
                                            {
                                                range: [35, 40],
                                                color: "orange"
                                            }
                                        ]
                                    },
                                    number: { font: { color: "#120078" } }
                                }
                            ]}
                            layout={{
                                margin: { t: 20, b: 20, l: 40, r: 40 }
                            }}
                            config={{ displaylogo: false }}
                            useResizeHandler
                            className="plot"
                        />
                    </div>
                    <div id="pieChartHole">
                        <div className="title">Leak Incident Types</div>
                        <Plot
                            data={this.pieChartHoleTraces}
                            layout={this.pieChartHoleLayout}
                            config={{ displayModeBar: false }}
                            useResizeHandler
                            className="plot"
                        />
                    </div>
                    <div id="efficiency">
                        <div className="title">
                            Current Process Efficiencies
                        </div>
                        <ProcessEfficiencyLines />
                    </div>
                    <div id="boxPlot">
                        <div className="title">Tank Fill Levels</div>
                        <Plot
                            data={[
                                {
                                    x: ["Tank 1", "Tank 2", "Tank 3", "Tank 4"],
                                    y: [4000, 9500, 6138, 8067],
                                    error_y: {
                                        type: "data",
                                        array: [1000, 500, 800, 1200],
                                        visible: true,
                                        color: "#120078"
                                    },
                                    type: "bar",
                                    marker: {
                                        color: "rgba(230, 236, 250, 0.5)",
                                        line: {
                                            color: "#120078",
                                            width: 1.5
                                        }
                                    },
                                    hoverlabel: { bgcolor: "#120078" }
                                }
                            ]}
                            layout={{
                                margin: { l: 40, r: 20, t: 20, b: 40 },
                                showlegend: false,
                                shapes: [
                                    {
                                        type: "line",
                                        xref: "paper",
                                        x0: 0,
                                        y0: 10000,
                                        x1: 1,
                                        y1: 10000,
                                        line: {
                                            color: "rgb(240, 79, 67)",
                                            width: 1,
                                            dash: "dash"
                                        }
                                    }
                                ]
                            }}
                            config={{ displayModeBar: false }}
                            useResizeHandler
                            className="plot"
                        />
                    </div>
                    <div id="map">
                        <MapContainer
                            center={[53.5293, -113.3774]}
                            zoom={14}
                            scrollWheelZoom={false}
                            style={{ height: "100%" }}
                        >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Marker position={[53.5234, -113.3775]}>
                                <Popup>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            marginBottom: "10px",
                                            textAlign: "center"
                                        }}
                                    >
                                        Site Echo
                                    </div>
                                    <Button
                                        onClick={this.openModal}
                                        appearance="primary"
                                        block
                                    >
                                        More Details
                                    </Button>
                                </Popup>
                            </Marker>
                            <Marker position={[53.532, -113.3653]}>
                                <Popup>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            marginBottom: "10px",
                                            textAlign: "center"
                                        }}
                                    >
                                        Site Bravo
                                    </div>
                                    <Button
                                        onClick={this.openModal}
                                        appearance="primary"
                                        block
                                    >
                                        More Details
                                    </Button>
                                </Popup>
                            </Marker>
                            <Marker position={[53.5306, -113.3858]}>
                                <Popup>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            marginBottom: "10px",
                                            textAlign: "center"
                                        }}
                                    >
                                        Site Charlie
                                    </div>
                                    <Button
                                        onClick={this.openModal}
                                        appearance="primary"
                                        block
                                    >
                                        More Details
                                    </Button>
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>

                <Modal
                    show={this.state.showWorkorderModal}
                    onClose={this.closeModal}
                    size="lg"
                >
                    <Modal.Header
                        style={{ display: "inline-block", padding: "1px" }}
                    >
                        <Modal.Title>Site Leaks</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <AdvancedTable
                            height={350}
                            data={this.state.data}
                            bordered
                            cellBordered
                            wordWrap
                            initialSortColumn="id"
                        >
                            <Column
                                flexGrow={1}
                                verticalAlign="middle"
                                align="center"
                                fixed
                                sortable
                            >
                                <HeaderCell>Id</HeaderCell>
                                <Cell dataKey="id" />
                            </Column>

                            <Column width={200} sortable verticalAlign="middle">
                                <HeaderCell>Start Time</HeaderCell>
                                <Cell dataKey="startTime" />
                            </Column>

                            <Column width={200} sortable verticalAlign="middle">
                                <HeaderCell>End Time</HeaderCell>
                                <Cell dataKey="endTime" />
                            </Column>

                            <Column width={200} sortable verticalAlign="middle">
                                <HeaderCell>Gas Name</HeaderCell>
                                <Cell dataKey="gasName" />
                            </Column>
                            <Column width={120} verticalAlign="middle">
                                <HeaderCell>Action</HeaderCell>

                                <Cell>
                                    {() => {
                                        function handleAction() {
                                            console.log("Show Plot");
                                        }
                                        return (
                                            <IconButton
                                                appearance="subtle"
                                                icon={<LegacyLineChartIcon />}
                                                circle
                                                size="md"
                                                onClick={handleAction}
                                            />
                                        );
                                    }}
                                </Cell>
                            </Column>
                        </AdvancedTable>
                    </Modal.Body>

                    <Modal.Footer>
                        <p>
                            <Button
                                appearance="primary"
                                onClick={this.closeModal}
                            >
                                submit
                            </Button>
                            <Button
                                appearance="subtle"
                                onClick={this.closeModal}
                            >
                                close
                            </Button>
                        </p>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

/**
 *
 * @param {Object} state contains the state data got from API's
 * Redux - Using redux we are getting the reducers state as props
 */
const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

/**
 *
 * @param {event} dispatch contains the event to get fired
 * Redux - Using redux we are dispatching few events/actions
 * to update the state data of reducers or passing updated
 * data to different components
 */
const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
