/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:21:28
 */

import React, { Component } from "react";

import { IconButton, Whisper, Popover, Tree, Panel } from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import { connect } from "react-redux";
import * as actions from "../store/actions/auth";
import { refreshToken } from "../shared/auth";
import { getAllFunctionalities } from "../services/functionalityManagement";
import AddFunctionalityModal from "../components/modals/functionalityManagement/addFunctionalityModal";
import DeleteFunctionalityModal from "../components/modals/functionalityManagement/deleteFunctionalityModal";
import ViewAndEditFunctionalityModal from "../components/modals/functionalityManagement/viewAndEditFunctionalityModal";

class FunctionalityManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingAddFunctionalityModal: false,
            showingDeleteFunctionalityModal: false,
            showingViewAndEditFunctionalityModal: false,
            disableDelete: true,
            treeData: [
                {
                    label: "Root",
                    value: "",
                    status: "active",
                    children: []
                }
            ],
            selectedFunctionality: {
                label: "",
                value: "",
                status: "active",
                children: []
            }
        };

        this.getTreeData = this.getTreeData.bind(this);
        this.handleTreeSelect = this.handleTreeSelect.bind(this);
        this.handleAddFunctionalityButtonClick =
            this.handleAddFunctionalityButtonClick.bind(this);
        this.handleDeleteFunctionalityButtonClick =
            this.handleDeleteFunctionalityButtonClick.bind(this);
        this.handleAddFunctionalityModalClose =
            this.handleAddFunctionalityModalClose.bind(this);
        this.handleDeleteFunctionalityModalClose =
            this.handleDeleteFunctionalityModalClose.bind(this);
        this.handleViewAndEditFunctionalityModalClose =
            this.handleViewAndEditFunctionalityModalClose.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        await this.getTreeData();
    }

    async getTreeData() {
        let functionalities = await getAllFunctionalities();
        if (!functionalities) {
            return;
        }
        let treeData = [
            {
                label: "",
                value: "",
                status: "active",
                children: []
            }
        ];

        let buildingTree = true;
        let unusedFunctionalities = functionalities;
        let currentParents = [treeData[0]];
        let selectedFunctionality = {
            label: "",
            value: "",
            status: "active",
            children: []
        };
        while (buildingTree) {
            let newUnusedFunctionalities = [];
            let newCurrentParents = [];
            for (let functionality of unusedFunctionalities) {
                let functionalityUsed = false;
                for (let currentParent of currentParents) {
                    if (functionality.parentName === currentParent.label) {
                        if (functionality.type === "function") {
                            currentParent.children.push({
                                label: functionality.commonName,
                                value: functionality._id,
                                status: functionality.status,
                                data: functionality
                            });
                        } else {
                            currentParent.children.push({
                                label: functionality.name,
                                value: functionality._id,
                                status: functionality.status,
                                data: functionality,
                                children: []
                            });
                            newCurrentParents.push(
                                currentParent.children[
                                    currentParent.children.length - 1
                                ]
                            );
                        }
                        functionalityUsed = true;

                        if (
                            functionality._id ===
                            this.state.selectedFunctionality.value
                        ) {
                            selectedFunctionality =
                                currentParent.children[
                                    currentParent.children.length - 1
                                ];
                        }
                    }
                }
                if (!functionalityUsed) {
                    newUnusedFunctionalities.push(functionality);
                }
            }

            if (newCurrentParents.length === 0) {
                buildingTree = false;
            } else {
                unusedFunctionalities = newUnusedFunctionalities;
                currentParents = newCurrentParents;
            }
        }

        treeData[0].label = "Root";
        this.setState({
            treeData: treeData,
            selectedFunctionality: selectedFunctionality
        });
    }

    renderTreeNode(nodeData) {
        let nodeStyle = {};
        if (nodeData.status === "inactive") {
            nodeStyle.color = "lightgrey";
        }
        return <span style={nodeStyle}>{nodeData.label}</span>;
    }

    handleTreeSelect(activeNode, value, event) {
        let disableDelete;
        let showingViewAndEditFunctionalityModal = false;
        if (activeNode.label === "Root") {
            activeNode.label = "";
            disableDelete = true;
        } else {
            disableDelete = false;
            if (this.state.selectedFunctionality.value === value) {
                showingViewAndEditFunctionalityModal = true;
            }
        }

        this.setState({
            selectedFunctionality: activeNode,
            disableDelete: disableDelete,
            showingViewAndEditFunctionalityModal:
                showingViewAndEditFunctionalityModal
        });
    }

    handleAddFunctionalityButtonClick() {
        this.setState({ showingAddFunctionalityModal: true });
    }

    handleDeleteFunctionalityButtonClick() {
        this.setState({ showingDeleteFunctionalityModal: true });
    }

    handleAddFunctionalityModalClose() {
        this.setState({ showingAddFunctionalityModal: false });
    }

    handleDeleteFunctionalityModalClose() {
        this.setState({ showingDeleteFunctionalityModal: false });
    }

    handleViewAndEditFunctionalityModalClose() {
        this.setState({ showingViewAndEditFunctionalityModal: false });
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Functionality Management
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Here you can add and delete functions and
                                categories.
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        appearance="primary"
                    />
                </Whisper>

                {/*<ButtonToolbar>
                    <IconButton
                        icon={<LegacyPlusIcon />}
                        appearance="primary"
                        circle
                        onClick={this.handleAddFunctionalityButtonClick}
                        disabled={
                            this.state.selectedFunctionality === null ||
                            !("children" in this.state.selectedFunctionality)
                        }
                    />
                    <IconButton
                        icon={<LegacyMinusIcon />}
                        appearance="primary"
                        circle
                        onClick={this.handleDeleteFunctionalityButtonClick}
                        disabled={this.state.disableDelete}
                    />
                </ButtonToolbar>*/}

                <Panel
                    bordered
                    style={{
                        marginTop: "20px",
                        height: "calc(100vh - 157px)",
                        overflow: "auto",
                        backgroundColor: "rgb(255, 255, 255)",
                        userSelect: "none"
                    }}
                >
                    <Tree
                        data={this.state.treeData}
                        value={this.state.selectedFunctionality.value}
                        onSelect={this.handleTreeSelect}
                        renderTreeNode={this.renderTreeNode}
                        style={{ maxHeight: "none" }}
                    />
                </Panel>

                <AddFunctionalityModal
                    open={this.state.showingAddFunctionalityModal}
                    parentName={this.state.selectedFunctionality.label}
                    parentId={this.state.selectedFunctionality.value}
                    parentStatus={this.state.selectedFunctionality.status}
                    onClose={this.handleAddFunctionalityModalClose}
                    onSubmit={this.getTreeData}
                />

                <DeleteFunctionalityModal
                    open={this.state.showingDeleteFunctionalityModal}
                    onClose={this.handleDeleteFunctionalityModalClose}
                    onSubmit={this.getTreeData}
                    functionalityId={this.state.selectedFunctionality.value}
                />

                {this.state.selectedFunctionality.value !== "" ? (
                    <ViewAndEditFunctionalityModal
                        open={this.state.showingViewAndEditFunctionalityModal}
                        functionality={this.state.selectedFunctionality.data}
                        onClose={this.handleViewAndEditFunctionalityModalClose}
                        onSubmit={this.getTreeData}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionalityManagement);
