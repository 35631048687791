/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:57:24
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:22:19
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import history from "../shared/history";
import * as validators from "../shared/modelValidators";
import { authenticate } from "../services/auth";
import { Form, Button, Schema } from "rsuite";
import { getProjectDetails } from "../services/projectManagement";
import { getFunctionality } from "../services/functionalityManagement";

class Auth extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessages: {},
            formJSON: {
                username: "",
                password: ""
            }
        };

        this.handleFormKeyDown = this.handleFormKeyDown.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);

        let schema = {
            username: validators.usernameValidation,
            password: validators.passwordValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    componentDidMount() {
        // Reset url to default url if unused url is used
        history.push("/");
    }

    async handleFormKeyDown(event) {
        if (event.key === "Enter") {
            await this.handleLoginClick();
        }
    }

    async handleLoginClick() {
        if (this.form.check()) {
            let [errorMessage, data] = await authenticate(this.state.formJSON);
            if (errorMessage !== "") {
                let errorMessages = JSON.parse(
                    JSON.stringify(this.state.errorMessages)
                );
                errorMessages.serverError = errorMessage;
                this.setState({ errorMessages: errorMessages });
                return;
            }

            if (data.userDetails.userType === "SuperAdmin") {
                this.props.startUserSession(data.userDetails);
                history.replace("/clientManagement");
                return;
            }

            let assignedProjects = [];
            for (let projectID of data.userDetails.projectIDs) {
                let project = await getProjectDetails(
                    this.props.endUserSession,
                    projectID
                );
                if (project === null) {
                    continue;
                }

                let projectSteps = JSON.parse(JSON.stringify(project.steps));
                let assignedSteps = [];

                for (let step of projectSteps) {
                    if (
                        !step.authorizedRoles.includes(
                            data.userDetails.user_roleId
                        )
                    ) {
                        continue;
                    }
                    step.functionality = await getFunctionality(
                        this.props.endUserSession,
                        step.functionality_id
                    );
                    assignedSteps.push(step);
                }
                project.steps = assignedSteps;

                assignedProjects.push(project);
            }

            this.props.startUserSession(data.userDetails, assignedProjects);
            history.replace("/myProjects");
        }
    }

    render() {
        return (
            <div
                style={{
                    border: "1px solid rgb(229, 229, 234)",
                    padding: "24px",
                    width: "100%",
                    maxWidth: "600px",
                    borderRadius: "6px",
                    backgroundColor: "white"
                }}
            >
                <Form
                    ref={(forum) => (this.form = forum)}
                    fluid
                    formValue={this.state.formJSON}
                    model={this.validationModel}
                    checkTrigger="none"
                    onChange={(newFormJSON) => {
                        this.setState({
                            formJSON: newFormJSON
                        });
                    }}
                    onCheck={(errorMessages) => {
                        this.setState({
                            errorMessages: errorMessages
                        });
                    }}
                    onKeyDown={this.handleFormKeyDown}
                    formError={this.state.errorMessages}
                >
                    <Form.Group>
                        <Form.Control name="username" placeholder="Username" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="password" type="password" placeholder="Password" />
                    </Form.Group>
                </Form>
                <Button
                    block
                    appearance="primary"
                    onClick={this.handleLoginClick}
                    style={{ marginTop: "24px" }}
                >
                    Log In
                </Button>

                {/*Place for server error messages*/}
                {this.state.errorMessages.serverError ? (
                    <div
                        style={{
                            marginTop: "20px",
                            textAlign: "center",
                            color: "#f44336"
                        }}
                    >
                        {this.state.errorMessages.serverError}
                    </div>
                ) : null}

                <a
                    href="/forgotPassword"
                    style={{
                        display: "inline-block",
                        marginTop: "20px",
                        marginLeft: "calc(50% - 56.2655px)"
                    }}
                >
                    Forgot Password?
                </a>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        assignedProjects: state.auth.assignedProjects
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startUserSession: (userDetails, stepsData, assignedProjects) =>
            dispatch(
                actions.startUserSession(
                    userDetails,
                    stepsData,
                    assignedProjects
                )
            )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
