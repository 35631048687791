/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2021-04-22 20:15:27
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 17:24:50
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { IconButton, Whisper, Popover, Container, Col, Row } from "rsuite";
import { toaster, Message } from "rsuite";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import axios from "axios";
import { refreshToken } from "../../shared/auth";
import * as actions from "../../store/actions/auth";
import BaseURL from "../../api-common";
import HMIDiagram from "../../components/Overview";
import { withRouter } from "react-router-dom";

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helpActive: false,
            sideNavExpanded: true,
            graphData: {},
            graphPositions: {},
            graphHideShowInfo: {},
            units: {},
            currentLiveData: {},
            validatorData: {},
            timeOut: null,
            diagramGrid: 24,
            setPointGrid: 24,
            firstTimeLoad: true,
            skipCount: 4320,
            delayTime: 500,
            timeZoneOffset: new Date().getTimezoneOffset() * 60 * 1000,

            line1: {
                x: [-3, -2, -1],
                y: [1, 2, 3],
                name: "Line 1"
            },
            line2: {
                x: [1, 2, 3],
                y: [-3, -2, -1],
                name: "Line 2"
            },
            layout: {
                datarevision: 0
            },
            revision: 0,
            layout: {
                hovermode: "x unified",
                hoverdistance: 1,
                title: {
                    text: null
                },
                responsive: true,
                uirevision: true,
                yaxis: {
                    title: {
                        text: null
                    }
                },
                xaxis: {
                    title: {
                        text: "Time"
                    },
                    type: "date"
                }
            }
        };

        this.handleExpandToggle = this.handleExpandToggle.bind(this);
        this.handleRelayout = this.handleRelayout.bind(this);
        this.handleTagClick = this.handleTagClick.bind(this);

        this.timeOut = setInterval(async () => {
            // await refreshToken(this.props.endUserSession);
            // for updating the opened graph dynamically with live data
            let graphHideShowInfo = this.state.graphHideShowInfo;
            let activeGraphs = [];
            let key;
            for (key in graphHideShowInfo) {
                if (graphHideShowInfo[key]) {
                    activeGraphs.push(key);
                    graphHideShowInfo[key] = false;
                }
            }

            this.setState({
                graphHideShowInfo: graphHideShowInfo
            });
            // calling to keep the opened graph still open
            for (key = 0; key < activeGraphs.length; key++) {
                this.handleTagClick("", true, activeGraphs[key]);
            }
            this.getLiveData();
        }, this.state.delayTime);
    }

    async componentDidMount() {
        setInterval(this.increaseGraphic, 1000);
        await refreshToken(this.props.endUserSession);

        this.getLiveData();
        // we are using skipCount to fastly fetch some historic data to show in graph
        // that is why we are setting delay to 100milliseconds
        this.setState({
            skipCount: this.state.skipCount - 1
        });
    }
    rand = () => parseInt(Math.random() * 10 + this.state.revision, 10);
    increaseGraphic = () => {
        const { line1, line2, layout } = this.state;
        line1.x.push(this.rand());
        line1.y.push(this.rand());
        if (line1.x.length >= 10) {
            line1.x.shift();
            line1.y.shift();
        }
        line2.x.push(this.rand());
        line2.y.push(this.rand());
        if (line2.x.length >= 10) {
            line2.x.shift();
            line2.y.shift();
        }
        this.setState({ revision: this.state.revision + 1 });
        layout.datarevision = this.state.revision + 1;
    };

    async componentWillUnmount() {
        clearInterval(this.timeOut);
    }

    async getLiveData() {
        try {
            let skipCount = this.state.skipCount;
            // once the skipCount reaches to 0 we are resetting the delay to 5000milliseconds
            if (this.state.skipCount == 0) {
                clearInterval(this.timeOut);
                let delayTime = 500;

                this.setState({
                    skipCount: 4320,
                    timeOut: setInterval(async () => {
                        // await refreshToken(this.props.endUserSession);
                        // for updating the opened graph dynamically with live data
                        let graphHideShowInfo = this.state.graphHideShowInfo;
                        let activeGraphs = [];
                        let key;
                        for (key in graphHideShowInfo) {
                            if (graphHideShowInfo[key]) {
                                activeGraphs.push(key);
                                graphHideShowInfo[key] = false;
                            }
                        }

                        this.setState({
                            graphHideShowInfo: graphHideShowInfo
                        });
                        // calling to keep the opened graph still open
                        for (key = 0; key < activeGraphs.length; key++) {
                            this.handleTagClick("", true, activeGraphs[key]);
                        }
                        this.getLiveData();
                    }, delayTime)
                });
            } else {
                skipCount = this.state.skipCount - 1;
            }

            let url =
                BaseURL.getBaseURL() +
                this.props.location.pathname +
                "/overView/getLiveData";

            let res = await axios({
                method: "post",
                url: url,
                withCredentials: true,
                data: {
                    skipCount: this.state.skipCount
                }
            });
            if (res.data.msg === "UNAUTHORIZED") {
                this.props.endUserSession();
                return;
            } else if (res.data.statusId === "ERROR") {
                throw "The server has encountered an error.";
            } else if (
                res.data.hasOwnProperty("isValidData") &&
                !res.data.isValidData
            ) {
                throw "The get Overview request is invalid.";
            }
            // this.setFormData(res.data.tagsInfo.inputs);
            let tagData = res.data.tagData;
            let graphData = this.state.graphData;
            if (Object.keys(tagData).length) {
                for (let key in res.data.tagData) {
                    if (key != "Time") {
                        if (
                            graphData.hasOwnProperty(key) &&
                            Object.keys(graphData[key]).length
                        ) {
                            if (
                                tagData.hasOwnProperty(key + "_predicted") ||
                                key.includes("_predicted")
                            ) {
                                key = key.replace("_predicted", "");
                                // if current record time is new
                                let prevTime =
                                    graphData[key][0].x[
                                        graphData[key][0].x.length - 1
                                    ];
                                let curTime = tagData["Time"];
                                if (prevTime != curTime) {
                                    graphData[key][0].x.push(
                                        tagData["Time"] +
                                            this.state.timeZoneOffset
                                    );
                                    graphData[key][0].y.push(
                                        Number(tagData[key])
                                    );
                                    graphData[key][1].x.push(
                                        tagData["Time"] +
                                            this.state.timeZoneOffset
                                    );
                                    graphData[key][1].y.push(
                                        Number(tagData[key + "_predicted"])
                                    );
                                    graphData[key][2].x.push(
                                        tagData["Time"] +
                                            this.state.timeZoneOffset
                                    );
                                    graphData[key][2].y.push(
                                        res.data.units[key].lowLimit
                                    );
                                    graphData[key][3].x.push(
                                        tagData["Time"] +
                                            this.state.timeZoneOffset
                                    );

                                    graphData[key][3].y.push(
                                        res.data.units[key].highLimit
                                    );
                                    // showing only past 30 records
                                    if (graphData[key][0].y.length > 30) {
                                        graphData[key][0].x.shift();
                                        graphData[key][0].y.shift();
                                        graphData[key][1].x.shift();
                                        graphData[key][1].y.shift();
                                        graphData[key][2].x.shift();
                                        graphData[key][2].y.shift();
                                        graphData[key][3].x.shift();
                                        graphData[key][3].y.shift();
                                    }

                                    graphData[key + "_predicted"][0].x.push(
                                        tagData["Time"] +
                                            this.state.timeZoneOffset
                                    );
                                    graphData[key + "_predicted"][0].y.push(
                                        Number(tagData[key])
                                    );
                                    graphData[key + "_predicted"][1].x.push(
                                        tagData["Time"] +
                                            this.state.timeZoneOffset
                                    );
                                    graphData[key + "_predicted"][1].y.push(
                                        Number(tagData[key + "_predicted"])
                                    );
                                    graphData[key + "_predicted"][2].x.push(
                                        tagData["Time"] +
                                            this.state.timeZoneOffset
                                    );
                                    graphData[key + "_predicted"][2].y.push(
                                        res.data.units[key].lowLimit
                                    );
                                    graphData[key + "_predicted"][3].x.push(
                                        tagData["Time"] +
                                            this.state.timeZoneOffset
                                    );

                                    graphData[key + "_predicted"][3].y.push(
                                        res.data.units[key].highLimit
                                    );
                                    // showing only past 30 records
                                    if (
                                        graphData[key + "_predicted"][0].y
                                            .length > 30
                                    ) {
                                        graphData[
                                            key + "_predicted"
                                        ][0].x.shift();
                                        graphData[
                                            key + "_predicted"
                                        ][0].y.shift();
                                        graphData[
                                            key + "_predicted"
                                        ][1].x.shift();
                                        graphData[
                                            key + "_predicted"
                                        ][1].y.shift();
                                        graphData[
                                            key + "_predicted"
                                        ][2].x.shift();
                                        graphData[
                                            key + "_predicted"
                                        ][2].y.shift();
                                        graphData[
                                            key + "_predicted"
                                        ][3].x.shift();
                                        graphData[
                                            key + "_predicted"
                                        ][3].y.shift();
                                    }
                                }
                            } else {
                                if (Array.isArray(graphData[key])) {
                                    // if current record time is new

                                    let prevTime =
                                        graphData[key][0].x[
                                            graphData[key][0].x.length - 1
                                        ];
                                    let curTime = tagData["Time"];
                                    if (prevTime != curTime) {
                                        graphData[key][0].x.push(
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        );
                                        graphData[key][0].y.push(tagData[key]);
                                        graphData[key][1].x.push(
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        );
                                        graphData[key][1].y.push(
                                            res.data.units[key].lowLimit
                                        );
                                        graphData[key][2].x.push(
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        );

                                        graphData[key][2].y.push(
                                            res.data.units[key].highLimit
                                        );
                                        if (graphData[key][0].y.length > 30) {
                                            graphData[key][0].x.shift();
                                            graphData[key][0].y.shift();
                                            graphData[key][1].x.shift();
                                            graphData[key][1].y.shift();
                                            graphData[key][2].x.shift();
                                            graphData[key][2].y.shift();
                                        }
                                    }
                                } else {
                                    // if current record time is new
                                    let prevTime =
                                        graphData[key].x[
                                            graphData[key].x.length - 1
                                        ];
                                    let curTime = tagData["Time"];
                                    if (prevTime != curTime) {
                                        graphData[key].x.push(
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        );
                                        graphData[key].y.push(tagData[key]);
                                        // showing only past 30 records
                                        if (graphData[key].y.length > 30) {
                                            graphData[key].x.shift();
                                            graphData[key].y.shift();
                                        }
                                    }
                                }
                            }
                        } else {
                            if (tagData.hasOwnProperty(key + "_predicted")) {
                                graphData[key] = [
                                    {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [Number(tagData[key])],
                                        name: "Actual"
                                        // showlegend: false,
                                        // mode: "lines+markers",
                                        // hovertemplate: "%{y:.2f}"
                                        // mode: "markers+lines",
                                        // hoverinfo: "text"
                                        // mode: "markers",
                                        // marker: { size: 16 }
                                    },
                                    {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [
                                            Number(tagData[key + "_predicted"])
                                        ],
                                        name: "Predicted"
                                        // showlegend: false,
                                        // mode: "lines+markers",
                                        // hovertemplate: "%{y:.2f}"
                                        // mode: "markers+lines",
                                        // hoverinfo: "text"
                                        // mode: "markers",
                                        // marker: { size: 16 }
                                    },
                                    {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [res.data.units[key].lowLimit],
                                        name: "Low Limit",
                                        line: {
                                            dash: "dashdot",
                                            color: "grey"
                                        }
                                    },
                                    {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [res.data.units[key].highLimit],
                                        name: "High Limit",
                                        line: {
                                            dash: "dashdot",
                                            color: "grey"
                                        }
                                    }
                                ];
                                graphData[key + "_predicted"] = [
                                    {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [Number(tagData[key])],
                                        name: "Actual"
                                        // showlegend: false,
                                        // mode: "lines+markers"
                                        // mode: "markers",
                                        // marker: { size: 16 }
                                    },
                                    {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [
                                            Number(tagData[key + "_predicted"])
                                        ],
                                        name: "Predicted"
                                        // showlegend: false,
                                        // mode: "lines+markers"
                                        // mode: "markers",
                                        // marker: { size: 16 }
                                    },
                                    {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [res.data.units[key].lowLimit],
                                        name: "Low Limit",
                                        line: {
                                            dash: "dashdot",
                                            color: "grey"
                                        }
                                    },
                                    {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [res.data.units[key].highLimit],
                                        name: "High Limit",
                                        line: {
                                            dash: "dashdot",
                                            color: "grey"
                                        }
                                    }
                                ];
                            } else {
                                if (res.data.units.hasOwnProperty(key)) {
                                    if (
                                        res.data.units[key].hasOwnProperty(
                                            "lowLimit"
                                        ) &&
                                        res.data.units[key].hasOwnProperty(
                                            "highLimit"
                                        )
                                    ) {
                                        graphData[key] = [
                                            {
                                                mode: "lines+markers",
                                                x: [
                                                    tagData["Time"] +
                                                        this.state
                                                            .timeZoneOffset
                                                ],
                                                y: [tagData[key]],
                                                name: key
                                                // showlegend: false,
                                                // mode: "lines+markers"
                                                // mode: "markers",
                                                // marker: { size: 16 }
                                            },
                                            {
                                                mode: "lines+markers",
                                                x: [
                                                    tagData["Time"] +
                                                        this.state
                                                            .timeZoneOffset
                                                ],
                                                y: [
                                                    res.data.units[key].lowLimit
                                                ],
                                                name: "Low Limit",
                                                line: {
                                                    dash: "dashdot",
                                                    color: "grey"
                                                }
                                            },
                                            {
                                                mode: "lines+markers",
                                                x: [
                                                    tagData["Time"] +
                                                        this.state
                                                            .timeZoneOffset
                                                ],
                                                y: [
                                                    res.data.units[key]
                                                        .highLimit
                                                ],
                                                name: "High Limit",
                                                line: {
                                                    dash: "dashdot",
                                                    color: "grey"
                                                }
                                            }
                                        ];
                                    } else {
                                        graphData[key] = {
                                            mode: "lines+markers",
                                            x: [
                                                tagData["Time"] +
                                                    this.state.timeZoneOffset
                                            ],
                                            y: [tagData[key]],
                                            name: key
                                            // showlegend: false,
                                            // mode: "lines+markers"
                                            // mode: "markers",
                                            // marker: { size: 16 }
                                        };
                                    }
                                } else {
                                    graphData[key] = {
                                        mode: "lines+markers",
                                        x: [
                                            tagData["Time"] +
                                                this.state.timeZoneOffset
                                        ],
                                        y: [tagData[key]]
                                        // showlegend: false,
                                        // mode: "lines+markers"
                                        // mode: "markers",
                                        // marker: { size: 16 }
                                    };
                                }
                            }
                        }
                    }
                }
            }
            if (this.state.firstTimeLoad) {
                // this.handleTagClick("", true, "Cyclone Overflow P80");
            }
            this.setState({
                skipCount: skipCount,
                units: res.data.units,
                currentLiveData: res.data.tagData,
                graphData: graphData,
                firstTimeLoad: false
            });
        } catch (e) {
            toaster.push(
                <Message type="error" showIcon closable duration={10000}>
                    {
                        <text>
                            Data could not be updated
                            <br />
                            Error: {e.toString()}
                        </text>
                    }
                </Message>
            );
        }
    }

    handleRelayout(event) {
        // when zoom in
        if (event.hasOwnProperty("xaxis.range[0]")) {
            let start = event["xaxis.range[0]"];
            let end = event["xaxis.range[1]"];
            this.setState({
                zoomReset: false,
                currentRange: [start, end]
            });
        }
        // when moving with range slider
        if (event.hasOwnProperty("xaxis.range")) {
            let start = event["xaxis.range"][0];
            let end = event["xaxis.range"][1];
            this.setState({
                zoomReset: false,
                currentRange: [start, end]
            });
        }
        // when resetting the zoom
        if (event.hasOwnProperty("xaxis.autorange")) {
            if (this.props.predictions.length) {
                this.setState({
                    zoomReset: true,
                    currentRange: [
                        this.props.predictions[0].x[0],
                        this.props.predictions[0].x[1]
                    ]
                });
            }
        }
    }

    handleExpandToggle(expanded) {
        this.setState({ sideNavExpanded: expanded });
    }

    handleTagClick(e, flag = false, targetTagBrowseName) {
        let transformInfo;
        if (flag) {
            try {
                transformInfo = document
                    .getElementById(targetTagBrowseName + "_value")
                    .getAttribute("transform");
            } catch (err) {
                // Catch "Not Found" error if screen switches before this
                // function finishes
                return;
            }
        } else {
            targetTagBrowseName = e.target.getAttribute("name");
            /**
             * replacing the value tag name with empty.
             * Otherwise it will throw an error as the tag doesn't exists with _value
             */
            targetTagBrowseName = targetTagBrowseName.replace("_value", "");
            transformInfo = e.target.getAttribute("transform");
        }
        let graphPositions = this.state.graphPositions;
        let graphHideShowInfo = this.state.graphHideShowInfo;
        transformInfo = transformInfo.replace("(", " ");
        transformInfo = transformInfo.replace(")", " ");
        transformInfo = transformInfo.split(" ");
        let x = Number(transformInfo[transformInfo.length - 3]);
        let y = Number(transformInfo[transformInfo.length - 2]);
        if (targetTagBrowseName == "Cyclone Overflow P80") {
            // x = x + 50;
            y = y + 80;
            if (x + 750 > 1920) {
                x = x - (x + 628 - 1920);
            }
            if (y + 550 > 1080) {
                y = y - (y + 550 - 1080);
            }
        } else {
            if (x + 750 > 1920) {
                x = x - (x + 750 - 1920);
            }
            if (y + 550 > 1080) {
                y = y - (y + 550 - 1080);
            }
        }
        if (graphPositions.hasOwnProperty(targetTagBrowseName)) {
            graphPositions[targetTagBrowseName].x = Number(x);
            graphPositions[targetTagBrowseName].y = Number(y);
        } else {
            graphPositions[targetTagBrowseName] = {};
            graphPositions[targetTagBrowseName].x = Number(x);
            graphPositions[targetTagBrowseName].y = Number(y);
        }
        if (graphHideShowInfo.hasOwnProperty(targetTagBrowseName)) {
            graphHideShowInfo[targetTagBrowseName] =
                !graphHideShowInfo[targetTagBrowseName];
        } else {
            graphHideShowInfo[targetTagBrowseName] = true;
        }
        this.setState({
            graphPositions: graphPositions,
            graphHideShowInfo: graphHideShowInfo
        });
    }

    render() {
        let units = "µm";
        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Overview
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>
                                Operator can see the HMI interface and see the
                                realtime value along with trends and
                                recommendations
                            </p>
                        </Popover>
                    }
                >
                    <IconButton
                        size="sm"
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "6px",
                            marginLeft: "10px"
                        }}
                        color="primary"
                    />
                </Whisper>

                <Container>
                    <Row style={{ marginTop: "-2%" }}>
                        <Col md={this.state.diagramGrid}>
                            <HMIDiagram
                                width="100%"
                                height="100%"
                                onItemClick={(e) => {
                                    this.handleTagClick(e);
                                }}
                                graphHideShowInfo={this.state.graphHideShowInfo}
                                graphPositions={this.state.graphPositions}
                                currentLiveData={this.state.currentLiveData}
                                layout={this.state.layout}
                                units={this.state.units}
                                graphData={this.state.graphData}
                                onRelayout={this.handleRelayout}
                            ></HMIDiagram>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

/**
 *
 * @param {Object} state contains the state data got from API's
 * Redux - Using redux we are getting the reducers state as props
 */
const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        authRedirectPath: state.auth.authRedirectPath,
        userDetails: state.auth.userDetails
    };
};

/**
 *
 * @param {event} dispatch contains the event to get fired
 * Redux - Using redux we are dispatching few events/actions
 * to update the state data of reducers or passing updated
 * data to different components
 */
const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Overview)
);
