import BaseURL from "../api-common";
import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";

export async function getAllRoles(endUserSession) {
    try {
        let url = BaseURL.getBaseURL() + "/roleManagement/getAllRoles";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.data.isValidData) {
            throw new Error("The user get request is invalid.");
        }
        return res.data.data.allRoles;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        The roles could not be obtained
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getProjectsForRoleMapping(endUserSession) {
    try {
        let url =
            BaseURL.getBaseURL() + "/roleManagement/getProjectsForRoleMapping";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.data.isValidData) {
            throw new Error("The user get request is invalid.");
        }
        return res.data.data;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={10000}>
                {
                    <text>
                        The projects for role mapping could not be obtained
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}
