/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2021-04-22 20:24:56
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-22 17:22:56
 */

import React from "react";
import Plot from "react-plotly.js";
import "../containers/Overview/Overview.css";
import Auxiliary from "../hoc/Auxiliary/Auxiliary";

const trimValue = (value) => {
    if (value != undefined && value != "null" && String(value).length > 0) {
        // if value is between 0-1 then in some cases the value is after 4/5 decimal places. so we are giving upto 7 decimal places
        let constructValue;
        if (value == "Comm Fail") {
            return value;
        } else {
            if (Number(value) < 1 && Number(value) > 0) {
                constructValue = value.toString().match(/^-?\d+(?:\.\d{0,7})?/);
                if (Array.isArray(constructValue)) {
                    return constructValue[0];
                }
                return constructValue;
            } else {
                constructValue = value.toString().match(/^-?\d+(?:\.\d{0,2})?/);
                if (Array.isArray(constructValue)) {
                    return constructValue[0];
                }
                return constructValue;
            }
        }
    }
};

const overview = (props) => {
    let graphs = [];
    let graphKey;
    let currentLiveTime = null;
    if (props.currentLiveData.hasOwnProperty("Time")) {
        // currentLiveTime = new Date(props.currentLiveData["Time"]);
        // converting the date to string
        currentLiveTime = new Date(props.currentLiveData["Time"])
            .toISOString()
            .split("T");
        currentLiveTime =
            currentLiveTime[0] + " " + currentLiveTime[1].split(".000Z")[0];
    }
    let units;
    let layout;
    let popupWidth;
    let popupHeight;
    graphs.push(
        <foreignObject
            key="Cyclone Overflow P80_predicted_gauge"
            x={"1310px"}
            y={"220px"}
            width={"630"}
            height={"570"}
            fill="#1d4870"
            style={{ display: "block" }}
        >
            <div width="100%" height="100%" fill="#1d4870">
                <div
                    style={{
                        backgroundColor: "white",
                        marginTop: "30px"
                    }}
                >
                    <Plot
                        data={[
                            {
                                type: "indicator",
                                mode: "gauge+number",
                                value: trimValue(
                                    props.currentLiveData[
                                        "Cyclone Overflow P80_predicted"
                                    ]
                                ),
                                title: {
                                    text: "Prediction",
                                    text:
                                        Object.keys(props.units).length &&
                                        props.units.hasOwnProperty(
                                            "Cyclone Overflow P80"
                                        )
                                            ? "P80 Prediction (" +
                                              props.units[
                                                  "Cyclone Overflow P80"
                                              ].units +
                                              ")"
                                            : "P80 Prediction",
                                    font: { size: 24 }
                                },
                                gauge: {
                                    shape: "angular",
                                    axis: {
                                        range: [null, 200],
                                        tickwidth: 1,
                                        tickcolor: "darkblue"
                                    },
                                    bar: { color: "darkblue" },
                                    bgcolor: "white",
                                    borderwidth: 2,
                                    bordercolor: "gray",
                                    steps: [
                                        {
                                            range: [0, 75],
                                            color: "crimson"
                                        },
                                        {
                                            range: [75, 125],
                                            color: "green"
                                        },
                                        {
                                            range: [125, 200],
                                            color: "crimson"
                                        }
                                    ]
                                }
                            }
                        ]}
                        layout={{
                            paper_bgcolor: "#e5e5ea",
                            font: {
                                color: "darkblue",
                                family: "Arial"
                            }
                        }}
                        config={{
                            displaylogo: false,
                            displayModeBar: false
                        }}
                        style={{
                            width: "100%",
                            height: "calc(100% - 45px)"
                        }}
                    ></Plot>
                </div>
            </div>
        </foreignObject>
    );
    for (graphKey in props.graphHideShowInfo) {
        if (
            props.graphHideShowInfo[graphKey] &&
            props.graphData.hasOwnProperty(graphKey) &&
            Object.keys(props.graphData[graphKey]).length
        ) {
            layout = JSON.parse(JSON.stringify(props.layout));

            units =
                Object.keys(props.units).length &&
                props.units.hasOwnProperty(graphKey)
                    ? props.units[graphKey].units
                    : null;
            layout.title.text = graphKey + " (" + units + ")";
            layout.yaxis.title.text = graphKey + " (" + units + ")";
            if (graphKey === "Cyclone Overflow P80") {
                popupWidth = 630;
                popupHeight = 570;
            } else {
                popupWidth = 750;
                popupHeight = 570;
            }
            graphs.push(
                <foreignObject
                    key={graphKey + "_graph_graph"}
                    x={props.graphPositions[graphKey].x + "px"}
                    y={props.graphPositions[graphKey].y + "px"}
                    width={popupWidth}
                    height={popupHeight}
                    fill="#1d4870"
                    style={{ display: "block" }}
                >
                    <div
                        style={{
                            position: "relative",
                            display: "inline-block",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#1d4870",
                            border: "3px solid grey"
                        }}
                    >
                        <div
                            style={{
                                width: "inherit",
                                height: "inherit",
                                color: "#fff",
                                borderRadius: "6px",
                                padding: "8px 0",
                                position: "absolute",
                                zIndex: "1"
                            }}
                        >
                            <div
                                width="100%"
                                height="100%"
                                fill="#1d4870"
                            ></div>
                            <div
                                id={graphKey + "_info"}
                                style={{ fontSize: "22px" }}
                            >
                                <div
                                    x="420"
                                    y="20"
                                    fill="white"
                                    fontSize="30"
                                    style={{ marginBottom: "0px" }}
                                >
                                    TIME: {currentLiveTime}
                                </div>
                                <div x="420" y="40" fill="white" fontSize="30">
                                    {graphKey} :{" "}
                                    {/* {logic to show the proper values when user click on actual tag & predicted tag in the UI} */}
                                    {Array.isArray(props.graphData[graphKey])
                                        ? graphKey.includes("_predicted")
                                            ? trimValue(
                                                  props.graphData[graphKey][1]
                                                      .y[
                                                      props.graphData[
                                                          graphKey
                                                      ][1].y.length - 1
                                                  ]
                                              ) +
                                              " (" +
                                              units +
                                              ") " +
                                              "Actual: " +
                                              trimValue(
                                                  props.graphData[graphKey][0]
                                                      .y[
                                                      props.graphData[
                                                          graphKey
                                                      ][0].y.length - 1
                                                  ]
                                              ) +
                                              " (" +
                                              units +
                                              ")"
                                            : trimValue(
                                                  props.graphData[graphKey][0]
                                                      .y[
                                                      props.graphData[
                                                          graphKey
                                                      ][0].y.length - 1
                                                  ]
                                              )
                                        : trimValue(
                                              props.graphData[graphKey].y[
                                                  props.graphData[graphKey].y
                                                      .length - 1
                                              ]
                                          )}{" "}
                                    {Object.keys(props.units).length &&
                                    props.units.hasOwnProperty(graphKey)
                                        ? "(" +
                                          props.units[graphKey].units +
                                          ")"
                                        : null}{" "}
                                    {props.graphData.hasOwnProperty(
                                        graphKey + "_predicted"
                                    )
                                        ? "Predicted: " +
                                          trimValue(
                                              props.graphData[
                                                  graphKey + "_predicted"
                                              ][1].y[
                                                  props.graphData[
                                                      graphKey + "_predicted"
                                                  ][1].y.length - 1
                                              ]
                                          ) +
                                          " (" +
                                          units +
                                          ")"
                                        : null}
                                </div>
                            </div>
                            {graphKey !== "Cyclone Overflow P80" ? (
                                <Auxiliary>
                                    <div
                                        className="tclose"
                                        fill="black"
                                        stroke="black"
                                        strokeMiterlimit="10"
                                        cx="493"
                                        cy="12"
                                        r="20"
                                        name={graphKey}
                                        onClick={props.onItemClick}
                                    ></div>
                                    <span
                                        className="tclose"
                                        transform="matrix(1 0 0 1 482 20)"
                                        fill="white"
                                        name={graphKey}
                                        onClick={props.onItemClick}
                                    >
                                        X
                                    </span>
                                </Auxiliary>
                            ) : null}

                            <div width="60%" height="100%" fill="#1d4870">
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        marginTop: "30px"
                                    }}
                                >
                                    <Plot
                                        data={
                                            Array.isArray(
                                                props.graphData[graphKey]
                                            )
                                                ? props.graphData[graphKey]
                                                : [props.graphData[graphKey]]
                                        }
                                        layout={
                                            layout
                                            // {
                                            // hovermode: "x unified",
                                            // hoverdistance: 1,
                                            // title: {
                                            //     text:
                                            //         graphKey +
                                            //         " (" +
                                            //         units +
                                            //         ")"
                                            // },
                                            // responsive: true,
                                            // uirevision: true,
                                            // yaxis: {
                                            //     title: {
                                            //         text:
                                            //             graphKey +
                                            //             " (" +
                                            //             units +
                                            //             ")"
                                            //     }
                                            // },
                                            // xaxis: {
                                            //     title: {
                                            //         text: "Time"
                                            //     },
                                            //     type: "date"
                                            // }
                                            // }
                                        }
                                        useResizeHandler
                                        style={{
                                            width: "100%",
                                            height: "calc(100% - 45px)"
                                        }}
                                        config={{
                                            displaylogo: false,
                                            displayModeBar: false
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </foreignObject>
            );
        }
    }
    return (
        <svg
            key="Layer_1"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1920 1080"
            style={{ enableBackground: "new 0 0 1920 1080" }}
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <polygon
                        className="st0"
                        points="410.5,392.5 369,469 370.5,221.5 410.5,305.5 		"
                    />
                    <rect
                        x="410.5"
                        y="296.5"
                        className="st0"
                        width="5"
                        strokeWidth="5"
                        height="105"
                    />
                    <rect
                        x="415.5"
                        y="310.5"
                        className="st0"
                        width="35"
                        height="76"
                    />
                </g>
                <g>
                    <g>
                        <rect
                            x="181.5"
                            y="221.5"
                            className="st1"
                            strokeWidth="5"
                            width="188"
                            height="248"
                        />
                        <path d="M369,222v247H182V222H369 M370,221H181v249h189V221L370,221z" />
                    </g>
                    <rect
                        x="196.5"
                        y="202.5"
                        transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 400 697)"
                        className="st2"
                        width="7"
                        height="292"
                    />
                    <g>
                        <g>
                            <circle className="st3" cx="225" cy="243" r="7.5" />
                            <path
                                d="M225,236c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S221.14,236,225,236 M225,235c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S229.42,235,225,235L225,235z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="283" cy="243" r="7.5" />
                            <path
                                d="M283,236c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S279.14,236,283,236 M283,235c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S287.42,235,283,235L283,235z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="343" cy="243" r="7.5" />
                            <path
                                d="M343,236c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S339.14,236,343,236 M343,235c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S347.42,235,343,235L343,235z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="226" cy="288" r="7.5" />
                            <path
                                d="M226,281c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S222.14,281,226,281 M226,280c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S230.42,280,226,280L226,280z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="284" cy="288" r="7.5" />
                            <path
                                d="M284,281c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S280.14,281,284,281 M284,280c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S288.42,280,284,280L284,280z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="344" cy="288" r="7.5" />
                            <path
                                d="M344,281c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S340.14,281,344,281 M344,280c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S348.42,280,344,280L344,280z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="226" cy="329" r="7.5" />
                            <path
                                d="M226,322c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S222.14,322,226,322 M226,321c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S230.42,321,226,321L226,321z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="284" cy="329" r="7.5" />
                            <path
                                d="M284,322c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S280.14,322,284,322 M284,321c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S288.42,321,284,321L284,321z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="344" cy="329" r="7.5" />
                            <path
                                d="M344,322c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S340.14,322,344,322 M344,321c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S348.42,321,344,321L344,321z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="227" cy="366" r="7.5" />
                            <path
                                d="M227,359c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S223.14,359,227,359 M227,358c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S231.42,358,227,358L227,358z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="285" cy="366" r="7.5" />
                            <path
                                d="M285,359c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S281.14,359,285,359 M285,358c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S289.42,358,285,358L285,358z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="345" cy="366" r="7.5" />
                            <path
                                d="M345,359c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S341.14,359,345,359 M345,358c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S349.42,358,345,358L345,358z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="228" cy="411" r="7.5" />
                            <path
                                d="M228,404c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S224.14,404,228,404 M228,403c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S232.42,403,228,403L228,403z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="286" cy="411" r="7.5" />
                            <path
                                d="M286,404c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S282.14,404,286,404 M286,403c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S290.42,403,286,403L286,403z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="346" cy="411" r="7.5" />
                            <path
                                d="M346,404c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S342.14,404,346,404 M346,403c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S350.42,403,346,403L346,403z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="228" cy="452" r="7.5" />
                            <path
                                d="M228,445c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S224.14,445,228,445 M228,444c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S232.42,444,228,444L228,444z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="286" cy="452" r="7.5" />
                            <path
                                d="M286,445c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S282.14,445,286,445 M286,444c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S290.42,444,286,444L286,444z"
                            />
                        </g>
                        <g>
                            <circle className="st3" cx="346" cy="452" r="7.5" />
                            <path
                                d="M346,445c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S342.14,445,346,445 M346,444c-4.42,0-8,3.58-8,8s3.58,8,8,8
					s8-3.58,8-8S350.42,444,346,444L346,444z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <polygon
                        className="st0"
                        points="133,374.5 182.1,469.18 180.31,221.68 133,314.5 		"
                    />
                    <rect
                        x="127.5"
                        y="306.5"
                        className="st0"
                        width="5"
                        height="76"
                    />
                    <g>
                        <path d="M127,315v48.62L111.37,315H127 M128,314h-18l18,56V314L128,314z M128,370v7V370L128,370z" />
                    </g>
                </g>
            </g>
            {/* <g>
                <g>
                    <g>
                        <path d="M1289,595v96h-47v-96H1289 M1290,594h-49v98h49V594L1290,594z" />
                    </g>
                    <g>
                        <path d="M1336.64,447l-45.38,146h-50.56l-51.3-146H1336.64 M1338,446h-150l52,148h52L1338,446L1338,446z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M1188,316v47h-79v-47H1188 M1189,315h-81v49h81V315L1189,315z" />
                    </g>
                    <g>
                        <line
                            className="st0"
                            x1="1149.5"
                            y1="315.5"
                            x2="1149.5"
                            y2="364.5"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M1337,404v41h-148v-41H1337 M1338,403h-150v43h150V403L1338,403z" />
                    </g>
                    <line
                        className="st4"
                        x1="1184"
                        y1="445.5"
                        x2="1342"
                        y2="445.5"
                    />
                </g>
                <g>
                    <g>
                        <path d="M1337,276v126h-148V276H1337 M1338,275h-150v128h150V275L1338,275z" />
                    </g>
                    <line
                        className="st4"
                        x1="1184"
                        y1="402.5"
                        x2="1342"
                        y2="402.5"
                    />
                    <line
                        className="st4"
                        x1="1181"
                        y1="274.5"
                        x2="1344"
                        y2="274.5"
                    />
                </g>
                <g>
                    <g>
                        <path d="M1278,224v50h-26v-50H1278 M1279,223h-28v52h28V223L1279,223z" />
                    </g>
                    <g>
                        <path d="M1329,197v26h-50v-26H1329 M1330,196h-52v28h52V196L1330,196z" />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1251.5,223.5c0,0,0-15,7-21c8.07-6.92,21-7,21-7"
                        />
                    </g>
                </g>
            </g> */}
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                strokeWidth="3"
                                stroke="#000000"
                                d="M1192,597v96h-47v-96H1192 M1193,596h-49v98h49V596L1193,596z"
                            />
                        </g>
                        <g>
                            <path
                                strokeWidth="3"
                                stroke="#000000"
                                d="M1239.64,449l-45.38,146h-50.56l-51.3-146H1239.64 M1241,448h-150l52,148h52L1241,448L1241,448z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                strokeWidth="3"
                                stroke="#000000"
                                d="M1091,318v47h-79v-47H1091 M1092,317h-81v49h81V317L1092,317z"
                            />
                        </g>
                        <g>
                            <line
                                className="st0"
                                x1="1052.5"
                                y1="317.5"
                                x2="1052.5"
                                y2="366.5"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                strokeWidth="3"
                                stroke="#000000"
                                d="M1240,406v41h-148v-41H1240 M1241,405h-150v43h150V405L1241,405z"
                            />
                        </g>
                        <line
                            className="st7"
                            x1="1087"
                            y1="447.5"
                            x2="1245"
                            y2="447.5"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                strokeWidth="3"
                                stroke="#000000"
                                d="M1240,278v126h-148V278H1240 M1241,277h-150v128h150V277L1241,277z"
                            />
                        </g>
                        <line
                            className="st7"
                            x1="1087"
                            y1="404.5"
                            x2="1245"
                            y2="404.5"
                        />
                        <line
                            className="st7"
                            x1="1084"
                            y1="276.5"
                            x2="1247"
                            y2="276.5"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                strokeWidth="3"
                                stroke="#000000"
                                d="M1181,226v50h-26v-50H1181 M1182,225h-28v52h28V225L1182,225z"
                            />
                        </g>
                        <g>
                            <path
                                strokeWidth="3"
                                stroke="#000000"
                                d="M1232,199v26h-50v-26H1232 M1233,198h-52v28h52V198L1233,198z"
                            />
                        </g>
                        <g>
                            <path
                                className="st0"
                                strokeWidth="3"
                                stroke="#000000"
                                d="M1154.5,225.5c0,0,0-15,7-21c8.07-6.92,21-7,21-7"
                            />
                        </g>
                    </g>
                </g>
                <path
                    strokeWidth="3"
                    stroke="#000000"
                    className="st0"
                    d="M1040,332.33"
                />
            </g>
            <g>
                <g>
                    <path
                        strokeWidth="3"
                        stroke="#000000"
                        d="M529.66,600l0.19,52.26l0.19,52.27l-29.51,0.47l-48.3-33.81l-0.28-45.84L451.77,600h38.94H529.66 M530.66,599h-39.95
			h-39.95l0.19,26.36l0.29,46.35L500.22,706l30.82-0.49l-0.19-53.26L530.66,599L530.66,599z"
                    />
                </g>
                <g>
                    <g>
                        <circle
                            className="st0"
                            cx="575.5"
                            cy="691.5"
                            r="11.5"
                        />
                    </g>
                    <g>
                        <polyline
                            className="st0"
                            points="593.5,706.5 600,718 576,718 552,718 558,707 			"
                        />
                    </g>
                    <path
                        strokeWidth="3"
                        stroke="#000000"
                        className="st5"
                        d="M576,701"
                    />
                    <rect
                        x="541"
                        y="686.5"
                        className="st0"
                        width="11"
                        height="10"
                    />
                    <line className="st5" x1="541" y1="683" x2="541" y2="699" />
                    <line className="st0" x1="540" y1="692" x2="531" y2="692" />
                    <g>
                        <path
                            strokeWidth="3"
                            stroke="#000000"
                            className="st0"
                            d="M598.52,696.24c-0.31,1.53-0.77,3.01-1.37,4.41s-1.32,2.74-2.17,3.99c-0.84,1.25-1.81,2.41-2.87,3.48
				s-2.23,2.02-3.48,2.87c-1.25,0.84-2.59,1.57-3.99,2.17s-2.88,1.06-4.41,1.37s-3.11,0.48-4.74,0.48s-3.21-0.16-4.74-0.48
				s-3.01-0.77-4.41-1.37s-2.74-1.32-3.99-2.17c-1.25-0.84-2.41-1.81-3.48-2.87s-2.02-2.23-2.87-3.48
				c-0.84-1.25-1.57-2.59-2.17-3.99s-1.06-2.88-1.37-4.41s-0.48-3.11-0.48-4.74s0.16-3.21,0.48-4.74s0.77-3.01,1.37-4.41
				s1.32-2.74,2.17-3.99c0.84-1.25,1.81-2.41,2.87-3.48s2.23-2.02,3.48-2.87c1.25-0.84,2.59-1.57,3.99-2.17s2.88-1.06,4.41-1.37
				s3.11-0.48,4.74-0.48"
                        />
                        <path
                            strokeWidth="3"
                            stroke="#000000"
                            className="st0"
                            d="M575.5,668.59V658.5h5.75H587h5.75h5.75v9.12v9.12v19.75c0,0,0-0.5,0,0"
                        />
                    </g>
                    <ellipse
                        className="st5"
                        cx="586.5"
                        cy="657.75"
                        rx="14"
                        ry="0.75"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                d="M983,879v11v15v15v15v15v15v15v10.8l-13.76-0.66l-1.34-6.02l-0.71-3.18l-3.26,0.05l-63.43,1l-54.43-1l-0.07,0l-0.07,0
					l-53.43,1l-66.43-1l-3.33-0.05l-0.65,3.27l-1.36,6.78H709v-11v-15v-15v-15v-15v-15v-15v-11h13.47l0.56,4.5l0.43,3.48l3.5,0.03
					l61.5,0.5l57.5,0.5l0.04,0l0.04,0l52.99-0.5l64-0.5l3.1-0.02l0.75-3.01l1.24-4.97H983 M987,875h-22l-2,8l-64,0.5l-53,0.5
					l-57.5-0.5L727,883l-1-8h-21v15v15v15v15v15v15v15v15h19l2-10l66.5,1l53.5-1l54.5,1l63.5-1l2,9l21,1v-15v-15v-15v-15v-15v-15
					v-15V875L987,875z"
                            />
                        </g>
                    </g>
                    <g>
                        <path d="M723,875v118h-17V875H723 M724,874h-19v120h19V874L724,874z" />
                    </g>
                    <g>
                        <path
                            stroke="#000000"
                            d="M985,876v118h-17V876H985 M986,875h-19v120h19V875L986,875z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <circle className="st3" cx="756" cy="905" r="7.5" />
                        <path
                            d="M756,898c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S752.14,898,756,898 M756,897c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S760.42,897,756,897L756,897z"
                        />
                    </g>
                    <g>
                        <circle className="st3" cx="846" cy="905" r="7.5" />
                        <path
                            d="M846,898c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S842.14,898,846,898 M846,897c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S850.42,897,846,897L846,897z"
                        />
                    </g>
                    <g>
                        <circle className="st3" cx="932" cy="905" r="7.5" />
                        <path
                            d="M932,898c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S928.14,898,932,898 M932,897c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S936.42,897,932,897L932,897z"
                        />
                    </g>
                    <g>
                        <circle className="st3" cx="757" cy="935" r="7.5" />
                        <path
                            d="M757,928c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S753.14,928,757,928 M757,927c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S761.42,927,757,927L757,927z"
                        />
                    </g>
                    <g>
                        <circle className="st3" cx="847" cy="935" r="7.5" />
                        <path
                            d="M847,928c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S843.14,928,847,928 M847,927c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S851.42,927,847,927L847,927z"
                        />
                    </g>
                    <g>
                        <circle className="st3" cx="933" cy="935" r="7.5" />
                        <path
                            d="M933,928c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S929.14,928,933,928 M933,927c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S937.42,927,933,927L933,927z"
                        />
                    </g>
                    <g>
                        <circle className="st3" cx="757" cy="965" r="7.5" />
                        <path
                            d="M757,958c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S753.14,958,757,958 M757,957c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S761.42,957,757,957L757,957z"
                        />
                    </g>
                    <g>
                        <circle className="st3" cx="847" cy="965" r="7.5" />
                        <path
                            d="M847,958c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S843.14,958,847,958 M847,957c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S851.42,957,847,957L847,957z"
                        />
                    </g>
                    <g>
                        <circle className="st3" cx="933" cy="965" r="7.5" />
                        <path
                            d="M933,958c3.86,0,7,3.14,7,7s-3.14,7-7,7s-7-3.14-7-7S929.14,958,933,958 M933,957c-4.42,0-8,3.58-8,8s3.58,8,8,8
				s8-3.58,8-8S937.42,957,933,957L933,957z"
                        />
                    </g>
                </g>
                <g>
                    <rect
                        x="685.5"
                        y="1028.5"
                        className="st6"
                        width="318"
                        height="15"
                    />
                    <line
                        className="st6"
                        x1="691"
                        y1="943"
                        x2="691"
                        y2="1029"
                    />
                    <line
                        className="st6"
                        x1="997"
                        y1="950"
                        x2="997"
                        y2="1029"
                    />
                </g>
                <g>
                    <rect
                        x="985.5"
                        y="915.5"
                        className="st5"
                        width="24"
                        height="34"
                    />
                    <rect
                        x="1011"
                        y="922.5"
                        className="st6"
                        width="5.5"
                        height="21"
                    />
                </g>
                <g>
                    <rect
                        x="681.5"
                        y="921.5"
                        className="st0"
                        strokeWidth="5"
                        width="24"
                        height="22"
                    />
                    <g>
                        <polygon
                            className="st5"
                            points="681.5,952.5 674.75,952.5 668,952.5 661.25,952.5 658.5,952.5 647.5,939.5 647.5,931.5 647.5,925.5
				658.5,912.5 665.25,912.5 668,912.5 674.75,912.5 681.5,912.5 681.5,922.5 681.5,932.5 681.5,942.5 			"
                        />
                    </g>
                </g>
                <g>
                    <line className="st5" x1="967" y1="897" x2="986" y2="897" />
                    <line className="st5" x1="967" y1="923" x2="986" y2="923" />
                    <line className="st5" x1="967" y1="944" x2="986" y2="944" />
                    <line className="st5" x1="967" y1="968" x2="986" y2="968" />
                </g>
            </g>
            <path className="st0" d="M509.29,612.29" />
            <g>
                <g>
                    <line
                        className="st0"
                        x1="502.79"
                        y1="349"
                        x2="502.79"
                        y2="591.5"
                    />
                    <polygon
                        className="st7"
                        points="495.56,583.63 502.89,583.6 510.22,583.57 502.92,598.67 		"
                    />
                </g>
                <line
                    className="st0"
                    x1="502.5"
                    y1="348.5"
                    x2="450.5"
                    y2="348.5"
                />
            </g>
            <path className="st0" d="M296.17,225" />
            <g>
                <line
                    className="st0"
                    x1="293.51"
                    y1="152.24"
                    x2="293.5"
                    y2="204.5"
                />
                <polygon
                    className="st7"
                    points="286.34,204.81 293.55,204.78 300.75,204.75 293.58,220.58 	"
                />
            </g>
            <path className="st0" d="M494.38,692.26" />
            <g>
                <polygon
                    className="st7"
                    points="508.11,723.07 500.78,723.1 493.45,723.13 500.75,707.71 	"
                />
                <g>
                    <line
                        className="st0"
                        x1="500.5"
                        y1="930.5"
                        x2="500.88"
                        y2="722.63"
                    />
                    <line
                        className="st0"
                        x1="500.5"
                        y1="930.5"
                        x2="647.5"
                        y2="931.5"
                    />
                </g>
            </g>
            <path className="st0" d="M1137,330.33" />
            <g>
                <g>
                    <line
                        className="st0"
                        x1="586.5"
                        y1="343.58"
                        x2="997.58"
                        y2="343.5"
                    />
                    <polygon
                        className="st7"
                        points="997.54,350.94 997.52,343.42 997.49,335.9 1009,343.39 		"
                    />
                </g>
                <line
                    className="st0"
                    x1="586.5"
                    y1="343.5"
                    x2="586.5"
                    y2="657.5"
                />
            </g>
            {/* <g>
                <g>
                    <line
                        className="st0"
                        x1="586.5"
                        y1="343.58"
                        x2="1093.5"
                        y2="343.5"
                    />
                    <polygon
                        className="st7"
                        points="1093.45,350.94 1093.42,343.42 1093.39,335.9 1107.59,343.39 		"
                    />
                </g>
                <line
                    className="st0"
                    x1="586.5"
                    y1="343.5"
                    x2="586.5"
                    y2="657.5"
                />
            </g> */}
            {/* <g>
                <g>
                    <polygon
                        className="st7"
                        points="1035.37,924.63 1035.4,931.96 1035.44,939.29 1018.37,931.99 		"
                    />
                    <line
                        className="st0"
                        x1="1265"
                        y1="932.24"
                        x2="1027.37"
                        y2="932.24"
                    />
                </g>
                <line
                    className="st0"
                    x1="1264.5"
                    y1="932.5"
                    x2="1266.5"
                    y2="692"
                />
            </g> */}
            <g>
                <g>
                    <polygon
                        className="st7"
                        points="1028.62,924.63 1028.64,931.96 1028.66,939.29 1018.37,931.99 		"
                    />
                    <line
                        className="st0"
                        x1="1167.1"
                        y1="932.24"
                        x2="1023.8"
                        y2="932.24"
                    />
                </g>
                <line
                    className="st0"
                    x1="1166.79"
                    y1="932.5"
                    x2="1168"
                    y2="692"
                />
            </g>
            {/* <g>
                <line
                    className="st0"
                    x1="1330"
                    y1="211.46"
                    x2="1372.81"
                    y2="211.45"
                />
                <polygon
                    className="st7"
                    points="1366.36,219.13 1366.33,211.44 1366.3,203.74 1380.5,211.4 	"
                />
            </g> */}
            <g>
                <line
                    className="st0"
                    x1="1233"
                    y1="211.45"
                    x2="1273.69"
                    y2="211.44"
                />
                <polygon
                    className="st7"
                    points="1267.56,216.96 1267.53,211.44 1267.5,205.91 1281,211.41 	"
                />
            </g>
            <g>
                <rect
                    x="17.5"
                    y="40.5"
                    className="st8"
                    width="478"
                    height="112"
                />
                <text
                    transform="matrix(1 0 0 1 24.4521 80.7134)"
                    className="st9 st10"
                >
                    SagFeed
                </text>
                <text
                    transform="matrix(1 0 0 1 25.8081 124.076)"
                    className="st9 st10"
                >
                    SAGWaterAdd
                </text>
                <text
                    transform="matrix(1 0 0 1 282.5284 80.5856)"
                    className="st9 st10"
                    name="SAG Fresh Feed_value"
                    id="SAG Fresh Feed_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["SAG Fresh Feed"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("SAG Fresh Feed")
                        ? props.units["SAG Fresh Feed"].units
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 282.8968 123.7131)"
                    className="st9 st10"
                    name="SAG Feed Water Addition_value"
                    id="SAG Feed Water Addition_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(
                        props.currentLiveData["SAG Feed Water Addition"]
                    )}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("SAG Feed Water Addition")
                        ? props.units["SAG Feed Water Addition"].units
                        : null}
                </text>
            </g>
            <g>
                <path className="st0" d="M294.44,591.05" />
                <rect
                    x="80.5"
                    y="587.5"
                    className="st8"
                    width="345"
                    height="112"
                />
                <text
                    transform="matrix(1 0 0 1 89.6743 628.3312)"
                    className="st9 st10"
                >
                    PBLev
                </text>
                <text
                    transform="matrix(1 0 0 1 91.0302 671.6939)"
                    className="st9 st10"
                >
                    PBWaterAdd
                </text>
                <text
                    transform="matrix(1 0 0 1 271.7505 628.2034)"
                    className="st9 st10"
                    name="BM Cyc Pumpbox Level_value"
                    id="BM Cyc Pumpbox Level_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["BM Cyc Pumpbox Level"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("BM Cyc Pumpbox Level")
                        ? props.units["BM Cyc Pumpbox Level"].units
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 271.1189 672.3309)"
                    className="st9 st10"
                    name="BM Cyc Pumpbox Water Addition_value"
                    id="BM Cyc Pumpbox Water Addition_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(
                        props.currentLiveData["BM Cyc Pumpbox Water Addition"]
                    )}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("BM Cyc Pumpbox Water Addition")
                        ? props.units["BM Cyc Pumpbox Water Addition"].units
                        : null}
                </text>
            </g>
            <g>
                <rect
                    x="607.5"
                    y="500.5"
                    className="st8"
                    width="345"
                    height="112"
                />
                <text
                    transform="matrix(1 0 0 1 617.2666 540.0698)"
                    className="st9 st10"
                >
                    CP-Current
                </text>
                <text
                    transform="matrix(1 0 0 1 618.6226 583.4326)"
                    className="st9 st10"
                >
                    CP-Speed
                </text>
                <text
                    transform="matrix(1 0 0 1 780.3428 539.9421)"
                    className="st9 st10"
                    name="CP-Amps_value"
                    id="CP-Amps_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["CP-Amps"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("CP-Amps")
                        ? props.units["CP-Amps"].units
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 780.7112 584.0696)"
                    className="st9 st10"
                    name="CP-Spd_value"
                    id="CP-Spd_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["CP-Spd"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("CP-Spd")
                        ? props.units["CP-Spd"].units
                        : null}
                </text>
            </g>
            <g>
                <rect
                    x="681.5"
                    y="739.5"
                    className="st8"
                    width="345"
                    height="112"
                />
                <text
                    transform="matrix(1 0 0 1 693.2666 776.159)"
                    className="st9 st10"
                >
                    BMPow
                </text>
                <text
                    transform="matrix(1 0 0 1 694.6226 819.5217)"
                    className="st9 st10"
                >
                    BMWaterAdd
                </text>
                <text
                    transform="matrix(1 0 0 1 875.3428 776.0312)"
                    className="st9 st10"
                    name="BM Power_value"
                    id="BM Power_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["BM Power"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("BM Power")
                        ? props.units["BM Power"].units
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 875.7112 820.1588)"
                    className="st9 st10"
                    name="BM Water Addition_value"
                    id="BM Water Addition_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["BM Water Addition"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("BM Water Addition")
                        ? props.units["BM Water Addition"].units
                        : null}
                </text>
            </g>
            <g>
                <rect
                    x="602.5"
                    y="177.5"
                    className="st8"
                    width="399"
                    height="143"
                />
                <text
                    transform="matrix(1 0 0 1 616.6226 209.2287)"
                    className="st9 st10"
                >
                    CPres
                </text>
                <text
                    transform="matrix(1 0 0 1 807.7112 209.8658)"
                    className="st9 st10"
                    name="BM Cyc Feed Pressure_value"
                    id="BM Cyc Feed Pressure_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["BM Cyc Feed Pressure"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("BM Cyc Feed Pressure")
                        ? props.units["BM Cyc Feed Pressure"].units
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 615.2666 249.866)"
                    className="st9 st10"
                >
                    CFlow
                </text>
                <text
                    transform="matrix(1 0 0 1 616.6226 293.2287)"
                    className="st9 st10"
                >
                    CFeedDens
                </text>
                <text
                    transform="matrix(1 0 0 1 807.3428 249.7383)"
                    className="st9 st10"
                    name="BM Cyc feed Flow_value"
                    id="BM Cyc feed Flow_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["BM Cyc feed Flow"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("BM Cyc feed Flow")
                        ? props.units["BM Cyc feed Flow"].units
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 807.7112 293.8658)"
                    className="st9 st10"
                    name="BM Cyc Feed Density_value"
                    id="BM Cyc Feed Density_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["BM Cyc Feed Density"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("BM Cyc Feed Density")
                        ? props.units["BM Cyc Feed Density"].units
                        : null}
                </text>
            </g>
            <g>
                <rect
                    x="1329.5"
                    y="757.5"
                    className="st8"
                    width="441"
                    height="279"
                />
                <path className="st0" d="M1550.45,763.73" />
                <text
                    transform="matrix(1 0 0 1 1345.687 801.0153)"
                    className="st9 st10"
                >
                    CVlv1
                </text>
                <text
                    transform="matrix(1 0 0 1 1345.043 846.3781)"
                    className="st9 st10"
                >
                    CVlv2
                </text>
                <text
                    transform="matrix(1 0 0 1 1487.7632 800.8876)"
                    className="st9 st10"
                    name="PRIMARY CYCLONE VALVE 1_value"
                    id="PRIMARY CYCLONE VALVE 1_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {props.currentLiveData["PRIMARY CYCLONE VALVE 1"]}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("PRIMARY CYCLONE VALVE 1")
                        ? "(" +
                          props.units["PRIMARY CYCLONE VALVE 1"].units +
                          ")"
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 1487.1316 845.015)"
                    className="st9 st10"
                    name="PRIMARY CYCLONE VALVE 2_value"
                    id="PRIMARY CYCLONE VALVE 2_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {props.currentLiveData["PRIMARY CYCLONE VALVE 2"]}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("PRIMARY CYCLONE VALVE 2")
                        ? "(" +
                          props.units["PRIMARY CYCLONE VALVE 2"].units +
                          ")"
                        : null}
                </text>
                <path className="st0" d="M1550.44,861.73" />
                <text
                    transform="matrix(1 0 0 1 1345.6743 899.0153)"
                    className="st9 st10"
                >
                    CVlv3
                </text>
                <text
                    transform="matrix(1 0 0 1 1347.0303 942.3781)"
                    className="st9 st10"
                >
                    CVlv4
                </text>
                <text
                    transform="matrix(1 0 0 1 1487.7505 898.8876)"
                    className="st9 st10"
                    name="PRIMARY CYCLONE VALVE 3_value"
                    id="PRIMARY CYCLONE VALVE 3_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {props.currentLiveData["PRIMARY CYCLONE VALVE 3"]}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("PRIMARY CYCLONE VALVE 3")
                        ? "(" +
                          props.units["PRIMARY CYCLONE VALVE 3"].units +
                          ")"
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 1487.1189 943.015)"
                    className="st9 st10"
                    name="PRIMARY CYCLONE VALVE 4_value"
                    id="PRIMARY CYCLONE VALVE 4_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {props.currentLiveData["PRIMARY CYCLONE VALVE 4"]}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("PRIMARY CYCLONE VALVE 4")
                        ? "(" +
                          props.units["PRIMARY CYCLONE VALVE 4"].units +
                          ")"
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 1347.5007 986.9167)"
                    className="st9 st10"
                >
                    CVlv5
                </text>
                <text
                    transform="matrix(1 0 0 1 1487.9856 986.6621)"
                    className="st9 st10"
                    name="PRIMARY CYCLONE VALVE 5_value"
                    id="PRIMARY CYCLONE VALVE 5_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {props.currentLiveData["PRIMARY CYCLONE VALVE 5"]}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("PRIMARY CYCLONE VALVE 5")
                        ? "(" +
                          props.units["PRIMARY CYCLONE VALVE 5"].units +
                          ")"
                        : null}
                </text>
            </g>
            <g>
                <rect
                    x="1351"
                    y="70.5"
                    className="st8"
                    width="490"
                    height="122.5"
                />
                <text
                    transform="matrix(1 0 0 1 1358.5487 118.3947)"
                    className="st9 st10"
                >
                    CycOFP80 Actual
                </text>
                <text
                    transform="matrix(1 0 0 1 1357.4238 163.6877)"
                    className="st9 st10"
                >
                    CycOFP80 Predicted
                </text>
                <text
                    transform="matrix(1 0 0 1 1605.6139 118.1269)"
                    className="st9 st10"
                    name="Cyclone Overflow P80_value"
                    id="Cyclone Overflow P80_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["Cyclone Overflow P80"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("Cyclone Overflow P80")
                        ? props.units["Cyclone Overflow P80"].units
                        : null}
                </text>
                <text
                    transform="matrix(1 0 0 1 1604.489 163.4198)"
                    className="st9 st10"
                    name="Cyclone Overflow P80_predicted_value"
                    id="Cyclone Overflow P80_predicted_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(
                        props.currentLiveData["Cyclone Overflow P80_predicted"]
                    )}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("Cyclone Overflow P80_predicted")
                        ? props.units["Cyclone Overflow P80_predicted"].units
                        : null}
                </text>
            </g>
            {/* <g>
                <rect
                    x="1351"
                    y="116"
                    className="st8"
                    width="389.5"
                    height="77"
                />
                <text
                    transform="matrix(1 0 0 1 1357.4238 163.6877)"
                    className="st9 st10"
                >
                    CycOFP80
                </text>
                <text
                    transform="matrix(1 0 0 1 1534.489 163.4198)"
                    className="st9 st10"
                    name="Cyclone Overflow P80_value"
                    id="Cyclone Overflow P80_value"
                    onClick={props.onItemClick}
                    cursor="pointer"
                >
                    {trimValue(props.currentLiveData["Cyclone Overflow P80"])}{" "}
                    {Object.keys(props.units).length &&
                    props.units.hasOwnProperty("Cyclone Overflow P80")
                        ? props.units["Cyclone Overflow P80"].units
                        : null}
                </text>
            </g> */}
            {graphs}
        </svg>
    );
};

export default overview;
