/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:43
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-01-14 16:45:19
 */

import React, { Component } from "react";
import { Button, Modal, Form } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import { mergeDatasets } from "../../../services/dataset";
import { withRouter } from "react-router-dom";
import * as validators from "../../../shared/modelValidators";

class MergeDatasetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formJSON: {}
        };

        this.handleMergeClick = this.handleMergeClick.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
    }

    async handleMergeClick() {
        if (!this.form.check()) {
            return;
        }
        this.setState({ loading: true });

        let pathParts = this.props.location.pathname.split("/");
        let homeUrl = "/" + pathParts[1] + "/" + pathParts[2];

        let body = { IDs: [], name: this.state.formJSON.name };
        for (let dataset of this.props.datasets) {
            body.IDs.push(dataset._id);
        }
        let result = await mergeDatasets(
            this.props.endUserSession,
            homeUrl,
            body
        );

        if (result) {
            this.props.onClose();
            this.props.onSubmit();
        } else {
            this.setState({ loading: false });
        }
    }

    handleModalOpen() {
        let formJSON = this.state.formJSON;
        for (let field in formJSON) {
            if (formJSON.hasOwnProperty(field)) {
                formJSON[field] = "";
            }
        }

        this.setState({
            formJSON: formJSON,
            loading: false
        });
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.props.open}
                    onOpen={this.handleModalOpen}
                    onClose={this.props.onClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Merge Datasets</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Form
                            ref={(forum) => (this.form = forum)}
                            layout="horizontal"
                            formValue={this.state.formJSON}
                            checkTrigger="none"
                            onChange={(newFormJSON) => {
                                this.setState({ formJSON: newFormJSON });
                            }}
                            style={{ marginBottom: "20px", marginTop: "20px" }}
                        >
                            <Form.Group>
                                <Form.ControlLabel>
                                    Merged Dataset Name
                                </Form.ControlLabel>
                                <Form.Control
                                    name="name"
                                    rule={validators.datasetNameValidation}
                                />
                            </Form.Group>
                        </Form>
                        <Button
                            appearance="primary"
                            onClick={this.handleMergeClick}
                            loading={this.state.loading}
                        >
                            Merge
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MergeDatasetModal)
);
