/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-10 20:57:49
 */

import React, { Component } from "react";

import { IconButton, Whisper, Tooltip, toaster, Message, Button } from "rsuite";
import LegacyCloseIcon from "@rsuite/icons/legacy/Close";
import LegacyBanIcon from "@rsuite/icons/legacy/Ban";
import LegacyEyeIcon from "@rsuite/icons/legacy/Eye";
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import LegacyGearCircle from "@rsuite/icons/legacy/GearCircle";
import LegacyFlowIcon from "@rsuite/icons/legacy/Flow";
import LegacyFilterIcon from "@rsuite/icons/legacy/Filter";
import { connect } from "react-redux";
import { refreshToken } from "../../shared/auth";
import * as actions from "../../store/actions/auth";
import { AgGridReact } from "ag-grid-react";
import { withRouter } from "react-router-dom";
import history from "../../shared/history";
import NewDatasetModal from "../../components/modals/nDatum/newDatasetModal";
import NewAnalysisModal from "../../components/modals/nDatum/newAnalysisModal";
import { getDatasets } from "../../services/dataset";
import DeleteDatasetModal from "../../components/modals/nDatum/deleteDatasetModal";
import PreprocessModal from "../../components/modals/nDatum/preprocessModal";
import MergeDatasetModal from "../../components/modals/nDatum/mergeDatasetModal";

import {
    cancelAnalysis,
    getAnalyses,
    getAnalysis
} from "../../services/analysis";

import DeleteAnalysisModal from "../../components/modals/nDatum/deleteAnalysisModal";

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            analysisRows: [],
            datasetRows: [],
            showingNewAnalysisModal: false,
            showingDeleteAnalysisModa: false,
            showingNewDatasetModal: false,
            showingDeleteDatasetModal: false,
            showingPreprocessModal: false,
            selectedAnalysis: null,
            selectedDataset: null,
            selectedDatasetRows: []
        };

        this.datasetColumnDefs = [
            {
                headerName: "Name",
                field: "name",
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true
            },
            { headerName: "Created Date", field: "createdDateString" },
            { headerName: "Modified Date", field: "modifiedDateString" },
            { headerName: "# of Records", field: "recordCount" },
            {
                headerName: "Actions",
                flex: 0,
                width: 140,
                resizable: false,
                sortable: false,
                filter: false,
                cellRenderer: "datasetActionColumn"
            }
        ];
        this.analysisColumnDefs = [
            { headerName: "Name", field: "name" },
            { headerName: "Created Date", field: "createdDateString" },
            { headerName: "Modified Date", field: "modifiedDateString" },
            { headerName: "Algorithm", field: "algorithm" },
            {
                headerName: "Status",
                field: "statusMessage",
                cellStyle: (params) => {
                    if (params.value === "Error Occurred") {
                        return { color: "rgb(244, 67, 54)" };
                    } else {
                        return { color: "black" };
                    }
                }
            },
            {
                headerName: "Actions",
                flex: 0,
                width: 100,
                resizable: false,
                sortable: false,
                filter: false,
                cellRenderer: "analysisActionColumn"
            }
        ];

        this.defaultColDef = {
            flex: 1,
            resizable: true,
            sortable: true,
            filter: true
        };

        this.frameworkComponents = {
            analysisActionColumn: this.renderAnalysisActionColumn.bind(this),
            datasetActionColumn: this.renderDatasetActionColumn.bind(this)
        };

        this.refreshAnalysisRows = this.refreshAnalysisRows.bind(this);

        this.handleCreateAnalysisClick =
            this.handleCreateAnalysisClick.bind(this);
        this.handleNewAnalysisModelClose =
            this.handleNewAnalysisModelClose.bind(this);
        this.handleDeleteAnalysisClick =
            this.handleDeleteAnalysisClick.bind(this);
        this.handleDeleteAnalysisModalClose =
            this.handleDeleteAnalysisModalClose.bind(this);
        this.handleDeleteAnalysisModalSubmit =
            this.handleDeleteAnalysisModalSubmit.bind(this);
        this.handleCancelAnalysisClick =
            this.handleCancelAnalysisClick.bind(this);
        this.handleCreateDatasetClick =
            this.handleCreateDatasetClick.bind(this);
        this.handleNewDatasetModalClose =
            this.handleNewDatasetModalClose.bind(this);
        this.handlePreprocessClick = this.handlePreprocessClick.bind(this);
        this.handlePreprocessModalClose =
            this.handlePreprocessModalClose.bind(this);
        this.handleMergeDatasetClick = this.handleMergeDatasetClick.bind(this);
        this.handleMergeDatasetModalClose =
            this.handleMergeDatasetModalClose.bind(this);
        this.handleDeleteDatasetClick =
            this.handleDeleteDatasetClick.bind(this);
        this.handleDeleteDatasetModalClose =
            this.handleDeleteDatasetModalClose.bind(this);
        this.handleDatasetModalSubmit =
            this.handleDatasetModalSubmit.bind(this);
        this.HandleGridReady = this.HandleGridReady.bind(this);
        this.handleTableSelectionChange =
            this.handleTableSelectionChange.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        let datasetRows = await this.getDatasetRows();
        let analysisRows = await this.getAnalysisRows();
        this.refreshAnalysisRowsInterval = setInterval(
            this.refreshAnalysisRows,
            1000 * 5
        );
        this.setState({ datasetRows: datasetRows, analysisRows: analysisRows });
    }

    componentWillUnmount() {
        clearInterval(this.refreshAnalysisRowsInterval);
    }

    async refreshAnalysisRows() {
        let analysisRows = await this.getAnalysisRows();
        this.setState({ analysisRows: analysisRows });
    }

    async getDatasetRows() {
        let datasets = await getDatasets(
            this.props.endUserSession,
            this.props.location.pathname
        );

        if (datasets === null) {
            return [];
        }
        let index = 0;
        for (let dataset of datasets) {
            dataset.useDataSet = index;
            dataset.createdDateString = new Date(
                dataset.createdEpoch
            ).toLocaleString("en-CA", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "America/Edmonton"
            });
            dataset.modifiedDateString = new Date(
                dataset.modifiedEpoch
            ).toLocaleString("en-CA", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "America/Edmonton"
            });
            index++;
        }
        return datasets;
    }

    async getAnalysisRows() {
        let analyses = await getAnalyses(
            this.props.endUserSession,
            this.props.location.pathname
        );

        if (analyses === null) {
            return [];
        }

        let analysisStatusToStatusMessage = {
            configuration: "Needs Configuration",
            progress: "Analyzing Data",
            report: "Analysis Complete",
            progressError: "Error Occurred"
        };
        for (let analysis of analyses) {
            analysis.createdDateString = new Date(
                analysis.createdEpoch
            ).toLocaleString("en-CA", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "America/Edmonton"
            });
            analysis.modifiedDateString = new Date(
                analysis.modifiedEpoch
            ).toLocaleString("en-CA", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "America/Edmonton"
            });
            analysis.statusMessage =
                analysisStatusToStatusMessage[analysis.status];
        }
        return analyses;
    }

    handleCreateAnalysisClick() {
        this.setState({ showingNewAnalysisModal: true });
    }

    handleNewAnalysisModelClose() {
        this.setState({ showingNewAnalysisModal: false });
    }

    handleDeleteAnalysisClick(analysis) {
        this.setState({
            showingDeleteAnalysisModa: true,
            selectedAnalysis: analysis
        });
    }

    handleDeleteAnalysisModalClose() {
        this.setState({
            showingDeleteAnalysisModa: false,
            selectedAnalysis: null
        });
    }

    async handleDeleteAnalysisModalSubmit() {
        let analysisRows = await this.getAnalysisRows();
        this.setState({ analysisRows: analysisRows });
    }

    async handleCancelAnalysisClick(analysis) {
        // Try to make sure the analysis has not complete when cancelling
        console.log(analysis);
        let newAnalysis = await getAnalysis(
            this.props.endUserSession,
            this.props.location.pathname,
            analysis._id
        );

        if (newAnalysis !== null) {
            if (newAnalysis.status === "progress") {
                await cancelAnalysis(
                    this.props.endUserSession,
                    this.props.location.pathname,
                    analysis._id
                );
            } else {
                toaster.push(
                    <Message type="info" showIcon closable duration={60000}>
                        {<text>The analysis has already finished.</text>}
                    </Message>
                );
            }
        }

        let analysisRows = await this.getAnalysisRows();
        this.setState({ analysisRows: analysisRows });
    }

    handleCreateDatasetClick() {
        this.setState({ showingNewDatasetModal: true });
    }

    handleNewDatasetModalClose() {
        this.setState({ showingNewDatasetModal: false });
    }

    handlePreprocessClick(dataset) {
        this.setState({
            showingPreprocessModal: true,
            selectedDataset: dataset
        });
    }

    handlePreprocessModalClose() {
        this.setState({
            showingPreprocessModal: false,
            selectedDataset: null
        });
    }

    handleMergeDatasetClick() {
        this.setState({
            showingMergeDatasetModal: true
        });
    }

    handleMergeDatasetModalClose() {
        this.setState({
            showingMergeDatasetModal: false,
            selectedDataset: null
        });
    }

    handleDeleteDatasetClick(dataset) {
        this.setState({
            showingDeleteDatasetModal: true,
            selectedDataset: dataset
        });
    }

    handleDeleteDatasetModalClose() {
        this.setState({
            showingDeleteDatasetModal: false,
            selectedDataset: null
        });
    }

    async handleDatasetModalSubmit() {
        let datasetRows = await this.getDatasetRows();
        this.setState({ datasetRows: datasetRows });
    }

    HandleGridReady(params) {
        this.gridApi = params.api;
    }

    handleTableSelectionChange() {
        const selectedRows = this.gridApi.getSelectedRows();
        this.setState({ selectedDatasetRows: selectedRows });
    }

    renderAnalysisActionColumn(event) {
        let statusDependentButtons = [];
        if (
            event.data.status === "configuration" ||
            event.data.status === "report"
        ) {
            statusDependentButtons.push(
                <Whisper
                    key={"delete"}
                    placement="bottom"
                    speaker={<Tooltip>Delete</Tooltip>}
                    delay={1000}
                >
                    <IconButton
                        icon={<LegacyCloseIcon />}
                        appearance="subtle"
                        onClick={() => {
                            this.handleDeleteAnalysisClick(event.data);
                        }}
                    />
                </Whisper>
            );
        } else if (event.data.status === "progress") {
            statusDependentButtons.push(
                <Whisper
                    key={"cancel"}
                    placement="bottom"
                    speaker={<Tooltip>Cancel Analysis</Tooltip>}
                    delay={1000}
                >
                    <IconButton
                        icon={<LegacyBanIcon />}
                        appearance="subtle"
                        onClick={async () => {
                            await this.handleCancelAnalysisClick(event.data);
                        }}
                    />
                </Whisper>
            );
        }

        return (
            <div>
                <Whisper
                    placement="bottom"
                    speaker={<Tooltip>View more details</Tooltip>}
                    delay={1000}
                >
                    <IconButton
                        icon={<LegacyEyeIcon />}
                        appearance="subtle"
                        onClick={() => {
                            history.push({
                                pathname:
                                    this.props.location.pathname +
                                    "/analyses/" +
                                    event.data._id,
                                state: { analysis: event.data }
                            });
                        }}
                    />
                </Whisper>
                {statusDependentButtons}
            </div>
        );
    }

    renderDatasetActionColumn(event) {
        console.log(event);
        return (
            <div>
                {/*<Whisper
                    placement="bottom"
                    speaker={<Tooltip>Preprocess Dataset</Tooltip>}
                    delay={1000}
                >
                    <IconButton
                        icon={<LegacyGearCircle />}
                        appearance="subtle"
                        onClick={() => {
                            this.handlePreprocessClick(event.data);
                        }}
                    />
                    </Whisper>*/}

                <Whisper
                    placement="bottom"
                    speaker={<Tooltip>View more details</Tooltip>}
                    delay={1000}
                >
                    <IconButton
                        icon={<LegacyEyeIcon />}
                        appearance="subtle"
                        onClick={() => {
                            history.push({
                                pathname:
                                    this.props.location.pathname +
                                    "/datasets/" +
                                    event.data._id,
                                state: { dataset: event.data }
                            });
                        }}
                    />
                </Whisper>

                <Whisper
                    placement="bottom"
                    speaker={<Tooltip>Delete</Tooltip>}
                    delay={1000}
                >
                    <IconButton
                        icon={<LegacyCloseIcon />}
                        appearance="subtle"
                        onClick={() => {
                            this.handleDeleteDatasetClick(event.data);
                        }}
                    />
                </Whisper>

                <Whisper
                    placement="bottom"
                    speaker={<Tooltip>Filter</Tooltip>}
                    delay={1000}
                >
                    <IconButton
                        icon={<LegacyFilterIcon />}
                        appearance="subtle"
                        onClick={() => {
                            history.push({
                                pathname:
                                    this.props.location.pathname +
                                    "/datasets/" +
                                    event.data._id +
                                    "/analytics",
                                state: { dataset: event.data }
                            });
                        }}
                    />
                </Whisper>
            </div>
        );
    }

    render() {
        return (
            <div style={{ margin: "20px" }}>
                {/*<h4
                    style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginRight: "20px"
                    }}
                >
                    Analyses
                </h4>

                <IconButton
                    circle
                    icon={<LegacyPlusIcon />}
                    appearance="primary"
                    onClick={this.handleCreateAnalysisClick}
                />

                <div
                    className="ag-theme-alpine"
                    style={{ height: 408, marginTop: "10px" }}
                >
                    <AgGridReact
                        rowData={this.state.analysisRows}
                        columnDefs={this.analysisColumnDefs}
                        defaultColDef={this.defaultColDef}
                        frameworkComponents={this.frameworkComponents}
                        multiSortKey={"ctrl"}
                        paginationAutoPageSize
                        pagination
                        rowDragManaged // Allow drag and drop
                        animateRows // Allow animation when dragging
                    />
                </div>*/}

                <div>
                    <h4
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            marginRight: "20px"
                        }}
                    >
                        Datasets
                    </h4>

                    <IconButton
                        circle
                        icon={<LegacyPlusIcon />}
                        appearance="primary"
                        onClick={this.handleCreateDatasetClick}
                        style={{ marginRight: "10px" }}
                    />
                    <IconButton
                        circle
                        icon={<LegacyFlowIcon />}
                        appearance="primary"
                        onClick={this.handleMergeDatasetClick}
                        disabled={this.state.selectedDatasetRows.length === 0}
                    />
                </div>

                <div
                    className="ag-theme-alpine"
                    style={{ height: 408, marginTop: "10px" }}
                >
                    <AgGridReact
                        rowData={this.state.datasetRows}
                        columnDefs={this.datasetColumnDefs}
                        defaultColDef={this.defaultColDef}
                        frameworkComponents={this.frameworkComponents}
                        onGridReady={this.HandleGridReady}
                        onSelectionChanged={this.handleTableSelectionChange}
                        multiSortKey={"ctrl"}
                        rowSelection={"multiple"}
                        paginationAutoPageSize
                        pagination
                        rowDragManaged // Allow drag and drop
                        animateRows // Allow animation when dragging
                    />
                </div>

                <NewAnalysisModal
                    open={this.state.showingNewAnalysisModal}
                    onClose={this.handleNewAnalysisModelClose}
                />
                <DeleteAnalysisModal
                    open={this.state.showingDeleteAnalysisModa}
                    onClose={this.handleDeleteAnalysisModalClose}
                    onSubmit={this.handleDeleteAnalysisModalSubmit}
                    analysis={this.state.selectedAnalysis}
                />

                <NewDatasetModal
                    open={this.state.showingNewDatasetModal}
                    onClose={this.handleNewDatasetModalClose}
                />
                <DeleteDatasetModal
                    open={this.state.showingDeleteDatasetModal}
                    onClose={this.handleDeleteDatasetModalClose}
                    onSubmit={this.handleDatasetModalSubmit}
                    dataset={this.state.selectedDataset}
                />
                <PreprocessModal
                    open={this.state.showingPreprocessModal}
                    onClose={this.handlePreprocessModalClose}
                    onSubmit={this.handleDatasetModalSubmit}
                    dataset={this.state.selectedDataset}
                />
                <MergeDatasetModal
                    open={this.state.showingMergeDatasetModal}
                    onClose={this.handleMergeDatasetModalClose}
                    onSubmit={this.handleDatasetModalSubmit}
                    datasets={this.state.selectedDatasetRows}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Overview)
);
