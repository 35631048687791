/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:43
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-01-14 16:45:16
 */

import React, { Component } from "react";
import { Button, Modal } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import { deleteAnalysis } from "../../../services/analysis";
import { withRouter } from "react-router-dom";

class DeleteAnalysisModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
    }

    async handleDeleteClick() {
        this.setState({ loading: true });

        let pathParts = this.props.location.pathname.split("/");
        let homeURL = "/" + pathParts[1] + "/" + pathParts[2];
        let result = await deleteAnalysis(
            this.props.endUserSession,
            homeURL,
            this.props.analysis._id
        );
        if (result) {
            this.props.onClose();
            this.props.onSubmit();
        } else {
            this.setState({ loading: false });
        }
    }

    handleModalOpen() {
        this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.props.open}
                    onOpen={this.handleModalOpen}
                    onClose={this.props.onClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Delete Analysis?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete the analysis?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.handleDeleteClick}
                            loading={this.state.loading}
                            style={{
                                backgroundColor: "rgb(244, 67, 54)",
                                color: "white"
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DeleteAnalysisModal)
);
