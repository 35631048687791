/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2022-06-10 15:36:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-06-10 18:22:39
 */

import axios from "axios";
import { toaster, Message } from "rsuite";
import React from "react";
import BaseURL from "../../api-common";

export async function getAllGHGSavings(endUserSession, clientPathName) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/ghgSavings/getAllGHGSavings";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The function request is invalid.");
        }

        return res.data.allGHGSavingsInfo;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={0}>
                {
                    <text>
                        Could not get all GHG Savings.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getLastWeekData(endUserSession, clientPathName) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/ghgSavings/getLastWeekData";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The function request is invalid.");
        }

        return res.data.lastWeekInfo;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={0}>
                {
                    <text>
                        Could not get Last Week GHG Savings.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getLastMonthData(endUserSession, clientPathName) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/ghgSavings/getLastMonthData";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The function request is invalid.");
        }

        return res.data.lastMonthInfo;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={0}>
                {
                    <text>
                        Could not get all GHG Savings.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getLastThreeMonthsData(endUserSession, clientPathName) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/ghgSavings/getLastThreeMonthsData";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The function request is invalid.");
        }

        return res.data.lastThreeMonthsInfo;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={0}>
                {
                    <text>
                        Could not get all GHG Savings.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}

export async function getLastYearData(endUserSession, clientPathName) {
    try {
        let url =
            BaseURL.getBaseURL() +
            clientPathName +
            "/ghgSavings/getLastYearData";

        let res = await axios({
            method: "get",
            url: url,
            withCredentials: true,
            data: {}
        });

        if (res.data.msg === "UNAUTHORIZED") {
            endUserSession();
            return;
        } else if (res.data.statusId === "ERROR") {
            throw new Error("The server has encountered an error.");
        } else if (!res.data.isValidData) {
            throw new Error("The function request is invalid.");
        }

        return res.data.lastYearInfo;
    } catch (e) {
        toaster.push(
            <Message type="error" showIcon closable duration={0}>
                {
                    <text>
                        Could not get all GHG Savings.
                        <br />
                        Error: {e.toString()}
                    </text>
                }
            </Message>
        );
        return null;
    }
}
