/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:22:04
 */

import React, { Component } from "react";

import {
    IconButton,
    ButtonToolbar,
    Table,
    Checkbox,
    Whisper,
    Popover
} from "rsuite";
import LegacyCloseIcon from "@rsuite/icons/legacy/Close";
import LegacyItalicIcon from "@rsuite/icons/legacy/Italic";
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import AddClientModal from "../components/modals/clientManagement/addClientModal";
import DeleteClientModal from "../components/modals/clientManagement/deleteClientModal";
import { connect } from "react-redux";
import { refreshToken } from "../shared/auth";
import * as actions from "../store/actions/auth";
import { getAllClients } from "../services/clientManagement";
import { getAllProjects } from "../services/projectManagement";
import { getAllUsers } from "../services/userManagement";

class ClientManagement extends Component {
    constructor() {
        super();
        this.state = {
            rows: [],
            projects: null,
            users: null,
            sideNavExpanded: true,
            showingAddClientModel: false,
            showingDeleteClientModel: false,
            clientDeletionSelection: null,
            clientDependencyMap: null
        };

        this.handleAddClientButtonClick =
            this.handleAddClientButtonClick.bind(this);
        this.handleDeleteClientButtonClick =
            this.handleDeleteClientButtonClick.bind(this);
        this.handleAddClientModalClose =
            this.handleAddClientModalClose.bind(this);
        this.handleDeleteClientModalClose =
            this.handleDeleteClientModalClose.bind(this);
        this.getTableData = this.getTableData.bind(this);
        this.renderRowDeleteButton = this.renderRowDeleteButton.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        this.getTableData();
    }

    async getTableData() {
        let clients = await getAllClients(this.props.endUserSession);
        if (!clients) {
            return;
        }
        let projects = await getAllProjects(this.props.endUserSession);
        let users = await getAllUsers(this.props.endUserSession);

        let clientDependencyMap = {};
        for (let client of clients) {
            clientDependencyMap[client._id] = { users: [], projects: [] };
        }

        for (let project of projects) {
            if (clientDependencyMap.hasOwnProperty(project.project_clientId)) {
                clientDependencyMap[project.project_clientId]["projects"].push(
                    project
                );
            }
        }

        for (let user of users) {
            if (clientDependencyMap.hasOwnProperty(user.user_clientId)) {
                clientDependencyMap[user.user_clientId]["users"].push(user);
            }
        }

        this.setState({
            rows: clients,
            clientDependencyMap: clientDependencyMap
        });
    }

    handleAddClientButtonClick() {
        this.setState({ showingAddClientModel: true });
    }

    handleDeleteClientButtonClick(client) {
        this.setState({
            showingDeleteClientModel: true,
            clientDeletionSelection: client
        });
    }

    handleAddClientModalClose() {
        this.setState({ showingAddClientModel: false });
    }

    handleDeleteClientModalClose() {
        this.setState({
            showingDeleteClientModel: false,
            clientDeletionSelection: null
        });
    }

    renderRowDeleteButton(client) {
        if (!(client._id in this.state.clientDependencyMap)) {
            return null;
        }
        let usersDependents =
            this.state.clientDependencyMap[client._id]["users"];
        let projectsDependents =
            this.state.clientDependencyMap[client._id]["projects"];

        if (usersDependents.length + projectsDependents.length > 0) {
            return (
                <Whisper
                    trigger="hover"
                    placement="leftStart"
                    speaker={
                        <Popover title="">
                            <div style={{ whiteSpace: "pre-line" }}>
                                This client still has user and/or project
                                dependents.
                            </div>
                        </Popover>
                    }
                >
                    <div style={{ marginLeft: "20px", width: "36px" }}>
                        <IconButton
                            icon={<LegacyCloseIcon />}
                            style={{
                                pointerEvents: "none"
                            }}
                            onClick={() => {
                                this.handleDeleteClientButtonClick(client);
                            }}
                            disabled={true}
                        />
                    </div>
                </Whisper>
            );
        }

        return (
            <IconButton
                icon={<LegacyCloseIcon />}
                style={{
                    marginLeft: "20px"
                }}
                onClick={() => {
                    this.handleDeleteClientButtonClick(client);
                }}
            />
        );
    }

    render() {
        const { Column, HeaderCell, Cell } = Table;

        // Don't load the screen until all the necessary data has been loaded in successfully
        if (this.state.clientDependencyMap === null) {
            return null;
        }

        return (
            <div style={{ margin: "20px" }}>
                <h3
                    style={{
                        display: "inline-block",
                        color: "#120078"
                    }}
                >
                    Client Management
                </h3>

                <Whisper
                    trigger="click"
                    placement="rightStart"
                    speaker={
                        <Popover style={{ width: 200 }}>
                            <p>Here you can add and delete clients.</p>
                        </Popover>
                    }
                >
                    <IconButton
                        width={500}
                        icon={<LegacyItalicIcon />}
                        circle
                        style={{
                            marginBottom: "17px",
                            marginLeft: "20px"
                        }}
                        appearance="primary"
                    />
                </Whisper>

                <ButtonToolbar>
                    <IconButton
                        icon={<LegacyPlusIcon />}
                        appearance="primary"
                        circle
                        onClick={this.handleAddClientButtonClick}
                    />
                </ButtonToolbar>

                <Table
                    bordered
                    cellBordered
                    autoHeight
                    data={this.state.rows}
                    style={{ marginTop: "20px" }}
                >
                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Client Name
                        </HeaderCell>
                        <Cell dataKey="clientName" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Database Name
                        </HeaderCell>
                        <Cell dataKey="databaseName" />
                    </Column>

                    <Column width={100} verticalAlign="middle">
                        <HeaderCell style={{ fontWeight: "bold" }}>
                            Actions
                        </HeaderCell>
                        <Cell>
                            {(client) => this.renderRowDeleteButton(client)}
                        </Cell>
                    </Column>
                </Table>

                <AddClientModal
                    open={this.state.showingAddClientModel}
                    onClose={this.handleAddClientModalClose}
                    onSubmit={this.getTableData}
                />
                <DeleteClientModal
                    open={this.state.showingDeleteClientModel}
                    onClose={this.handleDeleteClientModalClose}
                    onSubmit={this.getTableData}
                    client={this.state.clientDeletionSelection}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientManagement);
