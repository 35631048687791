/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-01-14 16:45:40
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import { withRouter } from "react-router-dom";
import { getAnalysis } from "../../services/analysis";
import AnalysisConfiguration from "./analysisConfiguration";
import AnalysisProgress from "./analysisProgress";
import GenericNotFound from "../notFound";
import AnalysisReport from "./analysisReport";
import history from "../../shared/history";

class AnalysisScreenManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            analysis: undefined,
            analysisLocallyModified: false // Should be set to true if the analysis is not one from the server
        };

        if (props.location.state !== undefined) {
            this.state.analysis = props.location.state.analysis;
            window.history.replaceState({}, document.title); // Clear state in case of page refresh
        }

        this.pathParts = props.location.pathname.split("/");
        this.homeURL = "/" + this.pathParts[1] + "/" + this.pathParts[2];

        this.handleAnalysisChange = this.handleAnalysisChange.bind(this);
    }

    async componentDidMount() {
        if (this.state.analysis !== undefined) {
            return;
        }

        let analysis = await getAnalysis(
            this.props.endUserSession,
            this.homeURL,
            this.pathParts[4]
        );

        if (analysis === null) {
            history.goBack();
            return;
        }

        this.setState({ analysis: analysis });
    }

    handleAnalysisChange(analysis, analysisLocallyModified = false) {
        this.setState({
            analysis: analysis,
            analysisLocallyModified: analysisLocallyModified
        });
    }

    render() {
        if (this.state.analysis === undefined) {
            return null;
        } else if (this.state.analysis === null) {
            return <GenericNotFound />;
        } else if (this.state.analysis.status === "configuration") {
            return (
                <AnalysisConfiguration
                    analysis={this.state.analysis}
                    analysisRestarted={this.state.analysisLocallyModified}
                    onAnalysisChange={this.handleAnalysisChange}
                />
            );
        } else if (
            this.state.analysis.status === "progress" ||
            this.state.analysis.status === "progressError"
        ) {
            return (
                <AnalysisProgress
                    analysis={this.state.analysis}
                    onAnalysisChange={this.handleAnalysisChange}
                />
            );
        } else if (this.state.analysis.status === "report") {
            return (
                <AnalysisReport
                    analysis={this.state.analysis}
                    onAnalysisChange={this.handleAnalysisChange}
                />
            );
        } else {
            return <GenericNotFound />;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AnalysisScreenManager)
);
