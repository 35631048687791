/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:58:23
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:17:26
 */

export const START_USER_SESSION = "START_USER_SESSION";
export const END_USER_SESSION = "END_USER_SESSION";
export const UPDATE_ASSIGNED_PROJECTS = "UPDATE_ASSIGNED_PROJECTS";
