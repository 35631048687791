/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:57:24
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:21:03
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { refreshToken } from "../../shared/auth";
import "./profile.css";

class Profile extends Component {
    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
    }

    render() {
        return (
            <div id="profile-screen">
                <div className="container">
                    {/*this.props.userDetails.userType !== "SuperAdmin" ? (
                        <div>
                            <h5 className="header">Company</h5>
                            <div className="info">Company X</div>
                        </div>
                    ) : null*/}
                    <h5 className="header">Username</h5>
                    <div className="info">
                        {this.props.userDetails.username}
                    </div>

                    <h5 className="header">First Name</h5>
                    <div className="info">
                        {this.props.userDetails.firstName}
                    </div>

                    <h5 className="header"> Last Name</h5>
                    <div className="info">
                        {this.props.userDetails.lastName}
                    </div>

                    <h5 className="header">Email</h5>
                    <div className="info">{this.props.userDetails.email}</div>

                    <h5 className="header">User Type</h5>
                    <div className="info">
                        {this.props.userDetails.userType}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
