/**
 * @copyright: 2020 NTWIST
 * @Author: NTWIST
 * @Date: 2021-01-19 10:56:31
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-01-14 16:45:23
 */

import React, { Component } from "react";
import { Button, Form, Modal, Schema } from "rsuite";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import * as validators from "../../../shared/modelValidators";
import { createDataset } from "../../../services/dataset";
import { withRouter } from "react-router-dom";
import history from "../../../shared/history";

class AddClientModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: {},
            formJSON: {
                name: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);

        let schema = {
            name: validators.datasetNameValidation
        };

        this.validationModel = Schema.Model(schema);
    }

    async handleSubmit() {
        if (this.form.check()) {
            this.setState({ loading: true });

            let dataset = {
                name: this.state.formJSON.name
            };

            dataset = await createDataset(
                this.props.endUserSession,
                this.props.location.pathname,
                dataset
            );
            if (dataset) {
                history.push({
                    pathname:
                        this.props.location.pathname +
                        "/datasets/" +
                        dataset._id,
                    state: { dataset: dataset }
                });
            } else {
                this.setState({ loading: false });
            }
        }
    }

    handleModalOpen() {
        let formJSON = this.state.formJSON;
        for (let field in formJSON) {
            if (formJSON.hasOwnProperty(field)) {
                formJSON[field] = "";
            }
        }

        this.setState({
            formJSON: formJSON,
            errorMessages: {},
            loading: false
        });
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onOpen={this.handleModalOpen}
                onClose={this.props.onClose}
                overflow={false}
                backdrop="static"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title>New Dataset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        ref={(forum) => (this.form = forum)}
                        layout="horizontal"
                        formValue={this.state.formJSON}
                        model={this.validationModel}
                        checkTrigger="none"
                        onChange={(newFormJSON) => {
                            this.setState({ formJSON: newFormJSON });
                        }}
                        onCheck={(errorMessages) => {
                            this.setState({ errorMessages: errorMessages });
                        }}
                    >
                        <Form.Group>
                            <Form.ControlLabel>Dataset Name</Form.ControlLabel>
                            <Form.Control
                                name="name"
                                errorMessage={this.state.errorMessages.name}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            onClick={this.handleSubmit}
                            appearance="primary"
                            type="submit"
                            loading={this.state.loading}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            appearance="subtle"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddClientModal)
);
