/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:58:17
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:17:13
 */

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/validations";

const initialState = {
    userDetails: null,
    loading: false,
    isUserAuthorized: false,
    assignedProjects: [
        { _id: "fjalfjl", projectName: "P1" },
        { _id: "fjlafj", projectName: "P2" }
    ]
};

const startUserSession = (state, action) => {
    return updateObject(state, {
        userDetails: action.userDetails,
        assignedProjects: action.assignedProjects,
        loading: false,
        isUserAuthorized: true
    });
};

const endUserSession = (state, action) => {
    return updateObject(state, {
        userDetails: null,
        assignedProjects: null,
        isUserAuthorized: false
    });
};

const updateAssignedProjects = (state, action) => {
    return updateObject(state, {
        assignedProjects: action.assignedProjects
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_USER_SESSION:
            return startUserSession(state, action);
        case actionTypes.END_USER_SESSION:
            return endUserSession(state, action);
        case actionTypes.UPDATE_ASSIGNED_PROJECTS:
            return updateAssignedProjects(state, action);
        default:
            return state;
    }
};

export default reducer;
