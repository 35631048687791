/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-02-03 16:30:57
 */

import React, { Component } from "react";
import { Button, Progress } from "rsuite";
import { connect } from "react-redux";
import { refreshToken } from "../../shared/auth";
import * as actions from "../../store/actions/auth";
import { withRouter } from "react-router-dom";
import history from "../../shared/history";
import { cancelAnalysis, getAnalysis } from "../../services/analysis";

class AnalysisProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelTrainingLoading: false
        };

        this.pathParts = props.location.pathname.split("/");
        this.homeURL = "/" + this.pathParts[1] + "/" + this.pathParts[2];

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);
        this.refreshAnalysisInterval = setInterval(async () => {
            let analysis = await getAnalysis(
                this.props.endUserSession,
                this.homeURL,
                this.props.analysis._id
            );

            if (analysis === null) {
                return;
            }

            this.props.onAnalysisChange(analysis);
        }, 500);
    }

    componentWillUnmount() {
        clearInterval(this.refreshAnalysisInterval);
        this.refreshAnalysisInterval = null;
    }

    handleBackClick() {
        history.goBack();
    }

    async handleCancelClick() {
        this.setState({ cancelTrainingLoading: true });

        let analysis = await cancelAnalysis(
            this.props.endUserSession,
            this.homeURL,
            this.props.analysis._id
        );

        if (analysis === null) {
            this.setState({ cancelTrainingLoading: false });
            return;
        }

        this.props.onAnalysisChange(analysis);
    }

    render() {
        let progressStatus;
        if (this.props.analysis.status === "progressError") {
            progressStatus = "fail";
        } else {
            progressStatus = "active";
        }

        return (
            <div
                style={{
                    padding: "20px",
                    height: "100%",
                    backgroundColor: "rgb(236, 236, 236)"
                }}
            >
                <Progress.Line
                    percent={this.props.analysis.progress}
                    status={progressStatus}
                />
                <div
                    style={{
                        padding: "10px",
                        backgroundColor: "white",
                        height: "calc(100% - 132px)",
                        borderRadius: "10px",
                        whiteSpace: "pre",
                        overflow: "auto"
                    }}
                >
                    <div>{this.props.analysis.output}</div>
                    <div style={{ color: "rgb(244, 67, 54)" }}>
                        {this.props.analysis.errorOutput}
                    </div>
                </div>
                <div style={{ float: "right", marginTop: "20px" }}>
                    <Button
                        appearance={"primary"}
                        onClick={this.handleCancelClick}
                        loading={this.state.cancelTrainingLoading}
                    >
                        Cancel Analysis
                    </Button>
                    <Button
                        style={{ marginLeft: "10px" }}
                        onClick={this.handleBackClick}
                    >
                        Back
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AnalysisProgress)
);
