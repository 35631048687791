/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-10-20 23:58:28
 * @Last Modified by: Pradeep Chandra
 * @Last Modified time: 2022-07-18 17:17:23
 */

import * as actionTypes from "./actionTypes";

export const startUserSession = (userDetails, assignedProjects = []) => {
    return {
        type: actionTypes.START_USER_SESSION,
        userDetails: userDetails,
        assignedProjects: assignedProjects
    };
};

export const endUserSession = () => {
    return {
        type: actionTypes.END_USER_SESSION
    };
};

export const updateAssignedProjects = (assignedProjects) => {
    return {
        type: actionTypes.UPDATE_ASSIGNED_PROJECTS,
        assignedProjects: assignedProjects
    };
};
