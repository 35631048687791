/**
 * @copyright: 2020 NTWIST.
 * @Author: NTWIST
 * @Date: 2020-11-23 20:51:46
 * @Last Modified by: Malcolm MacArthur
 * @Last Modified time: 2022-02-03 16:31:00
 */

import React, { Component } from "react";

import "./analysisReport.css";
import {
    Button,
    PanelGroup,
    Panel,
    Tooltip,
    IconButton,
    Whisper
} from "rsuite";
import LegacyEyeIcon from "@rsuite/icons/legacy/Eye";
import { connect } from "react-redux";
import { refreshToken } from "../../shared/auth";
import * as actions from "../../store/actions/auth";
import { withRouter } from "react-router-dom";
import history from "../../shared/history";
import DeleteAnalysisModal from "../../components/modals/nDatum/deleteAnalysisModal";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { getDataset } from "../../services/dataset";
import { AgGridReact } from "ag-grid-react";
const Plot = createPlotlyComponent(Plotly);

class AnalysisReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataset: null,
            showingDeleteAnalysisModel: false,
            rowAvailabilityTraces: [
                {
                    x: [],
                    y: [],
                    type: "scatter",
                    mode: "lines+markers",
                    fill: "tozeroy",
                    fillcolor: "#2c1c94",
                    marker: { color: "#2c1c94" }
                }
            ],
            dataAvailabilityTraces: [
                {
                    x: [],
                    y: [],
                    type: "bar",
                    marker: { color: "#2c1c94" }
                }
            ],
            cumulativeAvailabilityTraces: [
                {
                    x: [],
                    y: [],
                    type: "bar",
                    marker: { color: "#2c1c94" }
                }
            ],
            catUniqueRows: [],
            catUniqueColumnDefs: [],
            l1HistogramTraces: []
        };

        this.pathParts = props.location.pathname.split("/");
        this.homeURL = "/" + this.pathParts[1] + "/" + this.pathParts[2];

        this.defaultColDef = {
            resizable: true,
            sortable: true,
            filter: true
        };

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteAnalysisModelClose =
            this.handleDeleteAnalysisModelClose.bind(this);
        this.handleDeleteAnalysisModalSubmit =
            this.handleDeleteAnalysisModalSubmit.bind(this);
        this.handleRestartClick = this.handleRestartClick.bind(this);

        this.handleGridReady = this.handleGridReady.bind(this);
        this.handleGridRowDataChanged =
            this.handleGridRowDataChanged.bind(this);
    }

    async componentDidMount() {
        await refreshToken(this.props.endUserSession);

        let dataset = await getDataset(
            this.props.endUserSession,
            this.homeURL,
            this.props.analysis.datasetID
        );

        if (this.props.analysis.algorithm === "Data Quality") {
            let rowAvailabilityTraces = JSON.parse(
                JSON.stringify(this.state.rowAvailabilityTraces)
            );
            let dataAvailabilityTraces = JSON.parse(
                JSON.stringify(this.state.dataAvailabilityTraces)
            );

            for (let index in this.props.analysis.result.rowAvailability) {
                rowAvailabilityTraces[0].x.push(
                    this.props.analysis.result.rowAvailability[index][
                        "#_NaNs_tolerable"
                    ]
                );
                rowAvailabilityTraces[0].y.push(
                    this.props.analysis.result.rowAvailability[index][
                        "%_rows_remaining"
                    ]
                );
            }

            for (let index in this.props.analysis.result.dataAvailability) {
                dataAvailabilityTraces[0].x.push(
                    this.props.analysis.result.dataAvailability[index]["tag"]
                );
                dataAvailabilityTraces[0].y.push(
                    this.props.analysis.result.dataAvailability[index]["%_data"]
                );
            }

            let catUniqueColumnDefs = [];
            if (this.props.analysis.result.catUniques.length > 0) {
                for (let columnHeader in this.props.analysis.result
                    .catUniques[0]) {
                    catUniqueColumnDefs.push({ field: columnHeader });
                }
            }

            this.setState({
                dataset: dataset,
                rowAvailabilityTraces: rowAvailabilityTraces,
                dataAvailabilityTraces: dataAvailabilityTraces,
                catUniqueRows: this.props.analysis.result.catUniques,
                catUniqueColumnDefs: catUniqueColumnDefs
            });
        } else if (this.props.analysis.algorithm === "L1") {
            this.setState({
                dataset: dataset
            });
        } else {
            this.setState({
                dataset: dataset
            });
        }
    }

    handleDeleteClick() {
        this.setState({ showingDeleteAnalysisModel: true });
    }

    handleBackClick() {
        history.goBack();
    }

    handleDeleteAnalysisModelClose() {
        this.setState({ showingDeleteAnalysisModel: false });
    }

    handleDeleteAnalysisModalSubmit() {
        history.replace(this.homeURL);
    }

    async handleRestartClick() {
        let analysis = JSON.parse(JSON.stringify(this.props.analysis));
        analysis.status = "configuration";
        this.props.onAnalysisChange(analysis, true);
    }

    handleGridReady(params) {
        this.gridAPI = params.api;
        this.gridColumnAPI = params.columnApi;
    }

    handleGridRowDataChanged() {
        if (
            this.gridAPI === undefined ||
            this.gridColumnAPI === undefined ||
            this.state.catUniqueRows.length === 0
        ) {
            return;
        }

        this.gridAPI.sizeColumnsToFit();
        this.gridColumnAPI.autoSizeAllColumns();
    }

    renderDataQualityCards() {
        let cards = [];
        cards.push(
            <div className="card" key={"rowAvailability"}>
                <div className="title">Row Availability vs NaN Tolerance </div>
                <Plot
                    useResizeHandler
                    data={this.state.rowAvailabilityTraces}
                    layout={{
                        margin: { r: 20, t: 20 },
                        responsive: true,
                        uirevision: true,
                        plot_bgcolor: "rgba(0,0,0,0)",
                        paper_bgcolor: "rgba(0,0,0,0)",
                        modebar: {
                            bgcolor: "rgba(0,0,0,0)",
                            color: "black",
                            activecolor: "#2c1c94"
                        },
                        yaxis: { title: "Rows Available (%)" },
                        xaxis: { title: "# of NaN Tolerable" }
                    }}
                    config={{
                        displaylogo: false
                    }}
                    style={{
                        width: "100%",
                        height: "400px"
                    }}
                />
            </div>
        );

        cards.push(
            <div className="card" key={"columnAvailability"}>
                <div className="title">Data Availability</div>
                <Plot
                    useResizeHandler
                    data={this.state.dataAvailabilityTraces}
                    layout={{
                        margin: { l: 75, r: 40, t: 20, b: 100 },
                        responsive: true,
                        uirevision: true,
                        plot_bgcolor: "rgba(0,0,0,0)",
                        paper_bgcolor: "rgba(0,0,0,0)",
                        modebar: {
                            bgcolor: "rgba(0,0,0,0)",
                            color: "black",
                            activecolor: "#2c1c94"
                        },
                        yaxis: { title: "Tag Data Available (%)" },
                        xaxis: { title: "Tag" }
                    }}
                    config={{
                        displaylogo: false
                    }}
                    style={{
                        width: "100%",
                        height: "400px"
                    }}
                />
            </div>
        );

        cards.push(
            <div className="card" key={"catUniques"}>
                <div className="title">Cat Uniques</div>
                <div
                    className="test ag-theme-alpine"
                    style={{
                        marginTop: "10px",
                        height: "calc(100% - 45.703px)"
                    }}
                >
                    <AgGridReact
                        rowData={this.state.catUniqueRows}
                        columnDefs={this.state.catUniqueColumnDefs}
                        defaultColDef={this.defaultColDef}
                        frameworkComponents={this.frameworkComponents}
                        multiSortKey={"ctrl"}
                        paginationAutoPageSize
                        pagination
                        rowDragManaged // Allow drag and drop
                        animateRows // Allow animation when dragging
                        onGridReady={this.handleGridReady}
                        onRowDataChanged={this.handleGridRowDataChanged}
                    />
                </div>
            </div>
        );

        return cards;
    }

    renderL1Cards() {
        let cards = [];

        for (let column in this.props.analysis.result.histogram) {
            let quartiles =
                this.props.analysis.result.histogram[column].quartiles;

            let shapes = [];
            let annotations = [];
            for (let key in quartiles) {
                let height = (50 - Math.abs(50 - parseInt(key))) / 50;

                shapes.push({
                    yref: "paper",
                    type: "line",
                    x0: quartiles[key],
                    y0: 0,
                    x1: quartiles[key],
                    y1: height * 0.4,
                    line: {
                        color: "red",
                        width: 3,
                        dash: "dot"
                    },
                    hovertext: quartiles[key]
                });
                annotations.push({
                    yref: "paper",
                    x: quartiles[key],
                    y: height * 0.4,
                    text: key + " th",
                    showarrow: false,
                    bgcolor: "rgba(255, 255, 255, 0.5)",
                    font: {
                        fontWeight: "bold",
                        color: "red"
                    },
                    hovertext: quartiles[key],
                    hoverlabel: { bgcolor: "#2c1c94" }
                });
            }

            cards.push(
                <div className="card" key={column}>
                    <div className="title">{column} Frequency</div>
                    <Plot
                        useResizeHandler
                        data={[
                            {
                                x: this.props.analysis.result.histogram[column]
                                    .bins,
                                y: this.props.analysis.result.histogram[column]
                                    .counts,
                                type: "bar",
                                marker: { color: "#2c1c94" }
                            }
                        ]}
                        layout={{
                            margin: { r: 20, t: 20 },
                            responsive: true,
                            uirevision: true,
                            plot_bgcolor: "rgba(0,0,0,0)",
                            paper_bgcolor: "rgba(0,0,0,0)",
                            modebar: {
                                bgcolor: "rgba(0,0,0,0)",
                                color: "black",
                                activecolor: "#2c1c94"
                            },
                            yaxis: { title: "Count" },
                            xaxis: { title: column },
                            bargap: 0.0,
                            shapes: shapes,
                            annotations: annotations
                        }}
                        config={{
                            displaylogo: false
                        }}
                        style={{
                            width: "100%",
                            height: "400px"
                        }}
                    />
                </div>
            );
        }

        return cards;
    }

    render() {
        let datasetName = "";
        if (this.state.dataset !== null) {
            datasetName = this.state.dataset.name;
        }

        let options = [];
        for (let firstKey in this.props.analysis.options) {
            for (let secondKey in this.props.analysis.options[firstKey]) {
                let title = secondKey.replace(/([A-Z]{1,})/g, " $1");
                title = title.charAt(0).toUpperCase() + title.slice(1);
                options.push(
                    <div key={firstKey + secondKey}>
                        {title +
                            ": " +
                            this.props.analysis.options[firstKey][secondKey]}
                    </div>
                );
            }
        }

        let cards = null;
        if (this.props.analysis.algorithm === "Data Quality") {
            cards = this.renderDataQualityCards();
        } else {
            cards = this.renderL1Cards();
        }

        return (
            <div id={"analysis-report"}>
                <div className="grid">
                    <div className="card">
                        <div className="title">Analysis Details</div>
                        <div
                            style={{ marginLeft: "20px", marginRight: "20px" }}
                        >
                            <div style={{ marginTop: "20px" }}>
                                Algorithm: {this.props.analysis.algorithm}
                            </div>
                            <div style={{ display: "inline-block" }}>
                                Dataset Name: {datasetName}
                            </div>
                            <Whisper
                                placement="bottom"
                                speaker={<Tooltip>View more details</Tooltip>}
                                delay={1000}
                                style={{ display: "inline-block" }}
                            >
                                <IconButton
                                    circle
                                    icon={<LegacyEyeIcon />}
                                    appearance="subtle"
                                    onClick={() => {
                                        history.push({
                                            pathname:
                                                this.homeURL +
                                                "/datasets/" +
                                                this.state.dataset._id,
                                            state: {
                                                dataset: this.state.dataset
                                            }
                                        });
                                    }}
                                />
                            </Whisper>
                            <div
                                style={{
                                    whiteSpace: "pre",
                                    marginBottom: "20px"
                                }}
                            >
                                {options}
                            </div>
                        </div>
                    </div>

                    {cards}
                </div>
                <PanelGroup
                    accordion
                    style={{
                        width: "calc(100% - 40px)",
                        maxWidth: "1920px",
                        backgroundColor: "white",
                        marginLeft: "20px",
                        marginRight: "20px"
                    }}
                >
                    <Panel header="Analysis Progress Log">
                        <div
                            style={{
                                whiteSpace: "pre",
                                overflow: "auto"
                            }}
                        >
                            {this.props.analysis.output}
                        </div>
                    </Panel>
                    <Panel header="Raw Output">
                        <div
                            style={{
                                whiteSpace: "pre"
                            }}
                        >
                            {JSON.stringify(
                                this.props.analysis.result,
                                null,
                                4
                            )}
                        </div>
                    </Panel>
                </PanelGroup>

                <div
                    style={{
                        width: "calc(100% - 40px)",
                        maxWidth: "1920px",
                        marginTop: "20px",
                        marginBottom: "20px"
                    }}
                >
                    <div style={{ float: "right" }}>
                        <Button
                            appearance={"primary"}
                            onClick={this.handleRestartClick}
                        >
                            Restart
                        </Button>
                        <Button
                            appearance={"primary"}
                            onClick={this.handleDeleteClick}
                            style={{ marginLeft: "10px" }}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={this.handleBackClick}
                            style={{ marginLeft: "10px" }}
                        >
                            Back
                        </Button>
                    </div>
                </div>
                <DeleteAnalysisModal
                    open={this.state.showingDeleteAnalysisModel}
                    onClose={this.handleDeleteAnalysisModelClose}
                    onSubmit={this.handleDeleteAnalysisModalSubmit}
                    analysis={this.props.analysis}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUserAuthenticated: state.auth.isUserAuthorized,
        userDetails: state.auth.userDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        endUserSession: () => dispatch(actions.endUserSession())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AnalysisReport)
);
